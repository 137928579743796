import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormDatePicker } from "@components/FormDatePicker";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { organizationAtom } from "@recoil/auth";
import {
  useAdminSeasonSeasonIdGet,
  useAdminSeasonSeasonIdPatch,
  useAdminSportGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalizeEveryWord } from "@utils/capitalize";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

export const SeasonEdit = () => {
  const navigate = useNavigate();
  const { seasonId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const organizationId = useRecoilValue(organizationAtom);

  const {
    data: season,
    isFetching: isSeasonFetching,
    error: error
  } = useAdminSeasonSeasonIdGet(seasonId as string);
  useEffect(() => {
    if (error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
  }, [error]);
  const { data: sports, isLoading: isSportLoading } = useAdminSportGet({
    organizationId: organizationId!
  });
  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name!,
        value: sport.sportId!
      })) || [],
    [sports]
  );
  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid, isDirty },
    reset
  } = useForm({
    mode: "onTouched"
  });

  useEffect(() => {
    if (!isSportLoading && !isSeasonFetching) {
      reset(
        {
          name: season?.data?.name,
          organizationId: organizationId,
          sportId: season?.data?.sportId,
          startDate: season?.data?.startDate
            ? new Date(season?.data?.startDate)
            : "",
          endDate: season?.data?.endDate ? new Date(season?.data?.endDate) : ""
        },
        {
          keepDirtyValues: true
        }
      );
    }
  }, [season, sportOptions]);

  const { mutate: save, isLoading: isSaving } = useAdminSeasonSeasonIdPatch();
  const saveHandler = (formValues) => {
    const values = {
      ...formValues
    };

    save(
      {
        seasonId: seasonId as string,
        data: values
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Season saved successfully!", {
            variant: "success"
          });
          navigate(`/seasons/${seasonId}`);
        },
        onError: () => {
          enqueueSnackbar("Failed to save Season!", { variant: "error" });
        }
      }
    );
  };

  return (
    <Container>
      <Toolbar title="Edit Season" />
      <Form>
        <Loader isLoading={isSeasonFetching}>
          <Grid container spacing={3}>
            <Grid xs={12} md={6} data-testid="EDIT_SEASON_NAME">
              <FormInput
                control={control}
                name="name"
                type="text"
                label="Name"
                required={true}
                onChange={(e) => {
                  setValue("name", capitalizeEveryWord(e.target.value));
                }}
                rules={{
                  required: "Name is required"
                }}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="SEASON_EDIT_SPORT">
              <FormSelect
                control={control}
                name="sportId"
                label="Sport"
                required={true}
                isLoading={isSportLoading}
                disabled={sportOptions.length === 1}
                options={sportOptions}
                rules={{
                  required: "Sport is required"
                }}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="SEASON_EDIT_START_DATE">
              <FormDatePicker
                control={control}
                name="startDate"
                label="Start Date"
                required={true}
                rules={{
                  required: "Start Date is required"
                }}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="SEASON_EDIT_END_DATE">
              <FormDatePicker
                control={control}
                name="endDate"
                label="End Date"
                required={true}
                rules={{
                  required: "End Date is required"
                }}
              />
            </Grid>
          </Grid>
        </Loader>
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler)}
        isDisabled={!isValid || isSaving || !isDirty}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/seasons")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
