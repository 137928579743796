import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormInput } from "@components/FormInput";
import { FormSwitch } from "@components/FormSwitch";
import Spacer from "@components/Spacer";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import { FormLabel, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";
import {
  ModelPermission,
  PermissionAction,
  useAdminPermissionGet,
  useAdminRoleRoleIdGet,
  useAdminRoleRoleIdPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "../../recoil/auth";
import { capitalize } from "@utils/capitalize";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));
const StyledDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  backgroundColor: "#F4F6F9",
  padding: "8px 20px 8px 0",
  minWidth: 600,
  width: "65%",
  [theme.breakpoints.down("xl")]: {
    width: "100%"
  }
}));
export const RoleEdit = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { roleId } = useParams();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const organizationId = useRecoilValue(organizationAtom);
  const [disabledCheckBox, setDisabledCheckBox] = useState<string[]>([]);
  const [permanentDisabledCheckBox, setPermanentDisabledCheckBox] = useState<
    string[]
  >([]);
  const [permanentCheckedCheckBox, setPermanentCheckedCheckBox] = useState<
    string[]
  >([]);

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
    setValue,
    getValues,
    reset,
    watch
  } = useForm({
    mode: "onTouched"
  });

  const roleType = watch("type");

  const renderChildren = (
    parentId,
    children,
    control,
    setValue,
    getValues,
    indentLevel = 1
  ) => {
    return (
      <>
        {children
          .filter((c) =>
            role?.permissions?.some((rp) => rp.permissionId === c.permissionId)
          )
          .map((child) => {
            const key = child.permissionId.replace(/\./g, "__");
            const override = role?.permissions
              ?.find((rp) => rp.permissionId === child.permissionId)
              ?.permission?.roleTypeAliasPermissions?.find(
                (rtap) =>
                  rtap.roleAlias === role?.alias && rtap.roleType === role.type
              );
            if (override) {
              child.name = override.nameOverride;
              child.actions = override.allowActions;
            }
            return (
              <div data-testid={child.permissionId} key={key}>
                <StyledDiv>
                  <Typography
                    variant="permissionNames"
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: 300,
                      paddingLeft: `${indentLevel * 20}px`
                    }}
                  >
                    {child.name}
                  </Typography>

                  <div
                    style={{ display: "flex", flexDirection: "row", flex: 1 }}
                  >
                    {child.type === "MULTI" &&
                      ["ALL", "VIEW", "ADD", "EDIT", "DELETE"].map((action) => {
                        if (child.actions.includes(action)) {
                          return (
                            <FormCheckbox
                              key={`${key}__${action}`}
                              control={control}
                              name={`${key}__${action}`}
                              label={action[0] + action.slice(1).toLowerCase()}
                              labelPadding="4px 0"
                              labelTypographyProps={{
                                variant: "body1",
                                marginLeft: "8px"
                              }}
                              disabled={
                                disabledCheckBox.includes(
                                  `${key}__${action}`
                                ) ||
                                (action === "VIEW" &&
                                  (getValues(`${key}__ADD`) == true ||
                                    getValues(`${key}__EDIT`) == true ||
                                    getValues(`${key}__DELETE`) == true)) ||
                                permanentDisabledCheckBox.includes(
                                  `${key}__${action}`
                                )
                              }
                              onChange={(e) => {
                                const checked = e.target.checked;
                                if (action === "ALL") {
                                  child.actions.map((action) => {
                                    if (
                                      !permanentCheckedCheckBox.includes(
                                        `${key}__${action}`
                                      )
                                    )
                                      setValue(`${key}__${action}`, checked);
                                  });
                                } else if (action === "VIEW") {
                                  if (!checked) {
                                    child.actions.map((action) => {
                                      if (
                                        ["ADD", "EDIT", "DELETE"].includes(
                                          action
                                        )
                                      ) {
                                        setValue(`${key}__${action}`, checked);
                                      }
                                    });
                                  }
                                } else if (["DELETE"].includes(action)) {
                                  if (checked) {
                                    setValue(`${key}__VIEW`, checked);
                                  }
                                } else if (["EDIT"].includes(action)) {
                                  if (checked) {
                                    setValue(`${key}__VIEW`, checked);
                                    if (
                                      !control._fields[`${key}__ADD`] != true
                                    ) {
                                      setValue(`${key}__ADD`, checked);
                                    }
                                  }
                                } else if (["ADD"].includes(action)) {
                                  if (checked) {
                                    setValue(`${key}__VIEW`, checked);
                                    if (
                                      !control._fields[`${key}__EDIT`] != true
                                    ) {
                                      setValue(`${key}__EDIT`, checked);
                                    }
                                  }
                                }

                                const actionsLength = child.actions.length - 1;
                                let actionsChecked = 0;
                                child.actions.map((action) => {
                                  if (
                                    getValues(`${key}__${action}`) &&
                                    action !== "ALL"
                                  ) {
                                    actionsChecked++;
                                  }
                                });
                                setValue(
                                  `${key}__ALL`,
                                  actionsLength === actionsChecked
                                );
                                const permission = key.split("__")[0];
                                const type = key.split("__")[1];
                                const permissionType = type.includes("-")
                                  ? type.split("-").pop().trim()
                                  : type.trim();
                                if (
                                  key == "team__teams" ||
                                  key == "training-programs__training-programs"
                                ) {
                                  setValue(
                                    `${permission}__associated-${permissionType}__ALL`,
                                    getValues(`${permission}__${type}__ALL`)
                                  );
                                  setValue(
                                    `${permission}__associated-${permissionType}__VIEW`,
                                    true
                                  );
                                  setValue(
                                    `${permission}__associated-${permissionType}__EDIT`,
                                    getValues(`${permission}__${type}__EDIT`)
                                  );
                                  setValue(
                                    `${permission}__associated-${permissionType}__DELETE`,
                                    getValues(`${permission}__${type}__DELETE`)
                                  );
                                  getValues(`${permission}__${type}__ALL`) ==
                                  true
                                    ? setDisabledCheckBox((prev) => [
                                        ...prev,
                                        `${permission}__associated-${permissionType}__ALL`
                                      ])
                                    : setDisabledCheckBox((prev) =>
                                        prev.filter(
                                          (item) =>
                                            item !==
                                            `${permission}__associated-${permissionType}__ALL`
                                        )
                                      );

                                  getValues(`${permission}__${type}__EDIT`) ==
                                  true
                                    ? setDisabledCheckBox((prev) => [
                                        ...prev,
                                        `${permission}__associated-${permissionType}__EDIT`
                                      ])
                                    : setDisabledCheckBox((prev) =>
                                        prev.filter(
                                          (item) =>
                                            item !==
                                            `${permission}__associated-${permissionType}__EDIT`
                                        )
                                      );
                                  getValues(`${permission}__${type}__DELETE`) ==
                                  true
                                    ? setDisabledCheckBox((prev) => [
                                        ...prev,
                                        `${permission}__associated-${permissionType}__DELETE`
                                      ])
                                    : setDisabledCheckBox((prev) =>
                                        prev.filter(
                                          (item) =>
                                            item !==
                                            `${permission}__associated-${permissionType}__DELETE`
                                        )
                                      );
                                }
                                if (
                                  getValues(`${key}__ADD`) === true ||
                                  getValues(`${key}__EDIT`) === true ||
                                  getValues(`${key}__DELETE`) === true
                                ) {
                                  setDisabledCheckBox((prev) => [
                                    ...prev,
                                    `${key}__VIEW`
                                  ]);
                                }
                                if (
                                  (getValues(`${key}__ADD`) == false ||
                                    getValues(`${key}__ADD`) == undefined) &&
                                  (getValues(`${key}__EDIT`) == false ||
                                    getValues(`${key}__EDIT`) == undefined) &&
                                  (getValues(`${key}__DELETE`) == false ||
                                    getValues(`${key}__DELETE`) == undefined)
                                ) {
                                  setDisabledCheckBox((prev) =>
                                    prev.filter(
                                      (item) => item !== `${key}__VIEW`
                                    )
                                  );
                                }
                                if (key == "general__calendar") {
                                  if (getValues(`${key}__VIEW`)) {
                                    setDisabledCheckBox((prev) =>
                                      prev.filter(
                                        (item) =>
                                          item !== "general__calendar-import"
                                      )
                                    );
                                  } else {
                                    setValue("general__calendar-import", false);
                                    setDisabledCheckBox((prev) => [
                                      ...prev,
                                      `general__calendar-import`
                                    ]);
                                  }
                                }
                              }}
                            />
                          );
                        } else {
                          return (
                            <div
                              key={`${key}__${action}`}
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                width: "100%"
                              }}
                            />
                          );
                        }
                      })}
                    {child.type === "BOOL" && child.actions.includes("ON") && (
                      <FormSwitch
                        control={control}
                        name={`${key}`}
                        disabled={
                          disabledCheckBox.includes(`${key}`) ||
                          (getValues(`team__all-social`) == true &&
                            (key == "team__associated-comment" ||
                              key == "team__associated-post" ||
                              key == "team__associated-share")) ||
                          (getValues(`training-programs__all-social`) == true &&
                            (key == "training-programs__associated-post" ||
                              key == "training-programs__associated-comment" ||
                              key == "training-programs__associated-share"))
                        }
                        onChange={(e) => {
                          const on = e.target.checked;
                          const permission = key.split("__")[0];
                          if (key == `${permission}__all-social`) {
                            setValue(`${permission}__associated-comment`, on);
                            setValue(`${permission}__associated-post`, on);
                            setValue(`${permission}__associated-share`, on);
                            if (
                              getValues(`${permission}__all-social`) == true
                            ) {
                              setDisabledCheckBox((prev) => [
                                ...prev,
                                `${permission}__associated-comment`,
                                `${permission}__associated-post`,
                                `${permission}__associated-share`
                              ]);
                            } else {
                              setDisabledCheckBox((prev) =>
                                prev.filter(
                                  (item) =>
                                    item !== `${permission}__associated-comment`
                                )
                              );
                              setDisabledCheckBox((prev) =>
                                prev.filter(
                                  (item) =>
                                    item !== `${permission}__associated-post`
                                )
                              );
                              setDisabledCheckBox((prev) =>
                                prev.filter(
                                  (item) =>
                                    item !== `${permission}__associated-share`
                                )
                              );
                            }
                          }
                          if (key == `${permission}__associated-post`) {
                            setValue(`${permission}__associated-share`, on);
                            setValue(`${permission}__associated-comment`, on);
                          }
                        }}
                      />
                    )}
                  </div>
                </StyledDiv>

                {([
                  "general",
                  "user",
                  "admin",
                  "live-streaming",
                  "tech",
                  "hr",
                  "support"
                ].includes(parentId) ||
                  [
                    "crm.leads",
                    "crm.accounts-owner-edit",
                    "crm.contacts",
                    "crm.manager-feedback",
                    "crm.override-pricing",
                    ...(roleType === "Organization" || organizationId
                      ? ["crm.activities", "feed.canned-messaged"]
                      : [])
                  ].includes(child.permissionId as string)) && (
                  <Spacer
                    pb={8}
                    style={{ backgroundColor: "#FFF", maxWidth: 800 }}
                  />
                )}
                {child.children.length > 0 &&
                  renderChildren(
                    child.permissionId,
                    child.children,
                    control,
                    setValue,
                    getValues,
                    indentLevel + 1
                  )}
              </div>
            );
          })}
      </>
    );
  };

  const {
    data: roleRequest,
    isFetching: isRoleFetching,
    error: error
  } = useAdminRoleRoleIdGet(roleId as string, {});
  useEffect(() => {
    if (roleRequest && roleRequest.data.alias === "ADMIN")
      navigate("/not-found");
    if (error?.code === "ERR_BAD_REQUEST") navigate("/not-found");
  }, [error, roleRequest]);
  const { data: permissionsRequest, isFetching: isPermissionsFetching } =
    useAdminPermissionGet({
      organizationId
    });

  const role = useMemo(() => roleRequest?.data, [roleRequest]);
  const permissions = useMemo(
    () => permissionsRequest?.data || [],
    [permissionsRequest]
  );
  const rolePermissions = useMemo(() => {
    if (!permissions.length || !role) {
      return {};
    }
    return (
      role?.permissions?.reduce((acc, permission) => {
        const key = permission.permissionId?.replace(/\./g, "__");
        if (!key) return acc;
        switch (permission.permission?.type) {
          case "BOOL":
            acc[key] = permission.actions?.includes("ON");
            break;
          case "MULTI":
            permission.actions?.map((action) => {
              acc[key + "__" + action] = true;
            });
            break;
        }
        return acc;
      }, {}) || {}
    );
  }, [permissions, role]);

  useEffect(() => {
    if (role && rolePermissions) {
      reset(
        {
          name: role?.name || "",
          displayText: role?.displayText || "",
          description: role?.description || "",
          type: capitalize(role?.type as string) || "",
          ...rolePermissions
        },
        {
          keepDirty: true
        }
      );
    }
  }, [role, rolePermissions]);

  const { mutate: update, isLoading: isUpdateLoading } =
    useAdminRoleRoleIdPut();
  const saveHandler = (formValues) => {
    const permissions = Object.keys(formValues).reduce(
      (acc, key) => {
        if (["name", "type", "displayText", "description"].includes(key)) {
          return acc;
        }
        if (formValues[key]) {
          const parts = key.split("__");
          const action = parts[parts.length - 1];
          let permissionId = parts.join(".");
          if (["ALL", "VIEW", "ADD", "EDIT", "DELETE"].includes(action)) {
            permissionId = parts.slice(0, parts.length - 1).join(".");
            if (!acc[permissionId]) {
              acc[permissionId] = [];
            }
            acc[permissionId].push(action as PermissionAction);
          } else {
            acc[permissionId] = ["ON"];
          }
        } else {
          const parts = key.split("__");
          const action = parts[parts.length - 1];
          let permissionId = parts.join(".");
          if (["ALL", "VIEW", "ADD", "EDIT", "DELETE"].includes(action)) {
            permissionId = parts.slice(0, parts.length - 1).join(".");
            if (!acc[permissionId]) {
              acc[permissionId] = [];
            }
          } else {
            if (!acc[permissionId]) {
              acc[permissionId] = [];
            }
          }
        }
        return acc;
      },
      {} as { [key: string]: PermissionAction[] }
    );
    const data = {
      permissions: Object.keys(permissions).map((permissionId) => {
        return {
          permissionId,
          actions: permissions[permissionId]
        };
      }) as ModelPermission[]
    };
    if (formValues["displayText"])
      data["displayText"] = formValues["displayText"];

    if (formValues["description"])
      data["description"] = formValues["description"];

    update(
      {
        roleId: roleId as string,
        data: data
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Role updated successfully!", {
            variant: "success"
          });
          navigate(`/roles/${roleId}`);
        },
        onError: () => {
          enqueueSnackbar("Failed to update role!", { variant: "error" });
        }
      }
    );
  };

  useEffect(() => {
    if (role) {
      const permissions = role.permissions;
      if (
        permissions?.find((p) => p.permissionId == "general.calendar") &&
        permissions?.find((p) => p.permissionId == "general.calendar-import")
      ) {
        const calendarPer = permissions?.find(
          (p) => p.permissionId == "general.calendar"
        );
        if (!calendarPer?.actions?.includes("VIEW")) {
          setDisabledCheckBox((prev) => [...prev, `general__calendar-import`]);
        }
      }
      if (
        permissions?.find((p) => p.permissionId === "team.associated-teams")
      ) {
        if (role.type === "ORGANIZATION" || role.type === "PERSON") {
          setDisabledCheckBox((prev) => [
            ...prev,
            `team__associated-teams__VIEW`
          ]);
          setPermanentDisabledCheckBox((prev) => [
            ...prev,
            "team__associated-teams__VIEW"
          ]);
          setPermanentCheckedCheckBox((prev) => [
            ...prev,
            "team__associated-teams__VIEW"
          ]);
          setValue("team__associated-teams__VIEW", true);
        }
      }
      if (
        permissions?.find(
          (p) => p.permissionId === "training-programs.associated-programs"
        )
      ) {
        if (role.type === "ORGANIZATION" || role.type === "PERSON") {
          setDisabledCheckBox((prev) => [
            ...prev,
            `training-programs__associated-programs__VIEW`
          ]);
          setPermanentDisabledCheckBox((prev) => [
            ...prev,
            "training-programs__associated-programs__VIEW"
          ]);
          setPermanentCheckedCheckBox((prev) => [
            ...prev,
            "training-programs__associated-programs__VIEW"
          ]);
          setValue("training-programs__associated-programs__VIEW", true);
        }
      }
      if (permissions?.find((p) => p.permissionId === "general.dashboard")) {
        setPermanentDisabledCheckBox((prev) => [
          ...prev,
          "general__dashboard__VIEW"
        ]);
        setPermanentCheckedCheckBox((prev) => [
          ...prev,
          "general__dashboard__VIEW"
        ]);
        setValue("general__dashboard__VIEW", true);
      }
    }
  }, [role]);

  return (
    <Container>
      <Toolbar title="Edit Role &amp; Permissions" />
      <Form>
        <Loader isLoading={isRoleFetching || isPermissionsFetching}>
          <Grid container spacing={2}>
            <Grid xs={12} md={6} data-testid="role-name">
              <FormInput
                control={control}
                name="name"
                type="text"
                label="Name"
                required={true}
                disabled={true}
                InputProps={{
                  placeholder: role?.name
                }}
              />
            </Grid>
            {!organizationId && (
              <Grid xs={12} md={6} data-testid="role-type">
                <FormInput
                  control={control}
                  name="type"
                  type="text"
                  label="Role Type"
                  required={true}
                  disabled={true}
                />
              </Grid>
            )}
            {!organizationId && (
              <Grid xs={12} md={6} data-testid="role-displayText">
                <FormInput
                  control={control}
                  name="displayText"
                  type="text"
                  label="Display Text"
                  required={true}
                  rules={{
                    required: "Display Text is required"
                  }}
                />
              </Grid>
            )}
            <Grid xs={12} data-testid="role-description">
              <FormInput
                control={control}
                name="description"
                type="text"
                label="Description"
                required={true}
                multiline={true}
                rules={{
                  required: "Description is required"
                }}
                TextProps={{
                  rows: 2
                }}
              />
            </Grid>
            <Grid data-testid="permissions" xs={12}>
              <StyledFormLabel required={true}>
                <Typography display="inline" variant="formLabel">
                  Permissions
                </Typography>
              </StyledFormLabel>
              {permissions
                ?.filter((permission) =>
                  role?.permissions?.some(
                    (rp) => rp.permission?.parentId === permission.permissionId
                  )
                )
                .map((permission) => {
                  const key = permission.permissionId?.replace(/\./g, "__");
                  return (
                    <React.Fragment key={key}>
                      <Spacer mt={8} />
                      <Typography
                        data-testid={permission.permissionId}
                        sx={{
                          textTransform: "uppercase",
                          color: "#ccc",
                          fontWeight: "bold"
                        }}
                      >
                        {permission.name}
                      </Typography>
                      {renderChildren(
                        permission.permissionId,
                        permission.children,
                        control,
                        setValue,
                        getValues
                      )}
                    </React.Fragment>
                  );
                })}
            </Grid>
          </Grid>
        </Loader>
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler)}
        isDisabled={!isValid || isUpdateLoading || !isDirty}
        isLoading={isUpdateLoading}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/roles")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
