import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { ContactDetailsForm } from "./ContactDetailsForm";
import { Form } from "@components/crud/Form";
import { Footer } from "@components/crud/Footer";
import {
  useAdminCrmContactContactIdGet,
  useAdminCrmContactContactIdPut,
  useAdminCrmOrgContactContactIdGet,
  useAdminCrmOrgContactContactIdPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { cleanObject } from "@utils/cleanObject";
import { enqueueSnackbar } from "notistack";
import { hasPermission } from "@services/Casbin";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";

export const ContactEdit = () => {
  const navigate = useNavigate();
  const { contactId } = useParams();
  const organizationId = useRecoilValue(organizationAtom);
  const form = useForm({
    mode: "onTouched",
    shouldUnregister: true
  });

  const {
    reset,
    getValues,
    formState: { isValid }
  } = form;
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [relatedAccounts, setRelatedAccounts] = useState<
    {
      accountId: string;
      account: string;
      jobTitle: string;
      isPrimaryContact: boolean;
      workLocation?: string;
      workPhone?: string;
      workEmail?: string;
    }[]
  >([]);

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        organizationId ? "ORGANIZATION" : "SYSTEM",
        organizationId || "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const edit = await checkPermission("crm.contacts", "EDIT");
      if (!edit) navigate("/not-found");
    };
    fetchPermissions();
  }, []);

  const [homeLocationSameAs, setHomeLocationSameAs] = useState<string | null>(
    null
  );
  const [isHomeLocationSameAs, setIsHomeLocationSameAs] = useState(false);

  const [relatedLinks, setRelatedLinks] = useState<
    {
      url: string;
      name: string;
      otherName: string;
    }[]
  >([]);

  const { mutate: save, isLoading: isSaving } = organizationId
    ? useAdminCrmOrgContactContactIdPut()
    : useAdminCrmContactContactIdPut();
  const onSave = () => {
    const data = {
      firstName: getValues().firstName,
      middleName: getValues().middleName || "",
      lastName: getValues().lastName,
      suffix: getValues().suffix || "",
      location: getValues().homeLocation,
      phone: getValues().homePhone || "",
      whatsappNumber: getValues().homeWhatsappNumber || "",
      email: getValues().personalEmail || "",
      accounts: relatedAccounts.map((acc) => ({
        accountId: acc.accountId,
        jobTitle: acc.jobTitle,
        isPrimary: acc.isPrimaryContact,
        workPhone: acc.workPhone,
        workEmail: acc.workEmail,
        workPhoneType: acc.workPhoneType
      })),
      ...(organizationId && {
        organizationId: organizationId
      }),
      links: relatedLinks.map((rl) => ({
        type: rl.name,
        link: rl.url,
        altName: rl.otherName
      }))
    };
    save(
      {
        contactId: contactId!,
        data: cleanObject(data)
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Contact Edited successfully!", {
            variant: "success"
          });
          navigate("/crm/contacts");
        },
        onError: () => {
          enqueueSnackbar("Failed to Edit Contact!", {
            variant: "error"
          });
        }
      }
    );
  };

  const { data: contactDetails, isLoading: isLoading } = organizationId
    ? useAdminCrmOrgContactContactIdGet(contactId!)
    : useAdminCrmContactContactIdGet(contactId!);
  useEffect(() => {
    if (contactDetails?.data) {
      const contact = contactDetails.data;
      let locationSameAs: string | undefined;
      for (const acc of contact.accounts || []) {
        if (acc?.account?.officeAddress === contact.location) {
          locationSameAs = acc.accountId;
          break;
        }
      }
      const links =
        contact.links?.reduce((acc, link, idx) => {
          const key = `url${idx}`;
          acc[key] = link.link;
          if (link.altName) acc[`urlOtherName${idx}`] = link.altName;
          return acc;
        }, {}) || {};
      reset({
        firstName: contact.firstName,
        middleName: contact.middleName,
        lastName: contact.lastName,
        suffix: contact.suffix,
        homeLocation: contact.location,
        homePhone: contact.phone,
        homeWhatsappNumber: contact.whatsappNumber,
        personalEmail: contact.email,
        isHomeLocationSameAs: !!locationSameAs,
        ...links
      });
      setIsHomeLocationSameAs(!!locationSameAs);
      setHomeLocationSameAs(locationSameAs as string);
      setRelatedLinks(
        contact.links?.map((l) => ({
          url: l.link || "",
          name: l.type || "",
          otherName: l.altName || ""
        })) || []
      );
      setRelatedAccounts(
        contact.accounts?.map((acc) => ({
          accountId: acc.accountId!,
          account: acc?.account?.name,
          jobTitle: acc.jobTitle!,
          isPrimaryContact: !!acc.isPrimary,
          workLocation: acc?.account?.officeAddress,
          accountPhone: acc?.account?.phone,
          accountEmail: acc?.account?.email,
          workPhone: acc?.workPhone || "",
          workEmail: acc?.workEmail || ""
        })) || []
      );
    }
  }, [contactDetails]);
  return (
    <Container>
      <Toolbar title="Edit Contact" />
      <Loader isLoading={isLoading}>
        <Form>
          <ContactDetailsForm
            form={form}
            relatedAccounts={relatedAccounts}
            setRelatedAccounts={setRelatedAccounts}
            relatedLinks={relatedLinks}
            setRelatedLinks={setRelatedLinks}
            homeLocationSameAs={homeLocationSameAs}
            setHomeLocationSameAs={setHomeLocationSameAs}
            isHomeLocationSameAs={isHomeLocationSameAs}
            organizationId={organizationId}
            setIsHomeLocationSameAs={setIsHomeLocationSameAs}
            isEdit={true}
          />
        </Form>
      </Loader>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={() => {
          onSave();
        }}
        isDisabled={!isValid || isSaving}
        isLoading={isLoading || isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/crm/contacts")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
