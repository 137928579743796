import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { PaymentDetailsForm } from "@components/PaymentDetailsForm";
import Grid from "@mui/system/Unstable_Grid";
import { Form } from "@components/crud/Form";
export const PaymentDetailsCreate = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  return (
    <Container>
      <Toolbar
        title="Add Payment Details"
        backBtnClick={() => setOpenCancelDialog(true)}
        backBtnLabel="Cancel"
      />

      <Loader isLoading={false}>
        <Form>
          <Grid container xs={12} justifyContent="center">
            <PaymentDetailsForm
              txnType="TOKENIZATION"
              paymentFor="ADD_PAYMENT"
              details={{
                merchantId: import.meta.env.VITE_APP_MERCHANT_ID,
                orderId: orderId!
              }}
            />
          </Grid>
        </Form>
      </Loader>

      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate(`/crm/orders/${orderId}?tab=Payment+Details`)}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
