import { Backdrop, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Unstable_Grid2";
import colors from "theme/colors";
import { BugCardOverview } from "@pages/dashboard/Technology/QA/components/BugCardOverview";

const StyledBox = styled(Box)(() => ({
  backgroundColor: "#fff",
  borderRadius: "16px",
  maxWidth: "80vw"
}));

const StyledBoxHeader = styled(Box)({
  height: "64px",
  backgroundColor: colors.info.main,
  borderRadius: "16px 16px 0px 0px"
});

const StyledDiv = styled("div")`
  .bug-card-overview {
    margin: 24px !important;
    min-height: 300px;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0;
    width: calc(100% - 48px);
    min-width: 300px;
  }
`;

export const GanttChartBugOverview = ({ onClose }: { onClose: () => void }) => {
  return (
    <Backdrop
      sx={{
        overflow: "hidden",
        overflowY: "none",
        color: "#fff",
        zIndex: 1200
      }}
      open={true}
    >
      <StyledBox>
        <StyledBoxHeader>
          <Grid
            container
            style={{
              padding: "18px 20px 20px 32px",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Grid>
              <Typography
                style={{
                  fontSize: "16px",
                  color: "#fff",
                  fontStyle: "normal",
                  lineHeight: "20px",
                  fontWeight: "600"
                }}
              >
                Build Overview
              </Typography>
            </Grid>
            <Grid>
              <CloseIcon style={{ cursor: "pointer" }} onClick={onClose} />
            </Grid>
          </Grid>
        </StyledBoxHeader>
        <StyledDiv>
          <BugCardOverview />
        </StyledDiv>
      </StyledBox>
    </Backdrop>
  );
};
