export const formatNumber = (value: string | number | undefined) => {
  if (!value) return "";

  value = value.toString();
  const number = parseFloat(value.replace(/,/g, ""));
  if (isNaN(number)) {
    return "";
  }

  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    useGrouping: true
  }).format(number);
};
