import { Box } from "@mui/material";

export const HeaderUnderLine = (props: {
  marginLeft?: string;
  width?: string;
  marginTop?: string;
}) => {
  return (
    <Box
      style={{
        boxSizing: "border-box",
        width: props.width ? props.width : "98%",
        marginTop: props.marginTop ? props.marginTop : "8px",
        backgroundColor: "#2B337A",
        borderTop: `3px solid #2B337A`,
        height: "3px",
        marginLeft: props.marginLeft ? props.marginLeft : ""
      }}
    ></Box>
  );
};
