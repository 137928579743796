import { Grid } from "@mui/material";
import React from "react";
import { SubscriptionBillingSettings } from "./SubscriptionsBillingSettings";
import { LiveStreamingSetting } from "./LiveStreamingSetting";
import { AppVersion } from "./AppVersion";

export const MobileApp = ({ activeTab }: { activeTab: string }) => {
  return (
    <Grid container direction="column" spacing="10px">
      <Grid
        container
        direction="column"
        spacing={3}
        sx={{ marginTop: "1px", paddingLeft: "24px" }}
      >
        {activeTab === "Subscription & Billing" && (
          <SubscriptionBillingSettings />
        )}
        {activeTab === "Live Streaming" && <LiveStreamingSetting />}
        {activeTab === "App Version" && <AppVersion />}
      </Grid>
    </Grid>
  );
};
