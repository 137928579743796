import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import {
  lookupCountryGet,
  ModelRole,
  ModelSportsgravyUser,
  RoleAlias,
  RoleType,
  SGError,
  useAdminRoleGet,
  useAdminUserUserIdGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { UserDetailsForm } from "./UserDetailsForm";
import { useRecoilState } from "recoil";
import { organizationAtom } from "@recoil/auth";
import convertUTCDateToLocalDate from "@utils/convertUtcToLocal";
import { Footer } from "@components/crud/Footer";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { adminUserEdit, adminUserInvitePost } from "@services/Network";
import { enqueueSnackbar } from "notistack";
import { calculateAge } from "@utils/calculateAge";
import { UserAvailability } from "./UserAvailability";
import dayjs, { Dayjs } from "dayjs";
import {
  convertDateToTimeString,
  convertTimeToDate
} from "@utils/convertStringAndTime";
import { useQuery } from "@tanstack/react-query";
import { formatPhoneWithCountryCode } from "@utils/phoneFormatters";

export const UserEdit = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [organizationId] = useRecoilState(organizationAtom);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [fullDays, setFullDays] = useState<Dayjs[]>([]);
  const [halfDays, setHalfDays] = useState<Dayjs[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<
    { roleId: string | undefined }[]
  >([]);
  const [tab, setTab] = useState("User Details");

  const form = useForm({
    mode: "onTouched"
  });

  const {
    setValue,
    trigger,
    reset,
    getValues,
    formState: { isValid, isDirty }
  } = form;
  const [teamProgramRoles, setTeamProgramRoles] = useState<string[]>([]);
  const [showTPErrorModal, setShowTPErrorModal] = useState(false);

  const { data: countriesResponse, isLoading: isFetchingCountries } = useQuery({
    queryFn: lookupCountryGet,
    queryKey: ["countries"],
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24
  });
  const countries = useMemo(() => countriesResponse?.data, [countriesResponse]);

  const organizationOption = organizationId
    ? {
        organizationId: organizationId
      }
    : {};

  const {
    data: userResponse,
    isLoading: isFetchingUser,
    error: error
  } = useAdminUserUserIdGet(userId as string, organizationOption);
  useEffect(() => {
    if (error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
  }, [error]);
  const user = useMemo(() => userResponse?.data, [userResponse]);

  const { data: roleOptionsRequest, isLoading: isRoleOptionsLoading } =
    useAdminRoleGet({
      organizationId,
      type: organizationId ? RoleType.ORGANIZATION : undefined,
      pageSize: "100",
      includeChildren: true
    });

  const roleOptions = () => {
    if (!roleOptionsRequest?.data?.roles) {
      return [];
    }

    if (!organizationId) {
      const SGRoles = roleOptionsRequest.data.roles.filter(
        (role) => role.type === "SYSTEM"
      );
      return SGRoles.map((role) => {
        return {
          label: role.name as string,
          value: role.roleId as string,
          children: role.children,
          role: role
        };
      });
    } else {
      const FSORoles = roleOptionsRequest.data.roles.filter(
        (role) => role.type === "ORGANIZATION"
      );
      return FSORoles.map((role) => {
        return {
          label:
            (role.name as string) + (role.type === "SYSTEM" ? " (System)" : ""),
          value: role.roleId as string,
          children: role.children
            ? role.children.filter((child) => child.type === "ORGANIZATION")
            : [],
          role: role
        };
      });
    }
  };

  useEffect(() => {
    if (isFetchingCountries || isFetchingUser) return;

    const dialCode = countries?.find(
      (country) => country?.countryId === user?.country
    )?.dialCode;
    let roles = [] as { roleId: string | undefined }[];
    const options = roleOptions().map((option) => option.value);
    const rolesSelected =
      user?.user?.roles?.reduce((acc, role) => {
        if (
          (organizationId &&
            role.roleId &&
            (options.includes(role.roleId) ||
              options.includes(role.role?.parentId || ""))) ||
          (!organizationId &&
            role.roleId &&
            typeof role.roleId === "string" &&
            role.roleId.toLowerCase().startsWith("system")) ||
          (role.role &&
            typeof role.role.parentId === "string" &&
            role.role.parentId.toLowerCase().startsWith("system"))
        ) {
          const key = role.roleId?.replace(".", "_") + "_SELECTED";
          acc[key] = true;
          roles = [...roles, { roleId: role.roleId }];
        }
        return acc;
      }, {}) || {};

    const rolesWithTeamOrProgram =
      user?.user?.roles
        ?.filter((r) => {
          if (r.role?.type === "TEAM") {
            return true;
          }

          if (r.role?.type === "TRAINING_PROGRAM") {
            const program = r.role?.trainingProgram;

            if (program && program.dates && program.dates.length > 0) {
              const now = new Date();
              return program.dates.some((date) => {
                const endDate = new Date(date.endDate);
                const isRecurring = date.timings?.some(
                  (t) => Object.keys(t.customRepeat || {}).length > 0
                );

                // If recurring or if the end date is in the future, include the program
                if (isRecurring || endDate >= now) {
                  return true;
                }

                return false;
              });
            }
          }

          return false;
        })
        ?.map((r) => r.role?.inheritedFromId || "") || [];
    setTeamProgramRoles(rolesWithTeamOrProgram);

    const rolesInvited =
      user?.invites
        ?.filter((inv) => inv.invitedFor?.length || 0 > 0)
        ?.reduce((acc, inv) => {
          inv!.invitedFor!.map((role) => {
            if (!roles.find((r) => r.roleId === role.roleId)) {
              const key = role.roleId?.replace(".", "_") + "_SELECTED";
              acc[key] = true;
              if (organizationId && options.includes(role!.roleId!)) {
                const key = role!.roleId?.replace(".", "_") + "_SELECTED";
                acc[key] = true;
                roles = [...roles, { roleId: role!.roleId }];
              }
            }
          });

          return acc;
        }, {}) || {};

    setSelectedRoles(roles);
    const bannedAliases =
      user?.user?.roleAliasBans
        ?.filter((r) => !r.organizationId)
        ?.map((alias) => alias.alias)
        .filter(
          (alias) => alias && ["COACH", "MANAGER", "ADMIN"].includes(alias)
        ) || [];
    const bannedRoles =
      user?.user?.roleAliasBans?.filter(
        (r) => r.organizationId === organizationId
      ) || [];
    const banReason =
      !organizationId && bannedAliases.length > 0
        ? user!.user!.roleAliasBans!.filter((r) => !r.organizationId)?.[0]
            .reason || ""
        : bannedRoles.length > 0
          ? bannedRoles[0].reason
          : "";
    const bannedRolesAliases = !organizationId
      ? bannedAliases
      : user?.user?.roleAliasBans
          ?.filter((r) => r.organizationId === organizationId)
          ?.map((r) => r.alias) || [];

    const defaultValues = {
      firstName: user?.firstName,
      middleName: user?.middleName,
      lastName: user?.lastName,
      suffix: user?.suffix,
      email: user?.email,
      country: user?.addressPrimary?.country,
      dialCode,
      province: user?.addressPrimary?.province,
      locality: user?.addressPrimary?.locality,
      address1: user?.addressPrimary?.lines?.[0],
      address2: user?.addressPrimary?.lines?.[1],
      postalCode: user?.addressPrimary?.postalCode,
      phone: user?.phone,
      guardians: user?.guardians,
      birthedAt: user?.birthedAt
        ? convertUTCDateToLocalDate(new Date(user?.birthedAt))
        : "",
      roles: user?.user?.roles,
      isBanned: user?.user
        ? user.user.status === "ACCOUNT_LOCKED" || bannedRolesAliases.length > 0
        : false,
      bannedRoles: bannedRolesAliases,
      bannedRolesSG: bannedAliases,
      bannedReason: banReason,
      isUnderAge: user?.birthedAt ? calculateAge(user.birthedAt) < 18 : false,
      photoUrl: user?.avatar?.baseUrl
        ? user?.avatar?.baseUrl + user?.avatar?.path
        : undefined,
      sportsgravyUser: user?.sportsgravyUser
        ? {
            ...user.sportsgravyUser,
            sgNumber: formatPhoneWithCountryCode(
              user.sportsgravyUser.sgNumber as string
            ),
            hourlyRate: user.sportsgravyUser.rates?.[0]?.rate,
            startDate: user?.sportsgravyUser.startDate
              ? convertUTCDateToLocalDate(
                  new Date(user?.sportsgravyUser.startDate)
                )
              : "",
            startTime: user?.sportsgravyUser.startTime
              ? convertTimeToDate(user?.sportsgravyUser.startTime)
              : "",
            endTime: user?.sportsgravyUser.endTime
              ? convertTimeToDate(user?.sportsgravyUser.endTime)
              : ""
          }
        : undefined,
      sportsGravyUserAvgHoursPerWeek: user?.sportsgravyUser
        ? user.sportsgravyUser.avgHoursPerMonth
          ? user.sportsgravyUser.avgHoursPerMonth / 4
          : undefined
        : undefined,
      ...rolesSelected,
      ...rolesInvited
    };
    reset(defaultValues);
    if (user?.sportsgravyUser?.timeoff) {
      const fullDays = user?.sportsgravyUser.timeoff
        .filter((day) => day?.isFullDay)
        .map((day) => dayjs(day.date));
      const halfDays = user.sportsgravyUser.timeoff
        .filter((day) => !day?.isFullDay)
        .map((day) => dayjs(day.date));
      setFullDays(fullDays);
      setHalfDays(halfDays);
    }
  }, [countries, user]);

  const selectAddressSuggestion = (place) => {
    const addressComponents = place?.address_components || [];
    const streetNumber = addressComponents.find((c) =>
      c.types.includes("street_number")
    );
    const route = addressComponents.find((c) => c.types.includes("route"));
    const address1 = `${streetNumber?.long_name} ${route?.long_name}`;
    const subpremise = addressComponents.find((c) =>
      c.types.includes("subpremise")
    );
    const country = addressComponents.find((c) => c.types.includes("country"));
    const state = addressComponents.find((c) =>
      c.types.includes("administrative_area_level_1")
    );
    const city = addressComponents.find((c) => c.types.includes("locality"));
    const zip = addressComponents.find((c) => c.types.includes("postal_code"));

    setValue("address1", address1);
    if (subpremise) setValue("address2", subpremise?.long_name);
    setValue("country", country?.short_name);
    setValue("province", state?.short_name);
    setValue("locality", city?.long_name);
    setValue("postalCode", zip?.long_name);
    trigger("address1");
    trigger("country");
    trigger("province");
    trigger("locality");
    trigger("postalCode");
  };

  const onCancelClick = () => {
    setIsConfirmationDialogOpen(true);
  };

  const onConfirmCancel = () => {
    setIsConfirmationDialogOpen(false);
    navigate("/users");
  };

  const onCancelCancel = () => {
    setIsConfirmationDialogOpen(false);
  };

  const onChangeRolesSelected = (roleId: string, checked: boolean) => {
    const rolesSelected = selectedRoles.slice();

    const index = rolesSelected.findIndex((role) => role.roleId === roleId);
    if (checked) {
      if (index === -1) {
        rolesSelected.push({ roleId: roleId });
      }
    } else {
      rolesSelected.splice(index, 1);
    }
    setSelectedRoles(rolesSelected);
  };
  const { mutate, isLoading } = adminUserEdit();
  const { mutate: mutateinvite, isLoading: isLoadingInvite } =
    adminUserInvitePost();
  const onSaveClick = (formValues) => {
    if (user?.personId && user?.user?.roles?.length) {
      const data: {
        isUserBanned: boolean;
        roleAliases: never | RoleAlias[];
        roles: { roleId: string | undefined }[];
        reason: string;
        organizationId?: string;
        sportsgravyUser?: ModelSportsgravyUser;
      } = {
        isUserBanned: formValues.isBanned,
        roleAliases: formValues.isBanned
          ? organizationId
            ? formValues.bannedRoles
            : formValues.bannedRolesSG
          : [],
        roles: selectedRoles,
        reason: formValues.bannedReason
      };
      if (formValues.sportsgravyUser && !organizationId) {
        const sgUser = formValues.sportsgravyUser;
        if (!sgUser.whatsappNumber) delete sgUser.whatsappNumber;
        if (!sgUser.skypeId) delete sgUser.skypeId;
        data.sportsgravyUser = {
          ...sgUser,
          sgNumber: sgUser.sgNumber
            ? `+${sgUser.sgNumber.replace(/\D/g, "")}`
            : null,
          startTime: convertDateToTimeString(sgUser.startTime),
          endTime: convertDateToTimeString(sgUser.endTime),
          hourlyRate: parseFloat(sgUser.hourlyRate),
          yearsOfService: parseInt(sgUser.yearsOfService),
          avgHoursPerMonth: parseInt(sgUser.avgHoursPerMonth)
        };
      }
      if (organizationId) data.organizationId = organizationId;

      if (user.sportsgravyUser) {
        const timeOffAdded: Array<{ date: Date | string; isFullDay: boolean }> =
          [];

        halfDays.forEach((day) => {
          timeOffAdded.push({
            date: `${day.format("YYYY-MM-DD")}T00:00:00Z`,
            isFullDay: false
          });
        });
        fullDays.forEach((day) => {
          timeOffAdded.push({
            date: `${day.format("YYYY-MM-DD")}T00:00:00Z`,
            isFullDay: true
          });
        });
        let timeOffRemoved: { timeOffId: string }[] = [];
        if (user.sportsgravyUser?.timeoff) {
          timeOffRemoved = user.sportsgravyUser?.timeoff
            .filter((item2) => {
              const dateExists = timeOffAdded.some(
                (item1) => item1.date === item2.date
              );
              const isFulldayChanged = timeOffAdded.some(
                (item1) =>
                  item1.date === item2.date &&
                  item1.isFullDay !== item2.isFullDay
              );
              return !dateExists || isFulldayChanged;
            })
            .map((item) => ({ timeOffId: item.timeOffId as string }));
        }

        data["timeOffAdded"] = timeOffAdded;
        data["timeOffRemoved"] = timeOffRemoved;
      }
      mutate(
        {
          userId: user.personId,
          data: data
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Update Successful", {
              variant: "success"
            });
            reset();
            navigate("/users");
          },
          onError: (e: unknown) => {
            const error = e as { response: { data: SGError } };
            const errorCode = error?.response?.data?.code;
            enqueueSnackbar("Update Failed" + errorCode, {
              variant: "error"
            });
          }
        }
      );
    } else if (user?.personId) {
      const data: {
        roles: ModelRole[];
        organizationId?: string | undefined;
      } = {
        roles: selectedRoles
      };
      if (organizationId) data.organizationId = organizationId;
      mutateinvite(
        {
          userId: user.personId,
          data: data
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Update Successful", {
              variant: "success"
            });
            navigate("/users");
          },
          onError: (e: unknown) => {
            const error = e as { response: { data: SGError } };
            const errorCode = error?.response?.data?.code;
            enqueueSnackbar("Update Failed" + errorCode, {
              variant: "error"
            });
          }
        }
      );
    }
  };

  const [isBannedEditPopupOpen, setBannedEditPopupOpen] = useState(false);

  const getSGBannedRolesOptionsEdit = () => {
    return [
      { label: "Admin", value: "ADMIN" },
      { label: "Coach", value: "COACH" },
      { label: "Manager", value: "MANAGER" }
    ];
  };

  const hideSGUserFields = () => {
    return (
      !(
        user?.status === "PRIMARY" || user?.user?.status === "ACCOUNT_LOCKED"
      ) ||
      !!organizationId ||
      !user.sportsgravyUser ||
      !user?.user
    );
  };

  const onTabChange = (event: SyntheticEvent, value: unknown) => {
    setTab(value as string);
  };

  return (
    <Container>
      <Loader
        isLoading={
          isFetchingCountries || isFetchingUser || isRoleOptionsLoading
        }
      >
        <Toolbar
          title="Edit User"
          {...(!hideSGUserFields() && {
            tabs: {
              tabs: ["User Details", "Availability"],
              onTabChange: onTabChange,
              activeTab: tab,
              disabled: !isValid ? ["Availability"] : []
            }
          })}
        />

        <Form>
          <Grid container spacing={3}>
            <Grid xs={12}>
              {(tab == "User Details" || hideSGUserFields()) && (
                <UserDetailsForm
                  disabled={false}
                  isEditing={true}
                  form={form}
                  country={user?.addressPrimary?.country as string}
                  hideSGUserFields={hideSGUserFields()}
                  selectAddressSuggestion={selectAddressSuggestion}
                  countries={countries}
                  isFetchingCountries={isFetchingCountries}
                  organizationId={organizationId}
                  roles={user?.user?.roles ? user.user.roles : []}
                  hideBanPortion={
                    !(
                      user?.status === "PRIMARY" ||
                      user?.user?.status === "ACCOUNT_LOCKED"
                    )
                  }
                  roleOptions={roleOptions()}
                  bannedRolesOptions={
                    !organizationId
                      ? getSGBannedRolesOptionsEdit()
                      : roleOptions().map((role) => {
                          return {
                            label: role.role.name,
                            value: role.role.alias
                          };
                        })
                  }
                  isUserBanned={getValues().isBanned}
                  onChangeRolesSelected={onChangeRolesSelected}
                  orgBannedRoles={getValues().bannedRoles}
                  sgBannedRoles={getValues().bannedRolesSG}
                  userChildren={organizationId ? user?.children : []}
                  userParentRoles={
                    organizationId
                      ? user?.user?.roles?.filter(
                          (r) => r.role?.alias === "PARENT"
                        ) || []
                      : []
                  }
                  teamProgramRoles={teamProgramRoles}
                  setShowTPErrorModal={setShowTPErrorModal}
                  userId={userResponse?.data.userId}
                />
              )}
              {tab === "Availability" && (
                <UserAvailability
                  disabled={false}
                  setFullDays={setFullDays}
                  setHalfDays={setHalfDays}
                  fullDays={fullDays}
                  halfDays={halfDays}
                />
              )}
            </Grid>
          </Grid>
        </Form>
      </Loader>
      <Footer
        cancelBtnClick={onCancelClick}
        saveBtnClick={() => {
          if (user && getValues().isBanned) {
            const allBannedRoles = organizationId
              ? getValues().bannedRoles
              : getValues().bannedRolesSG;
            const matchingRoles = user?.user?.roles
              ? user.user.roles.filter(
                  (role) =>
                    (role.teamId || role.trainingProgramId) &&
                    allBannedRoles.includes(role.role?.alias || "")
                )
              : [];
            setBannedEditPopupOpen(matchingRoles.length > 0);
            if (matchingRoles.length === 0) onSaveClick(getValues());
          } else {
            onSaveClick(getValues());
          }
        }}
        saveBtnLabel="Update"
        isLoading={
          isFetchingUser ||
          isFetchingCountries ||
          isRoleOptionsLoading ||
          isLoading ||
          isLoadingInvite
        }
        isDisabled={!isValid || isLoading || !isDirty}
      />
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        onConfirm={onConfirmCancel}
        onCancel={onCancelCancel}
      />
      <ConfirmationDialog
        open={isBannedEditPopupOpen}
        title="Are you sure you want to Ban this user?"
        body="By banning this user, they will be removed from all associated teams and training programs for the selected roles and any related sub roles. Proceed with banning?"
        onConfirm={() => {
          setBannedEditPopupOpen(false);
          onSaveClick(getValues());
        }}
        onCancel={() => {
          setBannedEditPopupOpen(false);
        }}
      />
      <ConfirmationDialog
        open={showTPErrorModal}
        title="Cannot Remove Role"
        body={`This user is assigned this role/subrole on behalf of one or more teams/training programs that are currently in progress or occurring in the future, therefore you cannot remove them from this role/subrole`}
        close={() => setShowTPErrorModal(false)}
        onConfirm={() => {
          setShowTPErrorModal(false);
        }}
        confirmBtnText="Ok"
        hideCancelBtn
        confirmBtnVariant="admin-warning"
        icon="warning"
      />
    </Container>
  );
};
