import { TableView } from "@components/TableView";
import { GridColDef } from "@mui/x-data-grid";
import { getDepartments } from "@services/Network";
import {
  ModelDepartment,
  ModelTwilioNumber
} from "@sportsgravyengineering/sg-api-react-sdk";
import { formatPhone } from "@utils/phoneFormatters";
import parsePhoneNumberFromString from "libphonenumber-js";

import React from "react";
import { useNavigate } from "react-router-dom";

const LIST_COLUMNS: GridColDef<ModelDepartment>[] = [
  {
    headerName: "Name",
    field: "name",
    minWidth: 150,
    flex: 1,
    sortable: true
  },
  {
    headerName: "SportsGravy Email",
    field: "email",
    minWidth: 150,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => {
      return row.email ? row.email : "-";
    }
  },
  {
    headerName: "SportsGravy Number",
    field: "sgNumber",
    minWidth: 150,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => {
      return (row?.twilioNumber as ModelTwilioNumber)?.number
        ? `+${
            parsePhoneNumberFromString(
              (row?.twilioNumber as ModelTwilioNumber).number as string
            )?.countryCallingCode
          } ${formatPhone((row?.twilioNumber as ModelTwilioNumber).number)}`
        : "-";
    }
  },
  {
    headerName: "Forward To",
    field: "ForwardTo",
    minWidth: 150,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => {
      return row.forwardToJobs && row?.forwardToJobs.length == 0
        ? "Call Center"
        : "Individual Jobs";
    }
  },
  {
    headerName: "Participating Jobs",
    field: "jobs",
    minWidth: 150,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => {
      return (
        row.forwardToJobs && row.forwardToJobs.map((job) => job.name).join(", ")
      );
    }
  }
];

export const Departments = () => {
  const navigate = useNavigate();

  const [refreshKey] = React.useState(0);

  const onAdd = () => navigate("/departments/create");
  const onEdit = (row) => navigate(`/departments/${row.departmentId}/edit`);
  const onView = (row) => navigate(`/departments/${row.departmentId}`);

  return (
    <>
      <TableView
        title="Departments"
        useGet={getDepartments}
        columns={LIST_COLUMNS}
        hideFilter={true}
        hideLabelContainer={true}
        searchable={false}
        getRowId={(row) => row.departmentId}
        onAdd={onAdd}
        onEdit={onEdit}
        onView={onView}
        isDeleteDisabled={() => true}
        refreshKey={refreshKey}
      />
    </>
  );
};
