import React, { useEffect, useRef, useState } from "react";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import { Form } from "@components/crud/Form";
import { Gallery } from "./Gallery";
import { GalleryItemType } from "./GalleryItem";
import { Close, FilterList, MoreVert } from "@mui/icons-material";
import { SearchInput } from "@components/SearchInput";
import { useNavigate } from "react-router-dom";
import {
  MediaContextMenu,
  MoreOption
} from "../../components/MediaContextMenu";
import { PostToFeed } from "@components/PostToFeed";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { ShareModal } from "@components/ShareModal";
import { enqueueSnackbar } from "notistack";
import { PhotosVideoFilterModal } from "./PhotosVideosFilterModal";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  MediaPhotosVideosAtom,
  MediaPhotosVideosFilterAtom,
  organizationAtom
} from "@recoil/auth";
import { getAdminMedia } from "@services/Network";
import { SkeletonFeedCommentLoader } from "@components/SkeletonFeedCommentLoader";
import { Loader } from "@components/crud/Loader";
import { AdminMediaGetParams } from "@sportsgravyengineering/sg-api-react-sdk";
import { getDateRangeBounds } from "../../utils/GetDateRange";
export const PAGE_SIZE = 10;
export const PhotosVideos = () => {
  const organizationId = useRecoilValue(organizationAtom);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [openPostToFeed, setOpenPostToFeed] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [openShare, setOpenShare] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const [filterState, setFilterState] = useState<
    | undefined
    | {
        category: string;
        options: { label: string; value: string; details? }[];
        selected: string[];
        queryKey: string;
      }[]
  >();
  const [filters, setFilters] = useState({});

  const mediaGet = getAdminMedia(
    {
      organizationId: organizationId!,
      pageNo: page,
      pageSize: PAGE_SIZE,
      ...filters,
      ...(textSearch && { textSearch })
    },
    {
      query: {
        queryKey: [
          page,
          ...Object.entries(filters).flat(),
          textSearch,
          organizationId
        ],
        refetchOnWindowFocus: false
      }
    }
  );
  const [galleryItems, setGalleryItems] = useState<GalleryItemType[]>([]);
  const [lastElement, setLastElement] = useState<HTMLElement | null>(null);
  const setMediaPhotosVideosAtom = useSetRecoilState(MediaPhotosVideosAtom);
  const setMediaPhotosVideosFilterAtom = useSetRecoilState(
    MediaPhotosVideosFilterAtom
  );
  const { data, isLoading } = mediaGet;
  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        const first = entries[0];
        console.log("IMAZ__REACHEDEND", first, hasMore, isLoading);
        if (first.isIntersecting && hasMore && (!isLoading || page == 0)) {
          setPage((page) => page + 1);
        }
      },
      {
        rootMargin: "20px 0px",
        threshold: 1.0
      }
    )
  );
  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;
    if (isLoading || !hasMore) currentObserver.disconnect();
    else if (currentElement) {
      currentObserver.observe(currentElement);
    }
    setMediaPhotosVideosAtom(galleryItems);
    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [galleryItems]);

  useEffect(() => {
    if (!isLoading && data) {
      if (data.length < PAGE_SIZE) {
        setHasMore(false);
      }
      if (page === 0 && data.length >= PAGE_SIZE) setHasMore(true);

      const addData = data
        .filter((m) => m.baseUrl && m.path)
        .map((m) => {
          const path = m.baseUrl! + m.path!;
          return {
            id: m.mediaId!,
            type: m.type! as "IMAGE" | "VIDEO",
            date: m.createdAt! as string,
            thumbnailSrc:
              m.type === "IMAGE" ? path : path.replace(".m3u8", ".0000001.jpg"),
            ...(m.type === "VIDEO" && {
              duration: Math.round(m.metadata?.duration || 0)
            }),
            mediaDetails: m
          };
        }) as GalleryItemType[];
      if (page !== 0) {
        setGalleryItems((d) => d.concat(addData));
      } else {
        setGalleryItems(addData);
      }
    }
  }, [isLoading, data]);

  const groupedImages = galleryItems.reduce((acc, item) => {
    const date = new Date(item.date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric"
    }); // Format the date
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(item);
    return acc;
  }, {}) as { date: string; items: GalleryItemType[] };
  const handleSelect = (id) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
    );
  };
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const moreOptionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const downloadFile = async (url: string, fileName: string) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = fileName; // You can change the filename here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      enqueueSnackbar("Error downloading the file");
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (filterState) {
      const obj = {} as AdminMediaGetParams;
      filterState
        .filter((f) => f.selected.length)
        .map((f) => {
          if (f.queryKey !== "dtStart") obj[f.queryKey] = [...f.selected];
          else {
            const { dtStart, dtEnd } = getDateRangeBounds(f.selected);
            obj["dtStart"] = dtStart;
            obj["dtEnd"] = dtEnd;
          }
        });
      setPage(0);
      setFilters({ ...obj });
      setMediaPhotosVideosFilterAtom({ ...obj });
    }
  }, [filterState]);
  return (
    <Container>
      <Toolbar title="Photos & Videos" />

      <Form>
        {selectedItems.length > 0 ? (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            style={{
              backgroundColor: "#F3F4F7",
              width: "100%",
              height: "48px",
              marginBottom: "20px"
            }}
          >
            <Grid item container alignItems="center" xs={10}>
              <Grid item>
                <IconButton
                  onClick={() => {
                    setSelectedItems([]);
                  }}
                >
                  <Close htmlColor="black" fontSize="small" />
                </IconButton>
              </Grid>
              <Grid item>
                <span
                  style={{
                    color: "#0F0F0F",
                    fontWeight: 600,
                    fontSize: "14px"
                  }}
                >
                  {selectedItems.length} items selected
                </span>
              </Grid>
            </Grid>
            <Grid item alignSelf="center">
              <IconButton onClick={moreOptionsClick}>
                <MoreVert htmlColor="#2B337A" />
              </IconButton>
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="row" marginBottom="20px" spacing="20px">
            <Grid item width="90%">
              <SearchInput
                placeholder="Search Tags"
                onChange={(e) => setTextSearch(e.target.value)}
                required={false}
                style={{
                  border: "1px solid #E5E5E5"
                }}
              />
            </Grid>
            <Grid item width="10%">
              <Button
                variant="outlined"
                startIcon={<FilterList />}
                onClick={() => {
                  setOpenFilter(true);
                }}
                style={{
                  textTransform: "none",
                  width: "100%",
                  height: "50px",
                  color: "#666666",
                  borderRadius: "6px",
                  border: "1px solid #E5E5E5"
                }}
              >
                Filter
              </Button>
            </Grid>
          </Grid>
        )}

        {Object.entries(groupedImages).map(([date, items]) => (
          <div
            key={date}
            style={{
              marginBottom: "20px"
            }}
          >
            <Typography
              style={{
                fontSize: "16px",
                fontWeight: 600,
                color: "#000000"
              }}
              variant="formLabel"
            >
              {date}
            </Typography>
            <Gallery
              items={items as GalleryItemType[]}
              selectedItems={selectedItems}
              onSelect={handleSelect}
              onClick={(id) => {
                navigate(`/photos-videos/${id}`);
              }}
            />
          </div>
        ))}

        {isLoading && hasMore && <SkeletonFeedCommentLoader type="media" />}

        <MediaContextMenu
          id={id}
          open={open}
          handleClose={handleClose}
          anchorEl={anchorEl}
          items={[
            <MoreOption
              key="1"
              onClick={() => {
                handleClose();
                setOpenPostToFeed(true);
              }}
            >
              Post to Feed
            </MoreOption>,
            <MoreOption
              key="2"
              onClick={async () => {
                console.log("loading....");
                await Promise.all(
                  selectedItems.map(async (i) => {
                    const mediaFound = galleryItems.find((g) => g.id === i);
                    return downloadFile(
                      mediaFound!.mediaDetails!.baseUrl! +
                        mediaFound!.mediaDetails!.path,
                      i
                    );
                  })
                );

                console.log("done....");
              }}
            >
              Download
            </MoreOption>,
            <MoreOption
              key="3"
              onClick={() => {
                handleClose();
                setOpenShare(true);
              }}
            >
              Share Outside of SportsGravy
            </MoreOption>
          ]}
        />
        {openPostToFeed && selectedItems.length <= 10 && (
          <PostToFeed
            onClose={() => {
              setOpenPostToFeed(false);
            }}
            media={selectedItems.map((s) => {
              return galleryItems.find((g) => g.id === s)!.mediaDetails!;
            })}
          />
        )}
        {openShare && (
          <ShareModal
            onClose={() => setOpenShare(false)}
            mediaIds={selectedItems}
          />
        )}
        <ConfirmationDialog
          open={openPostToFeed && selectedItems.length > 10}
          title="Photo/Video Limit"
          body="You have selected too many items. You may only select up to 10 items to include in a single post."
          onConfirm={() => setOpenPostToFeed(false)}
          confirmBtnText="Okay"
          onCancel={() => setOpenPostToFeed(false)}
          hideCancelBtn
        />

        <PhotosVideoFilterModal
          open={openFilter}
          handleClose={(filters) => {
            setOpenFilter(false);
            setFilterState(filters);
          }}
          selectedFiterState={filterState}
          excludeFilters={["Live Stream"]}
        />
      </Form>
      <Loader isLoading={isLoading}>
        <div ref={setLastElement} />
      </Loader>
    </Container>
  );
};
