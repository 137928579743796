import { Editor } from "@components/Editor";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormInput } from "@components/FormInput";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { FormSelect } from "@components/FormSelect";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { Form } from "@components/crud/Form";
import { FormLabel, Grid, Typography, styled } from "@mui/material";
import { ModelDepartment } from "@sportsgravyengineering/sg-api-react-sdk";
import { arraysEqual } from "@utils/ArrayFunctions";
import { capitalizeEveryWord } from "@utils/capitalize";
import { CRM_ORG_RATING } from "@utils/constants";
import React, { ChangeEvent, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));
export const JobTitleForm = ({
  form,
  disabled,
  departments,
  isLoadingDepartments,
  isActiveReadOnly,
  canSetDemo,
  setCanSetDemo,
  canSellAccount,
  setCanSellAccount,
  canSupportAccount,
  setCanSupportAccount,
  setIsDescValid
}: {
  form: UseFormReturn;
  disabled?: boolean;
  departments: ModelDepartment[];
  isLoadingDepartments?: boolean;
  isActiveReadOnly?: boolean;
  canSetDemo?: boolean;
  setCanSetDemo;
  canSellAccount?: boolean;
  setCanSellAccount;
  canSupportAccount?: boolean;
  setCanSupportAccount;
  setIsDescValid?;
}) => {
  const { control, setValue, getValues } = form;
  const [suportCalls, setSupportCalls] = useState(false);
  const [descValid, setDescValid] = useState(true);
  const validateDescriptionArray = (value) => {
    if (value && value.length >= 2) {
      if (setIsDescValid) setIsDescValid(true);
      setDescValid(true);
    } else if (value && value.length == 1 && value[0].insert !== "\n") {
      if (setIsDescValid) setIsDescValid(true);
      setDescValid(true);
    } else {
      if (setIsDescValid) setIsDescValid(false);
      setDescValid(false);
    }
  };

  useEffect(() => {
    if (canSellAccount || canSupportAccount || canSetDemo) {
      setValue("supportsCalls", true);
      setSupportCalls(true);
      setValue("supportsCallCenterCalls", false);
    }
    if (!canSellAccount && !canSupportAccount && !canSetDemo) {
      setValue("supportsCalls", false);
      setSupportCalls(false);
      setValue("supportsCallCenterCalls", false);
    }
  }, [canSetDemo, canSellAccount, canSupportAccount]);
  return (
    <Form>
      <Grid container direction="column" spacing="20px">
        <Grid item container direction="row" spacing="24px">
          <Grid item xs={6} data-testid="jobTitle-name">
            <FormInput
              disabled={disabled}
              name="name"
              onChange={(e) => {
                setValue(
                  "name",
                  capitalizeEveryWord(
                    (e as ChangeEvent<HTMLInputElement>).target.value
                  )
                );
              }}
              control={control}
              label="Name"
              required
              rules={{
                required: "Name is required"
              }}
              type="text"
            />
          </Grid>

          <Grid item xs={6} data-testid="jobTitle-abbv">
            <FormInput
              disabled={disabled}
              name="abbreviation"
              capitalize
              control={control}
              label="Abbreviation"
              required
              rules={{
                required: "Abbreviation is required"
              }}
              type="text"
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          spacing="24px"
          data-testid="jobTitle-isActive"
          style={{
            marginTop: "-45px"
          }}
        >
          <Grid item>
            <FormCheckbox
              name="isActive"
              control={control}
              label="Is Active"
              disabled={disabled || !!isActiveReadOnly}
            />
          </Grid>
        </Grid>

        <Grid item container direction="row" spacing="24px">
          <Grid item xs={6} data-testid="jobTitle-department">
            <FormSelect
              name="departmentId"
              disabled={disabled}
              control={control}
              isLoading={isLoadingDepartments}
              label="Department"
              options={
                departments
                  ? departments.map((department) => ({
                      label: department.name!,
                      value: department.departmentId!
                    }))
                  : []
              }
              required
              rules={{
                required: "Department is required"
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="permissionNames"
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              letterSpacing: "10%",
              lineHeight: "14.52px"
            }}
          >
            INCLUDED JOB RESPONSIBILITIES
          </Typography>
          <HeaderUnderLine width="100%" />
        </Grid>
        <Grid item>
          <FormCheckbox
            control={control}
            disabled={disabled}
            label="Responsible for Setting Up Demos"
            name="canSetDemo"
            onChange={(e) => {
              setCanSetDemo(e.target.checked);
            }}
          />
        </Grid>
        {canSetDemo && (
          <Grid item container direction="row" spacing="24px">
            <Grid item xs={4}>
              <FormInput
                disabled={disabled}
                name="maxAccountsDemo"
                control={control}
                label="Max No. of Assigned Accounts"
                type="number"
                required
                rules={{
                  required: "Max No. of Assigned Accounts is required"
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormMultiSelect
                disabled={disabled}
                name="orgRatingDemo"
                control={control}
                label="Organization Sales Rating"
                options={CRM_ORG_RATING}
              />
            </Grid>
            <Grid item xs={4}>
              <FormInput
                disabled={disabled}
                name="athletesThresholdDemo"
                control={control}
                label="No. of Athletes Threshold"
                type="text"
                TextProps={{
                  inputProps: {
                    onInput: (e) => {
                      (e as ChangeEvent<HTMLInputElement>).target.value = (
                        e as ChangeEvent<HTMLInputElement>
                      ).target.value.replace(/[^0-9]/g, "");
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
        )}
        <Grid item>
          <FormCheckbox
            disabled={disabled}
            control={control}
            label="Responsible for Selling New Accounts"
            name="canSellAccount"
            onChange={(e) => {
              setCanSellAccount(e.target.checked);
            }}
          />
        </Grid>
        {canSellAccount && (
          <Grid item container direction="row" spacing="24px">
            <Grid item xs={4}>
              <FormInput
                disabled={disabled}
                name="maxOpenOppSell"
                control={control}
                label="Max No. of Open Opportunities Allowed"
                type="number"
                required
                rules={{
                  required: "Max No. of Open Opportunities Allowed"
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormMultiSelect
                disabled={disabled}
                name="orgRatingSell"
                control={control}
                label="Organization Sales Rating"
                options={CRM_ORG_RATING}
              />
            </Grid>
            <Grid item xs={4}>
              <FormInput
                disabled={disabled}
                name="athletesThresholdSell"
                control={control}
                label="No. of Athletes Threshold"
                type="text"
                TextProps={{
                  inputProps: {
                    onInput: (e) => {
                      (e as ChangeEvent<HTMLInputElement>).target.value = (
                        e as ChangeEvent<HTMLInputElement>
                      ).target.value.replace(/[^0-9]/g, "");
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
        )}
        <Grid item>
          <FormCheckbox
            disabled={disabled}
            control={control}
            label="Responsible for Customer Success and Renewing Existing Accounts"
            name="canSupportAccount"
            onChange={(e) => {
              setCanSupportAccount(e.target.checked);
            }}
          />
        </Grid>
        {canSupportAccount && (
          <Grid item container direction="row" spacing="24px">
            <Grid item xs={4}>
              <FormInput
                disabled={disabled}
                name="maxAccountsSupport"
                control={control}
                label="Max No. of Assigned Accounts"
                type="number"
                required
                rules={{
                  required: "Max No. of Assigned Accounts is required"
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormMultiSelect
                disabled={disabled}
                name="orgRatingSupport"
                control={control}
                label="Organization Sales Rating"
                options={CRM_ORG_RATING}
              />
            </Grid>
            <Grid item xs={4}>
              <FormInput
                disabled={disabled}
                name="athletesThresholdSupport"
                control={control}
                label="No. of Athletes Threshold"
                type="text"
                TextProps={{
                  inputProps: {
                    onInput: (e) => {
                      (e as ChangeEvent<HTMLInputElement>).target.value = (
                        e as ChangeEvent<HTMLInputElement>
                      ).target.value.replace(/[^0-9]/g, "");
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
        )}
        <Grid item>
          <FormCheckbox
            disabled={disabled}
            control={control}
            label="Responsible for Customer Onboarding Demos"
            name="canConductOnboardingDemo"
          />
        </Grid>
        <Grid item>
          <FormCheckbox
            disabled={
              disabled || canSellAccount || canSupportAccount || canSetDemo
            }
            control={control}
            label="Accept Incoming & Make Outgoing Calls"
            name="supportsCalls"
            onChange={(e) => {
              if (e.target.checked) {
                setValue("supportsCallCenterCalls", false);
              }
              setSupportCalls(e.target.checked);
            }}
          />
        </Grid>
        <Grid item>
          <FormCheckbox
            disabled={
              disabled ||
              canSellAccount ||
              canSupportAccount ||
              canSetDemo ||
              suportCalls
            }
            control={control}
            label="Accept Incoming Calls (Call Center Only)"
            name="supportsCallCenterCalls"
          />
        </Grid>

        <Grid
          item
          container
          direction="column"
          spacing="10px"
          minHeight="300px"
          marginBottom="30px"
          data-testid="jobTitle-description"
        >
          <Grid item>
            <StyledFormLabel required>
              <Typography variant="formLabel">Description</Typography>
            </StyledFormLabel>
          </Grid>
          <Grid item>
            <Editor
              disabled={disabled}
              html={getValues().description ? getValues().description : null}
              onBlur={() => {
                validateDescriptionArray(getValues().description);
              }}
              onValueChange={(deltaOperations, hasMedia) => {
                if (getValues().description && deltaOperations) {
                  if (!arraysEqual(getValues().description, deltaOperations)) {
                    setValue("description", deltaOperations, {
                      shouldValidate: true,
                      shouldDirty: true
                    });
                    setValue("hasMedia", hasMedia);
                    validateDescriptionArray(deltaOperations);
                  }
                } else {
                  setValue("description", deltaOperations, {
                    shouldValidate: true,
                    shouldDirty: true
                  });
                  setValue("hasMedia", hasMedia);
                  validateDescriptionArray(deltaOperations);
                }
              }}
              style={{
                height: "300px"
              }}
            />
          </Grid>
        </Grid>
        {!descValid && (
          <Grid item style={{ marginTop: "10px" }}>
            <Typography style={{ color: "#E82C2C" }} variant="body2">
              Description is required
            </Typography>
          </Grid>
        )}
      </Grid>
    </Form>
  );
};
