import { styled } from "@mui/material";
import { MAX_CLIP_DURATION, MIN_CLIP_DURATION } from "@utils/constants";
import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";

const StyledArrow = styled("div")`
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  cursor: ew-resize !important;
`;

const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
    2,
    "0"
  )}`;
};

const formatTimeToMMSS = (time: number) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;

  if (hours > 0) {
    return `${hours}h ${seconds}s`;
  } else if (minutes > 0) {
    return `${minutes} ${minutes > 1 ? "mins" : "min"} ${
      seconds > 0 ? `${seconds} sec` : ""
    }`;
  } else {
    return `${seconds} sec`;
  }
};

export const VideoFrames = ({
  details,
  onIndicatorMove,
  playerRef,
  setIsDraggingBox
}: {
  details: {
    streamId: string;
    sportId: string;
    clipId?: string;
    url?: string;
    teamProgramId: string | undefined;
    duration: number;
    startTime?: number;
    endTime?: number;
  };
  onIndicatorMove: (startTime: number, endTime: number) => void;
  playerRef: React.RefObject<ReactPlayer>;
  setIsDraggingBox: (isDragging: boolean) => void;
}) => {
  const FRAME_WIDTH = 51;

  const [frames, setFrames] = useState<{ time: number; url: string }[]>([]);
  const [frameInterval, setFrameInterval] = useState(1);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [linePosition, setLinePosition] = useState(0);
  const [isRightArrowDragging, setIsRightArrowDragging] = useState(false);
  const [isBoxDragging, setIsBoxDragging] = useState(false);
  const [localStartTime, setLocalStartTime] = useState(details.startTime ?? 0);
  const [localEndTime, setLocalEndTime] = useState(details.endTime ?? 0);
  const [framesGenerated, setFramesGenerated] = useState<number>(0);

  useEffect(() => {
    if (details.startTime) {
      setLocalStartTime(details.startTime);
    }
    if (details.endTime) {
      setLocalEndTime(details.endTime);
    }
  }, [details.startTime, details.endTime]);

  useEffect(() => {
    const handleLoadedMetadata = () => {
      let frameInterval = 1;
      const duration = Math.floor(details.duration - 2);
      const newFrames: { time: number; url: string }[] = [];
      if (!details.url) return;
      if (duration > 300) {
        frameInterval = 30;
      } else if (duration > 180) {
        frameInterval = 15;
      } else if (duration > 90) {
        frameInterval = 10;
      } else if (duration > 25) {
        frameInterval = 5;
      }

      for (let i = 0; i <= duration; i += frameInterval) {
        const imageUrl = details.url.replace(
          ".m3u8",
          `.${String(i).padStart(7, "0")}.jpg`
        );
        newFrames.push({ time: i, url: imageUrl });
      }

      setFrameInterval(frameInterval);
      setFrames(newFrames);
    };
    handleLoadedMetadata();
  }, [details.url]);

  const gePositionfromTime = (
    time: number,
    duration: number,
    totalFrames: number
  ) => {
    return (time / duration) * (totalFrames * FRAME_WIDTH);
  };

  const getTotalFrames = () => {
    return Math.floor((details.duration - 2) / frameInterval);
  };

  useEffect(() => {
    if (details.startTime !== undefined && framesGenerated === frames.length) {
      const totalFrames = getTotalFrames();
      const newPosition = gePositionfromTime(
        details.startTime,
        details.duration,
        totalFrames
      );
      setLinePosition(newPosition);
      if (containerRef.current && !isBoxDragging) {
        containerRef.current.scrollTo({
          left: newPosition - 100,
          behavior: "smooth"
        });
      }
    }
  }, [
    details.startTime,
    details.endTime,
    details.duration,
    frameInterval,
    framesGenerated
  ]);

  useEffect(() => {
    const currentTime = playerRef.current?.getCurrentTime();
    if (!currentTime) return;
    if (
      details?.startTime &&
      details?.endTime &&
      currentTime >= details?.endTime
    ) {
      playerRef.current?.seekTo(details.startTime);
      return;
    }
    const totalFrames = getTotalFrames();
    const newPosition = gePositionfromTime(
      currentTime,
      details.duration,
      totalFrames
    );
    setLinePosition(newPosition);
  }, [playerRef.current?.getCurrentTime()]);

  const handleMouseMove = (event: MouseEvent) => {
    setIsDraggingBox(true);
    if (isDragging && containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      let newLeft = event.clientX - rect.left + containerRef.current.scrollLeft;
      newLeft = Math.max(
        0,
        Math.min(newLeft, rect.width + containerRef.current.scrollLeft)
      );

      const threshold = 1;
      if (Math.abs(newLeft - linePosition) > threshold) {
        const totalFrames = getTotalFrames();
        let newTime =
          (newLeft / (totalFrames * FRAME_WIDTH)) * details.duration;
        newTime = Math.min(newTime, details.duration);

        const timeDiff = localEndTime - newTime;
        if (
          timeDiff < MAX_CLIP_DURATION &&
          timeDiff >= MIN_CLIP_DURATION &&
          newTime <= details.duration
        ) {
          playerRef.current?.seekTo(newTime);
          onIndicatorMove(newTime, localEndTime);
          setLocalStartTime(newTime);
          if (newTime < details.duration) {
            setLinePosition(newLeft);
          }
        }
      }
    }

    if (isRightArrowDragging && containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const newRight =
        event.clientX - rect.left + containerRef.current.scrollLeft;
      const totalFrames = getTotalFrames();
      const newTime =
        (newRight / (totalFrames * FRAME_WIDTH)) * details.duration;

      const updatedEndTime = Math.min(
        Math.max(newTime, localStartTime! + MIN_CLIP_DURATION),
        localStartTime! + MAX_CLIP_DURATION,
        details.duration
      );

      onIndicatorMove(localStartTime, updatedEndTime);
      setLocalEndTime(updatedEndTime);
    }

    if (isBoxDragging && containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const newLeft =
        event.clientX - rect.left + containerRef.current.scrollLeft;

      const totalFrames = getTotalFrames();
      const rawStartTime =
        (newLeft / (totalFrames * FRAME_WIDTH)) * details.duration;

      const dragSpeedFactor = 1;
      const targetStartTime =
        localStartTime + (rawStartTime - localStartTime) * dragSpeedFactor;

      const duration = localEndTime - localStartTime;
      const SMOOTHING_FACTOR = 0.25;
      const interpolatedStartTime =
        localStartTime + (targetStartTime - localStartTime) * SMOOTHING_FACTOR;

      const updatedStartTime = Math.max(
        0,
        Math.min(interpolatedStartTime, details.duration - duration)
      );
      const updatedEndTime = updatedStartTime + duration;

      const SCROLL_THRESHOLD = 50;
      const SCROLL_SPEED = 10;
      if (event.clientX - rect.left < SCROLL_THRESHOLD) {
        containerRef.current.scrollLeft = Math.max(
          0,
          containerRef.current.scrollLeft - SCROLL_SPEED
        );
      } else if (event.clientX > rect.right - SCROLL_THRESHOLD) {
        containerRef.current.scrollLeft = Math.min(
          containerRef.current.scrollWidth - containerRef.current.clientWidth,
          containerRef.current.scrollLeft + SCROLL_SPEED
        );
      }

      requestAnimationFrame(() => {
        playerRef.current?.seekTo(updatedStartTime);
        onIndicatorMove(updatedStartTime, updatedEndTime);
        setLocalStartTime(updatedStartTime);
        setLocalEndTime(updatedEndTime);
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setIsRightArrowDragging(false);
    setIsBoxDragging(false);
    setIsDraggingBox(false);
  };

  useEffect(() => {
    if (isDragging || isRightArrowDragging || isBoxDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, isRightArrowDragging, isBoxDragging]);

  const startTime = localStartTime;
  const endTime = localEndTime;
  const totalFrames = Math.floor((details.duration - 2) / frameInterval);
  const frameWidth = 51;
  const boxStart = (startTime / details.duration) * (totalFrames * frameWidth);
  const boxEnd = (endTime / details.duration) * (totalFrames * frameWidth);
  const boxWidth = boxEnd - boxStart;

  const handleRightArrowMouseDown = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsRightArrowDragging(true);
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleBoxMouseDown = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsBoxDragging(true);
  };

  return (
    <div>
      <div
        ref={containerRef}
        tabIndex={-1}
        style={{
          position: "relative",
          display: framesGenerated === frames.length ? "flex" : "none",
          overflow: "auto",
          flexDirection: "row",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          userSelect: "none"
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "25px",
            left: 0,
            width: `${boxStart}px`,
            height: "48px",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            pointerEvents: "none",
            zIndex: 1
          }}
        ></div>

        <div
          style={{
            position: "absolute",
            top: "25px",
            left: `${boxStart}px`,
            width: `${boxEnd + 10 - boxStart}px`,
            height: "48px",
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            pointerEvents: "none",
            zIndex: 2
          }}
        ></div>

        <div
          style={{
            position: "absolute",
            top: "25px",
            left: `${boxEnd + 10}px`,
            width: "100vw",
            maxWidth: getTotalFrames() * FRAME_WIDTH - boxEnd,
            height: "48px",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            pointerEvents: "none",
            zIndex: 1
          }}
        ></div>

        {frames.map((frame) => {
          return (
            <div
              key={frame.time}
              style={{
                textAlign: "left",
                position: "relative",
                zIndex: 0
              }}
            >
              <div
                style={{
                  color: "#B3B3B3",
                  fontSize: "12px",
                  padding: "2px 0",
                  marginBottom: "2px",
                  pointerEvents: "none",
                  userSelect: "none"
                }}
              >
                {formatTime(frame.time)}
              </div>
              <img
                src={frame.url}
                style={{
                  width: "48px",
                  height: "48px",
                  pointerEvents: "none",
                  userSelect: "none"
                }}
                onLoad={() => {
                  setFramesGenerated((prev) => prev + 1);
                }}
              />
            </div>
          );
        })}

        {details.startTime !== undefined && (
          <>
            <div
              className="indicator-line"
              style={{
                position: "absolute",
                top: "30px",
                left: `${linePosition}px`,
                height: "40px",
                border: "2px solid red",
                boxSizing: "border-box",
                zIndex: 2,
                cursor: "move"
              }}
              onMouseDown={handleMouseDown}
            ></div>
            <div
              style={{
                position: "absolute",
                top: "25px",
                left: `${boxStart - 15}px`,
                width: `${boxWidth + 36}px`,
                height: "50px",
                border: "2px solid #FFCA45",
                borderTop: "5px solid #FFCA45",
                borderBottom: "5px solid #FFCA45",
                borderLeft: "15px solid #FFCA45",
                borderRight: "15px solid #FFCA45",
                boxSizing: "border-box",
                borderRadius: "4px",
                zIndex: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0 8px",
                cursor: isBoxDragging ? "grabbing" : "grab"
              }}
              onMouseDown={handleBoxMouseDown}
            >
              <StyledArrow
                className="arrow"
                style={{
                  borderRight: "6px solid #000",
                  left: "-12px"
                }}
                onMouseDown={handleMouseDown}
              />
              <StyledArrow
                className="arrow"
                style={{
                  borderLeft: "6px solid #000",
                  right: "-12px"
                }}
                onMouseDown={handleRightArrowMouseDown}
              />
            </div>
          </>
        )}
      </div>
      <div
        style={{
          color: "#B3B3B3",
          textAlign: "center"
        }}
      >
        {formatTimeToMMSS(Number((localEndTime - localStartTime).toFixed(0)))}
      </div>
    </div>
  );
};
