import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { useAdminHelpCategoryPost } from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalizeEveryWord } from "@utils/capitalize";
import { useSnackbar } from "notistack";
import { ChangeEvent, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export const HelpCategoryCreate = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid },
    reset
  } = useForm({
    mode: "onTouched"
  });
  const { mutate: save, isLoading: isSaving } = useAdminHelpCategoryPost();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    async (formValues) => {
      const values = {
        ...formValues
      };
      values["name"] = values["name"]
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      save(
        {
          data: values
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Help category added successfully!", {
              variant: "success"
            });
            if (resetInsteadOfRoute) {
              reset();
            } else {
              navigate("/help-category");
            }
          },
          onError: () => {
            enqueueSnackbar("Failed to add Help category!", {
              variant: "error"
            });
          }
        }
      );
    };

  return (
    <Container>
      <Toolbar title="Add Help Category" />
      <Form>
        <Grid data-testid="helpCategory-add-form" container spacing={3}>
          <Grid data-testid="helpCategory-name" xs={12} md={6}>
            <FormInput
              control={control}
              name="name"
              type="text"
              label="Name"
              required={true}
              onChange={(e) => {
                setValue(
                  "name",
                  capitalizeEveryWord(
                    (e as ChangeEvent<HTMLInputElement>).target.value
                  )
                );
              }}
              rules={{
                required: "Name is required"
              }}
            />
          </Grid>
        </Grid>
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler(false))}
        saveAndNewBtnClick={handleSubmit(saveHandler(true))}
        isDisabled={!isValid || isSaving}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/help-category")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
