import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import {
  useAdminImportTmplProviderIdGet,
  useAdminImportTmplProviderIdPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalizeEveryWord } from "@utils/capitalize";
import { ERP_TYPE_OPTIONS } from "@utils/constants";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

export const ImportTemplateProviderEdit = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const { providerId } = useParams();
  const {
    data: provider,
    isFetching: isProviderFetching,
    error: error
  } = useAdminImportTmplProviderIdGet(providerId as string);
  useEffect(() => {
    if (error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
  }, [error]);
  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid, isDirty },
    reset
  } = useForm({
    mode: "onTouched"
  });

  useEffect(() => {
    if (provider) {
      reset(provider.data);
    }
  }, [provider]);

  const { mutate: save, isLoading: isSaving } =
    useAdminImportTmplProviderIdPut();
  const saveHandler = (formValues) => {
    const values = {
      ...formValues
    };

    save(
      {
        providerId: providerId as string,
        data: values
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Import Template Provider updated successfully!", {
            variant: "success"
          });
          navigate(`/import-template-providers`);
        },
        onError: () => {
          enqueueSnackbar("Failed to update import template provider!", {
            variant: "error"
          });
        }
      }
    );
  };

  return (
    <Container>
      <Toolbar title="Edit Import Template Provider" />
      <Form>
        <Loader isLoading={isProviderFetching}>
          <Grid container spacing={3}>
            <Grid xs={12} md={6} data-testid="ImportTpl_edit_name">
              <FormInput
                control={control}
                name="name"
                type="text"
                label="Name"
                required={true}
                onChange={(e) => {
                  setValue("name", capitalizeEveryWord(e.target.value));
                }}
                rules={{
                  required: "Name is required"
                }}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="ImportTpl_edit_erpType">
              <FormSelect
                control={control}
                name="erpType"
                label="ERP Type"
                required={true}
                rules={{ required: "ERP Type is required" }}
                options={ERP_TYPE_OPTIONS}
              />
            </Grid>
            <Grid xs={12} data-testid="ImportTpl_edit_desc">
              <FormInput
                control={control}
                name="description"
                type="text"
                label="Description"
                multiline={true}
                TextProps={{
                  minRows: 3
                }}
              />
            </Grid>
          </Grid>
        </Loader>
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler)}
        isDisabled={!isValid || isSaving || !isDirty}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/import-template-providers")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
