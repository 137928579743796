import { FormInput } from "@components/FormInput";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import {
  ModelFeature,
  ModelFeatureRelease,
  ModelPerson,
  useAdminDirectoryGet,
  useAdminFeatureGet,
  useAdminReleaseIdGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import { Typography, styled } from "@mui/material";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormSelect } from "@components/FormSelect";
import { Form } from "@components/crud/Form";
import TickIcon from "../../assets/icons/tick.svg";
import { FeatureBar } from "./FeatureBar";
import { DuplicateReleaseModal } from "@components/DuplicateReleaseModal";

interface TestcaseStatus {
  totalFeatures: number;
  totalFeaturesPassed: number;
  testcasePassed: number;
  testcaseNotTested: number;
  testcaseFailed: number;
  testcaseHold: number;
  testcaseBlocked: number;
}

const StyledStatusHeader = styled(Typography)(() => ({
  color: "#666666",
  fontWeight: 500,
  fontSize: "14px",
  display: "inline-block",
  width: "170px"
}));
const StyledStatusValue = styled(Typography)(() => ({
  color: "#666666",
  fontWeight: 500,
  display: "inline-block"
}));

const filterOptions = [
  { label: "All", value: "all" },
  { label: "Mobile", value: "MOB" },
  { label: "Web", value: "WEB" }
];

const renderTree = (control, testerOptions, node, level) => {
  return (
    <div key={node.featureId}>
      <FeatureBar
        key={node.featureId}
        control={control}
        companyDirectory={testerOptions}
        level={level}
        node={node}
        disabled={true}
      />
      {node.children &&
        node.children.length > 0 &&
        node.children.map((child) =>
          renderTree(control, testerOptions, child, level + 1)
        )}
    </div>
  );
};

export const ReleaseView = () => {
  const organizationId = useRecoilValue(organizationAtom);
  const navigate = useNavigate();
  const { releaseId } = useParams();
  const [filter, setFilter] = useState("all");
  const [options, setOptions] = useState<ModelFeature[]>([]);
  const [duplicateModel, setDuplicateModel] = useState(false);
  //@ts-ignore
  const [testcaseStatus, setTestcaseStatus] = useState<TestcaseStatus>({});

  const { control, reset } = useForm({
    mode: "onTouched",
    defaultValues: {
      name: "",
      description: "",
      filter: "all"
    }
  });
  const { data: data, isLoading: isLoading } = useAdminFeatureGet({
    flatten: false,
    platform: filter,
    sortField: "name",
    sortDirection: "asc",
    pageSize: 100
  });
  const { data: companyDirectory } = useAdminDirectoryGet({
    pageSize: 1000
  });

  const { data: release, isLoading: releaseLoading } = useAdminReleaseIdGet(
    releaseId!
  );

  useEffect(() => {
    if (release?.data) {
      const defaultValues = {
        name: release.data.name,
        description: release.data.description
      };
      //@ts-ignore
      setTestcaseStatus(release.data.testcaseStatus);
      release.data.features!.map((feature: ModelFeatureRelease) => {
        defaultValues[feature.featureId] = true;
        if (feature.iosTesterId)
          defaultValues[feature.featureId + ".iosTester"] = feature.iosTesterId;
        if (feature.andTesterId)
          defaultValues[feature.featureId + ".andTester"] = feature.andTesterId;
        if (feature.webTesterId)
          defaultValues[feature.featureId + ".webTester"] = feature.webTesterId;
      });
      if (release?.data?.duplicateOf) {
        defaultValues["relatedTo"] = release?.data?.duplicateOf?.name;
      }
      reset(defaultValues);
    }
  }, [release]);

  const testerOptions = useMemo(
    () =>
      (companyDirectory &&
        companyDirectory.data.persons &&
        companyDirectory.data.persons.map((person: ModelPerson) => {
          return {
            label: person.firstName + " " + person.lastName,
            value: person.personId
          };
        })) ||
      [],
    [companyDirectory?.data]
  );

  useEffect(() => {
    if (data?.data?.features) {
      setOptions(data.data.features);
    }
  }, [data]);
  useEffect(() => {
    if (organizationId) navigate("/not-found");
    const view = hasPermission(
      "ORGANIZATION",
      organizationId!,
      "tech.test-cases",
      "VIEW"
    );
    view.then((res) => {
      if (!res) navigate("/not-found");
    });
  }, [organizationId]);

  const duplicateReleaseModelClose = () => {
    setDuplicateModel(false);
  };
  return (
    <Container>
      <Loader isLoading={releaseLoading}>
        <Toolbar
          title="View Release"
          backBtnClick={() => navigate("/releases")}
          editBtnClick={() => navigate(`/releases/${releaseId}/edit`)}
          {...(!release?.data?.duplicateTo && {
            duplicateBtnClick: () => setDuplicateModel(true)
          })}
        />
        <Form sx={{ paddingBottom: "0 !important" }}>
          <Grid data-testid="release-add-form" container spacing={3}>
            <Grid data-testid="release-name" xs={6}>
              <FormInput
                control={control}
                name="name"
                type="text"
                label="Name"
                disabled
                required={true}
                rules={{
                  required: "Name is required"
                }}
              />
            </Grid>
            <Grid data-testid="release-description" xs={6}>
              <FormInput
                control={control}
                name="description"
                type="text"
                disabled
                label="Description"
              />
            </Grid>
            {release?.data?.duplicateOf && (
              <>
                <Grid data-testid="release-relatedTo" xs={6}>
                  <FormInput
                    control={control}
                    name="relatedTo"
                    type="text"
                    disabled
                    label="Related Release"
                  />
                </Grid>
                <Grid
                  sx={{ visibility: "hidden" }}
                  data-testid="release-relatedTo"
                  xs={6}
                >
                  <FormInput
                    control={control}
                    name="relatedTo"
                    type="text"
                    disabled
                    label="Related Release"
                  />
                </Grid>
              </>
            )}
            <Grid data-testid="release-status" xs={3}>
              <Typography variant="formLabel">Status</Typography>
              {release?.data?.releaseStatus == "IN_PROGRESS" && (
                <div
                  style={{
                    background: "#F3F4F7",
                    padding: "14px",
                    borderRadius: "6px",
                    marginTop: "10px"
                  }}
                >
                  <div>
                    <StyledStatusHeader>Total Features </StyledStatusHeader>
                    <StyledStatusValue>
                      {testcaseStatus.totalFeatures &&
                        testcaseStatus.totalFeatures.toLocaleString()}
                    </StyledStatusValue>
                  </div>
                  <div>
                    <StyledStatusHeader>Features Passed</StyledStatusHeader>
                    <StyledStatusValue>
                      {testcaseStatus.totalFeaturesPassed &&
                        testcaseStatus.totalFeaturesPassed.toLocaleString()}
                    </StyledStatusValue>
                  </div>
                  <div>
                    <StyledStatusHeader> Total Test Cases</StyledStatusHeader>
                    <StyledStatusValue>
                      {testcaseStatus.testcasePassed &&
                        (
                          testcaseStatus.testcasePassed +
                          testcaseStatus.testcaseNotTested +
                          testcaseStatus.testcaseFailed +
                          testcaseStatus.testcaseHold +
                          testcaseStatus.testcaseBlocked
                        ).toLocaleString()}
                    </StyledStatusValue>
                  </div>
                  <div>
                    <StyledStatusHeader> Test Cases Passed</StyledStatusHeader>
                    <StyledStatusValue>
                      {testcaseStatus.testcasePassed &&
                        testcaseStatus.testcasePassed.toLocaleString()}
                    </StyledStatusValue>
                  </div>
                  <div>
                    <StyledStatusHeader>
                      Test Cases Not Tested
                    </StyledStatusHeader>
                    <StyledStatusValue>
                      {testcaseStatus.testcaseNotTested &&
                        testcaseStatus.testcaseNotTested.toLocaleString()}
                    </StyledStatusValue>
                  </div>
                  <div>
                    <StyledStatusHeader>Test Cases Failed </StyledStatusHeader>
                    <StyledStatusValue>
                      {testcaseStatus.testcaseFailed &&
                        testcaseStatus.testcaseFailed.toLocaleString()}
                    </StyledStatusValue>
                  </div>
                  <div>
                    <StyledStatusHeader> Test Cases Onhold</StyledStatusHeader>
                    <StyledStatusValue>
                      {testcaseStatus.testcaseHold &&
                        testcaseStatus.testcaseHold.toLocaleString()}
                    </StyledStatusValue>
                  </div>
                  <div>
                    <StyledStatusHeader>Test Cases Blocked </StyledStatusHeader>
                    <StyledStatusValue>
                      {testcaseStatus.testcaseBlocked &&
                        testcaseStatus.testcaseBlocked.toLocaleString()}
                    </StyledStatusValue>
                  </div>
                </div>
              )}
              {release?.data?.releaseStatus == "READY_TO_RELEASE" && (
                <div
                  style={{
                    background: "#E2FCF7",
                    padding: "14px",
                    color: "#095A4A",
                    fontWeight: 500,
                    fontSize: "14px",
                    borderRadius: "6px",
                    marginTop: "10px",
                    width: "153px"
                  }}
                >
                  Ready for Release
                </div>
              )}
              {release?.data?.releaseStatus == "RELEASED" && (
                <div
                  style={{
                    background: "#1ABC9C",
                    padding: "14px",
                    color: "#fff",
                    fontWeight: 500,
                    fontSize: "14px",
                    borderRadius: "6px",
                    marginTop: "10px",
                    width: "123px",
                    display: "flex"
                  }}
                >
                  <img src={TickIcon} />
                  <span style={{ marginLeft: "8px" }}>Released</span>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              background: "#f3f4f7",
              marginTop: "24px",
              padding: "12px 24px",
              margin: "24px -24px 0px -24px"
            }}
          >
            <Grid xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <div style={{ maxWidth: "50px" }}>
                <FormCheckbox control={control} disabled name="allFeatures" />
              </div>
              <Typography
                style={{
                  marginLeft: "20px",
                  marginRight: "24px",
                  fontWeight: 600,
                  fontSize: "18px",
                  color: "#666666"
                }}
              >
                Features
              </Typography>
              <FormSelect
                name="filter"
                options={filterOptions}
                onChange={(e) => setFilter(e.target.value)}
                sx={{ width: "200px", background: "#fff" }}
              />
            </Grid>
            <Grid xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "18px",
                  color: "#666666"
                }}
              >
                Platforms / Testers
              </Typography>
            </Grid>
          </Grid>
          <Grid sx={{ padding: "0px 24px" }} xs={6}>
            <Loader isLoading={isLoading}>
              {options.map((feature) =>
                renderTree(control, testerOptions, feature, 0)
              )}
            </Loader>
          </Grid>
        </Form>
        {duplicateModel && (
          <DuplicateReleaseModal
            title="Duplicate Release"
            onClose={duplicateReleaseModelClose}
            release={release?.data}
          />
        )}
      </Loader>
    </Container>
  );
};
