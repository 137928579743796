import { TableView } from "@components/TableView";
import { GridColDef } from "@mui/x-data-grid-pro";
import {
  getReportPostList,
  getReportCommentList,
  getLivestreamReports,
  getLivestreamCommentsReports
} from "@services/Network";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "../../recoil/auth";
import { DataGridRenderCellTooltip } from "@components/DataGridRenderCellTooltip";
import { styled, Typography } from "@mui/material";
import {
  ModelStream,
  ModelStreamAction
} from "@sportsgravyengineering/sg-api-react-sdk";
import { format } from "date-fns";

const TwoLineText = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
`;

const wentLiveDate = (isoString: string | undefined | Date) => {
  if (!isoString) return "Not Started";
  return format(new Date(isoString), "h:mm a MM/dd/yyyy");
};

const dateFormat = (date: string | Date | undefined) => {
  if (!date) return "-";
  const formatedDate = new Date(date);
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  //@ts-ignore
  return formatedDate.toLocaleDateString("en-US", options);
};

export const ReportTableView = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const { filter, type } = useParams<{ filter: string; type: string }>() as {
    filter: string;
    type: string;
  };
  const [searchParams] = useSearchParams();
  const [tableType] = useState({
    type: type?.charAt(0).toUpperCase() + type.slice(1),
    filter: filter?.charAt(0).toUpperCase() + filter.slice(1)
  });
  const [COLUMNS, setCOLUMNS] = useState<GridColDef[]>([]);

  useEffect(() => {
    if (!searchParams.get("post") || type === "comments") {
      if (filter === "flagged") {
        setCOLUMNS([
          {
            headerName: `${tableType.type.slice(0, -1)} Author`,
            field: "createdBy",
            minWidth: 150,
            valueGetter: ({ row }) =>
              row?.[row.$kind == "Post" ? "createdBy" : "user"]?.person
                ?.firstName +
                " " +
                row?.[row.$kind == "Post" ? "createdBy" : "user"]?.person
                  ?.lastName || "",
            flex: 1
          },
          {
            headerName: "Post Date",
            field: "postDate",
            minWidth: 110,
            renderCell: ({ row }) => {
              return !searchParams.get("post")
                ? dateFormat(row?.createdAt)
                : dateFormat(row?.stream?.createdAt);
            },
            flex: 1
          },
          ...(organizationId
            ? [
                {
                  headerName: "Team/Training Program",
                  field: "team/trainingProgramName",
                  minWidth: 120,
                  flex: 1,
                  renderCell: !searchParams.get("post")
                    ? DataGridRenderCellTooltip("name", 1)
                    : ({ row }) =>
                        row.stream?.teamId
                          ? row.stream?.team?.name
                          : row.stream?.programId
                            ? row.stream?.trainingProgram?.name
                            : "-",
                  sortable: true
                }
              ]
            : []),
          {
            headerName: "Times Flagged",
            field: "timesFlagged",
            valueGetter: ({ row }) => row?._count?.reports || 0,
            minWidth: 90,
            flex: 1
          }
        ]);
      } else if (filter === "approved") {
        setCOLUMNS([
          {
            headerName: "Date Approved",
            field: "createdAt",
            minWidth: 110,
            renderCell: ({ row }) => {
              if (!searchParams.get("post")) return dateFormat(row.createdAt);
              return dateFormat(row.reportActions?.[0]?.createdAt);
            },
            flex: 1
          },
          {
            headerName: "Approved By",
            field: "takenBy",
            minWidth: 110,
            sortable: true,
            renderCell: ({ row }) => {
              if (!searchParams.get("post"))
                return (
                  row.takenBy?.person?.firstName +
                    " " +
                    row.takenBy?.person?.lastName || ""
                );
              return (
                row.reportActions?.[0]?.takenBy?.person?.firstName +
                " " +
                row.reportActions?.[0]?.takenBy?.person?.lastName
              );
            },
            flex: 1
          },
          {
            headerName: `${tableType.type.slice(0, -1)} Author`,
            field: "createdBy",
            minWidth: 150,
            renderCell: ({ row }) => {
              if (!searchParams.get("post"))
                return (
                  row?.[type.slice(0, -1)]?.[
                    row.$kind == "PostCommentAction" ? "user" : "createdBy"
                  ]?.person?.firstName +
                    " " +
                    row?.[type.slice(0, -1)]?.[
                      row.$kind == "PostCommentAction" ? "user" : "createdBy"
                    ]?.person?.lastName || ""
                );
              return row?.user?.person
                ? row.user.person.firstName + " " + row.user.person.lastName
                : "-";
            },
            flex: 1
          },
          {
            headerName: "Post Date",
            field: "postDate",
            minWidth: 110,
            flex: 1,
            renderCell: ({ row }) => {
              if (!searchParams.get("post"))
                return dateFormat(row?.[type.slice(0, -1)]?.createdAt);
              return dateFormat(row.createdAt);
            }
          },
          ...(organizationId
            ? [
                {
                  headerName: "Team/Training Program",
                  field: "team/trainingProgramName",
                  minWidth: 120,
                  renderCell: !searchParams.get("post")
                    ? DataGridRenderCellTooltip("name", 1)
                    : ({ row }) =>
                        row.stream?.teamId
                          ? row.stream?.team?.name
                          : row.stream?.programId
                            ? row.stream?.trainingProgram?.name
                            : "-",
                  flex: 1,
                  sortable: true
                }
              ]
            : []),
          {
            headerName: "Times Flagged",
            field: "timesFlagged",
            renderCell: ({ row }) => {
              if (!searchParams.get("post"))
                return row?.[type.slice(0, -1)]?._count?.reports || 0;
              return row?._count?.reports || 0;
            },
            minWidth: 90,
            flex: 1
          }
        ]);
      } else if (filter === "removed") {
        setCOLUMNS([
          {
            headerName: "Date Removed",
            field: "createdAt",
            minWidth: 110,
            flex: 1,
            renderCell: ({ row }) => {
              if (!searchParams.get("post")) return dateFormat(row.createdAt);
              return dateFormat(row.reportActions?.[0]?.createdAt);
            }
          },
          {
            headerName: "Removed By",
            field: "takenBy",
            renderCell: ({ row }) => {
              if (!searchParams.get("post"))
                return (
                  row.takenBy?.person?.firstName +
                    " " +
                    row.takenBy?.person?.lastName || ""
                );
              return (
                row.reportActions?.[0]?.takenBy?.person?.firstName +
                " " +
                row.reportActions?.[0]?.takenBy?.person?.lastName
              );
            },
            sortable: true,
            minWidth: 110,
            flex: 1
          },
          {
            headerName: `${tableType.type.slice(0, -1)} Author`,
            field: "createdBy",
            minWidth: 150,
            renderCell: ({ row }) => {
              if (!searchParams.get("post"))
                return (
                  row?.[type.slice(0, -1)]?.[
                    row.$kind == "PostCommentAction" ? "user" : "createdBy"
                  ]?.person?.firstName +
                    " " +
                    row?.[type.slice(0, -1)]?.[
                      row.$kind == "PostCommentAction" ? "user" : "createdBy"
                    ]?.person?.lastName || ""
                );
              return row.user.person
                ? row.user.person.firstName + " " + row.user.person.lastName
                : "-";
            },
            flex: 1
          },
          {
            headerName: "Post Date",
            field: "postDate",
            renderCell: ({ row }) => {
              if (!searchParams.get("post"))
                return dateFormat(row?.[type.slice(0, -1)]?.createdAt);
              return dateFormat(row.createdAt);
            },
            minWidth: 110,
            flex: 1
          },
          ...(organizationId
            ? [
                {
                  headerName: "Team/Training Program",
                  field: "team/trainingProgramName",
                  minWidth: 120,
                  renderCell: !searchParams.get("post")
                    ? DataGridRenderCellTooltip("name", 1)
                    : ({ row }) =>
                        row.stream?.teamId
                          ? row.stream?.team?.name
                          : row.stream?.programId
                            ? row.stream?.trainingProgram?.name
                            : "-",
                  flex: 1,
                  sortable: true
                }
              ]
            : []),
          {
            headerName: "Times Flagged",
            field: "timesFlagged",
            renderCell: ({ row }) => {
              if (!searchParams.get("post"))
                return row?.[type.slice(0, -1)]?._count?.reports || 0;
              return row?._count?.reports || 0;
            },
            minWidth: 90,
            flex: 1
          }
        ]);
      }
      return;
    }
    if (searchParams.get("post") === "livestream") {
      setCOLUMNS([
        ...(filter === "shutdown"
          ? [
              {
                headerName: "Date Shutdown",
                field: "shutdownAt",
                minWidth: 110,
                valueGetter: ({ row }: { row: ModelStreamAction }) =>
                  dateFormat(row.createdAt),
                flex: 1
              },
              {
                headerName: "Shutdown By",
                field: "takenBy",
                minWidth: 110,
                flex: 1,
                valueGetter: ({ row }: { row: ModelStreamAction }) =>
                  row.takenBy?.person?.firstName +
                  " " +
                  row.takenBy?.person?.lastName
              }
            ]
          : []),
        ...(filter === "approved"
          ? [
              {
                headerName: "Date Approved",
                field: "createdAt",
                minWidth: 160,
                valueGetter: ({ row }: { row: ModelStreamAction }) =>
                  dateFormat(row.createdAt),
                flex: 1
              },
              {
                headerName: "Approved By",
                field: "takenBy",
                minWidth: 150,
                flex: 1,
                valueGetter: ({ row }: { row: ModelStreamAction }) =>
                  row.takenBy?.person?.firstName +
                  " " +
                  row.takenBy?.person?.lastName
              }
            ]
          : []),
        ...(!organizationId && filter !== "shutdown"
          ? [
              {
                headerName: `Organization`,
                field: "organization",
                minWidth: 150,
                flex: 1,
                valueGetter: ({
                  row
                }: {
                  row: ModelStream & ModelStreamAction;
                }) =>
                  filter === "flagged"
                    ? row.organization?.name
                    : row.stream?.organization?.name
              }
            ]
          : []),
        ...(filter !== "shutdown"
          ? [
              {
                headerName: "Title",
                field: "title",
                minWidth: 150,
                flex: 1,
                renderCell: ({
                  row
                }: {
                  row: ModelStream & ModelStreamAction;
                }) => (
                  <TwoLineText>
                    {filter === "flagged" ? row.title : row?.stream?.title}
                  </TwoLineText>
                )
              }
            ]
          : []),
        {
          headerName: "Filmer",
          field: "createdBy",
          minWidth: 110,
          flex: 1,
          renderCell: ({ row }: { row: ModelStream & ModelStreamAction }) =>
            filter === "flagged"
              ? row.streamedBy && row.streamedBy.person
                ? row.streamedBy?.person?.firstName +
                  " " +
                  row.streamedBy?.person?.lastName
                : "-"
              : row.stream?.streamedBy && row.stream?.streamedBy.person
                ? row.stream?.streamedBy?.person?.firstName +
                  " " +
                  row.stream?.streamedBy?.person?.lastName
                : "-"
        },
        ...(filter !== "approved"
          ? [
              {
                headerName: "Created Date",
                field: "createdAt",
                minWidth: 100,
                flex: 1,
                valueGetter: ({
                  row
                }: {
                  row: ModelStream & ModelStreamAction;
                }) =>
                  filter === "flagged"
                    ? dateFormat(row.createdAt)
                    : dateFormat(row.stream?.createdAt)
              }
            ]
          : []),
        {
          headerName: "Went Live On",
          field: "liveAt",
          minWidth: 150,
          flex: 1,
          sortable: true,
          valueGetter: ({ row }: { row: ModelStream & ModelStreamAction }) =>
            filter === "flagged"
              ? wentLiveDate(row.startedAt)
              : wentLiveDate(row.stream?.startedAt)
        },
        ...(organizationId
          ? [
              {
                headerName: "Team/Training Program",
                field: "team/trainingProgramName",
                minWidth: 220,
                flex: 1,
                sortable: true,
                renderCell: ({
                  row
                }: {
                  row: ModelStream & ModelStreamAction;
                }) =>
                  filter === "flagged"
                    ? row.teamId
                      ? row.team?.name
                      : row.programId
                        ? row.trainingProgram?.name
                        : "-"
                    : row.stream?.teamId
                      ? row.stream?.team?.name
                      : row.stream?.programId
                        ? row.stream?.trainingProgram?.name
                        : "-"
              }
            ]
          : []),
        {
          headerName: "Times Flagged",
          field: "timesFlagged",
          valueGetter: ({ row }: { row: ModelStream & ModelStreamAction }) =>
            filter === "flagged"
              ? row?._count?.reports || 0
              : row.stream?._count?.reports || 0,
          minWidth: 150,
          flex: 1
        }
      ]);
    }
    setRefreshKey((prev) => prev + 1);
  }, [filter, searchParams, type]);

  const onView = (row) => {
    if (searchParams.get("post") === "livestream" && type === "posts") {
      return navigate(`${row?.streamId}?post=livestream`);
    }
    if (searchParams.get("post") === "livestream" && type === "comments") {
      return navigate(`${row?.commentId}?post=livestream`);
    }
    navigate(`${row?.[type.slice(0, -1) + "Id"]}`);
  };

  const filterConfig = {
    field: "interval",
    placeholderOption: {
      label: "All",
      value: ""
    },
    options: [
      {
        label: "Today",
        value: "DAY"
      },
      {
        label: "This Week",
        value: "WEEK"
      },
      {
        label: "This Month",
        value: "MONTH"
      }
    ]
  };
  return (
    <>
      <TableView
        key={Math.random()}
        title={
          !searchParams.get("post") || type === "comments"
            ? `${tableType.filter} ${tableType.type}`
            : searchParams.get("post") === "livestream"
              ? `${tableType.filter} Live Streams`
              : `${tableType.filter} ${tableType.type}`
        }
        useGet={
          searchParams.get("post") != "livestream"
            ? type == "comments"
              ? getReportCommentList
              : getReportPostList
            : type === "posts"
              ? getLivestreamReports
              : getLivestreamCommentsReports
        }
        action={
          searchParams.get("post") != "livestream"
            ? filter != "flagged"
              ? filter?.slice(0, -1).toUpperCase()
              : filter?.toUpperCase()
            : filter === "flagged"
              ? "FLAGGED"
              : filter === "approved"
                ? "APPROVE"
                : "REMOVE"
        }
        columns={COLUMNS}
        filterConfig={filterConfig}
        isDeleteDisabled={() => true}
        hideSortButton={true}
        isEditDisabled={() => true}
        onView={onView}
        refreshKey={refreshKey}
        getRowId={(row) => {
          if (row.$kind === "PostCommentAction") {
            return row.commentActionId;
          }
          if (row.$kind === "StreamCommentAction") {
            return row.commentActionId;
          }
          if (row.$kind === "StreamAction") {
            return row.streamActionId;
          } else if (row.$kind === "Post") {
            return row.postId;
          } else if (row.$kind === "PostComment") {
            return row.commentId;
          } else if (row.$kind === "Stream") {
            return row.streamId;
          } else if (row.$kind === "StreamComment") {
            return row.commentId;
          } else {
            return row.postActionId;
          }
        }}
        defaultSort={
          filter === "flagged" ? [{ field: "timesFlagged", sort: "desc" }] : []
        }
        formSelectType="Interval"
        backButton={true}
      />
    </>
  );
};
