import { FormInput } from "@components/FormInput";
import { UseFormReturn } from "react-hook-form";
import { Grid } from "@mui/material";
import { StyledFormLabel } from "@components/StyledFormLabel";
import { formatNumber } from "@utils/formatNumber";

export const CRMAccountSettings = (props: {
  disabled?: boolean;
  onSave: (values) => void;
  form: UseFormReturn;
  isLoading: boolean;
}) => {
  const { getValues, trigger, setValue, control } = props.form;

  const validateRange = (currentValue: string, prevValue: string) => {
    const sanitizedCurrentValue = currentValue?.replace(/,/g, "");
    const sanitizedPrevValue = prevValue?.replace(/,/g, "");

    const currVal =
      sanitizedCurrentValue !== undefined
        ? parseInt(sanitizedCurrentValue?.split("-")[0])
        : null;
    const currValEnd =
      sanitizedCurrentValue !== undefined
        ? parseInt(sanitizedCurrentValue?.split("-")[1])
        : null;
    const prevVal =
      sanitizedPrevValue !== undefined
        ? parseInt(sanitizedPrevValue?.split("-")[1])
        : null;

    if (
      !sanitizedCurrentValue.includes("-") ||
      sanitizedCurrentValue.split("-").length > 2
    ) {
      return "Invalid Format";
    }
    if (prevVal !== null && currVal !== null && currVal <= prevVal) {
      return `This value must be greater than ${prevVal}`;
    }
    if (currValEnd !== null && currValEnd < currVal!) {
      return "This value must be greater than start value";
    }

    return true;
  };

  const onSave = () => {
    const data = getValues("rating");
    const formattedValue = Object.entries(data).map(([type, range]) => {
      const sanitizedRange = (range as string).replace(/,/g, "");
      const [start, end] = sanitizedRange.split(/[-\s+]+/);
      return {
        type,
        start: parseInt(start, 10),
        end: end === "" ? "+" : parseInt(end, 10)
      };
    });
    props.onSave(formattedValue);
  };
  return (
    <Grid container direction="row" columnSpacing={3} rowSpacing={1.5}>
      <Grid
        item
        xs={12}
        md={6}
        style={{
          paddingTop: "0px"
        }}
      >
        <StyledFormLabel required style={{ fontWeight: 600 }}>
          Rating
        </StyledFormLabel>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        style={{
          paddingTop: "0px"
        }}
      >
        <StyledFormLabel required style={{ fontWeight: 600 }}>
          No. of Yearly Athlete Registrations
        </StyledFormLabel>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormInput
          type="text"
          label=""
          name="orgRatingAAA"
          control={control}
          enteredValue="AAA"
          disabled
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormInput
          type="text"
          label=""
          name="rating.AAA"
          control={control}
          rules={{
            required: "Rating is Required"
          }}
          disabled={true}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <FormInput
          type="text"
          label=""
          name="orgRatingAA"
          control={control}
          enteredValue="AA"
          disabled
          onBlur={onSave}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormInput
          type="text"
          label=""
          name="rating.AA"
          control={control}
          rules={{
            required: "Rating is Required",
            validate: (value) => validateRange(value, getValues("rating.A"))
          }}
          disabled={props.disabled}
          onBlur={(e) => {
            const formattedValue = e.target.value
              .split("-")
              .map((val) => formatNumber(val))
              .join(" - ");
            setValue("rating.AA", formattedValue);

            setValue(
              "rating.AAA",
              `${formatNumber(parseInt(getValues("rating.AA")?.split("-")[1].replace(/,/g, "")) + 1)} +`
            );
            onSave();
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormInput
          type="text"
          label=""
          name="orgRatingA"
          control={control}
          enteredValue="A"
          disabled
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormInput
          type="text"
          label=""
          name="rating.A"
          control={control}
          rules={{
            required: "Rating is Required",
            validate: (value) => validateRange(value, getValues("rating.B"))
          }}
          disabled={props.disabled}
          onBlur={(e) => {
            const formattedValue = e.target.value
              .split("-")
              .map((val) => formatNumber(val))
              .join(" - ");
            setValue("rating.A", formattedValue);
            if (getValues("rating.AA") && getValues("rating.AA") != "")
              trigger("rating.AA");
            onSave();
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <FormInput
          type="text"
          label=""
          name="orgRatingB"
          control={control}
          enteredValue="B"
          disabled
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormInput
          type="text"
          label=""
          name="rating.B"
          control={control}
          rules={{
            required: "Rating is Required",
            format: (value) =>
              /^[0-9]+-[0-9]+$/.test(value) ||
              "Value must be in format start-end",
            validate: (value) => validateRange(value, getValues("rating.C"))
          }}
          disabled={props.disabled}
          onBlur={(e) => {
            const formattedValue = e.target.value
              .split("-")
              .map((val) => formatNumber(val))
              .join(" - ");
            setValue("rating.B", formattedValue);
            if (getValues("rating.A") && getValues("rating.A") != "")
              trigger("rating.A");
            onSave();
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <FormInput
          type="text"
          label=""
          name="orgRatingC"
          control={control}
          enteredValue="C"
          disabled
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormInput
          type="text"
          label=""
          name="rating.C"
          control={control}
          rules={{
            required: "Rating is Required"
          }}
          disabled={props.disabled}
          onBlur={(e) => {
            const formattedValue = e.target.value
              .split("-")
              .map((val) => formatNumber(val))
              .join(" - ");
            setValue("rating.C", formattedValue);
            if (getValues("rating.B") && getValues("rating.B") != "")
              trigger("rating.B");
            onSave();
          }}
        />
      </Grid>
    </Grid>
  );
};
