import { FormInput } from "@components/FormInput";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import {
  ModelFeature,
  ModelFeatureRelease,
  ModelPerson,
  useAdminBuildBuildIdGet,
  useAdminDirectoryGet,
  useAdminFeatureGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import { styled, Typography } from "@mui/material";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormSelect } from "@components/FormSelect";
import { Form } from "@components/crud/Form";
import { FeatureBar } from "@pages/releases/FeatureBar";
import DoneIcon from "@mui/icons-material/Done";

const StatusChip = styled("div")`
  padding: 14px 16px;
  color: #fff;
  border-radius: 6px;
  display: flex;
  align-items: center;
`;

const filterOptions = [
  { label: "All", value: "all" },
  { label: "Mobile", value: "MOB" },
  { label: "Web", value: "WEB" }
];

const renderTree = (control, testerOptions, node, level, platform) => {
  return (
    <div key={node.featureId}>
      <FeatureBar
        key={node.featureId}
        control={control}
        companyDirectory={testerOptions}
        level={level}
        node={node}
        disabled={true}
        showPlatforms={[platform]}
      />
      {node.children &&
        node.children.length > 0 &&
        node.children.map((child) =>
          renderTree(control, testerOptions, child, level + 1, platform)
        )}
    </div>
  );
};

export const BuildView = () => {
  const organizationId = useRecoilValue(organizationAtom);
  const navigate = useNavigate();
  const { buildId } = useParams();
  const [filter, setFilter] = useState("all");
  const [options, setOptions] = useState<ModelFeature[]>([]);

  const { control, reset } = useForm({
    mode: "onTouched",
    defaultValues: {
      name: "",
      description: "",
      filter: "all"
    }
  });
  const { data: data, isLoading: isLoading } = useAdminFeatureGet({
    flatten: false,
    platform: filter,
    endToEnd: true,
    sortField: "name",
    sortDirection: "asc",
    pageSize: 1000
  });
  const { data: companyDirectory } = useAdminDirectoryGet({
    pageSize: 1000
  });

  const { data: build, isLoading: releaseLoading } = useAdminBuildBuildIdGet(
    buildId!
  );

  useEffect(() => {
    if (build?.data) {
      const defaultValues = {
        buildNumber: build.data.buildNumber,
        description: build.data.description,
        platform: build.data.platform
      };
      build.data.features!.map((feature: ModelFeatureRelease) => {
        defaultValues[feature.featureId] = true;
        if (feature.iosTesterId)
          defaultValues[feature.featureId + ".iosTester"] = feature.iosTesterId;
        if (feature.andTesterId)
          defaultValues[feature.featureId + ".andTester"] = feature.andTesterId;
        if (feature.webTesterId)
          defaultValues[feature.featureId + ".webTester"] = feature.webTesterId;
      });
      reset(defaultValues);
    }
  }, [build]);

  const testerOptions = useMemo(
    () =>
      (companyDirectory &&
        companyDirectory.data.persons &&
        companyDirectory.data.persons.map((person: ModelPerson) => {
          return {
            label: person.firstName + " " + person.lastName,
            value: person.personId
          };
        })) ||
      [],
    [companyDirectory?.data]
  );

  useEffect(() => {
    if (data?.data?.features) {
      setOptions(data.data.features);
    }
  }, [data]);
  useEffect(() => {
    if (organizationId) navigate("/not-found");
    const view = hasPermission(
      "ORGANIZATION",
      organizationId!,
      "tech.test-cases",
      "VIEW"
    );
    view.then((res) => {
      if (!res) navigate("/not-found");
    });
  }, [organizationId]);

  return (
    <Container>
      <Loader isLoading={releaseLoading}>
        <Toolbar
          title="View Build"
          backBtnClick={() => navigate("/builds")}
          editBtnClick={() => navigate(`/builds/${buildId}/edit`)}
        />
        <Form sx={{ paddingBottom: "0 !important" }}>
          <Grid container spacing={3}>
            <Grid xs={6}>
              <FormInput
                control={control}
                name="buildNumber"
                type="text"
                label="Build Number"
                disabled
                required={true}
                rules={{
                  required: "Build Number is required"
                }}
              />
            </Grid>
            <Grid xs={6}>
              <FormSelect
                control={control}
                name="platform"
                label="Platform"
                disabled
                options={[
                  {
                    label: "iOS",
                    value: "IOS"
                  },
                  {
                    label: "AND",
                    value: "AND"
                  },
                  {
                    label: "WEB",
                    value: "WEB"
                  }
                ]}
              />
            </Grid>
            <Grid xs={12}>
              <FormInput
                control={control}
                name="description"
                type="text"
                label="Description"
                disabled
              />
            </Grid>
            <Grid xs={12}>
              <Typography variant="formLabel">Status</Typography>
            </Grid>
            <Grid xs={12}>
              <div>
                {build?.data.buildStatus === "PASSED" ? (
                  <StatusChip
                    style={{
                      background: "#1ABC9C",
                      color: "#fff",
                      maxWidth: "110px",
                      gap: "6px"
                    }}
                  >
                    <DoneIcon style={{ height: "20px", width: "20p" }} />
                    <span style={{ fontSize: "14px", fontWeight: 600 }}>
                      Passed
                    </span>
                  </StatusChip>
                ) : build?.data.buildStatus === "FAILED" ? (
                  <StatusChip
                    style={{
                      background: "#FFEEEE",
                      color: "#E82C2C",
                      maxWidth: "73px"
                    }}
                  >
                    <span style={{ fontSize: "14px", fontWeight: 600 }}>
                      Failed
                    </span>
                  </StatusChip>
                ) : (
                  <StatusChip
                    style={{
                      background: "#FBF1BC",
                      color: "#7B5B08",
                      maxWidth: "110px"
                    }}
                  >
                    <span style={{ fontSize: "14px", fontWeight: 600 }}>
                      Not Tested
                    </span>
                  </StatusChip>
                )}
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              background: "#f3f4f7",
              marginTop: "24px",
              padding: "12px 24px",
              margin: "24px -24px 0px -24px"
            }}
          >
            <Grid xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <div style={{ maxWidth: "50px" }}>
                <FormCheckbox control={control} disabled name="allFeatures" />
              </div>
              <Typography
                style={{
                  marginLeft: "20px",
                  marginRight: "24px",
                  fontWeight: 600,
                  fontSize: "18px",
                  color: "#666666"
                }}
              >
                Features
              </Typography>
              <FormSelect
                name="filter"
                options={filterOptions}
                onChange={(e) => setFilter(e.target.value)}
                sx={{ width: "200px", background: "#fff" }}
              />
            </Grid>
            <Grid xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "18px",
                  color: "#666666"
                }}
              >
                Platforms / Testers
              </Typography>
            </Grid>
          </Grid>
          <Grid sx={{ padding: "0px 24px" }} xs={6}>
            <Loader isLoading={isLoading}>
              {options.map((feature) =>
                renderTree(
                  control,
                  testerOptions,
                  feature,
                  0,
                  build?.data.platform
                )
              )}
            </Loader>
          </Grid>
        </Form>
      </Loader>
    </Container>
  );
};
