import { Container } from "@components/crud/Container";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import { useApiSelectOptions } from "@hooks/useApiSelectOptions";
import { organizationAtom } from "@recoil/auth";
import {
  ModelRole,
  ModelRolePermission,
  ModelTeam,
  useAdminLevelGet,
  useAdminPermissionGet,
  useAdminPositionGet,
  useAdminSeasonGet,
  useAdminSportGet,
  useAdminTeamTeamIdDelete,
  useAdminTeamTeamIdGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { useRecoilValue } from "recoil";
import {
  CoachOption,
  CoachSelectionForm
} from "../components/CoachSelectionForm";
import {
  ManagerOption,
  ManagerSelectionForm
} from "../components/ManagerSelectionForm";
import {
  PlayerOption,
  PlayerSelectionForm
} from "../components/PlayerSelectionForm";
import { TeamDetailsForm } from "./TeamDetailsForm";
import { PermissionSelectionForm } from "../components/PermissionSelectionForm";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { useSnackbar } from "notistack";
import { hasPermission } from "@services/Casbin";
import { Form } from "@components/crud/Form";

export const TeamView = () => {
  const navigate = useNavigate();
  const { teamId } = useParams();
  const [toDelete, setToDelete] = useState<ModelTeam | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [permissions, setPermissions] = useState({
    delete: false,
    edit: false
  });

  const organizationId = useRecoilValue(organizationAtom);

  if (!organizationId) {
    navigate("/organizations");
  }

  const [tab, setTab] = useState("Team Details");
  const onTabChange = (event: SyntheticEvent, value: unknown) => {
    setTab(value as string);
  };
  const {
    data: teamResponse,
    isFetching: isFetchingTeam,
    error: error
  } = useAdminTeamTeamIdGet(teamId as string);
  useEffect(() => {
    if (error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
  }, [error]);
  const [roleOptions, setRoleOptions] = useState<ModelRole[]>([]);

  const team = useMemo(() => {
    if (teamResponse?.data?.roles) setRoleOptions([...teamResponse.data.roles]);
    return teamResponse?.data;
  }, [teamResponse]);

  const form = useForm({
    mode: "onTouched"
  });
  const { control, reset } = form;

  const getCoachesOrManagers = (alias) => {
    if (!team) return;
    const invitedUsers = team.invites?.filter(
      (item) => item.invitedFor![0]?.alias === alias
    );
    const activeUsers = team.userRoles?.filter(
      (item) => item.role?.alias === alias
    );
    let invites: CoachOption[] = [],
      accepted: CoachOption[] = [];

    if (activeUsers) {
      accepted = activeUsers.map((user) => {
        const formattedPerson = {
          label: `${user?.user?.person?.firstName} ${user?.user?.person?.lastName}`,
          id: user!.user!.personId!,
          role: alias,
          personId: user!.user!.personId!,
          value: user!.user!.personId!,
          roleId: user.roleId!,
          ...user,
          permissions: user?.permissionOverrides,
          status: "ACTIVE"
        };
        return formattedPerson;
      });
    }
    if (invitedUsers) {
      invites = invitedUsers
        .filter((iu) => !accepted.find((a) => a.id === iu!.person!.personId))
        .map((user) => {
          const formattedPerson = {
            label: `${user?.person?.firstName} ${user?.person?.lastName}`,
            id: user!.person!.personId!,
            value: user!.person!.personId!,
            personId: user!.person!.personId!,
            role: alias,
            roleId: user.invitedFor![0].roleId!,
            ...user,
            status: "PENDING",
            permissions: Object.keys(user.metadata as object).includes(
              "permissions"
            )
              ? (user.metadata as object)["permissions"]
              : []
          };
          return formattedPerson;
        });
    }
    return [...invites, ...accepted];
  };
  const getPlayers = () => {
    if (!team) return;
    const selectedPlayersInvite = team.invites?.filter(
      (item) => item.invitedFor![0]?.alias === "PLAYER"
    );
    const addedPlayers = team.userRoles?.filter(
      (item) => item.role?.alias === "PLAYER"
    );

    const getParentObject = (parentId) => {
      const invitedParent = team.invites?.find(
        (invite) => invite?.personId === parentId
      );
      if (invitedParent)
        return {
          id: invitedParent.personId!,
          personId: invitedParent.personId!,
          value: invitedParent.personId!,
          label: `${invitedParent?.person?.firstName} ${invitedParent?.person?.lastName}`,
          ...invitedParent,
          roleId: invitedParent!.invitedFor![0].roleId!,
          permissions: Object.keys(invitedParent.metadata as object).includes(
            "permissions"
          )
            ? (invitedParent.metadata as object)["permissions"]
            : []
        };
      const addedParent = team.userRoles?.find(
        (player) => player?.user?.personId === parentId
      );
      if (addedParent)
        return {
          id: addedParent.user!.personId!,
          personId: addedParent.user?.person?.personId as string,
          value: addedParent.user!.personId!,
          label: `${addedParent?.user?.person?.firstName} ${addedParent?.user?.person?.lastName}`,
          ...addedParent,
          roleId: addedParent.roleId!,
          permissions: addedParent?.permissionOverrides
        };
    };

    let invites: PlayerOption[] = [],
      accepted: PlayerOption[] = [];
    if (addedPlayers) {
      accepted = addedPlayers.map((player) => {
        const isChild = player?.user?.person?.guardians?.length;
        const formattedPerson = {
          label: `${player?.user?.person?.firstName} ${player?.user?.person?.lastName}`,
          id: player!.user!.personId!,
          personId: player!.user!.personId!,
          value: player!.user!.personId!,
          gender: player?.user?.person?.gender,
          roleId: player.roleId!,
          ...player,
          permissions: player?.permissionOverrides,
          parent: isChild
            ? getParentObject(
                player?.user?.person?.guardians![0].guardian?.personId
              )
            : undefined,
          status: isChild ? "CHILD_ACCEPTED" : "USER_ACCEPTED"
        };
        return formattedPerson;
      });
    }
    if (selectedPlayersInvite) {
      invites = selectedPlayersInvite
        .filter((iu) => !accepted.find((a) => a.id === iu!.person!.personId))
        .map((player) => {
          const checkIfParentAccepted = (parentId) => {
            if (!addedPlayers || !addedPlayers.length) return false;
            const accepted = addedPlayers.findIndex(
              (player) => player!.user!.personId === parentId
            );
            if (accepted !== -1) return true;
            return false;
          };
          const isChild = player?.person?.guardians?.length;
          const formattedPerson = {
            label: `${player?.person?.firstName} ${player?.person?.lastName}`,
            id: player!.person!.personId!,
            value: player!.person!.personId!,
            gender: player?.person?.gender,
            roleId: player.invitedFor![0].roleId!,
            ...player,
            personId: player!.person!.personId!,
            status: isChild
              ? checkIfParentAccepted(
                  player?.person?.guardians![0].guardian?.personId
                )
                ? "PARENT_ACCEPTED"
                : "CHILD_PENDING"
              : "USER_PENDING",
            parent: isChild
              ? getParentObject(
                  player?.person?.guardians![0].guardian?.personId
                )
              : undefined,
            permissions: Object.keys(player.metadata as object).includes(
              "permissions"
            )
              ? ((player.metadata as object)["permissions"] as [])
              : []
          };
          return formattedPerson;
        });
    }
    return [...invites, ...accepted];
  };
  const populateUsers = () => {
    if (!team) return;
    const playersSelected = getPlayers();
    if (playersSelected) setPlayersWithValues([...playersSelected]);
    const coachesSelected = getCoachesOrManagers("COACH");
    if (coachesSelected) setCoachesWithValues([...coachesSelected]);
    const managersSelected = getCoachesOrManagers("MANAGER");
    if (managersSelected) setManagerWithValues([...managersSelected]);
  };
  useEffect(() => {
    if (!team) return;
    if (team.sportId) setSportId(team.sportId);
    populateUsers();
    const defaultValues = {
      name: team.name,
      sportId: team.sportId,
      gender: team.genders,
      level: team.levelId,
      season: team.seasonId
    };
    reset(defaultValues);
  }, [team]);

  const [sportId, setSportId] = useState<string | undefined>(undefined);
  const [, setSeasonId] = useState<string | undefined>(undefined);
  const [players, setPlayers] = useState<string[]>([]);
  const [playersWithValues, setPlayersWithValues] = useState<PlayerOption[]>(
    []
  );
  const [coachesWithValues, setCoachesWithValues] = useState<CoachOption[]>([]);
  const [managerWithValues, setManagerWithValues] = useState<ManagerOption[]>(
    []
  );
  const [defaultPermissions, setDefaultPermissions] = useState<
    (ModelRole & {
      permissions: ModelRolePermission[];
    })[]
  >([]);
  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminTeamTeamIdDelete();

  const onConfirmDelete = async () => {
    if (!toDelete?.teamId) return;
    try {
      await deleteAsync({ teamId: toDelete.teamId });
      enqueueSnackbar("Team deleted successfully", {
        variant: "success"
      });
      setToDelete(null);
      navigate("/teams");
    } catch (error) {
      enqueueSnackbar("Something went wrong! Unable to delete the team.", {
        variant: "error"
      });
      setToDelete(null);
    }
  };
  const [coaches, setCoaches] = useState<string[]>([]);
  const [managers, setManagers] = useState<string[]>([]);

  const { data: permissionRequest } = useAdminPermissionGet();

  const permissionsList = useMemo(() => {
    if (permissionRequest?.data) {
      const children = permissionRequest.data.find(
        (permission) => permission.permissionId === "team"
      )?.children;
      return (
        children?.filter((permission) =>
          permission.permissionId?.includes("associated")
        ) || []
      );
    }
    return [];
  }, [permissionRequest]);

  const coachesSubRole = useMemo(() => {
    return roleOptions
      ?.find((item) => item.alias === "COACH")
      ?.children?.map((item) => {
        return {
          ...item,
          label: item?.name,
          value: item?.roleId
        };
      });
  }, [roleOptions]);

  const managerSubRole = useMemo(() => {
    return roleOptions
      ?.find((item) => item.alias === "MANAGER")
      ?.children?.map((item) => {
        return {
          ...item,
          label: item?.name,
          value: item?.roleId
        };
      });
  }, [roleOptions]);

  const { data: sports, isLoading: isSportLoading } = useAdminSportGet({
    organizationId: organizationId!
  });
  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name!,
        value: sport.sportId!,
        genders: sport.organizations
          ?.find((org) => org.organizationId === organizationId)
          ?.offeredFor?.map((g) => ({
            label: g,
            value: g
          }))
      })) || [],
    [sports]
  );

  const { options: seasonOptions, isLoading: seasonOptionsLoading } =
    useApiSelectOptions({
      api: useAdminSeasonGet,
      dataField: "results",
      labelField: "name",
      valueField: "seasonId",
      params: {
        sportId: sportId,
        organizationId: organizationId
      },
      options: {
        query: {
          enabled: !!sportId
        }
      }
    });

  const { options: levelOptions, isLoading: levelOptionsLoading } =
    useApiSelectOptions({
      api: useAdminLevelGet,
      dataField: "levels",
      labelField: "name",
      valueField: "levelId",
      params: {
        sportId: sportId,
        organizationId: organizationId
      },
      options: {
        query: {
          enabled: !!sportId
        }
      }
    });

  const { options: positionOptions } = useApiSelectOptions({
    api: useAdminPositionGet,
    dataField: "positions",
    labelField: "name",
    valueField: "positionId",
    params: {
      sportId: sportId
    },
    options: {
      query: {
        enabled: !!sportId
      }
    }
  });

  const getTabs = () => {
    return ["Team Details", "Players", "Coaches", "Managers", "Permissions"];
  };
  const onBackClick = () => {
    navigate("/teams");
  };

  const getBadgeCounts = () => {
    return [
      0,
      playersWithValues.length,
      coachesWithValues.length,
      managerWithValues.length,
      0
    ];
  };

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const del = await checkPermission("team.teams", "DELETE");
      const edit = await checkPermission("team.teams", "EDIT");
      setPermissions({
        delete: del,
        edit
      });
    };
    fetchPermissions();
  }, []);
  return (
    <Container>
      <Toolbar
        title="View Team"
        {...(permissions.edit && {
          editBtnClick: () => navigate(`/teams/${teamId}/edit`)
        })}
        backBtnClick={onBackClick}
        tabs={{
          tabs: getTabs(),
          onTabChange: onTabChange,
          activeTab: tab
        }}
        {...(permissions.delete && {
          deleteBtnClick: () => setToDelete(team!)
        })}
        badgeCounts={getBadgeCounts()}
      />

      <Loader isLoading={isSportLoading || isFetchingTeam}>
        <Grid item container spacing={3}>
          {tab === "Team Details" && (
            <Grid item xs={12}>
              <Form>
                <TeamDetailsForm
                  form={form}
                  disabled={true}
                  isEditing={false}
                  control={control}
                  isLoadingOptions={
                    isSportLoading &&
                    seasonOptionsLoading &&
                    levelOptionsLoading
                  }
                  sportOptions={sportOptions}
                  seasonOptions={seasonOptions}
                  levelOptions={levelOptions}
                  setSportsId={setSportId}
                  setSeasonId={setSeasonId}
                  playersWithValues={playersWithValues}
                  genderOptions={
                    sportOptions.find((s) => s.value === sportId)?.genders || []
                  }
                />
              </Form>
            </Grid>
          )}
          {tab === "Players" && (
            <Grid item xs={12}>
              <PlayerSelectionForm
                form={form}
                disabled={true}
                isEditing={false}
                isLoadingUsers={isFetchingTeam}
                userOptions={[]}
                positionOptions={positionOptions || []}
                players={players}
                playersWithValues={playersWithValues}
                setPlayersWithValues={setPlayersWithValues}
                setPlayers={setPlayers}
                roleId={
                  roleOptions.find((r) => r.alias === "PLAYER")
                    ?.roleId as string
                }
                parentRoleId={
                  roleOptions.find((r) => r.alias === "PARENT")
                    ?.roleId as string
                }
                sportId={sportId}
              />
            </Grid>
          )}
          {tab === "Coaches" && (
            <Grid item xs={12}>
              <CoachSelectionForm
                form={form}
                disabled={true}
                isEditing={false}
                isLoadingUsers={isFetchingTeam}
                userOptions={[]}
                subRoleOptions={coachesSubRole || []}
                coaches={coaches}
                coachesWithValues={coachesWithValues}
                setCoachesWithValues={setCoachesWithValues}
                setCoaches={setCoaches}
                roleId={
                  roleOptions.find((r) => r.alias === "COACH")?.roleId as string
                }
              />
            </Grid>
          )}
          {tab === "Managers" && (
            <Grid item xs={12}>
              <ManagerSelectionForm
                form={form}
                disabled={true}
                isEditing={false}
                isLoadingUsers={isFetchingTeam}
                userOptions={[]}
                subRoleOptions={managerSubRole || []}
                managers={managers}
                managersWithValues={managerWithValues}
                setManagersWithValues={setManagerWithValues}
                setManagers={setManagers}
                roleId={
                  roleOptions.find((r) => r.alias === "MANAGER")
                    ?.roleId as string
                }
              />
            </Grid>
          )}
          {tab === "Permissions" && (
            <Grid item xs={12}>
              <Form>
                <PermissionSelectionForm
                  form={form}
                  disabled
                  defaultPermissions={defaultPermissions}
                  setDefaultPermissions={setDefaultPermissions}
                  playersWithValues={playersWithValues}
                  coachesWithValues={coachesWithValues}
                  managerWithValues={managerWithValues}
                  players={players}
                  coaches={coaches}
                  managers={managers}
                  setManagerWithValues={setManagerWithValues}
                  setCoachesWithValues={setCoachesWithValues}
                  setPlayersWithValues={setPlayersWithValues}
                  playerRole={roleOptions.find((r) => r.alias === "PLAYER")!}
                  parentRole={roleOptions.find((r) => r.alias === "PARENT")!}
                  coachRole={roleOptions.find((r) => r.alias === "COACH")!}
                  coachSubRoles={coachesSubRole || []}
                  managerRole={roleOptions.find((r) => r.alias === "MANAGER")!}
                  managerSubRoles={managerSubRole || []}
                  permissions={permissionsList}
                />
              </Form>
            </Grid>
          )}
        </Grid>
      </Loader>

      <ConfirmationDialog
        open={!!toDelete}
        title="Delete Team"
        body={`Are you sure you want to delete ${toDelete?.name}?`}
        close={() => setToDelete(null)}
        onConfirm={onConfirmDelete}
        onCancel={() => setToDelete(null)}
        isConfirming={isDeleting}
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </Container>
  );
};
