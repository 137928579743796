/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Avatar,
  Popover,
  Box,
  IconButton,
  Toolbar,
  Typography,
  Paper,
  Backdrop,
  CircularProgress
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useMemo, useState } from "react";
import { Container } from "./crud/Container";
import Grid from "@mui/material/Unstable_Grid2";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { VisibilityOff } from "@mui/icons-material";
import { FeedMediaGrid } from "./FeedMediaGrid";
import { FeedComment } from "./FeedComment";
import { PostCommentAction } from "./PostCommentAction";
import { removePost } from "@services/Network";
import { useRecoilValue } from "recoil";
import { organizationAtom, organizationsAtom } from "../recoil/auth";
import CelebrateIcon from "@assets/icons/celebrate.svg";
import ConfusedIcon from "@assets/icons/confused.svg";
import InsightfulIcon from "@assets/icons/insightful.svg";
import LikeIcon from "@assets/icons/like.svg";
import LoveIcon from "@assets/icons/love.svg";
import NeutralIcon from "@assets/icons/neutral.svg";
import { Link } from "react-router-dom";
import { LivestreamPost } from "@pages/feed/components/LivestreamPost";
import {
  LookupOgResponse,
  ModelTag,
  useLookupOgPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { ConfirmationDialog } from "./ConfirmationDialog";

const StyledToolbar = styled(Toolbar)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "2rem 0",

  "& h2": {
    fontSize: "32px",
    fontWeight: 300
  },
  "& .rePost": {
    padding: "30px 0px 3px 0px !important"
  }
}));
const StyledLabel = styled(Typography)(() => ({
  color: "#838383",
  fontFamily: "Inter",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "16px",
  " &.comment-hover": {
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer"
    }
  }
}));
const StyledReactions = styled(Avatar)(() => ({
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  backgroundColor: "#fff",
  border: "0.5px solid #C8CDF8",
  marginLeft: "-4px"
}));
const StyledPaper = styled(Paper)(({ theme }) => ({
  width: "696px",
  marginTop: "-2rem",
  display: "flex",
  flexWrap: "wrap",
  fontFamily: "Open Sans",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "8px",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  background: "#FFF",
  padding: "15px",
  boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.13)",
  "& h2": {
    fontSize: "32px",
    fontWeight: 300
  },
  [theme.breakpoints.down("md")]: {
    width: "100%"
  }
}));
const LinkPreviewBox = styled(Box)`
  cursor: pointer;
  background-color: #efefef;
  border-radius: 6px;
  margin-top: 12px;
  width: 100%;
  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    max-height: 400px;
  }
  div {
    background-color: #efefef;
    padding: 15px 15px 15px 15px;
  }
  div p:nth-child(1) {
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    color: #000;
  }
  div p:nth-child(2) {
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    color: #666;
  }
`;

export const FeedPostView = (props: {
  content;
  userId;
  rePost;
  setPrevData?;
}) => {
  const [urls, setUrls] = useState<string[]>([]);
  const [urlMetaData, setUrlMetadata] = useState<undefined | LookupOgResponse>(
    undefined
  );

  const initialExpandedState = {};
  const [expandedState, setExpandedState] = useState(initialExpandedState);
  const [expandedCommentState, setExpandedCommentState] =
    useState(initialExpandedState);
  const [popoverText, setPopoverText] = useState("");
  const [showCommentsCount, setshowCommentsCount] = useState({});
  const [deleteExplanation, setDeleteExplanation] = useState({ message: "" });
  const [showMediaGrid] = useState(props.content?.media?.length > 0);
  const [onDelete, setOnDelete] = useState(false);
  const organizationId = useRecoilValue(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const toggleSeeMore = (id) => {
    setExpandedState((prevExpandedState) => ({
      ...prevExpandedState,
      [id]: !prevExpandedState[id]
    }));
  };

  const toggleComment = (id) => {
    setshowCommentsCount((prevshowCommentsCount) => ({
      ...prevshowCommentsCount,
      [id]: 3
    }));
    setExpandedCommentState((prevExpandedCommentState) => ({
      ...prevExpandedCommentState,
      [id]: !prevExpandedCommentState[id]
    }));
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const handlePopover = (event: React.MouseEvent<HTMLButtonElement>, text) => {
    setPopoverText(text);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDeleteClick = () => {
    setAnchorEl(null);
    setOnDelete(true);
  };
  const handleDeleteClose = () => {
    setOnDelete(false);
  };
  const handleDeleteConfirmation = () => {
    setOnDelete(false);
    setDeleteConfirmation(true);
  };
  useEffect(() => {
    if (onDelete || deleteConfirmation) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [onDelete, deleteConfirmation]);
  const mutation = removePost();
  const deletePost = () => {
    mutation.mutate(
      {
        data: {
          ...(organizationId && { organizationId: organizationId }),
          postId: props.content.postId,
          explanation: deleteExplanation.message,
          action: "REMOVE"
        }
      },
      {
        onSuccess: () => {
          props.setPrevData((prevData) =>
            prevData.filter((item) => item.postId !== props.content.postId)
          );
        }
      }
    );
  };
  window.addEventListener("scroll", handleClose);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const getUniqueReactions = (reactions) => {
    const uniqueReactions = [
      ...new Set(reactions.map((reaction) => reaction.type))
    ];
    return uniqueReactions;
  };

  const getNumberOfLinesFromText = (
    text,
    fontSize = 16,
    containerWidth = 525
  ) => {
    const tempElement = document.createElement("div");
    tempElement.style.width = `${containerWidth}px`;
    tempElement.style.fontSize = `${fontSize}px`;
    tempElement.style.visibility = "hidden";
    tempElement.style.whiteSpace = "pre-wrap";
    document.body.appendChild(tempElement);
    tempElement.innerText = text;
    const textHeight = tempElement.clientHeight;
    const lineHeight = parseFloat(getComputedStyle(tempElement).lineHeight);
    const numberOfLines = Math.round(textHeight / lineHeight);
    document.body.removeChild(tempElement);
    return numberOfLines;
  };

  const dateFormat = (date: string) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
      hour12: true
    };
    //@ts-ignore
    return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
  };
  const feedMediaGridDisplay = useMemo(() => {
    if (props.content) {
      return (
        showMediaGrid && (
          <Container
            data-testid="feed=media-grid"
            onClick={(e) => {
              e.stopPropagation();
            }}
            width={"100%"}
          >
            <FeedMediaGrid media={props.content?.media} />
          </Container>
        )
      );
    }
    return null;
  }, [showMediaGrid, props.content]);

  const formatBody = (
    body,
    userMentions,
    orgMentions,
    tags?: ModelTag[] | undefined
  ) => {
    body = body.trimEnd();
    const linkRegex = /(?:https?:\/\/|www\.)[^\s/$.?#].[^\s]*/g;
    const parts = body.split(/([@#][\w\d]+|(?:http|www)\S+|\n)/g);
    const bodyComponents = parts.map((part, index) => {
      if (part.match(/[@][\w\d]+/)) {
        const user = userMentions.find(
          (user) => user.personId === part.slice(1)
        );
        const org = orgMentions.find(
          (org) => org.organizationId === part.slice(1)
        );
        if (user) {
          return (
            <Link
              key={index + 1}
              to={`/users/${user?.personId}`}
              target="_blank"
            >
              <span style={{ color: "#007AFF" }}>
                @{user.firstName} {user.middleName} {user.lastName}
              </span>
            </Link>
          );
        }
        if (org) {
          return (
            <Link
              key={index + 2}
              to={`/organizations/${org?.organizationId}`}
              target="_blank"
            >
              <span key={index + 3} style={{ color: "#007AFF" }}>
                @{org.name}
              </span>
            </Link>
          );
        }
      } else if (part.match(/^#[\w\d]+/)) {
        return (
          <span key={index + 4} style={{ color: "#007AFF" }}>
            {part}
          </span>
        );
      } else if (part.match(linkRegex)) {
        if (urls.length == 0) {
          const url =
            part.startsWith("http://") || part.startsWith("https://")
              ? part.replace(/^http:\/\//i, "https://")
              : "https://" + part;
          setUrls([url]);
        }
        return (
          <a
            href={part}
            style={{ color: "#007AFF" }}
            key={index + 5}
            rel="noopener noreferrer"
          >
            {part}
          </a>
        );
      } else if (part === "\n") {
        return <br key={index + 6} />;
      }
      return part;
    });
    const tagComponents = tags
      ? tags.map((tag, index) => (
          <span
            key={index + 7}
            style={{
              color:
                !!tag?._count?.gameConcepts ||
                !!tag?._count?.gameSystems ||
                !!tag?._count?.skills
                  ? "#007AFF"
                  : "#000"
            }}
          >
            #{tag.name}{" "}
          </span>
        ))
      : [];
    return [
      ...bodyComponents,
      tagComponents.length > 0 && "\n",
      tagComponents.length > 0 && "\n",
      tagComponents.length > 0 && (
        <div key={1 + 8}>
          {body.length > 0 && <br />}
          {tagComponents}
        </div>
      )
    ];
  };

  const feedReactionDisplay = useMemo(() => {
    if (props.content?.reactions?.length > 0 && !props.rePost) {
      return (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px"
            }}
          >
            {getUniqueReactions(props.content.reactions)
              .slice(0, 5)
              .map((reaction, index) => {
                let reactionImage;
                switch (reaction) {
                  case "LIKE":
                    reactionImage = LikeIcon;
                    break;
                  case "LOVE":
                    reactionImage = LoveIcon;
                    break;
                  case "CONFUSED":
                    reactionImage = ConfusedIcon;
                    break;
                  case "CELEBRATE":
                    reactionImage = CelebrateIcon;
                    break;
                  case "INSIGHTFUL":
                    reactionImage = InsightfulIcon;
                    break;
                  case "NEUTRAL":
                    reactionImage = NeutralIcon;
                    break;
                  default:
                    reactionImage = null;
                }

                return (
                  <Grid
                    sx={{
                      width: "12px",
                      height: "12px",
                      marginRight: "7px"
                    }}
                    key={index + 9}
                  >
                    {reactionImage && (
                      <StyledReactions
                        src={reactionImage}
                        sx={{
                          padding: "3px",
                          width: 24,
                          height: 24
                        }}
                      ></StyledReactions>
                    )}
                  </Grid>
                );
              })}
          </Box>
          <Grid sx={{ marginLeft: "8px" }}>
            <StyledLabel data-testid="reaction" marginLeft={1}>
              {props.content.reactions.some(
                (reaction) => reaction.userId === props.userId
              )
                ? `You${
                    props.content.reactions.length > 1
                      ? ` and ${props.content.reactions.length - 1} ${
                          props.content.reactions.length === 2
                            ? "other"
                            : "others"
                        }`
                      : ""
                  }`
                : `${props.content.reactions.length}`}
            </StyledLabel>
          </Grid>
        </>
      );
    }
    return null;
  }, [props.content.reactions]);

  const { mutate: lookup } = useLookupOgPost();
  const lookupHandler = async (url) => {
    if (url) {
      lookup(
        {
          data: {
            url: url
          }
        },
        {
          onSuccess: (data) => {
            setUrlMetadata(data.data);
          }
        }
      );
    }
  };

  useEffect(() => {
    lookupHandler(urls[0]);
  }, [urls]);
  return (
    <>
      <Container
        data-testid="post"
        data-testid1={`post-${props.content.postId}`}
      >
        <StyledToolbar
          className={props.rePost ? "rePost" : ""}
          key={props.content.postId}
        >
          <StyledPaper style={{ maxWidth: "100%" }}>
            <Container>
              <Grid container alignItems="center">
                <Grid>
                  {props.content.createdBy.person.avatarId ? (
                    <Avatar
                      variant="square"
                      src={
                        props.content.createdBy.person.avatar.baseUrl +
                        props.content.createdBy.person.avatar.path
                      }
                      style={{
                        height: "48px",
                        width: "48px",
                        borderRadius: "8px"
                      }}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        bgcolor: "#" + props.content.createdBy.person.colorCode,
                        color: "#fff !important",
                        padding: "14px",
                        fontSize: "14px",
                        fontWeight: 700,
                        lineHeight: "20px",
                        textAlign: "center",
                        height: "48px",
                        width: "48px",
                        borderRadius: "8px"
                      }}
                      variant="square"
                    >
                      {`${props.content.createdBy.person.firstName} ${props.content.createdBy.person.lastName}`
                        .split(" ")
                        .map((name) => name[0])
                        .join("")
                        .toUpperCase()}
                    </Avatar>
                  )}
                </Grid>

                <Grid marginLeft={"11px"} xs container direction="column">
                  <Typography fontSize={"14px"} fontWeight={600}>
                    {`${props.content.createdBy.person.firstName} ${props.content.createdBy.person.middleName} ${props.content.createdBy.person.lastName}`}
                  </Typography>
                  <Typography fontSize={"14px"} fontWeight={400}>
                    {dateFormat(props.content.updatedAt)}
                  </Typography>
                </Grid>
                {!props.rePost && (
                  <IconButton onClick={(event) => handlePopover(event, "post")}>
                    <MoreHorizIcon />
                  </IconButton>
                )}
              </Grid>
            </Container>
            <Container
              data-testid="post-body"
              margin={"15px 0 0 0 !important"}
              style={{ position: "relative" }}
            >
              {(props.content.body != "" ||
                expandedState[props.content.postId]) && (
                <Typography
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: expandedState[props.content.postId]
                      ? "unset"
                      : props.content?.media?.length === 0
                        ? 5
                        : 2,
                    WebkitBoxOrient: "vertical",
                    textAlign: "justify",
                    fontSize: "16px",
                    fontWeight: "400",
                    overflowWrap: "break-word"
                  }}
                >
                  {formatBody(
                    props.content.body,
                    props.content.userMentions,
                    props.content.organizationMentions,
                    props.content?.tags
                  )}
                </Typography>
              )}
              {props.content.body === "" &&
                props.content.tags.length > 0 &&
                !expandedState[props.content.postId] && <br />}

              {((props.content.media &&
                props.content?.media?.length > 0 &&
                getNumberOfLinesFromText(
                  formatBody(
                    props.content.body,
                    props.content.userMentions,
                    props.content.organizationMentions,
                    props.content.tags
                  )
                ) > 2) ||
                (props.content?.media?.length === 0 &&
                  getNumberOfLinesFromText(
                    formatBody(
                      props.content.body,
                      props.content.userMentions,
                      props.content.organizationMentions,
                      props.content.tags
                    )
                  ) > 5)) &&
                !expandedState[props.content.postId] && (
                  <Typography
                    sx={{
                      cursor: "pointer",
                      width: "fit-content",
                      position: "absolute",
                      bottom: "0",
                      left: "86%",
                      backgroundColor: "#fff"
                    }}
                    color="#666"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleSeeMore(props.content.postId);
                    }}
                  >
                    ... see more
                  </Typography>
                )}
              {props.content.repostOf && (
                <button
                  data-testid="repost-redirect"
                  style={{
                    border: "none",
                    background: "none",
                    textAlign: "left",
                    cursor:
                      (organizationId &&
                        props.content.repostOf.organizations.some(
                          (org) => org.organizationId === organizationId
                        )) ||
                      !organizationId ||
                      props.content.repostOf.organizations.length == 0
                        ? "pointer"
                        : "default"
                  }}
                  onClick={() => {
                    if (
                      (organizationId &&
                        props.content.repostOf.organizations.some(
                          (org) => org.organizationId === organizationId
                        )) ||
                      !organizationId ||
                      props.content.repostOf.organizations.length == 0
                    ) {
                      window.open(
                        `${window.location.origin}/${
                          "feed/" + props.content.repostOf.postId
                        }`
                      );
                    }
                  }}
                >
                  <Container
                    style={{
                      width: "100%",
                      marginTop: "10px"
                    }}
                  >
                    <FeedPostView
                      rePost={true}
                      content={props.content.repostOf}
                      userId={"1"}
                    />
                  </Container>
                </button>
              )}
            </Container>
            {feedMediaGridDisplay}
            {props.content?.liveStream && (
              <LivestreamPost livestream={props.content?.liveStream} />
            )}
            {props.content.media &&
              !props.content.liveStream &&
              props.content.media.length == 0 &&
              urlMetaData && (
                <LinkPreviewBox
                  onClick={() => window.open(urlMetaData.url, "_blank")}
                >
                  {urlMetaData.image && <img src={urlMetaData.image} />}
                  {(urlMetaData.title || urlMetaData.url) && (
                    <Box>
                      {urlMetaData?.title && (
                        <Typography>{urlMetaData?.title}</Typography>
                      )}
                      {urlMetaData.url && (
                        <Typography
                          style={{ cursor: "pointer", wordWrap: "break-word" }}
                          onClick={() => window.open(urlMetaData.url, "_blank")}
                        >
                          {urlMetaData.url}
                        </Typography>
                      )}
                    </Box>
                  )}
                </LinkPreviewBox>
              )}
            <Container>
              {!props.rePost &&
                (props.content?._count?.comments > 0 ||
                  props.content?._count.shares > 0 ||
                  props.content?.reactions?.length > 0) && (
                  <Grid
                    container
                    marginTop={2}
                    justifyContent="space-between"
                    alignItems="center"
                    marginLeft={"5px"}
                  >
                    <Grid container alignItems="center">
                      {feedReactionDisplay}
                    </Grid>
                    <Grid container spacing={1}>
                      {props.content?._count?.comments > 0 && (
                        <Grid>
                          <StyledLabel
                            onClick={() => toggleComment(props.content.postId)}
                            className="comment-hover"
                          >
                            {props.content?._count?.comments === 1
                              ? "1 comment"
                              : // : `${props.content.comment.reduce(
                                //     (acc, comment) =>
                                //       acc + 1 + comment.replies.length,
                                //     0,
                                //   )} comments`}
                                `${props.content?._count?.comments} comments`}
                          </StyledLabel>
                        </Grid>
                      )}
                      {props.content?._count.shares > 0 && (
                        <Grid>
                          <StyledLabel>
                            {props.content?._count.shares === 1
                              ? "1 share"
                              : `${props.content?._count.shares} shares`}
                          </StyledLabel>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
            </Container>
            {expandedCommentState[props.content.postId] && (
              <FeedComment
                formatDate={dateFormat}
                formatBody={formatBody}
                postId={props.content.postId}
                comment={props.content.comment}
                commentCount={showCommentsCount}
              />
            )}
          </StyledPaper>
        </StyledToolbar>

        <Popover
          style={{ marginLeft: "25px" }}
          id={id}
          open={open}
          anchorEl={anchorEl}
          disableScrollLock={true}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <Container>
            <Grid
              sx={{
                borderRadius: "4px",
                padding: "12px",
                alignItems: "center",
                ":hover": {
                  backgroundColor: "#EBEBEB"
                }
              }}
              container
            >
              <Grid>
                <VisibilityOff />
              </Grid>

              <Grid
                style={{ cursor: "pointer" }}
                onClick={handleDeleteClick}
                marginLeft={"11px"}
                xs
                container
                direction="column"
              >
                <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                  Remove {popoverText}{" "}
                  {!organizationId
                    ? "from SportsGravy"
                    : "within organization "}
                </Typography>
                <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
                  Control this {popoverText}’s visibility within{" "}
                  {!organizationId ? "SportsGravy" : "your organization "}
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Popover>
      </Container>
      {onDelete && (
        <PostCommentAction
          setMessage={setDeleteExplanation}
          onClick={handleDeleteConfirmation}
          id={props.content.postId}
          displayMessage={
            !organizationId
              ? "Removing this post will delete it from the SportsGravy feed. Please provide an explanation to the author of this post."
              : "Removing this post will delete it from the feed of users within your organization who received this content. Please provide an explanation to the original poster author."
          }
          action={"delete"}
          button={"Remove Post"}
          onClose={handleDeleteClose}
          title={`Remove Post ${
            !organizationId ? "from SportsGravy" : "within Organization"
          }`}
          field={[
            {
              field: "Post Author",
              value: `${props.content.createdBy.person.firstName} ${props.content.createdBy.person.middleName} ${props.content.createdBy.person.lastName}`
            },
            {
              field: "Organization Removed From",
              value: `${
                !organizationId
                  ? "All SportsGravy Users"
                  : organizations.find(
                      (org) => org.organizationId === organizationId
                    )?.name
              }`
            }
          ]}
        />
      )}
      {deleteConfirmation && (
        <ConfirmationDialog
          open={deleteConfirmation}
          title="Delete Post"
          body={`Are you sure you want to delete this post ${
            !organizationId ? "from SportsGravy" : "within your organization"
          }? This change cannot be undone.`}
          close={() => setDeleteConfirmation(false)}
          onConfirm={() => {
            setDeleteConfirmation(false);
            deletePost();
          }}
          onCancel={() => setDeleteConfirmation(false)}
          confirmBtnVariant="admin-error"
          icon="error"
        />
      )}
      {mutation.isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={mutation.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
};
