import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import { FilterTreeSearchComponent } from "@components/FilterTreeSearchComponent";
import { FormInput } from "@components/FormInput";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { FormSelect } from "@components/FormSelect";
import { FormHelperText, styled } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import { selectedFilterAtom } from "@recoil/auth";
import {
  useAdminFeatureFeatureIdGet,
  useAdminFeatureTestcaseBulkPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { PLATFORM_OPTIONS, TESTCASE_STATUS_OPTIONS } from "@utils/constants";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";

const StyledHeader = styled(Grid)`
  padding: 16px 24px;
  border-bottom: 1px solid #e2e8f0;
  font-weight: 700;
  color: #1e293b;
  font-size: 16px;
`;

const StyledCell = styled(Grid)`
  padding: 16px 24px;
  border-bottom: 1px solid #e2e8f0;
`;

export const BulkTestcaseCreate = () => {
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { isValid }
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      status: "NOT_TESTED",
      platforms: [],
      featureId: ""
    }
  });
  const selectedFeatureId = useRecoilValue(selectedFilterAtom);
  const [tableData, setTableData] = useState<string[][]>([]);
  const [featureId, setFeatureId] = useState<string | undefined>(undefined);
  const [platformOptions, setPlatformOptions] = useState(PLATFORM_OPTIONS);
  const [featureFieldFocused, setFeatureFieldFocused] = useState<boolean>();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const { data: selectedFeature, isLoading: isSelectedFeatureLoading } =
    useAdminFeatureFeatureIdGet(
      featureId!,
      {},
      {
        query: {
          enabled: !!featureId
        }
      }
    );
  useEffect(() => {
    if (selectedFeatureId) setFeatureId(selectedFeatureId);
  }, [selectedFeatureId]);

  useEffect(() => {
    if (selectedFeature?.data && selectedFeature.data.platforms) {
      setPlatformOptions([]);
      setValue("featureId", selectedFeature.data.name!);
      selectedFeature.data.platforms.map((platform) => {
        if (platform == "IOS") {
          setPlatformOptions((prev) => [
            ...prev,
            { label: "iOS", value: "IOS" }
          ]);
        }
        if (platform == "WEB") {
          setPlatformOptions((prev) => [
            ...prev,
            { label: "Web", value: "WEB" }
          ]);
        }
        if (platform == "AND") {
          setPlatformOptions((prev) => [
            ...prev,
            { label: "AND", value: "AND" }
          ]);
        }
        if (platform == "API") {
          setPlatformOptions((prev) => [
            ...prev,
            { label: "API", value: "API" }
          ]);
        }
        if (platform == "DEV") {
          setPlatformOptions((prev) => [
            ...prev,
            { label: "DEVops", value: "DEV" }
          ]);
        }
      });
    }
  }, [selectedFeature]);

  const status = watch("status");

  const handlePaste = (event) => {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData("Text");
    const parsedData: string[][] = [];
    let currentRow: string[] = [];
    let currentCell = "";
    let insideQuotes = false;

    for (let i = 0; i < clipboardData.length; i++) {
      const char = clipboardData[i];

      if (char === '"') {
        insideQuotes = !insideQuotes;
      } else if (char === "\t" && !insideQuotes) {
        if (currentRow.length < 2) {
          currentRow.push(currentCell);
        }
        currentCell = "";
      } else if ((char === "\n" || char === "\r") && !insideQuotes) {
        if (currentCell || currentRow.length > 0) {
          if (currentRow.length < 2) {
            currentRow.push(currentCell);
          }
          parsedData.push(currentRow);
        }
        currentRow = [];
        currentCell = "";
      } else {
        currentCell += char;
      }
    }
    if (currentCell || currentRow.length > 0) {
      if (currentRow.length < 2) {
        currentRow.push(currentCell);
      }
      parsedData.push(currentRow);
    }
    setTableData(parsedData);
  };

  const { mutate: save, isLoading: isSaving } =
    useAdminFeatureTestcaseBulkPost();
  const saveHandler = async (formValues) => {
    const values = {
      ...formValues
    };
    const testcases: {
      description: string | null;
      expectedResult: string;
    }[] = [];
    let lastDefinedDescription = null;

    values.testcases.forEach(([description, expectedResult]) => {
      if (description) {
        lastDefinedDescription = description;
      }
      testcases.push({
        description: lastDefinedDescription,
        expectedResult
      });
    });
    const data = {
      featureId: featureId!,
      platforms: values.platforms,
      status: values.status,
      reason: values.reason,
      testcases: testcases
    };
    try {
      save(
        {
          data: data
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Testcases added successfully!", {
              variant: "success"
            });

            window.history.back();
          },
          onError: () => {
            enqueueSnackbar("Failed to add testcase!", {
              variant: "error"
            });
          }
        }
      );
    } catch (error) {
      enqueueSnackbar("Failed to add testcase!", {
        variant: "error"
      });
    }
  };

  return (
    <Container>
      <Toolbar title="Import Test Cases" />
      <Form>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <FilterTreeSearchComponent
              control={control}
              name="featureId"
              required={true}
              rules={{
                required: "Feature is required"
              }}
              selectedValue={featureId}
              label="Feature"
              onFilterChange={(val) => {
                setFeatureId(val);
                setValue("platforms", []);
              }}
              onClearFilter={() => {
                setFeatureId("");
                setValue("platforms", []);
              }}
              setValue={setValue}
              TextProps={{
                onBlur: () => {
                  setFeatureFieldFocused(false);
                },
                onFocus: () => {
                  setFeatureFieldFocused(true);
                }
              }}
            />
            <FormHelperText error={true}>
              {featureFieldFocused == false &&
                featureId == "" &&
                "Feature is required"}
            </FormHelperText>
          </Grid>
          <Grid xs={6}>
            <FormMultiSelect
              control={control}
              options={platformOptions}
              name="platforms"
              label="Platforms"
              disabled={!featureId}
              isLoading={isSelectedFeatureLoading && !!featureId}
              required={true}
              rules={{
                required: "Platforms is required"
              }}
            />
          </Grid>
          <Grid xs={6}>
            <FormSelect
              control={control}
              options={TESTCASE_STATUS_OPTIONS.filter(
                (status) =>
                  status.value !== "FAILED" && status.value !== "BLOCKED"
              )}
              name="status"
              label="Status"
              required={true}
              rules={{
                required: "Status is required"
              }}
            />
          </Grid>
          {status === "HOLD" && (
            <>
              <Grid xs={6}></Grid>
              <Grid xs={6}>
                <FormInput
                  control={control}
                  name="reason"
                  type="text"
                  multiline
                  rows={4}
                  label="Reason"
                  rules={{
                    required: "Reason is required"
                  }}
                  required
                />
              </Grid>
            </>
          )}
          <Grid xs={12}>
            <div
              style={{
                width: "100%",
                boxShadow: "0px 4px 6px 0px #00000014",
                border: "1px solid #0000001F",
                borderRadius: "10px"
              }}
            >
              <div>
                <Grid container>
                  <StyledHeader
                    xs={6}
                    display="flex"
                    justifyContent="center"
                    sx={{
                      borderRight: "1px solid #E2E8F0"
                    }}
                  >
                    Test Case Description
                  </StyledHeader>
                  <StyledHeader xs={6} display="flex" justifyContent="center">
                    Expected Result
                  </StyledHeader>
                </Grid>
              </div>
              <div>
                {tableData.length === 0 && (
                  <StyledCell
                    xs={12}
                    contentEditable
                    onPaste={handlePaste}
                    style={{ height: "100px" }}
                  >
                    Paste here!
                  </StyledCell>
                )}
                {tableData.map((row, rowIndex) => (
                  <Grid key={rowIndex} container>
                    {row.map((cell, cellIndex) => (
                      <StyledCell
                        xs={6}
                        key={cellIndex}
                        style={{
                          borderRight:
                            cellIndex === 0 ? "1px solid #E2E8F0" : "none"
                        }}
                      >
                        <div style={{ whiteSpace: "pre-wrap" }}>{cell}</div>
                        <div style={{ display: "none" }}>
                          <FormInput
                            label=""
                            control={control}
                            name={`testcases[${rowIndex}][${cellIndex}]`}
                            type="hidden"
                            enteredValue={cell}
                          />
                        </div>
                      </StyledCell>
                    ))}
                  </Grid>
                ))}
              </div>
            </div>
          </Grid>
        </Grid>
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler)}
        isDisabled={!isValid || isSaving}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => {
          window.history.back();
        }}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
