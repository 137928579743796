import { Grid, IconButton } from "@mui/material";
import { FormDatePicker } from "./FormDatePicker";
import { FormTimePicker } from "./FormTimePicker";
import { FormSelect } from "./FormSelect";
import { UseFormReturn } from "react-hook-form";
import { Button } from "./Button";
import { AddIcon } from "./Icons";
import { Cancel } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { CustomRecurrenceDialog } from "./CustomRecurrenceDialog";
import { RRule } from "rrule";
import convertRruleToMessage from "@utils/ConvertRruleToMessage";
// import { useEffect, useState } from "react";

export const ProgramDateAndTime = ({
  form,
  index,
  clearable,
  onlyTime,
  isLastElement,
  isImmediateChildDateTime,
  onAddElement,
  onDeleteElement,
  component,
  onChangeInput,
  disabled,
  isEditing
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any, undefined>;
  index: number;
  clearable?: boolean;
  onlyTime?: boolean;
  isLastElement?: boolean;
  isImmediateChildDateTime?: boolean;
  onAddElement: (component, index) => void;
  onDeleteElement?: (index) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
  onChangeInput: (type, event) => void;
  disabled?: boolean;
  isEditing?: boolean;
}) => {
  const { control } = form;
  const repeatoptionsStandard = [
    {
      label: "Does not repeat",
      value: "DNR"
    },
    {
      label: "Every day ",
      value: "DAILY"
    },
    {
      label: "Every Week",
      value: "WEEKLY"
    },
    {
      label: "Every Month",
      value: "MONTHLY"
    },
    {
      label: "Custom",
      value: "Custom"
    }
  ];
  const addTimeComponent = {
    clearable: true,
    control: control,
    onlyTime: true
  };
  const addDateTimeComponent = {
    clearable: true,
    control: control,
    onlyTime: false
  };
  const [isCustomPopupOpen, setCustomPopupOpen] = useState(false);
  const [repeatoptions, setRepeatOptions] = useState(repeatoptionsStandard);
  const [repeatValue, setRepeatValue] = useState("");
  useEffect(() => {
    const { freqName, isCustomText, customText } = convertRruleToMessage(
      component.customRepeat
    );
    if (isCustomText) {
      const updatedoptions = [...repeatoptions];
      const i = repeatoptions.findIndex(
        (option) => option.value === "CUSTOM_TEXT"
      );
      if (i === -1) {
        updatedoptions.push({ label: customText, value: "CUSTOM_TEXT" });
      } else {
        updatedoptions[i].label = customText;
      }
      setRepeatOptions(updatedoptions);
      setRepeatValue("CUSTOM_TEXT");
      return;
    }
    setRepeatValue(freqName);
  }, [component]);

  const setupRepeat = () => {
    const { freqName, isCustomText, customText } = convertRruleToMessage(
      component.customRepeat
    );
    if (isCustomText) {
      const updatedoptions = [...repeatoptions];
      const i = repeatoptions.findIndex(
        (option) => option.value === "CUSTOM_TEXT"
      );
      if (i === -1) {
        updatedoptions.push({ label: customText, value: "CUSTOM_TEXT" });
      } else {
        updatedoptions[i].label = customText;
      }
      setRepeatOptions(updatedoptions);
      setRepeatValue("CUSTOM_TEXT");
      return;
    }
    setRepeatValue(freqName);
  };
  return (
    <>
      <Grid item container direction="row" spacing="24px">
        {!onlyTime && (
          <>
            <Grid item xs={2.5} data-testid="PROGRAM_DATE_TIME_START_DATE">
              <FormDatePicker
                name={`startDate[${index}]`}
                disabled={disabled}
                minDate={new Date()}
                selectedValue={component.startDate}
                required
                rules={{
                  required: "Start Date is required"
                }}
                control={control}
                label="Start Date"
                onChange={(event) => {
                  onChangeInput("startDate", event);
                }}
                trigger={form.trigger}
              />
            </Grid>
            <Grid item xs={2.5} data-testid="PROGRAM_DATE_TIME_END_DATE">
              <FormDatePicker
                name={`endDate[${index}]`}
                disabled={disabled}
                minDate={
                  component.startDate
                    ? component.startDate > new Date()
                      ? component.startDate
                      : new Date()
                    : new Date()
                }
                selectedValue={component.endDate}
                required
                control={control}
                label="End Date"
                rules={{
                  required: "End Date is required"
                }}
                onChange={(event) => {
                  onChangeInput("endDate", event);
                }}
                trigger={form.trigger}
              />
            </Grid>
          </>
        )}
        <Grid item xs={2} data-testid="PROGRAM_DATE_TIME_START_TIME">
          <FormTimePicker
            name={`startTime[${index}]`}
            disabled={disabled}
            selectedValue={component.startTime}
            required
            control={control}
            label="Start Time"
            rules={{
              required: "Start Time is required"
            }}
            onChange={(event) => {
              onChangeInput("startTime", event);
            }}
          />
        </Grid>
        <Grid item xs={2} data-testid="PROGRAM_DATE_TIME_END_TIME">
          <FormTimePicker
            name={`endTime[${index}]`}
            disabled={disabled}
            selectedValue={component.endTime}
            required
            control={control}
            minTime={component.startTime ? component.startTime : undefined}
            label="End Time"
            rules={{
              required: "End Time is required"
            }}
            onChange={(event) => {
              onChangeInput("endTime", event);
            }}
          />
        </Grid>
        {!disabled && clearable && (
          <Grid item xs={1.5} data-testid="PROGRAM_DATE_TIME_CLEAR_BTN">
            <IconButton
              style={{ marginTop: "24px" }}
              onClick={() => {
                onDeleteElement!(index);
              }}
            >
              <Cancel color="action" fontSize="large" />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Grid item container spacing={4}>
        <Grid item xs={4} data-testid="PROGRAM_DATE_TIME_REPEAT">
          <FormSelect
            required
            //name={`repeat[${index}]`}
            disabled={disabled}
            value={repeatValue}
            label="Repeat"
            options={repeatoptions}
            onChange={(e) => {
              setRepeatValue(e.target.value);
              if (e.target.value === "DNR") onChangeInput("customRepeat", {});
              else if (e.target.value === "Custom") {
                setCustomPopupOpen(true);
              } else {
                const customRepeat = new RRule({
                  freq: RRule[e.target.value],
                  interval: 1
                });
                onChangeInput("customRepeat", customRepeat.origOptions);
              }
            }}
          />
        </Grid>
      </Grid>
      {!disabled && (isLastElement || isImmediateChildDateTime) && (
        <Grid item container direction="row" spacing="24px">
          {isLastElement && (
            <Grid item data-testid="PROGRAM_DATE_TIME_ADD_DATE_TIME">
              <Button
                variant="admin-primary"
                type="button"
                onClick={() => {
                  onAddElement(addDateTimeComponent, index);
                }}
                startIcon={<AddIcon />}
              >
                Add Date & Time
              </Button>
            </Grid>
          )}
          <Grid item data-testid="PROGRAM_DATE_TIME_ADD_TIME">
            <Button
              variant="admin-primary"
              type="button"
              onClick={() => {
                onAddElement(addTimeComponent, index);
              }}
              startIcon={<AddIcon />}
            >
              Add New Time
            </Button>
          </Grid>
        </Grid>
      )}

      <CustomRecurrenceDialog
        open={isCustomPopupOpen}
        startDate={new Date(component.startDate)}
        confirmBtnText="Done"
        cancelBtnText="Cancel"
        title="Custom Recurrence"
        customRepeatData={
          isEditing && !!component.generatedObject
            ? component.generatedObject
            : undefined
        }
        onConfirm={(customText, interval, timeFrame, days) => {
          const customRepeat = new RRule({
            freq: RRule[timeFrame],
            interval: interval,
            byweekday: days
          });
          const updatedoptions = [...repeatoptions];
          const i = repeatoptions.findIndex(
            (option) => option.value === "CUSTOM_TEXT"
          );
          if (i === -1) {
            // updatedoptions.push({ label: customText, value: "CUSTOM_TEXT" });
            updatedoptions.splice(updatedoptions.length - 1, 0, {
              label: customText,
              value: "CUSTOM_TEXT"
            });
          } else {
            updatedoptions[i].label = customText;
          }
          setRepeatOptions(updatedoptions);
          setRepeatValue("CUSTOM_TEXT");
          onChangeInput("customRepeat", customRepeat.origOptions);
          if (isEditing && !!component.generatedObject)
            onChangeInput("generatedObject", false);
          setCustomPopupOpen(false);
        }}
        onCancel={() => {
          if (!component.customRepeat?.freq) {
            setRepeatValue("DNR");
            onChangeInput("customRepeat", {});
          } else {
            setupRepeat();
          }
          setCustomPopupOpen(false);
        }}
      />
    </>
  );
};
