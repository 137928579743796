import { Box, Typography, Grid, IconButton, styled } from "@mui/material";
import Modal from "@mui/material/Modal";
import {
  Header,
  HeaderText,
  MainContainer
} from "../components/CreateMeetingModal";
import { CloseIcon } from "@components/Icons";
import { Button } from "@components/Button";
import { FormInput } from "@components/FormInput";
import { ChangeEvent } from "react";
import { useForm } from "react-hook-form";
const ButtonContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "right",
  width: "100%",
  padding: "8px 24px",
  gap: "16px",
  "& .MuiButtonBase-root": {
    minWidth: "120px"
  },
  "@media (max-width: 500px)": {
    gap: "10px",

    "& .MuiButtonBase-root": {
      padding: "8px 12px"
    },
    "& .MuiTypography-root": {
      fontSize: "11px"
    }
  }
}));

export const OpportunityClosedLostModal = ({
  open,
  handleClose,
  handleReject,
  rejectionNotes,
  setRejectionNotes,
  opportunityName,
  opportunityOwner
}: {
  open: boolean;
  opportunityName: string;
  opportunityOwner: string;
  handleClose: () => void;
  handleReject: () => void;
  rejectionNotes: string;
  setRejectionNotes: (val: string) => void;
}) => {
  const maxCharacters = 250;

  const { control } = useForm({ mode: "onBlur" });

  return (
    <Modal open={open} onClose={handleClose}>
      <MainContainer>
        <Header>
          <HeaderText>Reason For Closed Lost Opportunity</HeaderText>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Header>
        <Box style={{ padding: "16px", marginTop: "20px" }}>
          <Typography style={{ marginBottom: "16px", opacity: "50%" }}>
            Please provide an explanation for why this opportunity was lost
          </Typography>

          <Grid container marginTop="20px">
            <Grid item xs={4}>
              <Typography style={{ opacity: "50%" }}>
                Opportunity Name
              </Typography>
              <Typography style={{ fontWeight: 600 }}>
                {opportunityName}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ opacity: "50%" }}>
                Opportunity Owner
              </Typography>
              <Typography style={{ fontWeight: 600 }}>
                {opportunityOwner}
              </Typography>
            </Grid>
          </Grid>

          {/* Explanation Field */}
          <Box style={{ marginTop: "24px" }}>
            <FormInput
              control={control}
              rules={{
                required: "Explanation is required"
              }}
              label="Explanation"
              required
              type="text"
              name="rejectionNotes"
              multiline
              rows={4}
              onChange={(e) =>
                setRejectionNotes(
                  (e as ChangeEvent<HTMLInputElement>).target.value
                )
              }
              TextProps={{
                inputProps: {
                  maxLength: maxCharacters
                }
              }}
            />
            <Typography style={{ textAlign: "right", marginTop: "8px" }}>
              {rejectionNotes.length}/{maxCharacters}
            </Typography>
          </Box>
        </Box>

        <ButtonContainer>
          <Button variant="admin-secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="admin-error"
            onClick={handleReject}
            disabled={rejectionNotes.length === 0}
          >
            Submit
          </Button>
        </ButtonContainer>
      </MainContainer>
    </Modal>
  );
};
