import { Editor } from "@components/Editor";
import { FormInput } from "@components/FormInput";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { FormSelect } from "@components/FormSelect";
import { Form } from "@components/crud/Form";
import { FormLabel, Grid, Typography, styled } from "@mui/material";
import { ModelHelpCategory } from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalizeFirstCharacter } from "@utils/capitalize";
import {
  DISPLAY_CHANNEL_OPTIONS,
  HELP_ARTICLE_RELATED_TO
} from "@utils/constants";
import React, { ChangeEvent, useState } from "react";
import { UseFormReturn } from "react-hook-form";
const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));
export const HelpArticleForm = ({
  form,
  disabled,
  categories,
  isLoadingCategories
}: {
  form: UseFormReturn;
  disabled?: boolean;
  categories: ModelHelpCategory[];
  isLoadingCategories?: boolean;
}) => {
  const { control, setValue, getValues } = form;
  const [contentValid, setContentValid] = useState(true);
  const validateContentArray = (value) => {
    if (value && value.length >= 2) {
      setContentValid(true);
    } else if (value && value.length == 1 && value[0].insert !== "\n") {
      setContentValid(true);
    } else {
      setContentValid(false);
    }
  };

  return (
    <Form>
      <Grid container direction="column" spacing="25px">
        <Grid item container direction="row" spacing="24px">
          <Grid item xs={6} data-testid="helpArticle-headline">
            <FormInput
              disabled={disabled}
              name="headline"
              onChange={(e) => {
                setValue(
                  "headline",
                  capitalizeFirstCharacter(
                    (e as ChangeEvent<HTMLInputElement>).target.value
                  )
                );
              }}
              control={control}
              label="Headline"
              required
              rules={{
                required: "Headline is required"
              }}
              type="text"
            />
          </Grid>
        </Grid>
        <Grid
          data-testid="helpArticle-article"
          item
          container
          direction="column"
          spacing="10px"
          minHeight="300px"
          marginBottom="30px"
        >
          <Grid item>
            <StyledFormLabel required>
              <Typography variant="formLabel">Article</Typography>
            </StyledFormLabel>
          </Grid>
          <Grid item>
            <Editor
              disabled={disabled}
              onBlur={() => {
                validateContentArray(getValues().content);
              }}
              html={getValues().content ? getValues().content : null}
              onValueChange={(deltaOperations, hasMedia) => {
                setValue("content", deltaOperations, {
                  shouldValidate: true,
                  shouldDirty: true
                });
                setValue("hasMedia", hasMedia);
                validateContentArray(getValues().content);
              }}
              style={{
                height: "600px"
              }}
            />
          </Grid>
        </Grid>
        {!contentValid && (
          <Grid item style={{ marginTop: "10px" }}>
            <Typography style={{ color: "#E82C2C" }} variant="body2">
              Article is required
            </Typography>
          </Grid>
        )}
        <Grid
          item
          container
          direction="row"
          spacing="24px"
          style={{
            paddingTop: !contentValid ? "0px" : "24px",
            marginTop: "1px"
          }}
        >
          <Grid item xs={6} data-testid="helpArticle-category">
            <FormSelect
              name="categoryId"
              disabled={disabled}
              control={control}
              isLoading={isLoadingCategories}
              label="Category"
              options={
                categories
                  ? categories.map((category) => ({
                      label: category.name!,
                      value: category.helpCategoryId
                    }))
                  : []
              }
              required
              rules={{
                required: "Category is required"
              }}
            />
          </Grid>
          <Grid item xs={6} data-testid="helpArticle-relatedTo">
            <FormSelect
              name="relatedTo"
              disabled={disabled}
              control={control}
              label="Related To"
              options={HELP_ARTICLE_RELATED_TO}
              required
              rules={{
                required: "Related to is required"
              }}
            />
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing="24px">
          <Grid item xs={6} data-testid="helpArticle-displayChannel">
            <FormMultiSelect
              name="displayChannel"
              disabled={disabled}
              value="displayChannel"
              control={control}
              label="Display Channels"
              options={DISPLAY_CHANNEL_OPTIONS}
              required
              rules={{
                required: "Display Channels is required"
              }}
              onChange={() => {}}
              onRemove={() => {}}
            />
          </Grid>
        </Grid>
        <Grid item></Grid>
      </Grid>
    </Form>
  );
};
