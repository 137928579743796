import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FilterTreeSearchComponent } from "@components/FilterTreeSearchComponent";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import { FormLabel, Grid, Typography, styled } from "@mui/material";
import { selectedBuildAtom } from "@recoil/auth";
import {
  AdminQAFeaturePutRequest,
  useAdminBuildFeatureFeatureIdGet,
  useAdminBuildFeatureFeatureIdPut,
  useAdminDirectoryGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { cleanObject } from "@utils/cleanObject";
import { FEATURE_PLATFORMS_OPTIONS } from "@utils/constants";
import formatFullName from "@utils/formatFullName";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

export const QABuildEdit = () => {
  const navigate = useNavigate();
  const { featureId } = useParams();
  const selectedBuildValue = useRecoilValue(selectedBuildAtom);
  const [buildId, setBuildId] = useState(selectedBuildValue);
  const [builds, setBuilds] = useState<
    { label: string; value: string; status?: string }[]
  >([]);

  const form = useForm({
    mode: "onTouched"
  });
  const [platform, setPlatform] = useState<string>();
  const { data: testersData } = useAdminDirectoryGet();
  const [testers, setTesters] = useState<{ label: string; value: string }[]>(
    []
  );

  useEffect(() => {
    if (testersData?.data) {
      setTesters(
        testersData.data.persons?.map((person) => ({
          label: formatFullName(person),
          value: person.personId!
        })) || []
      );
    }
  }, [testersData]);

  const { data: data, isLoading: isLoading } = useAdminBuildFeatureFeatureIdGet(
    featureId!,
    {
      buildId: buildId as string
    }
  );
  const {
    control,
    getValues,
    setValue,
    formState: { isValid, isDirty },
    reset
  } = form;

  useEffect(() => {
    if (data) {
      const feature = data.data;
      const defaultValues = {
        name: feature.feature?.name,
        parentId: feature.feature?.parentId,
        iosTesterId: feature.iosTesterId,
        andTesterId: feature.andTesterId,
        webtesterId: feature.webTesterId
      };
      if (feature.feature?.builds) {
        setBuilds(
          feature.feature.builds.map((build) => ({
            label: build.build?.buildNumber as string,
            value: build.build?.buildId as string,
            status: build.build?.buildStatus
          }))
        );
      }
      setPlatform(feature.feature?.builds?.[0]?.build?.platform);
      reset(defaultValues);
    }
  }, [data]);
  const { mutate: save, isLoading: isSaving } =
    useAdminBuildFeatureFeatureIdPut();
  const onSave = () => {
    save(
      {
        featureId: featureId!,
        params: {
          buildId: buildId!
        },
        data: cleanObject({
          iosTesterId: platform === "IOS" ? getValues().iosTesterId : null,
          andTesterId: platform === "AND" ? getValues().andTesterId : null,
          webTesterId: platform === "WEB" ? getValues().webTesterId : null
        }) as AdminQAFeaturePutRequest
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Feature edited successfully!", {
            variant: "success"
          });

          navigate(`/qa-builds/${featureId}`);
        },
        onError: () => {
          enqueueSnackbar("Failed to edit Feature!", {
            variant: "error"
          });
        }
      }
    );
  };
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  return (
    <Loader isLoading={isLoading}>
      <Container data-testid="editFeatureForm">
        <Toolbar
          title="Edit QA Feature"
          showFilters={
            <Grid
              container
              flexDirection="row"
              alignItems="center"
              marginLeft="10px"
            >
              <Grid item marginRight="10px">
                <Typography variant="gridToolbarFilterLabel">Build</Typography>
              </Grid>
              <Grid
                marginRight="10px"
                minWidth="85px"
                data-testid="releaseFilter"
              >
                <FormSelect
                  disabled
                  name="release"
                  isReleaseSelect
                  options={builds}
                  value={buildId}
                  onChange={(e) => {
                    setBuildId(e.target.value);
                  }}
                  sx={{
                    minWidth: 85
                  }}
                />
              </Grid>
            </Grid>
          }
        />
        <Form>
          <Grid container direction="column" spacing="25px">
            <Grid item container direction="row" spacing="24px">
              <Grid item xs={6} data-testid="name">
                <FormInput
                  name="name"
                  disabled
                  control={control}
                  label="Feature Name"
                  required
                  rules={{
                    required: "Feature Name Is Required"
                  }}
                  type="text"
                />
              </Grid>
            </Grid>
            {data?.data?.feature?.parentId && (
              <Grid item container direction="row" spacing="24px">
                <Grid item xs={6} data-testid="parentFeature">
                  <FilterTreeSearchComponent
                    disabled
                    placeHolder=" "
                    currentFeatureId={featureId}
                    disableSelected
                    selectedValue={getValues().parentId}
                    label="Parent Feature"
                    onFilterChange={(val) => {
                      console.log(val);
                      setValue("parentId", val, { shouldDirty: true });
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <Grid
              item
              container
              direction="row"
              spacing="24px"
              marginBottom="-10px"
            >
              <Grid item xs={6}>
                <StyledFormLabel required>
                  <Typography variant="formLabel">Platforms</Typography>
                </StyledFormLabel>
              </Grid>
              <Grid item xs={6}>
                <StyledFormLabel required>
                  <Typography variant="formLabel">Tester</Typography>
                </StyledFormLabel>
              </Grid>
            </Grid>
            <Grid item container direction="row" spacing="24px">
              <Grid item xs={6}>
                <FormSelect
                  name={`platform_${platform}`}
                  disabled
                  options={FEATURE_PLATFORMS_OPTIONS}
                  value={platform}
                />
              </Grid>
              <Grid item xs={6} key={platform}>
                <FormSelect
                  name={
                    platform === "IOS"
                      ? "iosTesterId"
                      : platform === "AND"
                        ? "andTesterId"
                        : platform === "WEB"
                          ? "webTesterId"
                          : ""
                  }
                  control={control}
                  options={testers}
                />
              </Grid>
            </Grid>
          </Grid>
        </Form>
        <Footer
          cancelBtnClick={() => setOpenCancelDialog(true)}
          saveBtnClick={() => {
            onSave();
          }}
          isDisabled={!isDirty || !isValid || isSaving}
          isLoading={isLoading || isSaving}
        />
        <ConfirmationDialog
          title="Are you sure you want to cancel?"
          body="All of your current changes will be lost."
          open={openCancelDialog}
          close={() => setOpenCancelDialog(false)}
          onCancel={() => setOpenCancelDialog(false)}
          onConfirm={() => navigate("/qa-features")}
          cancelBtnText="Cancel"
          confirmBtnText="Confirm"
        />
      </Container>
    </Loader>
  );
};
