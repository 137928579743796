import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FilterTreeSearchComponent } from "@components/FilterTreeSearchComponent";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormInput } from "@components/FormInput";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import { Grid } from "@mui/material";
import {
  AdminFeaturePostPutRequest,
  useAdminFeaturePost,
  useAdminFeatureEpicGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalizeEveryWord } from "@utils/capitalize";
import { cleanObject } from "@utils/cleanObject";
import { FEATURE_PLATFORMS_OPTIONS } from "@utils/constants";
import { enqueueSnackbar } from "notistack";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
export const FeatureCreate = () => {
  const { featureId } = useParams();
  const form = useForm({
    mode: "onTouched",
    defaultValues: {
      name: "",
      parentId: featureId || undefined,
      platforms: [] as string[],
      isActive: true,
      isSmokeTest: false
    }
  });

  const {
    control,
    getValues,
    setValue,
    reset,
    formState: { isValid }
  } = form;
  const { mutate: save, isLoading: isSaving } = useAdminFeaturePost();
  const [platforms, setPlatforms] = useState<string[]>([]);
  const onSave = (saveAndNew: boolean) => {
    save(
      {
        data: cleanObject(getValues()) as AdminFeaturePostPutRequest
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Feature Created successfully!", {
            variant: "success"
          });

          if (saveAndNew) {
            reset({
              name: "",
              parentId: getValues().parentId,
              isActive: true,
              isSmokeTest: false,
              platforms: getValues().platforms
            });
          } else {
            window.history.back();
          }
        },
        onError: () => {
          enqueueSnackbar("Failed to Create Feature!", {
            variant: "error"
          });
        }
      }
    );
  };
  const { data: jiraIssues } = useAdminFeatureEpicGet();

  const [epics, setEpics] = useState<string[]>([]);

  useEffect(() => {
    if (jiraIssues?.data) {
      setEpics(jiraIssues.data.map((d) => d.key as string));
    }
  }, [jiraIssues]);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const validateEpic = (val) => {
    return !val || epics.includes(val);
  };
  return (
    <Container>
      <Toolbar title="Add Feature" />
      <Form>
        <Grid container direction="column" spacing="25px">
          <Grid item container direction="row" spacing="24px">
            <Grid item xs={6} data-testid="feature-name">
              <FormInput
                name="name"
                onChange={(e) => {
                  setValue(
                    "name",
                    capitalizeEveryWord(
                      (e as ChangeEvent<HTMLInputElement>).target.value
                    )
                  );
                }}
                control={control}
                label="Feature Name"
                required
                rules={{
                  required: "Feature Name Is Required"
                }}
                type="text"
              />
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={6} data-testid="feature-isActive">
              <FormCheckbox
                key="isActive"
                control={control}
                name="isActive"
                label="Is Active"
                labelPadding="4px 8px"
                labelTypographyProps={{
                  variant: "body1"
                }}
              />
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={6} data-testid="feature-isSmokeTest">
              <FormCheckbox
                key="isSmokeTest"
                control={control}
                name="isSmokeTest"
                label="End-To-End"
                labelPadding="4px 8px"
                labelTypographyProps={{
                  variant: "body1"
                }}
              />
            </Grid>
          </Grid>
          <Grid item container direction="row" spacing="24px">
            <Grid item xs={6} data-testid="feature-parent">
              <FilterTreeSearchComponent
                placeHolder=" "
                selectedValue={getValues()?.parentId || featureId}
                label="Parent Feature"
                onFilterChange={(val) => {
                  console.log(val);
                  setValue("parentId", val, { shouldDirty: true });
                }}
                onClearFilter={() => {
                  setValue("parentId", undefined, { shouldDirty: true });
                }}
              />
            </Grid>
          </Grid>
          <Grid item container direction="row" spacing="24px">
            <Grid item xs={6} data-testid="feature-platforms">
              <FormMultiSelect
                name="platforms"
                value="platforms"
                control={control}
                label="Platforms"
                options={FEATURE_PLATFORMS_OPTIONS}
                required
                rules={{
                  required: "Platforms Is Required"
                }}
                onChange={(e) => {
                  setPlatforms(e.target.value);
                }}
                onRemove={(val) => {
                  setPlatforms(platforms.filter((p) => p !== val));
                }}
              />
            </Grid>
          </Grid>

          {platforms.map((platform) => (
            <Grid
              item
              xs={6}
              width="50%"
              data-testid={`${platform}-jira-epicId`}
              key={platform}
            >
              <FormInput
                name={`${
                  platform.toLowerCase() === "dev"
                    ? "devOps"
                    : platform.toLowerCase()
                }EpicId`}
                label={`${platform} Jira Epic ID`}
                type="text"
                capitalize
                rules={{
                  validate: (v) => validateEpic(v) || "Epic Id Not Found"
                }}
                control={control}
              />
            </Grid>
          ))}
        </Grid>
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={() => {
          onSave(false);
        }}
        saveAndNewBtnClick={() => {
          onSave(true);
        }}
        isDisabled={!isValid}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => window.history.back()}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
