import { Button } from "@components/Button";
import { FormInput } from "@components/FormInput";
import { LoadingSpinner } from "@components/LoadingSpinner";
import { NotificationCard } from "@components/NotificationCard";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  useAuthSignUpResendPersonCodePost,
  useAuthSignUpVerifyPersonCodePost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Buffer } from "buffer";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const FormRow = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
`;

const CodesButtonContainer = styled(Box)`
  margin-top: 3rem;
`;

const FormFieldContainer = styled(Box)`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
`;

const ResendContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  width: 60px;
  height: 100%;
`;

const SignInContainer = styled(Box)`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LinkButton = styled(Link)(({ theme }) => ({
  margin: "1rem 0 0 1rem",
  color: theme.palette.primary.main,
  "&:hover": {
    opacity: 0.8
  }
}));

const CODE_TYPE = "PHONE";

export const ResetPasswordVerify = () => {
  const navigate = useNavigate();
  const { b64Email = "", totp } = useParams();
  const [attempts, setAttempts] = useState(0);
  const [numResends, setNumResends] = useState(0);
  const [sentCode, setSentCode] = useState(false);

  const email = useMemo(() => {
    return Buffer.from(b64Email, "base64").toString("ascii");
  }, [b64Email]);

  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      smsCode: ""
    }
  });

  const resendMutation = useAuthSignUpResendPersonCodePost();

  const resendCode = async (skipResends = false) => {
    console.log("sending code", new Date());
    resendMutation.mutate(
      {
        data: { email, type: CODE_TYPE }
      },
      {
        onSettled: () => {
          if (!skipResends) {
            setNumResends(numResends + 1);
          }
        }
      }
    );
  };

  useEffect(() => {
    if (!sentCode) {
      setSentCode(true);
      resendCode(true);
    }
  }, [sentCode]);

  const verifyCodeMutation = useAuthSignUpVerifyPersonCodePost();

  const submitCode = async (data) => {
    verifyCodeMutation.mutate(
      {
        data: { code: data.smsCode, type: CODE_TYPE, email }
      },
      {
        onSuccess: () => {
          navigate(`/reset-password/${b64Email}/${totp}`);
        },
        onError: () => {
          setAttempts(attempts + 1);
        }
      }
    );
  };

  return (
    <FormContainer data-testid="forgot-password-verify">
      <Typography
        variant="h2"
        color="text.general.primary"
        sx={{ fontWeight: 400, mb: ".5rem" }}
      >
        Verify Your identity
      </Typography>
      <Typography variant="body2" color="text.general.primary">
        We sent you a code to your mobile phone associated with your account.
        Please enter the code below to reset your password.
      </Typography>
      {attempts > 0 && (
        <FormRow data-testid="forgot-password-invalidCode">
          <NotificationCard variant="error">
            {attempts >= 3
              ? "You have entered the incorrect code too many times, please contact the SportsGravy Support Team."
              : `Invalid code. You have ${
                  3 - attempts
                } attempts remaining. Please try again`}
          </NotificationCard>
        </FormRow>
      )}
      {resendMutation.isSuccess && numResends !== 0 && (
        <FormRow>
          <NotificationCard variant="success">
            We have successfully sent you a new code. Please enter it below to
            reset your password.
          </NotificationCard>
        </FormRow>
      )}
      <FormRow>
        <FormFieldContainer>
          <FormInput
            name="smsCode"
            type="text"
            label="Mobile Phone code"
            control={control}
            rules={{
              required: "Mobile Phone code is required"
            }}
          />
        </FormFieldContainer>
        {numResends === 0 && (
          <ResendContainer>
            {resendMutation.isLoading ? (
              <LoadingSpinner />
            ) : (
              <LinkButton to="#" onClick={() => resendCode()}>
                Resend Code
              </LinkButton>
            )}
          </ResendContainer>
        )}
      </FormRow>
      <CodesButtonContainer>
        <FormFieldContainer>
          <Button
            variant="primary"
            disabled={!isValid || attempts >= 3}
            type="button"
            isLoading={verifyCodeMutation.isLoading}
            onClick={handleSubmit(submitCode)}
          >
            Submit
          </Button>
        </FormFieldContainer>
      </CodesButtonContainer>
      <SignInContainer>
        <Typography variant="body1">
          Back to <Link to="/">Sign In</Link>
        </Typography>
      </SignInContainer>
    </FormContainer>
  );
};
