import { Button } from "@components/Button";
import { PasswordInput } from "@components/PasswordInput";
import { Box, List, ListItem, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { resetPassword } from "@services/Network";
import {
  PASSWORD_MIN_LENGTH,
  PASSWORD_REGEX_LOWER,
  PASSWORD_REGEX_NUMBER,
  PASSWORD_REGEX_SPECIAL_CHAR,
  PASSWORD_REGEX_UPPER,
  PASSWORD_REGEX_VALIDATE
} from "@utils/validation";
import { Buffer } from "buffer";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const FormRow = styled(Box)`
  display: flex;
  margin-top: 1.5rem;
  ${(props) => props.theme.breakpoints.up("xs")} {
    flex-direction: column;
  }
  ${(props) => props.theme.breakpoints.up("sm")} {
    flex-direction: row;
  }
`;

const ButtonContainer = styled(Box)`
  margin-top: 3rem;
  display: flex;
`;

const FormFieldContainer = styled(Box)`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
`;

const SignInContainer = styled(Box)`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ListContainer = styled(Box)`
  display: flex;
  margin-top: 1rem;

  ${(props) => props.theme.breakpoints.up("xs")} {
    flex-direction: column;
  }
  ${(props) => props.theme.breakpoints.up("sm")} {
    flex-direction: row;
  }
`;

const BulletList = styled(List)`
  list-style-type: disc;
`;

const BulletItem = styled(ListItem)(({ theme }) => ({
  padding: "0 0 0 .5rem",
  lineHeight: 0,

  "&:before": {
    content: '"•"',
    paddingRight: ".5rem",
    color: theme.palette.primary.main,
    fontSize: "48px",
    lineHeight: "8px"
  }
}));

const ListText = styled(Typography)`
  padding: 0;
`;

const StrikeThroughText = styled(Typography)`
  text-decoration: line-through;
`;

export const ResetPassword = () => {
  const navigate = useNavigate();
  const { b64Email = "", totp } = useParams();

  const email = useMemo(() => {
    return Buffer.from(b64Email, "base64").toString("ascii");
  }, [b64Email]);

  const {
    control,
    handleSubmit,
    formState: { isValid },
    watch
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      password: "",
      confirmPassword: ""
    }
  });

  const password = watch("password");

  const mutation = resetPassword();

  const submitForm = async (formValues) => {
    mutation.mutate(
      {
        data: {
          password: formValues.password,
          username: email,
          code: String(totp)
        }
      },
      {
        onSuccess: () => {
          navigate("/");
        }
      }
    );
  };

  return (
    <FormContainer data-testid="reset-password-form">
      <Typography
        variant="h2"
        color="text.general.primary"
        sx={{ fontWeight: 400, mb: ".5rem" }}
      >
        Reset Password
      </Typography>
      <Typography variant="body1" color="text.general.secondary">
        Enter your new password, and you’re good to go!
      </Typography>
      <form onSubmit={handleSubmit(submitForm)}>
        <FormRow>
          <FormFieldContainer>
            <PasswordInput
              name="password"
              required
              label="New Password"
              control={control}
              rules={{
                required: "New Password is required",
                pattern: {
                  value: PASSWORD_REGEX_VALIDATE,
                  message: "New Password does not meet requirements"
                }
              }}
            />
          </FormFieldContainer>
        </FormRow>
        <ListContainer>
          <BulletList>
            <BulletItem>
              {PASSWORD_REGEX_LOWER.test(password) ? (
                <StrikeThroughText>One lowercase character</StrikeThroughText>
              ) : (
                <ListText>One lowercase character</ListText>
              )}
            </BulletItem>
            <BulletItem>
              {PASSWORD_REGEX_UPPER.test(password) ? (
                <StrikeThroughText>One uppercase character</StrikeThroughText>
              ) : (
                <ListText>One uppercase character</ListText>
              )}
            </BulletItem>
            <BulletItem>
              {PASSWORD_REGEX_NUMBER.test(password) ? (
                <StrikeThroughText>One number</StrikeThroughText>
              ) : (
                <ListText>One number</ListText>
              )}
            </BulletItem>
          </BulletList>
          <BulletList>
            <BulletItem>
              {PASSWORD_REGEX_SPECIAL_CHAR.test(password) ? (
                <StrikeThroughText>One special character</StrikeThroughText>
              ) : (
                <ListText>One special character</ListText>
              )}
            </BulletItem>
            <BulletItem>
              {password.length >= PASSWORD_MIN_LENGTH ? (
                <StrikeThroughText>8 characters minimum</StrikeThroughText>
              ) : (
                <ListText>8 characters minimum</ListText>
              )}
            </BulletItem>
          </BulletList>
        </ListContainer>
        <FormRow>
          <FormFieldContainer>
            <PasswordInput
              name="confirmPassword"
              required
              label="Confirm New Password"
              control={control}
              rules={{
                required: "Confirm New Password is required",
                validate: {
                  passwordMatch: (value: string) =>
                    value === password ||
                    "New Password and Confirm New Password must match"
                }
              }}
            />
          </FormFieldContainer>
        </FormRow>
        <ButtonContainer>
          <FormFieldContainer>
            <Button
              variant="primary"
              disabled={!isValid}
              type="submit"
              isLoading={mutation.isLoading}
            >
              Reset Password
            </Button>
          </FormFieldContainer>
        </ButtonContainer>
      </form>
      <SignInContainer>
        <Typography variant="body1">
          Already have an account? <Link to="/">Sign In</Link>
        </Typography>
      </SignInContainer>
    </FormContainer>
  );
};
