import { Avatar, Box, Typography } from "@mui/material";
import { LocalOfferOutlined, LocationOnOutlined } from "@mui/icons-material";
import { ModelMedia } from "@sportsgravyengineering/sg-api-react-sdk";
import formatFullName from "@utils/formatFullName";
import { useRecoilValue } from "recoil";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import { useEffect, useState } from "react";
import { hasPermission } from "@services/Casbin";
export const MediaDetails = ({ media }: { media: ModelMedia }) => {
  const [permissions, setPermissions] = useState({
    viewTeam: false,
    viewTp: false,
    viewOrg: false
  });

  const organizationId = useRecoilValue(organizationAtom);
  const orgs = useRecoilValue(organizationsAtom);
  const currOrg = orgs.find((o) => o.organizationId === organizationId);
  const avatarUrl = media.createdBy?.person?.avatar?.baseUrl
    ? media.createdBy.person?.avatar?.baseUrl +
      media.createdBy.person?.avatar?.path
    : undefined;
  const initialsText = media.createdBy?.person
    ? `${media.createdBy.person?.firstName?.[0]}${media.createdBy.person?.lastName?.[0]}`
    : "";

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };

    const fetchPermissions = async () => {
      const viewTeam = await checkPermission("team.teams", "VIEW");
      const viewTp = await checkPermission(
        "training-programs.training-programs",
        "VIEW"
      );
      const viewOrg = await checkPermission("general.organization", "VIEW");
      setPermissions({
        viewTeam,
        viewOrg,
        viewTp
      });
    };
    fetchPermissions();
  }, [media]);

  const sharedWithData = [
    {
      name: currOrg?.name,
      type: "Organization",
      hasPermission: permissions.viewOrg,
      redirectUrl: `/organizations/${currOrg?.organizationId}`,
      img: currOrg?.avatar?.baseUrl
        ? currOrg?.avatar?.baseUrl + currOrg?.avatar.path
        : ""
    },
    ...(media.teams || []).map((t) => ({
      name: t.name,
      type: "Team",
      hasPermission: permissions.viewTeam,
      redirectUrl: `/teams/${t.teamId}`,
      img: currOrg?.avatar?.baseUrl
        ? currOrg?.avatar?.baseUrl + currOrg?.avatar.path
        : ""
    })),
    ...(media.trainingPrograms || []).map((tp) => ({
      name: tp.name,
      type: "Training Program",
      hasPermission: permissions.viewTp,
      redirectUrl: `/training-programs/${tp.programId}`,
      img: currOrg?.avatar?.baseUrl
        ? currOrg?.avatar?.baseUrl + currOrg?.avatar.path
        : ""
    }))
  ];
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "16px",
        borderRadius: "8px",
        backgroundColor: "#F7F9FC"
      }}
    >
      {/* Left Section */}
      <Box style={{ flex: 1 }}>
        <Typography
          style={{ color: "#0F0F0F", fontWeight: 600, fontSize: "14px" }}
        >
          {new Date(media.createdAt!).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric"
          })}
        </Typography>
        <Typography
          style={{
            color: "#0F0F0F",
            fontWeight: 400,
            fontSize: "14px",
            marginTop: "5px"
          }}
        >
          {media?.sport?.name || "No Sport Provided"}
        </Typography>

        <Box display="flex" alignItems="center" mt={1}>
          <LocationOnOutlined />
          <Typography
            style={{
              color: "#000",
              fontWeight: 400,
              marginLeft: "10px"
            }}
          >
            No Location Provided
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" mt={1}>
          <LocalOfferOutlined />
          <Typography
            style={{
              color: "#0F0F0F",
              fontWeight: 400,
              marginLeft: "10px"
            }}
          >
            {media?.tags && media.tags.length > 0
              ? media?.tags.join(", ")
              : "No Tags Provided"}
          </Typography>
        </Box>
        {media.createdBy && (
          <Box mt={2}>
            <Typography style={{ color: "#0F0F0F", fontWeight: 400 }}>
              Created By
            </Typography>
            <Box display="flex" mt={1} alignItems="center">
              {avatarUrl ? (
                <img
                  src={avatarUrl}
                  style={{
                    borderRadius: "8px",
                    width: "32px",
                    height: "32px"
                  }}
                />
              ) : (
                <Avatar
                  style={{
                    backgroundColor: "#F54748",
                    color: "white",
                    fontSize: "12px",
                    borderRadius: "8px",
                    fontWeight: 700
                  }}
                >
                  {initialsText}
                </Avatar>
              )}
              <Typography
                style={{
                  color: "#007AFF",
                  fontWeight: 400,
                  marginLeft: "10px",
                  cursor: "pointer"
                }}
                onClick={() =>
                  window.open(
                    "/users/" + media.createdBy?.person?.personId,
                    "_blank"
                  )
                }
              >
                {formatFullName(media.createdBy.person!)}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      {sharedWithData.length > 0 && (
        <Box style={{ flex: 1, marginLeft: "32px" }}>
          <Typography style={{ color: "#0F0F0F", fontWeight: 400 }}>
            Shared with
          </Typography>

          <Box
            style={{
              maxHeight: "200px", // Set the max height of the scrollable area
              overflowY: "auto" // Enable vertical scrolling for the list only
            }}
          >
            {sharedWithData.map((item, index) => (
              <Box display="flex" mt={1} alignItems="center" key={index}>
                <img src={item.img} width="32px" height="32px" />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <Typography
                    style={{
                      color:
                        item.redirectUrl && item.hasPermission
                          ? "#007AFF"
                          : "#000",
                      fontWeight: 400,
                      marginLeft: "10px",
                      cursor:
                        item.redirectUrl && item.hasPermission
                          ? "pointer"
                          : "default",
                      fontSize: "14px"
                    }}
                    onClick={() =>
                      item.redirectUrl &&
                      item.hasPermission &&
                      window.open(item.redirectUrl, "_blank")
                    }
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    style={{
                      color: "#64748B",
                      fontWeight: 400,
                      marginLeft: "10px",
                      fontSize: "12px"
                    }}
                  >
                    {item.type}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};
