import {
  Autocomplete,
  Box,
  Grid,
  Link,
  Stack,
  Typography,
  styled
} from "@mui/material";
import React, { useState } from "react";
import { differenceInYears } from "date-fns";
import { SearchInput } from "../components/SearchInput";
import { getUsers } from "../services/Network";
import formatFullName from "../utils/formatFullName";
import formatAddress from "../utils/formatAddress";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { capitalize, capitalizeFirstCharacter } from "@utils/capitalize";
import {
  ModelPerson,
  ModelUserRoleAliasBan,
  RoleAlias
} from "@sportsgravyengineering/sg-api-react-sdk";

const ProfileThumbnail = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "32px",
  height: "32px",
  borderRadius: "8px",
  color: theme.palette.white.main
}));

const StyledPersonOption = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  padding: "12px 16px",
  borderBottom: `1px solid ${theme.palette.divider}`,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F5F5F5"
  }
}));

const StyledAddPersonOption = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: "12px 16px"
}));

const LinkButton = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginLeft: "8px",
  cursor: "pointer",

  "&:hover": {
    opacity: 0.8
  },

  "&.disabled": {
    cursor: "default",

    "&:hover": {
      opacity: 1
    }
  }
}));
const PersonOption = ({
  person,
  onClick,
  organizationId,
  showTeamOrProgram,
  disableBannedRole,
  ...props
}: {
  person: ModelPerson | null;
  onClick: () => void;
  organizationId?: string | undefined;
  showTeamOrProgram?: boolean;
  disableBannedRole?: string;
}) => {
  const fullName = formatFullName(person);
  const age = person?.birthedAt
    ? differenceInYears(new Date(), new Date(person.birthedAt))
    : null;
  const ageText = age ? `${age} years old` : "";
  const address = formatAddress(person?.addressPrimary);
  const optionComponents = [fullName, ageText, address];
  if (showTeamOrProgram && person?.mappedTo && person?.mappedTo.length > 0)
    optionComponents.push(
      `${person?.mappedTo[0].team?.name || ""} - ${
        person?.mappedTo[0].team?.level?.name || ""
      }`
    );
  const optionText = optionComponents
    .filter((component) => !!component)
    .join(" | ");
  const avatarUrl = person?.avatar?.baseUrl
    ? person?.avatar?.baseUrl + person?.avatar?.path
    : undefined;
  const initialsText = person
    ? `${person?.firstName?.[0]}${person?.lastName?.[0]}`
    : "";
  const bannedRolesSG =
    person?.user?.roleAliasBans?.filter((r) => !r.organizationId) || [];

  const getBannedValues = (
    orgBannedRoles: ModelUserRoleAliasBan[],
    SGBannedRoles
  ) => {
    let isBanned = false;
    let isBannedFromMultiple = false;
    let bannedRolesNames = "";
    let countForMultiple = 2;

    if (SGBannedRoles.length) {
      isBanned = true;
      countForMultiple = countForMultiple - 1;
    }

    const isAdminBannedFromSG = SGBannedRoles.map(
      (role) => role.alias
    ).includes("ADMIN");

    if (orgBannedRoles && orgBannedRoles.length) {
      const organizationSet = new Set();
      orgBannedRoles.forEach((role) => {
        const orgId = role.organizationId ? role.organizationId : "0";
        organizationSet.add(orgId);
      });
      if (organizationSet.size > countForMultiple) isBannedFromMultiple = true;
      if (organizationId && organizationSet.has(organizationId))
        isBanned = true;
      if (!isBannedFromMultiple && isBanned)
        bannedRolesNames = orgBannedRoles
          .filter((o) => o.organizationId === organizationId)
          .map((role) => capitalizeFirstCharacter(role.alias) || "")
          .join(", ");
    }

    return {
      isBanned,
      isBannedFromMultiple,
      bannedRolesNames,
      isAdminBannedFromSG
    };
  };
  const {
    isBanned,
    isBannedFromMultiple,
    bannedRolesNames,
    isAdminBannedFromSG
  } = getBannedValues(
    person?.user?.roleAliasBans?.filter(
      (r) => !!r.organizationId
    ) as ModelUserRoleAliasBan[],
    bannedRolesSG
  );
  return (
    <StyledPersonOption
      container
      data-testid="ADD_PERSON_OPTION_CONTAINER"
      {...props}
      direction="row"
      alignItems="center"
      onClick={onClick}
      spacing="10px"
      style={
        isAdminBannedFromSG ||
        (disableBannedRole &&
          (bannedRolesSG
            .map((r) => r.alias)
            .includes(disableBannedRole as RoleAlias) ||
            person?.user?.roleAliasBans
              ?.filter((role) => role.organizationId === organizationId)
              .map((r) => r.alias)
              .includes(disableBannedRole as RoleAlias)))
          ? { pointerEvents: "none" }
          : {}
      }
    >
      <Grid item maxWidth="50px">
        <ProfileThumbnail
          style={{
            backgroundColor: `#${person?.colorCode}`
          }}
        >
          {avatarUrl ? (
            <img
              src={avatarUrl}
              style={{
                width: "32px",
                height: "32px",
                borderRadius: "8px"
              }}
              alt={`${person?.firstName} ${person?.lastName}`}
            />
          ) : (
            <span>{initialsText.toUpperCase()}</span>
          )}
        </ProfileThumbnail>
      </Grid>
      <Grid item xs={11}>
        {optionText}
      </Grid>
      {isBanned && (
        <Stack
          direction="row"
          alignItems="center"
          gap={0.75}
          marginTop={"3px"}
          marginLeft={"50px"}
          data-testid="ADD_USER_SEARCH_BANNED_VIEW"
        >
          <WarningAmberIcon
            sx={{
              color: "#E1A201",
              width: "15px",
              height: "15px",
              top: "294px",
              left: "368px"
            }}
          />
          <Typography
            sx={{
              color: "#E1A201",
              fontSize: "12px",
              font: "inter",
              lineHeight: "14.52px",
              letterSpacing: "10%",
              fontWeight: 400
            }}
          >
            {isBannedFromMultiple
              ? "This person has been banned by multiple organizations"
              : "This person has been banned by" +
                (!organizationId || bannedRolesSG.length
                  ? " Sportsgravy for the following roles: " +
                    bannedRolesSG
                      .map((role) => capitalize(role.alias as string))
                      .join(", ")
                  : " your organization for the following roles: " +
                    bannedRolesNames)}
          </Typography>
        </Stack>
      )}
    </StyledPersonOption>
  );
};

const AddPersonOption = ({ onClick }: { onClick: () => void }) => {
  return (
    <StyledAddPersonOption container direction="row" alignItems="center">
      <Grid
        container
        item
        xs={12}
        direction="row"
        alignItems="center"
        data-testid="ADD_NEW_PERSON_OPTION"
      >
        <Typography>Not finding who you're looking for?</Typography>
        <LinkButton onClick={onClick}>Add Person</LinkButton>
      </Grid>
    </StyledAddPersonOption>
  );
};

export const SearchAddPerson = ({
  personSelected,
  organizationId,
  seasonId,
  onlyOrg,
  showTeamOrProgram,
  disableBannedRole
}: {
  personSelected: (selectedPerson, personSelected, newPersonSelected) => void;
  organizationId?: string | undefined;
  onlyOrg?: boolean;
  seasonId?: string;
  showTeamOrProgram?: boolean;
  disableBannedRole?: string;
}) => {
  const [userSearch, setUserSearch] = useState("");
  const [addUserMenuOpen, setAddUserMenuOpen] = useState(false);
  const defaultPerson: ModelPerson = {
    personId: "",
    firstName: "",
    lastName: "",
    photo: "",
    addressPrimary: {
      lines: [],
      locality: "",
      province: "",
      postalCode: "",
      country: ""
    }
  };
  const onAddUserSearchChange = (event) => {
    setUserSearch(event.target.value);
  };
  const { data: addUserResults, isFetching: isFetchingUsers } = getUsers(
    {
      ...(userSearch && { textSearch: encodeURIComponent(userSearch) }),
      organizationId: onlyOrg ? organizationId : undefined,
      ...(seasonId && { seasonId: seasonId })
    },
    { staleTime: Infinity, enabled: !!userSearch?.length }
  );
  const addUserOptions = [...addUserResults, null];

  return (
    <Grid
      sx={{ padding: "12px 16px 12px 16px" }}
      item
      container
      direction="row"
      spacing="24px"
    >
      <Grid item xs={12}>
        <Autocomplete
          open={addUserMenuOpen}
          clearOnBlur={false}
          freesolo
          onOpen={() => setAddUserMenuOpen(true)}
          onClose={() => setAddUserMenuOpen(false)}
          // value={secondaryContact}
          getOptionLabel={(option) =>
            option ? `${option.firstName} ${option.lastName}` : ""
          }
          isOptionEqualToValue={(option, value) =>
            option?.personId === value?.personId
          }
          renderInput={(params) => (
            <SearchInput
              data-testid="ADD_USER_SEARCH_PERSON_INPUT"
              {...params}
              value={userSearch}
              placeholder="Search for existing users by name"
              onChange={onAddUserSearchChange}
            />
          )}
          filterOptions={(options) =>
            addUserMenuOpen && !isFetchingUsers ? options : []
          }
          renderOption={(props, option: ModelPerson, state) => {
            if (state.index === addUserOptions.length - 1) {
              return (
                <AddPersonOption
                  key={state.index}
                  onClick={() => {
                    personSelected(defaultPerson, false, true);
                  }}
                />
              );
            }
            return (
              <div
                data-testid={"SEARCH_OPTION_" + option.personId}
                key={option.personId}
              >
                <PersonOption
                  {...props}
                  key={option.personId}
                  person={option}
                  organizationId={organizationId}
                  showTeamOrProgram={showTeamOrProgram}
                  disableBannedRole={disableBannedRole}
                  onClick={() => {
                    personSelected(option, true, false);
                  }}
                />
              </div>
            );
          }}
          options={addUserOptions || []}
          loading={isFetchingUsers}
          loadingText="Loading..."
        />
      </Grid>
    </Grid>
  );
};
