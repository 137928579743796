import React, { useState } from "react";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import { Form } from "@components/crud/Form";
import { Footer } from "@components/crud/Footer";
import { StepProgressLine } from "@components/StepProgressLine";
import { FormProvider, useForm } from "react-hook-form";
import Grid from "@mui/system/Unstable_Grid";
import { AboutBuisness } from "./components/AboutBuisness";
import { AccountSetup } from "./components/AccountSetup";
import { Button } from "@components/Button";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { AddBankAccount } from "./components/AddBankAccount";
import { AboutOwner } from "./components/AboutOwner";
import {
  MerchantCreateRequest,
  useAdminMerchantPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useRecoilState, useRecoilValue } from "recoil";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

const formatDateToYYYYMMDD = (date: Date, time?: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  if (time) {
    const hours = String(time.getHours()).padStart(2, "0");
    const minutes = String(time.getMinutes()).padStart(2, "0");
    return `${year}${month}${day}${hours}${minutes}`;
  }
  return `${year}${month}${day}`;
};

const steps = [
  "About the Business",
  "About the Owner",
  "Account Setup",
  "Add Bank Account"
];

type Tabs =
  | "About the Business"
  | "About the Owner"
  | "Account Setup"
  | "Add Bank Account";

export const MerchantAdd = () => {
  const [tab, setTab] = useState<Tabs>("About the Business");
  const navigate = useNavigate();
  const activeStepNumber = steps.findIndex((step) => step === tab);
  const [organizations, setOrganizations] = useRecoilState(organizationsAtom);

  const organizationId = useRecoilValue(organizationAtom);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const form = useForm({
    mode: "onTouched"
  });
  const {
    trigger,
    handleSubmit,
    formState: { isValid }
  } = form;

  const onBackClick = () => {
    setTab(steps[activeStepNumber - 1] as Tabs);
    setTimeout(() => {
      trigger();
    }, 50);
  };

  const onCancelClick = () => {
    setIsConfirmationDialogOpen(true);
  };

  const onConfirmCancel = () => {
    setIsConfirmationDialogOpen(false);
  };

  const onCancelCancel = () => {
    setIsConfirmationDialogOpen(false);
  };

  const onContinueClick = () => {
    setTab(steps[activeStepNumber + 1] as Tabs);
  };

  const { mutate: save, isLoading: isSaving } = useAdminMerchantPost();

  const onSaveClick = (values) => {
    const data: MerchantCreateRequest = {
      data: {
        name: values.name || values.dba,
        email: values.email,
        phone: values.phone,
        website: values.website,
        address1: values.address1,
        city: values.city,
        state: values.state,
        zip: values.zip,
        country: values.country,
        type: parseInt(values.type),
        sdLabel: values.sdLabel,
        globalBusinessId: values.globalBusinessId,
        customerPhone: values.customerPhone,
        haveEmployees: values.haveEmployees ? 1 : 0,
        tcAttestation: 1,
        tcAcceptDate: formatDateToYYYYMMDD(
          values.tcAcceptDate,
          values.tcAcceptTime
        ),
        tcAcceptIp: values.tcAcceptIp,
        tcVersion: values.tcVersion,
        fax: values.fax,
        createLogin: false,
        orgEntities: [],
        publicToken: values.publicToken,
        accountToken: values.accountToken,
        public: values.public ? 1 : 0,
        accounts: [
          {
            primary: 1,
            account: {
              method: parseInt(values.method),
              routing: values.routing,
              number: values.number
            }
          }
        ],
        merchant: {
          dba: values.dba,
          established: formatDateToYYYYMMDD(values.established),
          avgTicket: values.avgTicket,
          annualCCSales: values.annualCCSales,
          mcc: values.mcc,
          ...(values.environment && { environment: values.environment }),
          new: 0,
          status: parseInt(values.status),
          members: values.ownerDetails.map((owner) => ({
            primary:
              values.ownerDetails.length == 1 ? 1 : owner.primary ? 1 : 0,
            first: owner.firstName,
            last: owner.lastName,
            middle: owner.middleName,
            dob: formatDateToYYYYMMDD(owner.dob),
            ssn: owner.ssn,
            title: owner.title,
            dl: owner.dl,
            dlstate: owner.dlstate,
            significantResponsibility: owner.significantResponsibility ? 1 : 0,
            politicallyExposed: owner.politicallyExposed ? 1 : 0,
            email: owner.email,
            phone: owner.phone,
            ownership:
              values.ownerDetails.length == 1
                ? 10000
                : parseInt(owner.ownership) * 100,
            ...(owner.hasSameAddress
              ? {
                  address1: values.address1,
                  city: values.city,
                  state: values.state,
                  zip: values.zip,
                  country: values.country
                }
              : {
                  address1: owner.address1,
                  city: owner.city,
                  state: owner.state,
                  zip: owner.zip,
                  country: owner.countryAltId
                })
          }))
        }
      },
      organizationId: organizationId
    };
    save(
      {
        data: data
      },
      {
        onSuccess: (data) => {
          const updatedOrgs = organizations.map((org) => {
            if (org.organizationId === organizationId) {
              return {
                ...org,
                merchantId: data.data as string
              };
            }
            return org;
          });
          setOrganizations(updatedOrgs);
          enqueueSnackbar("Merchant Account added successfully!", {
            variant: "success"
          });
          navigate("/merchant-account");
        },
        onError: (error) => {
          console.log(error.response.data.details);
          enqueueSnackbar(
            error.response?.data?.details || "Failed to add merchant account!",
            {
              variant: "error"
            }
          );
        }
      }
    );
  };

  const getPrimaryAction = () => {
    if (activeStepNumber < steps.length - 1) {
      return onContinueClick;
    } else {
      return handleSubmit(onSaveClick);
    }
  };

  return (
    <Container>
      <Toolbar title="Add Merchant Account" />
      <Form>
        <Grid xs={12}>
          <StepProgressLine
            steps={[
              "About the Business",
              "About the Owner",
              "Account Setup",
              "Add Bank Account"
            ]}
            activeStepNumber={activeStepNumber}
            onEditClick={(active) => {
              setTab(steps[active] as Tabs);
              setTimeout(() => {
                trigger();
              }, 50);
            }}
          />
        </Grid>
        <Grid sx={{ marginTop: "32px" }}>
          <FormProvider {...form}>
            {tab === "About the Business" && <AboutBuisness disabled={false} />}
            {tab === "About the Owner" && <AboutOwner disabled={false} />}
            {tab === "Account Setup" && <AccountSetup />}
            {tab === "Add Bank Account" && <AddBankAccount disabled={false} />}
          </FormProvider>
        </Grid>
      </Form>
      <Footer
        additionalBtns={[
          activeStepNumber > 0 ? (
            <Button
              variant="admin-secondary"
              type="button"
              onClick={onBackClick}
            >
              Back
            </Button>
          ) : null,
          <Button
            key={1}
            variant="admin-secondary"
            type="button"
            onClick={onCancelClick}
          >
            Cancel
          </Button>,
          <Button
            key={2}
            variant="admin-primary"
            type="button"
            onClick={getPrimaryAction()}
            isLoading={isSaving}
            disabled={!isValid || isSaving}
          >
            {activeStepNumber < steps.length - 1 ? "Continue" : "Save"}
          </Button>
        ]}
      />
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        onConfirm={onConfirmCancel}
        onCancel={onCancelCancel}
      />
    </Container>
  );
};
