/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, FormLabel, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TimePicker, TimeStepOptions } from "@mui/x-date-pickers";
import React from "react";
import { Control, Controller } from "react-hook-form";

const FormInputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

export const FormTimePicker = ({
  name,
  control,
  rules = undefined,
  label,
  required = false,
  onChange,
  disabled = false,
  disableFuture = false,
  disablePast = false,
  selectedValue,
  minTime,
  maxTime,
  timeSteps
}: {
  name: string;
  control: Control<any, any>;
  rules: any | undefined;
  label: string;
  required: boolean | undefined;
  onChange?: (event: React.ChangeEvent) => void;
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  selectedValue?: any;
  minTime?: Date;
  maxTime?: Date;
  timeSteps?: TimeStepOptions;
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        ...rules,
        validate: (value) => {
          if (!value && !required) return true;

          const parsedDate = Date.parse(value);
          if (isNaN(parsedDate)) return "Invalid Time";

          const date = new Date(parsedDate);
          const now = new Date();

          if (disablePast && date < now) return "Time is in the past";
          if (disableFuture && date > now) return "Time is in the future";
          if (minTime instanceof Date) {
            if (date < minTime) {
              return `Time should be after ${minTime.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit"
              })}`;
            }
          }
          return true;
        }
      }}
      render={({ fieldState, field }) => (
        <FormInputContainer data-testid={"TIME_PICKER_" + name}>
          <StyledFormLabel required={required}>
            <Typography display="inline" variant="formLabel">
              {label}
            </Typography>
          </StyledFormLabel>
          <TimePicker
            value={selectedValue || field.value || undefined}
            disabled={disabled}
            disableFuture={disableFuture}
            disablePast={disablePast}
            minTime={minTime}
            maxTime={maxTime}
            timeSteps={timeSteps}
            onChange={(e: unknown) => {
              const event = e as React.ChangeEvent;
              field.onChange(event);
              onChange && onChange(event);
              field.onBlur();
            }}
            slotProps={{
              textField: {
                helperText: fieldState.error?.message,
                onBlur: field.onBlur,
                error: !!fieldState.error,
                placeholder: "hh:mm AM/PM"
              }
            }}
            onClose={field.onBlur}
          />
        </FormInputContainer>
      )}
    />
  );
};
