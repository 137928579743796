/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconButton, styled } from "@mui/material";
import {
  AccountCallerDetails,
  CallEmailTemplateContext
} from "@templates/CallEmailTemplate";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import Replay10RoundedIcon from "@mui/icons-material/Replay10Rounded";
import Forward10RoundedIcon from "@mui/icons-material/Forward10Rounded";
import { FormInput } from "@components/FormInput";
import { useForm } from "react-hook-form";
import { Button } from "@components/Button";
import { CallContactDetails } from "./CallContactDetails";
import { updateCRMActivity } from "@services/Network";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { hasPermission } from "@services/Casbin";

const StyledBox = styled("div")`
  position: absolute;
  bottom: 0;
  right: 32px;
  width: 450px;
  border-radius: 8px 0;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 0px 24px 0px #00000029;
  background-color: #ffffff;
  z-index: 10000;
  max-height: 600px;
  overflow: auto;
  border-radius: 8px 8px 0 0;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
`;

const StyledHeader = styled("div")`
  background-color: #1abc9c;
  padding: 12px 24px;
  border-radius: 8px 8px 0 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  color: #ffffff;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const StyledIconButton = styled(IconButton)`
  padding: 0;
`;
const StyledTime = styled("span")`
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  color: #666666;
`;

const StyledForm = styled("div")`
  padding: 0 24px 24px 24px;

  button {
    margin-top: 10px;
    width: 100%;
  }
`;

const PlayPauseIconStyle = {
  height: "70px",
  width: "70px",
  color: "#2B337A"
};

const ReplaybuttonStyle = {
  height: "40px",
  width: "40px",
  color: "#B3B3B3"
};

export const PastCall = ({ account }: { account: AccountCallerDetails }) => {
  dayjs.extend(timezone);
  const mutation = updateCRMActivity();
  const { control } = useForm({
    mode: "onTouched",
    defaultValues: { feedback: "" }
  });
  const { setPastCall } = useContext(CallEmailTemplateContext);
  const [expanded, setExpanded] = useState(true);
  const [feedback, setFeedback] = useState("");
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [feedbackPermission, setFeedbackPermission] = useState(false);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const setAudioData = () => {
        setDuration(audio.duration);
        setCurrentTime(audio.currentTime);
      };

      const setAudioTime = () => setCurrentTime(audio.currentTime);
      audio.addEventListener("loadeddata", setAudioData);
      audio.addEventListener("timeupdate", setAudioTime);
      return () => {
        audio.removeEventListener("loadeddata", setAudioData);
        audio.removeEventListener("timeupdate", setAudioTime);
      };
    }
  }, []);

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const feedback = await checkPermission("crm.manager-feedback", "ON");
      setFeedbackPermission(feedback);
    };
    fetchPermissions();
  }, []);

  const handleSeek = (event) => {
    const seekTime = parseFloat(event.target.value);
    setCurrentTime(seekTime);
    if (audioRef.current) audioRef.current.currentTime = seekTime;
  };

  const togglePlay = () => {
    const audio = audioRef.current;
    if (audio) {
      if (isPlaying) {
        audio.pause();
      } else {
        audio.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    return [hours, minutes, seconds]
      .map((val) => (val < 10 ? `0${val}` : val))
      .filter((val, index) => val !== "00" || index > 0)
      .join(":");
  };

  const skip = (direction) => {
    if (audioRef.current) {
      if (direction === "backward") {
        audioRef.current.currentTime -= 10;
      } else {
        audioRef.current.currentTime += 10;
      }
    }
  };
  return (
    <>
      <StyledBox>
        <StyledHeader>
          <StyledIconButton onClick={() => setPastCall(undefined)}>
            <CloseRoundedIcon
              style={{ height: "24px", width: "24px", color: "#fff" }}
            />
          </StyledIconButton>
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <RadioButtonCheckedOutlinedIcon style={{ color: "#ffffff" }} />
            <span>
              Past{" "}
              {account?.callDirection == "inbound" ? "Incoming" : "Outgoing"}{" "}
              Call
            </span>
          </div>
          <StyledIconButton>
            {!expanded ? (
              <ExpandLessOutlinedIcon
                onClick={() => setExpanded(true)}
                style={{
                  height: "24px",
                  width: "24px",
                  color: "#fff",
                  cursor: "pointer"
                }}
              />
            ) : (
              <ExpandMoreOutlinedIcon
                onClick={() => setExpanded(false)}
                style={{
                  height: "30px",
                  width: "30px",
                  color: "#fff",
                  cursor: "pointer"
                }}
              />
            )}
          </StyledIconButton>
        </StyledHeader>
        <div
          style={{
            display: expanded ? "flex" : "none",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "24px 40px",
            gap: "16px"
          }}
        >
          <CallContactDetails account={account} showTimer={false} />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginTop: "40px"
            }}
          >
            <audio ref={audioRef} hidden onEnded={() => setIsPlaying(false)}>
              <source src={account.call?.url} type="audio/wav" />
            </audio>
            <StyledTime>{formatTime(currentTime)}</StyledTime>
            <input
              type="range"
              min="0"
              max={duration}
              value={currentTime}
              onChange={handleSeek}
              style={{ width: "240px", height: "4px", cursor: "pointer" }}
            />
            <StyledTime> {formatTime(duration)}</StyledTime>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "36px"
            }}
          >
            <StyledIconButton onClick={() => skip("backward")}>
              <Replay10RoundedIcon style={ReplaybuttonStyle} />
            </StyledIconButton>

            {isPlaying ? (
              <StyledIconButton>
                <PauseRoundedIcon
                  style={PlayPauseIconStyle}
                  onClick={togglePlay}
                />
              </StyledIconButton>
            ) : (
              <StyledIconButton>
                <PlayArrowRoundedIcon
                  style={PlayPauseIconStyle}
                  onClick={togglePlay}
                />
              </StyledIconButton>
            )}
            <StyledIconButton onClick={() => skip("forward")}>
              <Forward10RoundedIcon style={ReplaybuttonStyle} />
            </StyledIconButton>
          </div>
        </div>
        {feedbackPermission && (
          <StyledForm style={{ display: expanded ? "block" : "none" }}>
            <FormInput
              control={control}
              type="text"
              name="feedback"
              label="Manager Feedback"
              rows={4}
              multiline
              onChange={(e) => {
                setFeedback((e as ChangeEvent<HTMLInputElement>).target.value);
              }}
            />
            <Button
              variant="admin-primary"
              disabled={feedback == "" || mutation.isLoading}
              isLoading={mutation.isLoading}
              onClick={() => {
                if (account?.activityId)
                  mutation.mutate({
                    activityId: account?.activityId,
                    data: {
                      ...(account.accountId && {
                        accountId: account.accountId
                      }),
                      type: "CALL",
                      relatesTo: "ACCOUNT",
                      status: "COMPLETED",
                      date: new Date(),
                      timezone: dayjs.tz.guess(),
                      managerFeedback: feedback
                    }
                  });
              }}
            >
              Save
            </Button>
          </StyledForm>
        )}
      </StyledBox>
    </>
  );
};
