import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
  ListItemText,
  styled,
  Modal
} from "@mui/material";
import { Add, PlayArrow } from "@mui/icons-material";
import { TableViewInfinite } from "@components/TableViewInfinite";
import {
  getBuildFeatureTestcases,
  getQAFeatureTestcases
} from "@services/Network";
import {
  TESTCASE_STATUS_OPTIONS,
  TEST_CASE_RUNS_STATUS_OPTIONS
} from "@utils/constants";
import { BugIcon, CloseIcon } from "@components/Icons";
import InfoIcon from "../../../src/assets/icons/info.svg";
import formatFullName from "@utils/formatFullName";
import { RunTestCaseForm } from "./RunTestCaseForm";
import {
  ModelFeatureTestcase,
  useAdminFeatureFeatureIdOrderTestCasesPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Link } from "react-router-dom";
import { GridRowOrderChangeParams } from "@mui/x-data-grid-pro";
import { enqueueSnackbar } from "notistack";
import { ToolTip } from "@components/ToolTip";

const MainContainer = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "auto",
  maxHeight: "90%",
  backgroundColor: "white",
  borderRadius: 16,
  "&:focus-visible": {
    outline: "transparent"
  }
});

const Header = styled("div")({
  width: 1000,
  height: 60,
  position: "sticky", // Make the header sticky
  top: 0, // Stick the header to the top of the container
  zIndex: 1,
  backgroundColor: "#2B337A",
  borderTopLeftRadius: 16,
  borderTopRightRadius: 16,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: 16,
  paddingRight: 16
});

const HeaderText = styled("span")({
  fontSize: 18,
  color: "white"
});

const StyledChip = styled(Chip)(() => ({
  "&.MuiChip-root.Mui-disabled": {
    pointerEvents: "none",
    opacity: 1
  }
}));

const renderNewlines = (text) => {
  return text.split("\n").map((line, index) => {
    return (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    );
  });
};
const renderChip = (value, extra = "") => {
  const statusWithAutomated = [
    ...TESTCASE_STATUS_OPTIONS,
    { label: "Automated", value: "AUTOMATED" }
  ];
  const option = statusWithAutomated.find((option) => option.value === value);
  const label = option ? option.label.toLocaleUpperCase() : "";
  return (
    <>
      <Chip
        data-testid={"CHIP_" + value}
        style={{
          fontSize: "10px",
          padding: "4px 8px",
          fontWeight: 600
        }}
        label={label}
        sx={{
          background:
            value === "FAILED"
              ? "#FECACA"
              : value === "PASSED"
                ? "#BBF7D0"
                : value === "BLOCKED"
                  ? "#FF9F0A"
                  : value == "HOLD"
                    ? "#FBF1BC"
                    : "#E5E5E5",
          color:
            value === "FAILED"
              ? "#991B1B"
              : value === "PASSED"
                ? "#166534"
                : value === "BLOCKED"
                  ? "#FFFFFF"
                  : value == "HOLD"
                    ? "#7B5B08"
                    : "#666666"
        }}
      />
      {value == "HOLD" && (
        <ToolTip title={extra}>
          <img src={InfoIcon} data-testid="HOLD_TOOLTIP" />
        </ToolTip>
      )}
      {(value == "FAILED" || value == "BLOCKED") && (
        <Link
          style={{ marginTop: "5px" }}
          to={extra}
          target="_blank"
          data-testid="BUG_ICON"
        >
          <BugIcon style={{ marginLeft: "4px", cursor: "pointer" }} />
        </Link>
      )}
    </>
  );
};
const formatExpectedTime = (date) => {
  if (!date) return "";

  const milliseconds = new Date(date).getTime();
  const totalSeconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  let timeString = "";
  if (minutes > 0 || seconds > 0) {
    timeString += minutes > 0 ? `${minutes}m ` : "";
    timeString += seconds > 0 ? `${seconds}s` : "";
  }

  return timeString.trim();
};

const dateFormat = (date) => {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
    hour12: true
  };
  //@ts-ignore
  return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
};

const isDefaultStatusAutomated = (platform, type, testCase) => {
  return testCase[`${platform}Default${type}Status`] === "AUTOMATED";
};
export const TestCaseList = ({
  featureId,
  platform,
  release,
  testerId,
  releaseList,
  addTestCaseClick,
  includeChildren = false,
  type = "RELEASE"
}: {
  featureId: string;
  platform?: string;
  release: string;
  testerId?: string;
  addTestCaseClick?: () => void;
  releaseList: { label: string; value: string }[];
  includeChildren?: boolean;
  type?: "RELEASE" | "BUILD";
}) => {
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [testCaseToRun, setTestCaseToRun] = useState<ModelFeatureTestcase>();

  const LIST_COLUMNS = [
    {
      headerName: "Test Case Description",
      field: "description",
      minWidth: 250,
      sortable: false,
      cellClassName: "multiple-line-cell",
      renderCell: (params) => (
        <div
          style={{
            padding: "20px 0",
            wordWrap: "break-word",
            wordBreak: "break-all"
          }}
        >
          {renderNewlines(params.row.testCase?.description || "")}
        </div>
      )
    },
    {
      headerName: "Expected Result",
      field: "expectedResult",
      minWidth: 250,
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            padding: "20px 0",
            wordBreak: "break-word",
            whiteSpace: "pre-wrap"
          }}
        >
          {renderNewlines(params.row.testCase?.expectedResult || "")}
        </div>
      )
    },
    {
      headerName: "Tester",
      field: "tester",
      minWidth: 100,
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) =>
        row.testRuns && row.testRuns.length
          ? formatFullName(row.testRuns[0].tester?.person) || "-"
          : "-"
    },
    {
      headerName: "Date",
      field: "date",
      sortable: false,
      value: "date",
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row }) =>
        row.testRuns && row.testRuns.length
          ? dateFormat(new Date(row.testRuns[0].executedOn)) || "-"
          : "-"
    },
    {
      headerName: "Time Logged",
      field: "time",
      sortable: false,
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row }) =>
        row.testRuns && row.testRuns.length
          ? formatExpectedTime(row.testRuns[0].timeLogged) || "-"
          : "-"
    },
    {
      headerName: "UI Status",
      field: "uiStatus",
      minWidth: 115,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const isAutomated = isDefaultStatusAutomated(
          platform?.toLowerCase() || "ios",
          "UI",
          params.row.testCase
        );
        if (params.row.testRuns && params.row.testRuns.length > 0) {
          const run = params.row.testRuns[0];
          return renderChip(
            run.uiStatus,
            run.uiStatus === "HOLD"
              ? run.uiReason
              : run.uiStatus === "BLOCKED" || run.uiStatus === "FAILED"
                ? run.uiJiraLink
                : ""
          );
        } else {
          if (isAutomated) return renderChip("AUTOMATED");
          return renderChip("NOT_TESTED");
        }
      }
    },
    {
      headerName: "FN Status",
      field: "fnStatus",
      value: "date",
      minWidth: 115,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const isAutomated = isDefaultStatusAutomated(
          platform?.toLowerCase() || "ios",
          "FN",
          params.row.testCase
        );
        if (params.row.testRuns && params.row.testRuns.length > 0) {
          const run = params.row.testRuns[0];
          return renderChip(
            run.fnStatus,
            run.fnStatus === "HOLD"
              ? run.fnReason
              : run.fnStatus === "BLOCKED" || run.fnStatus === "FAILED"
                ? run.fnJiraLink
                : ""
          );
        } else {
          if (isAutomated) return renderChip("AUTOMATED");
          return renderChip("NOT_TESTED");
        }
      }
    },
    {
      headerName: "",
      field: "run",
      maxWidth: "40px",
      flex: 0.5,
      sortable: false,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              setTestCaseToRun(params.row.testCase as ModelFeatureTestcase);
              setShowModal(true);
            }}
          >
            <PlayArrow color="primary" />
          </IconButton>
        );
      }
    }
  ];

  const [rows, setRows] = useState<ModelFeatureTestcase[]>([]);
  const [refreshKey, setRefreshKey] = useState(0);

  const orderMutation = useAdminFeatureFeatureIdOrderTestCasesPut();
  const onRowOrderChange = (params: GridRowOrderChangeParams) => {
    const rowsClone = [...rows];
    const row = rowsClone.splice(params.oldIndex, 1)[0];
    rowsClone.splice(params.targetIndex, 0, row);
    setRows([...rowsClone]);
    orderMutation.mutate(
      {
        featureId: featureId,
        data: rowsClone.map((testCase) => testCase.testCaseId!)
      },
      {
        onSuccess: () => {
          setRefreshKey(refreshKey + 1);
        },
        onError: () => {
          enqueueSnackbar("Something went wrong! Failed to sort test cases.", {
            variant: "error"
          });
          setRefreshKey(refreshKey + 1);
        }
      }
    );
  };
  return (
    <Grid container direction="column">
      {/* <MUIToolbar
        style={
          style || {
            padding: "0 0 0 0",
            marginBottom: "-7px",
            marginTop: "5px"
          }
        }
      > */}
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{
          height: "56px",
          border: "0px 0px 1px 0px",
          padding: "8px 32px 8px 32px",
          backgroundColor: "#F3F4F7"
        }}
      >
        <Grid item xs={2}>
          <Typography
            style={{
              fontSize: "18px",
              color: "#666666",
              fontWeight: 600
            }}
          >
            Test Cases
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="row"
          xs={10}
          spacing="10px"
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <Grid item>
            <Typography variant="gridToolbarFilterLabel">View</Typography>
          </Grid>
          <Grid item data-testid="statusFilter">
            <Select
              multiple
              style={{
                minWidth: "208px",
                maxHeight: "40px"
              }}
              onChange={(e) => {
                //   const changedValues=e.target.value;
                setStatusFilter([...e.target.value]);
              }}
              value={statusFilter}
              renderValue={(selected) => {
                return (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value, index) => {
                      const label = TEST_CASE_RUNS_STATUS_OPTIONS.find(
                        (option) => option.value === value
                      )?.label;

                      const onDelete = (idx) => {
                        console.log("E", idx);
                        const newList = [
                          ...statusFilter.slice(0, index),
                          ...statusFilter.slice(index + 1)
                        ];
                        setStatusFilter([...newList]);
                      };

                      const onMouseDown = (event) => {
                        event.preventDefault();
                        event.stopPropagation();
                      };

                      return (
                        <StyledChip
                          data-testid={"CHIP_MULTI_SELECT_" + value}
                          key={value}
                          label={label}
                          onDelete={() => {
                            onDelete(index);
                          }}
                          onMouseDown={onMouseDown}
                        />
                      );
                    })}
                  </Box>
                );
              }}
            >
              {TEST_CASE_RUNS_STATUS_OPTIONS.map((opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                  <Checkbox checked={statusFilter.indexOf(opt.value) > -1} />
                  <ListItemText primary={opt.label} />
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item>
            <Button
              startIcon={<Add color="primary" />}
              onClick={() => {
                if (addTestCaseClick) addTestCaseClick();
              }}
            >
              <Typography
                color="primary"
                style={{
                  fontSize: "14px",
                  textTransform: "none",
                  fontWeight: 600
                }}
              >
                Add Test Case
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {/* </MUIToolbar> */}
      <Modal
        open={showModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <MainContainer>
          <Header>
            <HeaderText>Run Test Case</HeaderText>
            <IconButton
              onClick={() => {
                setShowModal(false);
                setRefreshKey(refreshKey + 1);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Header>

          {testCaseToRun && (
            <RunTestCaseForm
              testCase={testCaseToRun}
              platform={platform}
              release={release}
              releaseList={releaseList}
              onClose={() => {
                setShowModal(false);
                setRefreshKey(refreshKey + 1);
              }}
              type={type}
              disablePlatform={type === "BUILD"}
            />
          )}
        </MainContainer>
      </Modal>
      <TableViewInfinite
        tableKey={featureId || "0"}
        title="Test cases"
        rowReordering
        hideToolbar
        hideFilter
        disabledInfiniteScroll
        getRowHeight={() => "auto"}
        hasActionColumn={false}
        useGet={
          type === "RELEASE" ? getQAFeatureTestcases : getBuildFeatureTestcases
        }
        columns={LIST_COLUMNS}
        searchable={false}
        getRowId={(row) => row.testCaseId}
        hideFooter
        hideLabelContainer
        featureId={featureId}
        releaseConfig={{
          field: type === "RELEASE" ? "release" : "build",
          defaultValue: release,
          options: [{ label: "", value: release }],
          label: type === "RELEASE" ? "Release" : "Build"
        }}
        releaseId={release}
        testerId={testerId ? testerId : undefined}
        statusFilters={statusFilter}
        platformId={platform || "IOS"}
        showCustomNoRows
        rows={rows}
        setRows={(data) => setRows([...data])}
        onRowOrderChange={onRowOrderChange}
        noRowsView={
          <Grid
            justifySelf="flex-end"
            style={{
              padding: "8px 32px 8px 32px"
            }}
          >
            <Typography
              variant="permissionNames"
              sx={{
                color: "#00000",
                fontSize: "12px",
                font: "inter",
                lineHeight: "14.52px",
                letterSpacing: "10%",
                fontWeight: 400
              }}
            >
              No Test Cases Found
            </Typography>
          </Grid>
        }
        refreshKey={refreshKey}
        includeChildren={includeChildren}
        // defaultSort={[{ field: "name", sort: "asc" }]}
      />
    </Grid>
  );
};
