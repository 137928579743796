import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import {
  ModelSponsorshipLevel,
  useSponsorshipLevelGet,
  useSponsorshipLevelPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { organizationAtom } from "../../recoil/auth";
import { FormCheckbox } from "@components/FormCheckbox";
import {
  FormLabel,
  InputAdornment,
  LinearProgress,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { SponsorshipLevelExample } from "@components/SponsorshipLevelExample";
import SponsorAdPreview from "@assets/images/SponsoredByAdPreview.svg";
import SponsorAdSmallPreview from "@assets/images/SponsoredByAdSmallPreview.svg";
import OutStreamTextAdWithLogoPreview from "@assets/images/OutstreamTextAdWithLogoPreview.svg";
import OutStreamTextAdWithoutLogoPreview from "@assets/images/OutstreamTextAdWithoutLogoPreview.svg";
import OutStreamSmallBannerPreview from "@assets/images/OutstreamSmallBannerPreview.svg";
import OutStreamLargeBannerPreview from "@assets/images/OutstreamLargeBannerPreview.svg";
import OutStreamExLargeBannerPreview from "@assets/images/OutstreamExtraLargeBannerPreview.svg";
import OutStreamLrgConImgTopPreview from "@assets/images/OutstreamLrgContImgTopPreview.svg";
import OutStreamLrgConImgMidPreview from "@assets/images/OutsreamLrgConImgMidPreview.svg";
import OutStreamLrgConImgBotPreview from "@assets/images/OutstreamLrgConImgBotPreview.svg";
import OutStreamExLrgConImgTopPreview from "@assets/images/OutstreamExLrgConImgTopPreview.svg";
import OutStreamExLrgConImgMidPreview from "@assets/images/OutstreamExLrgConImgMidPreview.svg";
import OutStreamExLrgConImgBotPreview from "@assets/images/OutstreamExLrgConImgBotPreview.svg";
import FeedPostAdPhotoPreview from "@assets/images/FeedPostAdPhotoPreview.svg";
import FeedPostAdVideoPreview from "@assets/images/FeedPostAdVideoPreview.svg";
import FeedPostAdPhotoLinkPreview from "@assets/images/FeedPostAdPhotoLinkPreview.svg";
import FeedPostAdVideoLinkPreview from "@assets/images/FeedPostAdVideoLinkPreview.svg";
import FeedPostAdLinkPreview from "@assets/images/FeedPostAdLinkPreview.svg";

import InStreamAdPreview from "@assets/images/InStreamAdPreview.svg";
import { Loader } from "@components/crud/Loader";
import { useRecoilValue } from "recoil";
import { capitalizeEveryWord } from "@utils/capitalize";
const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));
const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 12,
  borderRadius: 8,
  marginTop: "8px",
  width: "100%"
}));
const TableHeader = styled(Typography)(() => ({
  color: "#B3B3B3",
  fontSize: "13px",
  fontWeight: 400,
  fontFamily: "Inter"
}));
const TableValue = styled(Typography)(() => ({
  color: "#000",
  fontSize: "14px",
  fontWeight: 600,
  fontFamily: "Inter"
}));
export const SponsorshipLevelCreate = () => {
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [sponsoredByAd, setSponsoredByAd] = useState(false);
  const [outStreamAd, setOutStreamAd] = useState(false);
  const [inStreamAd, setInStreamAd] = useState(false);
  const [feedPostAd, setFeedPostAd] = useState(false);
  const [showExample, setShowExample] = useState(false);
  const [showExampleDetails, setShowExampleDetails] = useState<{
    title: string;
    src?: string;
    radioOptionsMap?: { name: string; src: string }[];
  }>({
    title: ""
  });
  const [percentage, setPercentage] = useState({
    outStreamAd: { probability: 0 },
    sponsoredByAd: { probability: 0 },
    feedPostAd: { probability: 0 }
  });
  const { data: sponsorshipLevel, isLoading: sponsorshipLevelLoading } =
    useSponsorshipLevelGet({ organizationId: organizationId! });
  const {
    handleSubmit,
    control,
    formState: { isValid },
    reset,
    setValue,
    getValues
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      isActive: "YES",
      name: "",
      amount: 0.0
    }
  });

  useEffect(() => {
    if (sponsorshipLevel?.data.numRows == 4) {
      navigate("/sponsorship-levels");
    }
  }, [sponsorshipLevel?.data]);

  useEffect(() => {
    if (sponsorshipLevel?.data && !sponsorshipLevelLoading) {
      sponsorshipLevel.data.sponsorshipLevels.forEach((level) => {
        if (level.inStreamEnabled) {
          setValue(
            `inStreamAd.${level.sponsorshipId!}`,
            String(level.inStreamPercentage * 100)
          );
        }
        if (level.outStreamEnabled) {
          setValue(
            `outStreamAd.${level.sponsorshipId!}`,
            String(level.outStreamPercentage * 100)
          );
          setValue(`outStreamAd.percentage`, String(0));
          setPercentage((prevPercentage) => ({
            ...prevPercentage,
            outStreamAd: {
              ...prevPercentage.outStreamAd,
              [level.sponsorshipId!]: level.outStreamPercentage * 100,
              probability: 0
            }
          }));
        }
        if (level.sponsoredByEnabled) {
          setValue(
            `sponsoredByAd.${level.sponsorshipId!}`,
            String(level.sponsoredByPercentage * 100)
          );
          setValue(`sponsoredByAd.percentage`, String(0));
          setPercentage((prevPercentage) => ({
            ...prevPercentage,
            sponsoredByAd: {
              ...prevPercentage.sponsoredByAd,
              [level.sponsorshipId!]: level.sponsoredByPercentage * 100,
              probability: 0
            }
          }));
        }
        if (!organizationId && level.feedPostEnabled) {
          setValue(
            `feedPostAd.${level.sponsorshipId!}`,
            String(level.feedPostPercentage * 100)
          );
          setValue(`feedPostAd.percentage`, String(0));
          setPercentage((prevPercentage) => ({
            ...prevPercentage,
            feedPostAd: {
              ...prevPercentage.feedPostAd,
              [level.sponsorshipId!]: (level.feedPostPercentage || 0) * 100,
              probability: 0
            }
          }));
        }
      });
    }
  }, [sponsorshipLevel, sponsorshipLevelLoading]);

  const { mutate: save, isLoading: isSaving } = useSponsorshipLevelPost();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    async (formValues) => {
      const values = {
        ...formValues,
        organizationId
      };
      const levels: ModelSponsorshipLevel[] = [];
      levels.push({
        name: values.name,
        amount: parseFloat(values.amount),
        sponsoredByEnabled: sponsoredByAd,
        isActive: values.isActive === "YES",
        sponsoredByPercentage:
          (sponsoredByAd && Number(values.sponsoredByAd?.percentage) / 100) ||
          0,
        inStreamEnabled: inStreamAd,
        outStreamEnabled: outStreamAd,
        outStreamPercentage:
          (outStreamAd && Number(values.outStreamAd?.percentage) / 100) || 0,
        inStreamMaxDuration:
          inStreamAd && inStreamAd
            ? Number(values.instream_duration)
            : undefined,
        ...(organizationId && { organizationId }),
        ...(!organizationId && {
          feedPostEnabled: feedPostAd,
          feedPostPercentage:
            (feedPostAd && Number(values.feedPostAd?.percentage) / 100) || 0
        })
      });
      Object.keys(values).forEach((key) => {
        if (key.includes("Ad") && typeof values[key] === "object") {
          const adType = key;
          Object.keys(values[adType]).forEach((subKey) => {
            if (subKey !== "percentage") {
              const sponsorshipId = subKey;
              const percentage = parseFloat(values[adType][subKey]) / 100;
              const instreamPercentage =
                adType === "inStreamAd" && !organizationId ? percentage : 0;
              const sponsoredByPercentage =
                adType === "sponsoredByAd" ? percentage : 0;
              const outstreamPercentage =
                adType === "outStreamAd" ? percentage : 0;
              const feedPostPercentage =
                adType === "feedPostAd" ? percentage : 0;
              const existingEntry = levels.find(
                (entry) => entry.sponsorshipId === sponsorshipId
              );
              if (existingEntry) {
                existingEntry.sponsoredByPercentage += sponsoredByPercentage;
                existingEntry.outStreamPercentage += outstreamPercentage;
                if (!organizationId) {
                  existingEntry.feedPostPercentage += feedPostPercentage;
                  existingEntry.feedPostEnabled = !!(
                    existingEntry.feedPostPercentage &&
                    existingEntry.feedPostPercentage !== 0
                  );
                }
                existingEntry.sponsoredByEnabled =
                  existingEntry.sponsoredByPercentage != 0;
                existingEntry.outStreamEnabled =
                  existingEntry.outStreamPercentage != 0;
              } else {
                levels.push({
                  sponsorshipId,
                  inStreamPercentage: instreamPercentage,
                  sponsoredByPercentage: sponsoredByPercentage,
                  outStreamPercentage: outstreamPercentage,
                  ...(!organizationId && {
                    feedPostEnabled: !!(
                      feedPostPercentage && feedPostPercentage !== 0
                    ),
                    feedPostPercentage: feedPostPercentage
                  }),
                  sponsoredByEnabled: sponsoredByPercentage != 0,
                  outStreamEnabled: outstreamPercentage != 0,
                  ...(organizationId && { organizationId })
                });
              }
            }
          });
        }
      });
      save(
        {
          data: levels
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Sponsorship Level added successfully!", {
              variant: "success"
            });
            if (resetInsteadOfRoute) {
              reset();
            } else {
              navigate("/sponsorship-levels");
            }
          },
          onError: () => {
            enqueueSnackbar("Failed to add Sponsorship Level!", {
              variant: "error"
            });
          }
        }
      );
    };
  const getOptions = (max: number) => {
    const options: { label: string; value: string }[] = [];
    for (let i = 0; i <= 100 - max; i++) {
      options.push({
        label: `${i}%`,
        value: `${i}`
      });
    }
    return options;
  };
  const renderSub = (Adtype: string, levels?) => {
    const percentRemaining =
      100 -
      Object.values(percentage[Adtype] as Record<string, number>).reduce(
        (accumulator, currentValue) =>
          accumulator + (typeof currentValue === "number" ? currentValue : 0),
        0
      );

    if (levels.length == 0) {
      setValue(`${Adtype}.percentage`, 100);
    }
    return (
      <>
        <Grid sx={{ paddingLeft: "12px" }} xs={12} md={6} sm={12} spacing={3}>
          <FormSelect
            label="Probability Percentage of being Displayed"
            name={`${Adtype}.percentage`}
            disabled={levels.length == 0}
            required={true}
            options={getOptions(0)}
            control={control}
            onChange={(e) => {
              const updatedPercentage = {
                ...levels.reduce((acc, curr) => {
                  (acc[curr.sponsorshipId!] = 0), 10;
                  return acc;
                }, {})
              };
              setPercentage((prevPercentage) => ({
                ...prevPercentage,
                [Adtype]: {
                  probability: parseInt(e.target.value, 10),
                  ...updatedPercentage
                }
              }));
              levels.map((level) => {
                setValue(`${Adtype}.${level.sponsorshipId}`, 0);
              });
            }}
            rules={{ required: "Probability Percentage is required" }}
          />
        </Grid>
        {levels.length >= 1 && (
          <>
            <Grid
              container
              spacing={3}
              xs={12}
              md={6}
              marginLeft="5px"
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#F4F4F4"
              }}
            >
              <>
                {levels &&
                  levels.map((level, index) => (
                    <Grid key={level.sponsorshipId} xl={4} md={4} sm={12}>
                      <FormSelect
                        label={level.name}
                        name={`${Adtype}.${level.sponsorshipId!}`}
                        options={
                          index > 0
                            ? getOptions(
                                Object.keys(percentage[Adtype])
                                  .map((key, i) =>
                                    index >= i ? percentage[Adtype][key] : null
                                  )
                                  .filter((value) => value !== null)
                                  .reduce((a, b) => a + b, 0)
                              )
                            : getOptions(getValues(`${Adtype}.percentage`))
                        }
                        control={control}
                        onChange={(e) => {
                          const updatedPercentage = {
                            ...percentage[Adtype],
                            ...levels.reduce((acc, curr, i) => {
                              if (i > index) {
                                (acc[curr.sponsorshipId!] = 0), 10;
                              }
                              if (i == index) {
                                (acc[curr.sponsorshipId!] = parseInt(
                                  e.target.value,
                                  10
                                )),
                                  10;
                              }
                              return acc;
                            }, {})
                          };
                          setPercentage((prevPercentage) => ({
                            ...prevPercentage,
                            [Adtype]: updatedPercentage
                          }));

                          for (let i = index; i < levels.length; i++) {
                            setValue(
                              `${Adtype}.${levels[i].sponsorshipId!}`,
                              String(0)
                            );
                          }
                        }}
                      />
                    </Grid>
                  ))}
              </>
            </Grid>
            <Container
              sx={{
                padding: "12px !important",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Grid container justifyContent="space-between">
                <Grid>
                  <Typography
                    data-testid="import-player-overview-completed-status"
                    sx={{
                      color: "#7A7F89",
                      fontWeight: 500,
                      fontSize: "14px"
                    }}
                  >
                    {Adtype == "outStreamAd"
                      ? "Out-Stream"
                      : Adtype == "inStreamAd"
                        ? "In-Stream"
                        : Adtype == "feedPostAd"
                          ? "Feed Post"
                          : "Sponsored By"}{" "}
                    Ad fields must add up to 100%
                  </Typography>
                </Grid>
                <Grid data-testid="percenatge-remaining">
                  <TableHeader sx={{ display: "inline" }}>
                    Percentage Remaining:
                  </TableHeader>
                  <TableValue sx={{ display: "inline" }}>
                    {percentRemaining}%
                  </TableValue>
                </Grid>
              </Grid>
              <BorderLinearProgress
                className={`${percentRemaining == 0 ? "success" : "fail"}`}
                variant="determinate"
                color={percentRemaining == 0 ? "success" : "error"}
                value={100 - percentRemaining}
              />
            </Container>
          </>
        )}
        {levels.length == 0 && (
          <Grid
            spacing={3}
            xs={12}
            md={6}
            data-testid="sponsorship-level-note"
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#F4F4F4",
              height: "fit-content",
              marginTop: "28px"
            }}
          >
            <Typography>
              Note: Because this ad placement is only included in one
              sponsorship level it has been automatically set to be displayed
              100% of the time
            </Typography>
          </Grid>
        )}
      </>
    );
  };
  const checkSaveDisabled = () => {
    const sum = Object.values(percentage)
      .flatMap((adType) =>
        Object.values(adType).filter((value) => typeof value === "number")
      )
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    return (
      sum === 0 ||
      sum % 100 !== 0 ||
      (!inStreamAd && !outStreamAd && !sponsoredByAd && !feedPostAd)
    );
  };
  const showExampleClose = () => {
    setShowExample(false);
  };

  const [nameCharacterCount, setNameCharacterCount] = useState(0);
  return (
    <Loader isLoading={sponsorshipLevelLoading}>
      <Container>
        <Toolbar title="Add Sponsorship Level" />
        <Form>
          <Grid data-testid="sponsorship-level-form" container spacing={2}>
            <Grid container direction="row" spacing="24px" md={12}>
              <Grid container direction="column" spacing="1px" xs={6}>
                <Grid data-testid="sponsorship-level-name">
                  <FormInput
                    control={control}
                    name="name"
                    type="text"
                    label="Name"
                    required={true}
                    onChange={(e) => {
                      setValue("name", capitalizeEveryWord(e.target.value));
                      setNameCharacterCount(e.target?.value?.length || 0);
                    }}
                    rules={{
                      required: "Name is required",
                      maxLength: {
                        value: 10,
                        message: "Name must not exceed 10 characters"
                      }
                    }}
                  />
                </Grid>
                <Grid style={{ textAlign: "end" }}>
                  <Typography>
                    {"Character Count Remaining: " + (10 - nameCharacterCount)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid xs={6}>
                <FormInput
                  type="number"
                  control={control}
                  label="Amount"
                  name="amount"
                  required
                  rules={{
                    required: "Amount is required"
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">{"$"}</InputAdornment>
                    ),
                    step: "0.1"
                  }}
                />
              </Grid>
            </Grid>
            <Grid data-testid="sponsorship-level-isActive" xs={6}>
              <FormSelect
                control={control}
                options={[
                  {
                    label: "Yes",
                    value: "YES"
                  },
                  {
                    label: "No",
                    value: "NO"
                  }
                ]}
                name="isActive"
                label="Is Active"
              />
            </Grid>
            <Grid xs={12}>
              <StyledFormLabel required={true}>
                <Typography
                  style={{ fontWeight: "bold", fontSize: "13px" }}
                  display="inline"
                  variant="formLabel"
                >
                  Ad Placements
                </Typography>
              </StyledFormLabel>

              {!organizationId && (
                <Grid container direction="column">
                  <Grid container>
                    <Grid>
                      <FormCheckbox
                        label="Feed Post Ad - Feed/Recruiting Feed Posts "
                        name="feedPostEnabled"
                        control={control}
                        onChange={(e) => {
                          const levels =
                            sponsorshipLevel?.data.sponsorshipLevels.filter(
                              (level) => level.feedPostEnabled
                            );
                          setFeedPostAd(e.target.checked);
                          if (e.target.checked) {
                            setValue(`feedPostAd.percentage`, String(0));

                            setPercentage((prevPercentage) => ({
                              ...prevPercentage,
                              feedPostAd: {
                                ...prevPercentage.feedPostAd,
                                probability:
                                  levels.length == 0 && e.target.checked
                                    ? 100
                                    : 0
                              }
                            }));
                          }
                          if (!e.target.checked) {
                            levels.forEach((level) => {
                              setValue(
                                `feedPostAd.${level.sponsorshipId!}`,
                                String(level.feedPostPercentage * 100)
                              );
                              setValue(`feedPostAd.percentage`, String(0));
                              setPercentage((prevPercentage) => ({
                                ...prevPercentage,
                                feedPostAd: {
                                  ...prevPercentage.feedPostAd,
                                  [level.sponsorshipId!]:
                                    level.feedPostPercentage * 100,
                                  probability: 0
                                }
                              }));
                            });
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px"
                      }}
                    >
                      <Typography
                        style={{
                          color: "#3B6CF8",
                          fontWeight: 600,
                          fontSize: "13px",
                          cursor: "pointer"
                        }}
                        data-testid="feedPostAd-example"
                        onClick={() => {
                          setShowExample(true);
                          setShowExampleDetails({
                            title: "Feed Post Ad - Feed/Recruiting Feed Posts",
                            radioOptionsMap: [
                              {
                                name: "Photo",
                                src: FeedPostAdPhotoPreview
                              },
                              {
                                name: "Video",
                                src: FeedPostAdVideoPreview
                              },
                              {
                                name: "Photo + Link",
                                src: FeedPostAdPhotoLinkPreview
                              },
                              {
                                name: "Video + Link",
                                src: FeedPostAdVideoLinkPreview
                              },
                              {
                                name: "Link",
                                src: FeedPostAdLinkPreview
                              }
                            ]
                          });
                        }}
                      >
                        Show Example
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container xs={12} md={12} sm={12} xl={12}>
                    {feedPostAd && (
                      <>
                        {renderSub(
                          "feedPostAd",
                          sponsorshipLevel?.data.sponsorshipLevels.filter(
                            (level) => level.feedPostEnabled
                          )
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              )}

              <Grid container>
                <Grid>
                  <FormCheckbox
                    label="Sponsored By Ad - Live Stream Event Feed Posts"
                    name="sponsoredByAdEnabled"
                    control={control}
                    onChange={(e) => {
                      const levels =
                        sponsorshipLevel?.data.sponsorshipLevels.filter(
                          (level) => level.sponsoredByEnabled
                        );
                      setSponsoredByAd(e.target.checked);
                      if (e.target.checked) {
                        // levels.forEach((level) => {
                        //   setValue(`sponsoredByAd.${level.sponsorshipId!}`, 0);
                        // });
                        setValue(`sponsoredByAd.percentage`, String(0));
                        // const updatedPercentage = {
                        //   ...levels.reduce((acc, curr) => {
                        //     (acc[curr.sponsorshipId!] = 0), 10;
                        //     return acc;
                        //   }, {})
                        // };

                        // console.log("IMAZ:::up", updatedPercentage);
                        setPercentage((prevPercentage) => ({
                          ...prevPercentage,
                          sponsoredByAd: {
                            ...prevPercentage.sponsoredByAd,
                            probability:
                              levels.length == 0 && e.target.checked ? 100 : 0
                            // ...updatedPercentage
                          }
                        }));
                      }
                      if (!e.target.checked) {
                        levels.forEach((level) => {
                          setValue(
                            `sponsoredByAd.${level.sponsorshipId!}`,
                            String(level.sponsoredByPercentage * 100)
                          );
                          setValue(`sponsoredByAd.percentage`, String(0));
                          setPercentage((prevPercentage) => ({
                            ...prevPercentage,
                            sponsoredByAd: {
                              ...prevPercentage.sponsoredByAd,
                              [level.sponsorshipId!]:
                                level.sponsoredByPercentage * 100,
                              probability: 0
                            }
                          }));
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "10px"
                  }}
                >
                  <Typography
                    style={{
                      color: "#3B6CF8",
                      fontWeight: 600,
                      fontSize: "13px",
                      cursor: "pointer"
                    }}
                    data-testid="sponsoredByAd-example"
                    onClick={() => {
                      setShowExample(true);
                      setShowExampleDetails({
                        title: "Sponsored By Ad - Live Stream Event Feed Posts",
                        radioOptionsMap: [
                          {
                            name: "Small Banner Ad",
                            src: SponsorAdSmallPreview
                          },
                          {
                            name: "Large Banner Ad",
                            src: SponsorAdPreview
                          }
                        ]
                      });
                    }}
                  >
                    Show Example
                  </Typography>
                </Grid>
              </Grid>
              <Grid container xs={12} md={12} sm={12} xl={12}>
                {sponsoredByAd && (
                  <>
                    {renderSub(
                      "sponsoredByAd",
                      sponsorshipLevel?.data.sponsorshipLevels.filter(
                        (level) => level.sponsoredByEnabled
                      )
                    )}
                  </>
                )}
              </Grid>
              <Grid container>
                <Grid>
                  <FormCheckbox
                    label="Out-Stream Ad - View Live Stream Screen"
                    name="outStreamAdEnabled"
                    control={control}
                    onChange={(e) => {
                      const levels =
                        sponsorshipLevel?.data.sponsorshipLevels.filter(
                          (level) => level.outStreamEnabled
                        );
                      setOutStreamAd(e.target.checked);
                      if (e.target.checked) {
                        // levels.forEach((level) => {
                        //   setValue(`outStreamAd.${level.sponsorshipId!}`, 0);
                        // });
                        setValue(`outStreamAd.percentage`, String(0));
                        // const updatedPercentage = {
                        //   ...levels.reduce((acc, curr) => {
                        //     (acc[curr.sponsorshipId!] = 0), 10;
                        //     return acc;
                        //   }, {})
                        // };
                        setPercentage((prevPercentage) => ({
                          ...prevPercentage,
                          outStreamAd: {
                            ...prevPercentage.outStreamAd,
                            probability:
                              levels.length == 0 && e.target.checked ? 100 : 0
                            // ...updatedPercentage
                          }
                        }));
                      }
                      if (!e.target.checked) {
                        levels.forEach((level) => {
                          setValue(
                            `outStreamAd.${level.sponsorshipId!}`,
                            String(level.outStreamPercentage * 100)
                          );
                          setValue(`outStreamAd.percentage`, String(0));
                          setPercentage((prevPercentage) => ({
                            ...prevPercentage,
                            outStreamAd: {
                              ...prevPercentage.outStreamAd,
                              [level.sponsorshipId!]:
                                level.outStreamPercentage * 100,
                              probability: 0
                            }
                          }));
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "10px"
                  }}
                >
                  <Typography
                    style={{
                      color: "#3B6CF8",
                      fontWeight: 600,
                      fontSize: "13px",
                      cursor: "pointer"
                    }}
                    data-testid="outStreamAd-example"
                    onClick={() => {
                      setShowExample(true);
                      setShowExampleDetails({
                        title: "Out-Stream Ad - View Live Stream Screen",
                        radioOptionsMap: [
                          {
                            name: "Text Ad with Logo",
                            src: OutStreamTextAdWithLogoPreview
                          },
                          {
                            name: "Text Ad without Logo",
                            src: OutStreamTextAdWithoutLogoPreview
                          },
                          {
                            name: "Small Banner Ad",
                            src: OutStreamSmallBannerPreview
                          },
                          {
                            name: "Large Banner Ad",
                            src: OutStreamLargeBannerPreview
                          },
                          {
                            name: "Extra Large Banner Ad",
                            src: OutStreamExLargeBannerPreview
                          },
                          {
                            name: "Large Contextual Ad - Image Top",
                            src: OutStreamLrgConImgTopPreview
                          },
                          {
                            name: "Large Contextual Ad - Image Middle",
                            src: OutStreamLrgConImgMidPreview
                          },
                          {
                            name: "Large Contextual Ad - Image Bottom",
                            src: OutStreamLrgConImgBotPreview
                          },
                          {
                            name: "Extra Large Contextual Ad - Image Top",
                            src: OutStreamExLrgConImgTopPreview
                          },
                          {
                            name: "Extra Large Contextual Ad - Image Middle",
                            src: OutStreamExLrgConImgMidPreview
                          },
                          {
                            name: "Extra Large Contextual Ad - Image Bottom",
                            src: OutStreamExLrgConImgBotPreview
                          }
                        ]
                      });
                    }}
                  >
                    Show Example
                  </Typography>
                </Grid>
              </Grid>
              <Grid container xs={12} md={12} sm={12} xl={12}>
                {outStreamAd && (
                  <>
                    {renderSub(
                      "outStreamAd",
                      sponsorshipLevel?.data.sponsorshipLevels.filter(
                        (level) => level.outStreamEnabled
                      )
                    )}
                  </>
                )}
              </Grid>
              <Grid container>
                <Grid>
                  <FormCheckbox
                    label="In-Stream Ad - Live Stream Intermissions"
                    name="inStreamAdEnabled"
                    control={control}
                    onChange={(e) => {
                      setInStreamAd(e.target.checked);
                    }}
                  />
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "10px"
                  }}
                >
                  <Typography
                    style={{
                      color: "#3B6CF8",
                      fontWeight: 600,
                      fontSize: "13px",
                      cursor: "pointer"
                    }}
                    data-testid="inStreamAd-example"
                    onClick={() => {
                      setShowExample(true);
                      setShowExampleDetails({
                        title: "In-Stream Ad - Live Streaming Intermissions",
                        src: InStreamAdPreview
                      });
                    }}
                  >
                    Show Example
                  </Typography>
                </Grid>
              </Grid>

              {inStreamAd && (
                <>
                  <Grid
                    container
                    direction="row"
                    spacing="20px"
                    marginLeft="5px"
                    xs={12}
                    md={12}
                    sm={12}
                    xl={12}
                    alignItems="center"
                  >
                    <Grid md={6}>
                      <FormSelect
                        label="Max Duration of In-Stream Video"
                        name="instream_duration"
                        required={inStreamAd}
                        rules={{
                          required:
                            "Max Duration of In-Stream Video is required"
                        }}
                        options={[
                          {
                            label: "6 seconds",
                            value: "6"
                          },
                          {
                            label: "15 seconds",
                            value: "15"
                          },
                          {
                            label: "30 seconds",
                            value: "30"
                          }
                        ]}
                        control={control}
                      />
                    </Grid>
                    <Grid
                      spacing={3}
                      xs={12}
                      md={6}
                      data-testid="instream-level-note"
                      sx={{
                        display: "flex",
                        alignItems: "center",

                        backgroundColor: "#F4F4F4",
                        height: "fit-content",
                        marginTop: "28px"
                      }}
                    >
                      <Typography>
                        Note: During live stream intermissions, sponsorship
                        levels supporting this stream are shown in order of the
                        sort order your organization has set. Advertisements
                        within the Higher Sponsorship levels are shown first,
                        then everyone gets a turn!
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Form>
        <Footer
          cancelBtnClick={() => setOpenCancelDialog(true)}
          saveBtnClick={handleSubmit(saveHandler(false))}
          isDisabled={!isValid || isSaving || checkSaveDisabled()}
          isLoading={isSaving}
        />
        <ConfirmationDialog
          title="Are you sure you want to cancel?"
          body="All of your current changes will be lost."
          open={openCancelDialog}
          close={() => setOpenCancelDialog(false)}
          onCancel={() => setOpenCancelDialog(false)}
          onConfirm={() => navigate("/sponsorship-levels")}
          cancelBtnText="Cancel"
          confirmBtnText="Confirm"
        />
      </Container>
      {showExample && (
        <SponsorshipLevelExample
          radioOptionsMap={showExampleDetails.radioOptionsMap || undefined}
          defaultSelectedOption={
            showExampleDetails.radioOptionsMap
              ? showExampleDetails.radioOptionsMap[0].name
              : undefined
          }
          onClose={showExampleClose}
          title={showExampleDetails.title}
          src={showExampleDetails.src}
        />
      )}
    </Loader>
  );
};
