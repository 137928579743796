import React, { useMemo, useState } from "react";
import { Avatar, Backdrop, Box, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import CloseIcon from "@mui/icons-material/Close";
import { organizationAtom, organizationsAtom, profileAtom } from "@recoil/auth";
import { useRecoilValue } from "recoil";
import { EditIcon } from "@components/Icons";
import { Delete, Visibility } from "@mui/icons-material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import EventTimeIcon from "@assets/icons/eventTimeIcon.svg";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import {
  DatePicker,
  LocalizationProvider,
  TimeField
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LiveStreamIcon from "../../../src/assets/icons/liveIconRed.svg";
import DescriptionIcon from "../../../src/assets/icons/descriptionIcon.svg";
import {
  LiveStreamStatus,
  useAdminEventEventIdRecurrenceRecurrenceIdDelete,
  useAdminEventEventIdRecurrenceRecurrenceIdGet,
  useConfigGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import LocationIcon from "../../../src/assets/icons/locationIcon.svg";
import ShareWithIcon from "../../../src/assets/icons/shareWithIcon.svg";
import { Loader } from "@components/crud/Loader";
import { RRule } from "rrule";
import { useNavigate } from "react-router-dom";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { RemoveRecurringEvent } from "./components/RemoveRecurringEvent";
import { useSnackbar } from "notistack";
import TeamIcon from "../../../src/assets/icons/teamIcon.svg";
import personGroupIcon from "../../../src/assets/icons/personGroupIcon.svg";
import colors from "theme/colors";
import EditCalendarTwoToneIcon from "@mui/icons-material/EditCalendarTwoTone";
import VersusIcon from "@assets/icons/versusIcon.svg";
import { capitalize } from "@utils/capitalize";

const CONTEST_TYPE_OPTIONS = [
  {
    label: "General Event (No LiveStream)",
    value: null
  },
  { label: "Game / Match", value: "GAME" },
  { label: "Scrimmage", value: "SCRIMMAGE" },
  { label: "Practice", value: "PRACTICE" },
  {
    label: "Training Session",
    value: "TRAINING_SESSION"
  },
  { label: "Athletic Event", value: "EVENT" }
];

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "16px",
  marginLeft: "125px",
  width: "100%",
  paddingBottom: "24px",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "0%"
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "0px"
  },
  "& fieldset": {
    border: "none !important"
  },
  "& svg[data-testid='CalendarIcon'], svg[data-testid='ClockIcon']": {
    display: "none"
  },
  "& .time-picker button, .date-picker button": {
    display: "none"
  },
  "& input, select": {
    color: "#2B337A",
    "&:active": {
      color: "#000"
    }
  },
  "& .timezone .MuiInputBase-root": {
    padding: 0
  },
  "& .timezone .MuiInputBase-root::before, .recurrence .MuiInputBase-root::before, .shareWith .MuiInputBase-root::before, .title, .title .MuiInputBase-root::before":
    {
      borderBottom: "none !important"
    },
  "& .recurrence .MuiSelect-select, .sport .MuiSelect-select": {
    color: "#2B337A"
  },
  "& .sport .MuiSelect-select": {
    paddingTop: "0px",
    paddingBottom: "0px"
  },
  "& .shareWith .MuiInputBase-input::placeholder": {
    fontSize: "16px",
    color: "#64748B",
    fontWeight: 400
  },
  "& .date-picker .MuiInputBase-root ": {
    paddingRight: "0px"
  },
  "& .date-picker .MuiInputBase-input, .time-picker .MuiInputBase-input ": {
    padding: "0 0 0 10px"
  },
  "& .loader": {
    minHeight: "350px"
  }
}));
const StyledBoxHeader = styled(Box)({
  height: "64px",
  backgroundColor: colors.info.main,
  borderRadius: "16px 16px 0px 0px"
});

export const getRRuleText = (rrule) => {
  let rule = "Does not repeat";
  if (rrule) {
    const rruleObj = RRule.fromString(rrule);
    rule = rruleObj.toText();
    rule = rule
      .replace(/Monday/g, "Mon")
      .replace(/Tuesday/g, "Tue")
      .replace(/Wednesday/g, "Wed")
      .replace(/Thursday/g, "Thu")
      .replace(/Friday/g, "Fri")
      .replace(/Saturday/g, "Sat")
      .replace(/Sunday/g, "Sun");

    if (
      rule.includes("Sun") &&
      rule.includes("Sat") &&
      !rule.match(/Mon|Tue|Wed|Thu|Fri/)
    ) {
      rule = rule.replace(/Sat, Sun/g, "weekends");
    } else if (
      rule.match(/Mon|Tue|Wed|Thu|Fri/) &&
      !rule.includes("Sun") &&
      !rule.includes("Sat")
    ) {
      rule = rule.replace(/Mon, Tue, Wed, Thu, Fri/g, "weekdays");
    }
    if (rruleObj.options.freq === RRule.MONTHLY) {
      const dtstart = rruleObj.options.dtstart;
      const interval = rruleObj.options.interval;
      const date = new Date(dtstart).getDate();
      rule = `${interval === 1 ? "every" : `every ${interval}`} ${interval > 1 ? "months" : "month"} on day ${date}`;
    }
  }
  return rule;
};
interface ConnectionGroup {
  kind: string;
  id: string;
  name: string;
  description: string;
  orgOwned: boolean;
}
export const CalendarEventView = (props: {
  button;
  onClose;
  displayMessage?;
  setMessage?;
  details?;
  hasPermissionToEdit;
  hasPermissionToDelete;
  openEdit;
  reLoadCalendar?;
}) => {
  dayjs.extend(timezone);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const org = organizations.find(
    (org) => org.organizationId === organizationId
  );
  const loggedInUser = useRecoilValue(profileAtom);
  const [isLiveStream] = useState(props.details.isLiveStream);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [recurringDeleteOpen, setRecurringDeleteOpen] = useState(false);
  const [recurringEventDeleteType, setRecurringEventDeleteType] =
    useState("this");
  const { data: event, isLoading: eventLoading } =
    useAdminEventEventIdRecurrenceRecurrenceIdGet(
      props.details.id,
      props.details.recurrenceId
    );

  const { data: config, isLoading: isConfigLoading } = useConfigGet();

  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminEventEventIdRecurrenceRecurrenceIdDelete();

  const onConfirmDelete = async () => {
    try {
      await deleteAsync({
        eventId: props.details.id,
        recurrenceId: props.details.recurrenceId,
        params: {
          deleteAll: recurringEventDeleteType == "all",
          deleteFuture: recurringEventDeleteType == "future"
        }
      });
      props.onClose();
      enqueueSnackbar("Event deleted successfully", {
        variant: "success"
      });
      props.reLoadCalendar && props.reLoadCalendar();
    } catch (error) {
      enqueueSnackbar("Something went wrong! Unable to delete event.", {
        variant: "error"
      });
    }
  };

  const generateAddress = (location) => {
    if (location) {
      const { lines, locality, province, postalCode, country } = location;
      const street = lines.length > 0 ? lines[0] : "";
      return `${street}, ${locality}, ${province}, ${postalCode}, ${country}`;
    }
  };

  const handleRecurringEventDeleteClose = () => {
    setRecurringDeleteOpen(false);
  };
  const personAvatar = (person, index) => {
    return (
      <Container key={index} data-testid={`person-${person.personId}`}>
        <Grid container alignItems="center">
          <Grid>
            <Avatar
              sx={{
                bgcolor: "#" + person.colorCode,
                color: "#fff !important",
                padding: "14px",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "20px",
                textAlign: "center",
                borderRadius: "8px",
                width: "32px",
                height: "32px"
              }}
              variant="square"
            >
              {person.avatar?.baseUrl ? (
                <img
                  src={person.avatar.baseUrl + person.avatar.path}
                  width="32px"
                  height="32px"
                />
              ) : (
                <span>
                  {`${person.firstName} ${person.lastName}`
                    .split(" ")
                    .map((name) => name[0])
                    .join("")
                    .toUpperCase()}
                </span>
              )}
            </Avatar>
          </Grid>

          <Grid
            maxWidth={"fit-content"}
            marginLeft={"11px"}
            xs
            container
            direction="column"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <Typography style={{ fontSize: "14px", fontWeight: 400 }}>
                {`${person.firstName} ${person.lastName}`}
              </Typography>
            </div>

            <Typography
              style={{ fontSize: "14px", fontWeight: 400, color: "#64748B" }}
            >
              {`${person.email}`}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  };

  const transformData = (data) => {
    if (data) {
      const transformedPrograms = data.programs.map((program) => ({
        name: program.name,
        id: program.programId,
        description: "Training Program",
        orgOwned: !!program.organizationId,
        kind: program.$kind
      }));

      const teams = data.teams.map((team) => ({
        name: team.name,
        id: team.teamId,
        description: "Team",
        orgOwned: !!team.organizationId,
        kind: team.$kind
      }));

      const transformedConnectionGroups = data.connectionGroups
        .map((group) => {
          if (group.team) {
            return {
              name: group.team.name,
              description: `${group.name} > ${group.team.name}`,
              id: group.groupId,
              orgOwned: !!group.team.organizationId,
              kind: "Team"
            };
          } else if (group.program) {
            return {
              name: group.name,
              description: `${group.program.name} > ${group.name}`,
              id: group.groupId,
              orgOwned: !!group.program.organizationId,
              kind: group.$kind
            };
          }
        })
        .flat();

      const combinedData = [
        ...teams,
        ...transformedPrograms,
        ...transformedConnectionGroups
      ];

      return combinedData;
    }
  };

  const connectionGroupAvatar = (groups) => {
    //@ts-ignore
    const connectionGroups: ConnectionGroup[] = transformData(groups);
    if (!connectionGroups) return null;
    return connectionGroups.map((connectionGroup, index) => {
      let thumbnailContent;
      if (org) {
        if (
          (connectionGroup?.kind === "Team" ||
            connectionGroup?.kind === "TrainingProgram") &&
          !connectionGroup?.orgOwned
        ) {
          thumbnailContent = (
            <Avatar
              variant="square"
              style={{ width: "40px", height: "40px" }}
              src={TeamIcon}
              alt="Team Icon"
            />
          );
        } else if (
          connectionGroup?.kind === "ConnectionGroup" &&
          !connectionGroup?.orgOwned
        ) {
          thumbnailContent = (
            <Avatar
              variant="square"
              style={{ width: "40px", height: "40px" }}
              src={personGroupIcon}
              alt="Person Group Icon"
            />
          );
        } else if (org.avatarId) {
          thumbnailContent = (
            <Avatar
              variant="square"
              style={{ width: "40px", height: "40px" }}
              src={org.avatar!.baseUrl! + org.avatar!.path!}
              alt="Org Avatar"
            />
          );
        } else {
          if (connectionGroup?.kind === "ConnectionGroup") {
            thumbnailContent = (
              <Avatar
                variant="square"
                style={{ width: "40px", height: "40px" }}
                src={personGroupIcon}
                alt="Org Avatar"
              />
            );
          }
          if (
            connectionGroup?.kind === "Team" ||
            connectionGroup?.kind === "TrainingProgram"
          ) {
            thumbnailContent = (
              <Avatar
                variant="square"
                style={{ width: "40px", height: "40px" }}
                src={TeamIcon}
                alt="Org Avatar"
              />
            );
          }
        }
      }

      return (
        <Container
          key={index}
          data-testid={`connection-group-${connectionGroup.id}`}
        >
          <Grid container alignItems="center">
            <Grid>{thumbnailContent}</Grid>
            <Grid
              maxWidth={"fit-content"}
              marginLeft={"11px"}
              xs
              container
              direction="column"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <Typography style={{ fontSize: "14px", fontWeight: 400 }}>
                  {`${connectionGroup.name}`}
                </Typography>
              </div>
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#64748B"
                }}
              >
                {`${connectionGroup.description}`}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      );
    });
  };

  const StatusPill = ({
    livestreamStatus
  }: {
    livestreamStatus: LiveStreamStatus;
  }) => {
    const status = ["NOT_STARTED", "DELAYED", "POSTPONED"].includes(
      livestreamStatus
    )
      ? "NOT_STARTED"
      : livestreamStatus;

    return (
      <div
        style={{
          width: "fit-content",
          height: "32px",
          padding: "4px 8px 4px 8px",
          backgroundColor:
            status === "COMPLETED"
              ? "#E2FCF7"
              : status === "NOT_STARTED"
                ? "#FEF4E4"
                : "#FFEEEE",
          borderRadius: "4px",
          marginLeft: "8px"
        }}
      >
        <Typography
          style={{
            color:
              status === "COMPLETED"
                ? "#095A4A"
                : status === "NOT_STARTED"
                  ? "#603B03"
                  : "#A11212",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "24px"
          }}
        >
          {status === "COMPLETED"
            ? "Completed"
            : status === "NOT_STARTED"
              ? "Not Started"
              : capitalize(livestreamStatus.toLowerCase().replace(/_/g, " "))}
        </Typography>
      </div>
    );
  };

  const goLiveTime = useMemo(() => {
    if (config && config?.data)
      return config?.data.find(
        (config) => config.key === "live-stream.go-live-button"
      )?.value as number;
    else return 0;
  }, [config]);

  return (
    <Backdrop
      sx={{
        overflow: "hidden",
        overflowY: "none",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={true}
    >
      <Container>
        <StyledBox>
          <StyledBoxHeader>
            <Grid
              container
              style={{
                padding: "18px 20px 20px 32px",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Grid>
                <Typography
                  style={{
                    fontSize: "16px",
                    color: "#fff",
                    fontStyle: "normal",
                    lineHeight: "20px",
                    fontWeight: "600"
                  }}
                >
                  {props.details.title}
                </Typography>
              </Grid>
              <Grid>
                {props.details.action != "create" &&
                  !eventLoading &&
                  (props.details.isLiveStream
                    ? ![
                        "LIVE",
                        "COMPLETED",
                        "PAUSED",
                        "INTERMISSION",
                        "SHUT_DOWN",
                        "UNAVAILABLE"
                      ].includes(event?.data.liveStream?.status as string)
                    : true) &&
                  (props.details.isLiveStream
                    ? new Date(
                        event?.data?.liveStream?.scheduledAt as Date
                      ).getTime() -
                        new Date().getTime() >
                      goLiveTime * 60 * 1000
                    : true) &&
                  props.hasPermissionToEdit && (
                    <EditIcon
                      data-testid="editIcon"
                      style={{ marginLeft: "8px", cursor: "pointer" }}
                      onClick={() => {
                        if (isLiveStream) {
                          navigate(
                            `/live-stream/${event?.data.liveStream?.streamId}/edit`
                          );
                        } else {
                          props.onClose();
                          props.openEdit();
                        }
                      }}
                    />
                  )}
                {!eventLoading &&
                  props.details.action !== "create" &&
                  props.hasPermissionToDelete &&
                  (props.details.isLiveStream
                    ? ![
                        "LIVE",
                        "COMPLETED",
                        "PAUSED",
                        "INTERMISSION",
                        "SHUT_DOWN",
                        "UNAVAILABLE"
                      ].includes(event?.data.liveStream?.status as string)
                    : true) &&
                  (props.details.isLiveStream
                    ? new Date(
                        event?.data?.liveStream?.scheduledAt as Date
                      ).getTime() -
                        new Date().getTime() >
                      goLiveTime * 60 * 1000
                    : true) && (
                    <Delete
                      style={{ marginLeft: "24px", cursor: "pointer" }}
                      onClick={() => {
                        if (event?.data.repeat) {
                          setRecurringDeleteOpen(true);
                        } else {
                          setDeleteOpen(true);
                        }
                      }}
                    />
                  )}
                <CloseIcon
                  data-testid="post-comment-close-icon"
                  style={{ cursor: "pointer", marginLeft: "24px" }}
                  onClick={props.onClose}
                />
              </Grid>
            </Grid>
          </StyledBoxHeader>
          <Box sx={{ margin: "0 24px", minHeight: "350px" }}>
            <Loader isLoading={eventLoading || isConfigLoading}>
              <div
                style={{
                  display: "flex",
                  marginTop: "20px",
                  alignItems: "center",
                  gap: "16px"
                }}
              >
                <EditCalendarTwoToneIcon style={{ fill: "#9FA2AA" }} />
                <Typography color="#B3B3B3">
                  {
                    CONTEST_TYPE_OPTIONS.find(
                      (option) => option.value === event?.data.eventType
                    )?.label
                  }
                </Typography>
                {event?.data.sport && (
                  <Typography color="#B3B3B3">
                    {event?.data?.sport?.name}
                  </Typography>
                )}
                {event?.data.team || event?.data.program ? (
                  <Typography color="#B3B3B3">
                    {event?.data.team?.name || event?.data.program?.name}
                  </Typography>
                ) : (
                  ""
                )}
              </div>
              {event?.data.liveStream?.opponent && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "24px",
                    marginBottom: "20px",
                    gap: "16px"
                  }}
                >
                  <img src={VersusIcon} />

                  <Typography
                    style={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      width: "100%"
                    }}
                  >
                    {event?.data.liveStream?.opponent}
                  </Typography>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "24px",
                  marginBottom: "20px",
                  gap: "16px"
                }}
              >
                <div
                  style={{
                    height: "24px",
                    minWidth: "24px",
                    borderRadius: "4px",
                    backgroundColor: "#1ABC9C"
                  }}
                ></div>
                <Typography
                  style={{
                    fontSize: "20px",
                    lineHeight: "20px",
                    borderBottom: "2px solid #E5E5E5",
                    paddingBottom: "10px",
                    width: "100%"
                  }}
                >
                  {event?.data.name}
                </Typography>
              </div>
              {!isLiveStream && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "12px",
                    marginBottom: "10px"
                  }}
                >
                  <img src={EventTimeIcon} />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={dayjs(event?.data.recurrences![0].start)}
                      disabled
                      className="date-picker"
                      format="ddd, MMM DD"
                      sx={{
                        maxWidth: "100px",
                        marginLeft: "8px",
                        "& .MuiInputBase-input": {
                          "-webkit-text-fill-color": "#64748B"
                        }
                      }}
                    />
                  </LocalizationProvider>
                  <span style={{ color: "#64748B" }}>&#x25cf;</span>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimeField
                      value={dayjs(event?.data.recurrences![0].start)}
                      disabled
                      className="time-picker"
                      sx={{
                        width: "90px",
                        "& .MuiInputBase-input": {
                          "-webkit-text-fill-color": "#64748B"
                        }
                      }}
                    />
                  </LocalizationProvider>
                  <span style={{ color: "#64748B", fontSize: "14px" }}>-</span>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimeField
                      value={dayjs(event?.data.recurrences![0].end)}
                      disabled
                      className="time-picker"
                      sx={{
                        width: "90px",
                        "& .MuiInputBase-input": {
                          "-webkit-text-fill-color": "#64748B"
                        }
                      }}
                    />
                  </LocalizationProvider>{" "}
                  <span style={{ color: "#64748B" }}>&#x25cf;</span>
                  <Typography
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      marginLeft: "18px",
                      color: "#64748B"
                    }}
                  >
                    {event?.data.timezone}
                  </Typography>
                </div>
              )}
              {isLiveStream && (
                <>
                  {event?.data.liveStream?.preEventAt && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "12px"
                      }}
                      data-testId="preEventContainer"
                    >
                      <img src={EventTimeIcon} />
                      <Typography
                        style={{
                          fontSize: "14px",
                          lineHeight: "20px",
                          marginLeft: "18px",
                          color: "#64748B",
                          marginRight: "15px",
                          width: "75px"
                        }}
                      >
                        Pre Game
                      </Typography>
                      <span style={{ color: "#64748B" }}>&#x25cf;</span>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={dayjs(event?.data.liveStream.scheduledAt)}
                          disabled
                          className="date-picker"
                          format="ddd, MMM DD"
                          sx={{
                            maxWidth: "100px",
                            marginLeft: "3px",
                            "& .MuiInputBase-input": {
                              "-webkit-text-fill-color": "#64748B"
                            }
                          }}
                        />
                      </LocalizationProvider>
                      <span style={{ color: "#64748B" }}>&#x25cf;</span>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimeField
                          value={dayjs(event?.data.liveStream.preEventAt)}
                          disabled
                          className="time-picker"
                          sx={{
                            width: "90px",
                            "& .MuiInputBase-input": {
                              "-webkit-text-fill-color": "#64748B"
                            }
                          }}
                        />
                      </LocalizationProvider>
                      <span style={{ color: "#64748B", fontSize: "14px" }}>
                        -
                      </span>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimeField
                          value={dayjs(event?.data.liveStream.scheduledAt)}
                          disabled
                          className="time-picker"
                          sx={{
                            width: "90px",
                            "& .MuiInputBase-input": {
                              "-webkit-text-fill-color": "#64748B"
                            }
                          }}
                        />
                      </LocalizationProvider>{" "}
                      <span style={{ color: "#64748B" }}>&#x25cf;</span>
                      <Typography
                        style={{
                          fontSize: "14px",
                          lineHeight: "20px",
                          marginLeft: "18px",
                          color: "#64748B"
                        }}
                      >
                        {event.data.timezone}
                      </Typography>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                      marginBottom: "10px"
                    }}
                    data-testid="eventTimeContainer"
                  >
                    {!event?.data.liveStream?.preEventAt && (
                      <img src={EventTimeIcon} />
                    )}
                    {event?.data.liveStream?.preEventAt && (
                      <CheckBoxOutlineBlankIcon style={{ fill: "#fff" }} />
                    )}
                    <Typography
                      style={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        marginLeft: "18px",
                        color: "#64748B",
                        marginRight: "15px",
                        width: "75px"
                      }}
                    >
                      Main Event
                    </Typography>
                    <span style={{ color: "#64748B" }}>&#x25cf;</span>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={dayjs(event?.data.recurrences![0].start)}
                        disabled
                        className="date-picker"
                        format="ddd, MMM DD"
                        sx={{
                          maxWidth: "100px",
                          marginLeft: "3px",
                          "& .MuiInputBase-input": {
                            "-webkit-text-fill-color": "#64748B"
                          }
                        }}
                      />
                    </LocalizationProvider>
                    <span style={{ color: "#64748B" }}>&#x25cf;</span>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimeField
                        value={dayjs(event?.data.recurrences![0].start)}
                        disabled
                        className="time-picker"
                        sx={{
                          width: "90px",
                          "& .MuiInputBase-input": {
                            "-webkit-text-fill-color": "#64748B"
                          }
                        }}
                      />
                    </LocalizationProvider>
                    <span style={{ color: "#64748B", fontSize: "14px" }}>
                      -
                    </span>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimeField
                        value={dayjs(event?.data.recurrences![0].end)}
                        disabled
                        className="time-picker"
                        sx={{
                          width: "90px",
                          "& .MuiInputBase-input": {
                            "-webkit-text-fill-color": "#64748B"
                          }
                        }}
                      />
                    </LocalizationProvider>{" "}
                    {!event?.data.liveStream?.preEventAt && (
                      <>
                        <span style={{ color: "#64748B" }}>&#x25cf;</span>
                        <Typography
                          style={{
                            fontSize: "14px",
                            lineHeight: "20px",
                            marginLeft: "18px",
                            color: "#64748B"
                          }}
                        >
                          {event?.data.timezone}
                        </Typography>
                      </>
                    )}
                  </div>
                  {event?.data.liveStream?.postEventAt && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px"
                      }}
                      data-testid="postEventContainer"
                    >
                      <CheckBoxOutlineBlankIcon style={{ fill: "#fff" }} />
                      <Typography
                        style={{
                          fontSize: "14px",
                          lineHeight: "20px",
                          marginLeft: "18px",
                          color: "#64748B",
                          marginRight: "15px",
                          width: "75px"
                        }}
                      >
                        Post Game
                      </Typography>
                      <span style={{ color: "#64748B" }}>&#x25cf;</span>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={dayjs(event?.data.liveStream.postEventAt)}
                          disabled
                          className="date-picker"
                          format="ddd, MMM DD"
                          sx={{
                            maxWidth: "100px",
                            marginLeft: "3px",
                            "& .MuiInputBase-input": {
                              "-webkit-text-fill-color": "#64748B"
                            }
                          }}
                        />
                      </LocalizationProvider>
                      <span style={{ color: "#64748B" }}>&#x25cf;</span>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimeField
                          value={dayjs(event?.data.liveStream.postEventAt)}
                          disabled
                          className="time-picker"
                          sx={{
                            width: "90px",
                            "& .MuiInputBase-input": {
                              "-webkit-text-fill-color": "#64748B"
                            }
                          }}
                        />
                      </LocalizationProvider>
                      <span style={{ color: "#64748B", fontSize: "14px" }}>
                        -
                      </span>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimeField
                          value={dayjs(event?.data.liveStream.postEventEndAt)}
                          disabled
                          className="time-picker"
                          sx={{
                            width: "90px",
                            "& .MuiInputBase-input": {
                              "-webkit-text-fill-color": "#64748B"
                            }
                          }}
                        />
                      </LocalizationProvider>{" "}
                    </div>
                  )}
                </>
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: "559px"
                }}
              >
                <CheckBoxOutlineBlankIcon />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between"
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      marginLeft: "18px",
                      color: "#64748B"
                    }}
                  >
                    {isLiveStream
                      ? `Does not repeat`
                      : `${"Repeats "}
                    ${getRRuleText(event?.data.repeat).replace(
                      /\s+for\s+\d+\s+times/,
                      ""
                    )}`}
                  </Typography>
                </div>
              </div>
              {event?.data.location && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "24px"
                  }}
                >
                  <img src={LocationIcon} />
                  <Typography
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      marginLeft: "18px",
                      color: "#64748B"
                    }}
                  >
                    {generateAddress(event?.data.location)}
                  </Typography>
                </div>
              )}
              {props.details.isLiveStream && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "24px"
                  }}
                >
                  <img src={LiveStreamIcon} />
                  <Typography
                    style={{
                      marginLeft: "18px",
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: 400,
                      color: "#007AFF",
                      cursor: "pointer"
                    }}
                    onClick={() =>
                      navigate(
                        `/live-stream/${event?.data.liveStream?.streamId}`
                      )
                    }
                  >
                    View Live Stream
                  </Typography>
                  {!["LIVE", "PAUSED", "INTERMISSION"].includes(
                    event?.data.liveStream?.status as LiveStreamStatus
                  ) && (
                    <StatusPill
                      livestreamStatus={
                        event?.data.liveStream?.status as LiveStreamStatus
                      }
                    />
                  )}
                  {["LIVE", "PAUSED", "INTERMISSION"].includes(
                    event?.data?.liveStream?.status as LiveStreamStatus
                  ) && (
                    <>
                      <div
                        style={{
                          width: "51px",
                          height: "32px",
                          backgroundColor: "#E82C2C",
                          padding: "4px 8px 4px 8px",
                          borderRadius: "4px",
                          marginLeft: "8px"
                        }}
                      >
                        <Typography
                          style={{
                            color: "#fff",
                            fontSize: "16px",
                            fontWeight: 500
                          }}
                        >
                          LIVE
                        </Typography>
                      </div>
                      <div
                        style={{
                          width: "135px",
                          height: "32px",
                          backgroundColor: "#2B337A",
                          padding: "4px 8px 4px 8px",
                          borderRadius: "4px",
                          marginLeft: "8px",
                          display: "flex"
                        }}
                        onClick={() =>
                          navigate(
                            `/live-stream/${event?.data.liveStream?.streamId}`
                          )
                        }
                      >
                        <Visibility />
                        <Typography
                          style={{
                            color: "#fff",
                            fontSize: "16px",
                            fontWeight: 500,
                            marginLeft: "8px",
                            cursor: "pointer"
                          }}
                        >
                          Watch Live
                        </Typography>
                      </div>
                    </>
                  )}
                </div>
              )}
              {!isLiveStream &&
                ((event?.data.attendees && event?.data.attendees.length > 0) ||
                  (event?.data.teams && event.data.teams.length > 0) ||
                  (event?.data.programs && event.data.programs.length > 0)) && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        marginTop: "20px",
                        position: "relative"
                      }}
                    >
                      <img
                        src={ShareWithIcon}
                        style={{ position: "absolute", top: "10px" }}
                      />
                      <div
                        style={{
                          maxHeight: "160px",
                          overflow: "auto",
                          marginLeft: "20px"
                        }}
                      >
                        {event?.data.attendees
                          ?.sort((a, b) => {
                            if (
                              a.person!.personId ===
                              loggedInUser?.person?.personId
                            )
                              return -1;
                            if (
                              b.person!.personId ===
                              loggedInUser?.person?.personId
                            )
                              return 1;
                            return 0;
                          })
                          .map((attendee, index) =>
                            personAvatar(attendee.person, index)
                          )}
                        {connectionGroupAvatar(event?.data)}
                      </div>
                    </div>
                  </>
                )}
              {isLiveStream && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "18px"
                  }}
                >
                  <img src={ShareWithIcon} />

                  <Typography
                    style={{
                      marginLeft: "18px"
                    }}
                    color="#64748B"
                  >
                    {event?.data.liveStream?.isSharedWithOrg
                      ? "Shared with Organization"
                      : event?.data.liveStream?.teamId
                        ? "Shared with Team"
                        : "Shared with Training Program"}
                  </Typography>
                </div>
              )}
              {event?.data.description && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "25px"
                  }}
                >
                  <img src={DescriptionIcon} />
                  <Typography
                    style={{
                      marginLeft: "18px",
                      color: "#64748B",
                      whiteSpace: "pre-line",
                      maxHeight: "200px",
                      width: "100%",
                      overflowY: "auto",
                      overflowX: "hidden"
                    }}
                  >
                    {event?.data.description}
                  </Typography>
                </div>
              )}
            </Loader>
          </Box>
        </StyledBox>

        <ConfirmationDialog
          open={deleteOpen}
          title={
            !event?.data.repeat ? "Delete Event" : "Delete Recurring Event?"
          }
          body={
            isLiveStream
              ? "If you delete this event you are effectively deleting the live stream? Are you sure you want to do this?"
              : `Are you sure you want to delete ${event?.data.name}?`
          }
          close={() => setDeleteOpen(false)}
          onConfirm={onConfirmDelete}
          onCancel={() => {
            setDeleteOpen(false);
          }}
          isConfirming={isDeleting}
          confirmBtnVariant="admin-error"
          icon="error"
        />
        {recurringDeleteOpen && (
          <RemoveRecurringEvent
            onClose={handleRecurringEventDeleteClose}
            setRecurringDeleteType={setRecurringEventDeleteType}
            onConfirm={() => {
              setRecurringDeleteOpen(false);
              setDeleteOpen(true);
            }}
          />
        )}
      </Container>
    </Backdrop>
  );
};
