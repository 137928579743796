import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Toolbar } from "@components/crud/Toolbar";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { EmailTemplateForm } from "./EmailTemplateForm";
import { uploadAdminMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import {
  ModelMedia,
  useAdminEmailTemplatePost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";
export const EmailTemplateCreate = () => {
  const navigate = useNavigate();
  const form = useForm({
    mode: "onTouched"
  });

  const {
    register,
    setValue,
    getValues,
    formState: { isValid }
  } = form;

  const [isDescValid, setIsDescValid] = useState(false);

  React.useEffect(() => {
    register("description", { required: true });
    setValue("isActive", true);
  }, [register]);
  const [isUploadingFiles, setUploadingFiles] = useState(false);
  const { mutate: save, isLoading: isSaving } = useAdminEmailTemplatePost();
  const onSave = async () => {
    const formValues = getValues();
    const data = {
      name: formValues.name,
      departmentId: formValues.departmentId,
      jobTitleId: formValues.jobTitleId,
      isActive: formValues.isActive,
      subject: formValues.subject,
      body: formValues.description,
      attachments: [] as string[]
    };
    if (files.length > 0) {
      setUploadingFiles(true);
      const filesPromises = await Promise.all(
        files.map(async (file) => {
          if (file.file instanceof File) {
            const promise = uploadAdminMediaUsingPresignedUrl(file.file);
            return promise;
          }
        })
      );
      setUploadingFiles(false);
      data.attachments = filesPromises as string[];
    }
    save(
      {
        data: data
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Email Template Saved!", {
            variant: "success"
          });
          navigate("/email-templates");
        },
        onError: () => {
          enqueueSnackbar("Failed to Save Email Template!", {
            variant: "error"
          });
        }
      }
    );
  };
  const [departmentId, setDepartmentId] = useState<string>();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [files, setFiles] = useState<{ id: string; file: File | ModelMedia }[]>(
    []
  );
  return (
    <Container>
      <Toolbar title="Add Email Template" />
      <EmailTemplateForm
        form={form}
        setIsDescValid={setIsDescValid}
        departmentId={departmentId}
        setDepartmentId={setDepartmentId}
        files={files}
        setFiles={setFiles}
      />
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={() => {
          onSave();
        }}
        isDisabled={!isValid || !isDescValid}
        isLoading={isUploadingFiles || isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/email-templates")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
