import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Loader } from "@components/crud/Loader";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { DeleteIcon } from "@components/Icons";
import { SearchInput } from "@components/SearchInput";
import { StyledFormLabel } from "@components/StyledFormLabel";
import { Add } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Grid,
  InputAdornment,
  ListItemText,
  MenuItem,
  Typography
} from "@mui/material";
import { useLookupCountrySubdivisionGet } from "@sportsgravyengineering/sg-api-react-sdk";
import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import colors from "theme/colors";

export interface TaxFee {
  name: string;
  type: "TAX" | "FEE";
  calculationMethod: "PERCENTAGE" | "VALUE";
  rate: number;
  country?: string;
  subdivision?: string;
}

export const CRMOrderTaxFess = ({
  taxFees,
  setTaxFees,
  form,
  countries,
  disabled,
  taxCountry,
  setTaxCountry,
  taxCountrySuubdivision,
  setTaxCountrySubdivsion,
  taxCountrySubdivisionInputValue,
  setTaxCountrySubdivsionInputValue,
  hideHeader
}: {
  taxFees: TaxFee[];
  setTaxFees: (taxFees: TaxFee[]) => void;
  form: UseFormReturn;
  disabled: boolean;
  countries: { label: string; value: string }[];
  taxCountry: string | undefined;
  taxCountrySuubdivision: string | undefined;
  setTaxCountry;
  setTaxCountrySubdivsion;
  taxCountrySubdivisionInputValue: string;
  setTaxCountrySubdivsionInputValue;
  hideHeader?: boolean;
}) => {
  console.log(taxFees);

  const [toDelete, setToDelete] = useState<
    { index: number; tax: TaxFee } | undefined
  >(undefined);
  const { control, getValues, reset, setValue } = form;

  const { data: provincesResponse, isFetching: isFetchingProvinces } =
    useLookupCountrySubdivisionGet({
      query: {
        staleTime: Infinity
      }
    });
  const subdivisions = React.useMemo(() => {
    if (provincesResponse?.data) {
      const filteredValues = taxCountry
        ? provincesResponse?.data?.filter((t) => t.countryId === taxCountry)
        : provincesResponse?.data;
      return filteredValues.map((province) => {
        return {
          label: province.name as string,
          value: province.subdivisionId as string,
          countryId: province.countryId as string
        };
      });
    }
    return [];
  }, [provincesResponse, taxCountry]);
  useEffect(() => {
    if (
      !taxCountrySubdivisionInputValue &&
      subdivisions &&
      taxFees?.[0]?.name
    ) {
      const taxState = taxFees[0].country + "-" + taxFees[0].subdivision;
      console.log(taxState);
      setTaxCountrySubdivsionInputValue(
        subdivisions.find((s) => s.value === taxState)?.label
      );
    }
  }, [subdivisions]);
  const [taxFeesBySubdivision, setTaxFeesBySubdivsion] = useState<TaxFee[]>([]);
  const [taxIdx, setTaxIdx] = useState(0);
  useEffect(() => {
    const taxesBySubdivsion = taxFees.filter(
      (t) =>
        t.name &&
        t.subdivision === taxCountrySuubdivision?.split("-")[1] &&
        t.country === taxCountrySuubdivision?.split("-")[0]
    );
    const taxIdx = taxFees.findIndex(
      (t) =>
        t.name &&
        t.subdivision === taxCountrySuubdivision?.split("-")[1] &&
        t.country === taxCountrySuubdivision?.split("-")[0]
    );
    if (taxIdx === -1) {
      setValue(
        "taxFee",
        [
          ...taxFees,
          {
            name: "",
            type: "TAX",
            calculationMethod: "PERCENTAGE",
            rate: 0,
            country: taxCountry || "US",
            subdivision: taxCountrySuubdivision?.split("-")[1] || "MN"
          }
        ],
        {
          shouldDirty: true
        }
      );
      setTaxFees([
        ...taxFees,
        {
          name: "",
          type: "TAX",
          calculationMethod: "PERCENTAGE",
          rate: 0,
          country: taxCountry || "US",
          subdivision: taxCountrySuubdivision?.split("-")[1] || "MN"
        }
      ]);
    } else {
      setValue("taxFee", [...taxFees], {
        shouldDirty: true
      });
      setTaxFees([...taxFees.filter((t) => !!t.name)]);
    }
    setTaxIdx(taxIdx === -1 ? taxFees.length : taxIdx);

    setTaxFeesBySubdivsion(
      taxesBySubdivsion.length
        ? taxesBySubdivsion
        : [
            {
              name: "",
              type: "TAX",
              calculationMethod: "PERCENTAGE",
              rate: 0,
              country: taxCountry || "US",
              subdivision: taxCountrySuubdivision?.split("-")[1] || "MN"
            }
          ]
    );
  }, [taxCountrySuubdivision]);
  return (
    <Loader isLoading={isFetchingProvinces}>
      <Grid container direction="row" columnSpacing={3} rowSpacing={1.5}>
        {!hideHeader && (
          <Grid item container xs={12} direction="column">
            <Grid item>
              <Typography
                variant="permissionNames"
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  letterSpacing: "10%",
                  lineHeight: "14.52px",
                  opacity: "50%"
                }}
              >
                TAXES AND FEES
              </Typography>
            </Grid>

            <HeaderUnderLine width="100%" />
          </Grid>
        )}
        <Grid
          container
          item
          xs={12}
          style={{
            marginTop: "20px"
          }}
          columnSpacing={3}
        >
          <Grid item container direction="column" mt="15px">
            <Autocomplete
              clearOnBlur={false}
              options={subdivisions || []}
              disabled={disabled}
              inputValue={taxCountrySubdivisionInputValue}
              renderInput={(params) => {
                return (
                  <SearchInput
                    data-testid="SEARCH_ORGANIZATION_INPUT"
                    {...params}
                    value={taxCountrySubdivisionInputValue}
                    onChange={(e) => {
                      setTaxCountrySubdivsionInputValue(e.target.value);
                    }}
                    onBlur={() => {
                      const countrySub = subdivisions?.find((subdivision) =>
                        taxCountrySuubdivision
                          ? subdivision.value === taxCountrySuubdivision
                          : subdivision.value === "US-MN"
                      );
                      setTaxCountrySubdivsionInputValue(
                        countrySub?.label || ""
                      );
                    }}
                    placeholder="Search for Provinces/States"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                      disabled: disabled
                    }}
                  />
                );
              }}
              renderOption={(
                props,
                option: {
                  value: string | undefined;
                  label: string;
                  countryId: string;
                }
              ) => {
                return (
                  <MenuItem
                    data-testId={`COUNTRY_OPTION_${option.value}`}
                    key={option.value}
                    id={option.value}
                    selected={option.value === taxCountrySuubdivision}
                    disabled={option.value === taxCountrySuubdivision}
                    onClick={() => {
                      setTaxCountrySubdivsionInputValue(option.label);
                      setTaxCountrySubdivsion(option.value!);
                      setValue("taxCountry", option.countryId);
                      setTaxCountry(option.countryId);
                      setValue("taxProvvince", option.value);
                    }}
                  >
                    <ListItemText>{option.label}</ListItemText>
                  </MenuItem>
                );
              }}
            />
          </Grid>
          <Grid item container spacing="20px" mt="10px">
            <Grid item xs={6}>
              <FormSelect
                control={control}
                name="taxCountry"
                label="Country"
                disabled={disabled}
                options={countries}
                onChange={(e) => {
                  setTaxCountry(e.target.value);
                }}
                required
                rules={{
                  required: "Country is Required"
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormSelect
                control={control}
                name="taxProvvince"
                disabled={disabled || !taxCountry}
                label="State"
                options={subdivisions || []}
                onChange={(e) => {
                  setTaxCountrySubdivsion(e.target.value);
                  setTaxCountrySubdivsionInputValue(
                    subdivisions.find((s) => s.value === e.target.value)
                      ?.label || ""
                  );
                }}
                required
                rules={{
                  required: "State is Required"
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            mt="20px"
            xs={6}
            style={{
              paddingTop: "0px"
            }}
          >
            <StyledFormLabel required style={{ fontWeight: 600 }}>
              Label
            </StyledFormLabel>
          </Grid>
          <Grid
            item
            mt="15px"
            xs={taxFees.length <= 1 || disabled ? 1.5 : 1.25}
            style={{
              paddingTop: "0px"
            }}
          >
            <StyledFormLabel required style={{ fontWeight: 600 }}>
              Type
            </StyledFormLabel>
          </Grid>
          <Grid
            item
            mt="20px"
            xs={4}
            style={{
              paddingTop: "0px"
            }}
          >
            <StyledFormLabel required style={{ fontWeight: 600 }}>
              Value
            </StyledFormLabel>
          </Grid>

          <Grid item xs={12} container spacing={3}>
            {taxCountrySuubdivision &&
              taxFeesBySubdivision.map((rl, idx) => {
                const foundIdx = taxIdx + idx;
                return (
                  <Fragment key={taxCountrySuubdivision + foundIdx}>
                    <Grid item xs={6}>
                      <FormInput
                        name={`taxFee[${foundIdx}].name`}
                        label={""}
                        control={control}
                        type="text"
                        onChange={(e) => {
                          const updatedTaxFee = taxFees;
                          updatedTaxFee[foundIdx].name = (
                            e as ChangeEvent<HTMLInputElement>
                          ).target.value;
                          setTaxFees([...updatedTaxFee]);
                          const updatedTaxFeeBySubdivision =
                            taxFeesBySubdivision;
                          updatedTaxFeeBySubdivision[idx].name = (
                            e as ChangeEvent<HTMLInputElement>
                          ).target.value;
                          setTaxFeesBySubdivsion([
                            ...updatedTaxFeeBySubdivision
                          ]);
                        }}
                        disabled={disabled}
                        rules={{
                          required: "Label is required"
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={
                        taxFeesBySubdivision.length <= 1 || disabled
                          ? 1.5
                          : 1.25
                      }
                    >
                      <FormSelect
                        control={control}
                        name={`taxFee[${foundIdx}].type`}
                        label=" "
                        options={[
                          {
                            label: "Tax",
                            value: "TAX"
                          },
                          {
                            label: "Fee",
                            value: "FEE"
                          }
                        ]}
                        disabled={disabled}
                        onChange={(e) => {
                          const value = (e as ChangeEvent<HTMLInputElement>)
                            .target.value;
                          const updatedTaxFee = taxFees;
                          updatedTaxFee[foundIdx].type = value as "TAX" | "FEE";
                          setTaxFees([...updatedTaxFee]);
                          const updatedTaxFeeBySubdivision =
                            taxFeesBySubdivision;
                          updatedTaxFeeBySubdivision[idx].type = value as
                            | "TAX"
                            | "FEE";
                          setTaxFeesBySubdivsion([
                            ...updatedTaxFeeBySubdivision
                          ]);
                        }}
                        rules={{
                          required: "Type is required"
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormSelect
                        control={control}
                        name={`taxFee[${foundIdx}].calculationMethod`}
                        label=" "
                        options={[
                          {
                            label: "Percentage",
                            value: "PERCENTAGE"
                          },
                          {
                            label: "Currency",
                            value: "VALUE"
                          }
                        ]}
                        disabled={disabled}
                        onChange={(e) => {
                          const value = (e as ChangeEvent<HTMLInputElement>)
                            .target.value;
                          const updatedTaxFee = taxFees;
                          updatedTaxFee[foundIdx].calculationMethod = value as
                            | "PERCENTAGE"
                            | "VALUE";
                          setTaxFees([...updatedTaxFee]);
                          const updatedTaxFeeBySubdivision =
                            taxFeesBySubdivision;
                          updatedTaxFeeBySubdivision[idx].calculationMethod =
                            value as "PERCENTAGE" | "VALUE";
                          setTaxFeesBySubdivsion([
                            ...updatedTaxFeeBySubdivision
                          ]);
                        }}
                        rules={{
                          required: "Value type is required"
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={
                        taxFeesBySubdivision.length <= 1 || disabled
                          ? 1.5
                          : 1.25
                      }
                    >
                      <FormInput
                        control={control}
                        name={`taxFee[${foundIdx}].rate`}
                        label=" "
                        type="text"
                        disabled={disabled}
                        onChange={(e) => {
                          const value = (e as ChangeEvent<HTMLInputElement>)
                            .target.value;
                          const updatedTaxFee = taxFees;
                          updatedTaxFee[foundIdx].rate = Number(value);
                          setTaxFees([...updatedTaxFee]);
                          const updatedTaxFeeBySubdivision =
                            taxFeesBySubdivision;
                          updatedTaxFeeBySubdivision[idx].rate = Number(value);
                          setTaxFeesBySubdivsion([
                            ...updatedTaxFeeBySubdivision
                          ]);
                        }}
                        rules={{
                          required: "Value is required"
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {rl.calculationMethod === "PERCENTAGE"
                                ? "%"
                                : "$"}
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    {taxFeesBySubdivision.length > 1 && !disabled && (
                      <Grid item xs={0.5} padding={0} height="fit-content">
                        <div
                          style={{
                            padding: "8px",
                            borderRadius: "4px",
                            border: "1px solid #E5E5E5",
                            height: "40px",
                            width: "40px",
                            cursor: "pointer",
                            marginTop: "15px"
                          }}
                          onClick={() => {
                            setToDelete({ index: foundIdx, tax: rl });
                          }}
                        >
                          <DeleteIcon height="24px" width="24px" />
                        </div>
                      </Grid>
                    )}
                  </Fragment>
                );
              })}
          </Grid>
        </Grid>

        {!disabled && (
          <Grid
            style={{
              padding: "24px"
            }}
          >
            <Button
              startIcon={<Add />}
              disabled={disabled || !taxCountrySuubdivision}
              style={{
                textTransform: "capitalize",
                color: colors.primary.main
              }}
              onClick={() => {
                const taxState = taxCountrySuubdivision!.split("-")[1];
                const newTaxFee: TaxFee = {
                  name: "",
                  type: "TAX",
                  calculationMethod: "PERCENTAGE",
                  rate: 0,
                  country: taxCountry,
                  subdivision: taxState
                };
                const stateTaxes = taxFees.filter(
                  (t) =>
                    t.name &&
                    t.country === taxCountry &&
                    t.subdivision === taxState
                );
                const otherStateTaxes = taxFees.filter(
                  (t) =>
                    !(
                      t.name &&
                      t.country === taxCountry &&
                      t.subdivision === taxState
                    )
                );
                const updatedTaxFee = [
                  ...otherStateTaxes,
                  ...stateTaxes,
                  newTaxFee
                ];
                setTaxFees(updatedTaxFee);
                const rlBySubdivsion = taxFeesBySubdivision;
                setTaxFeesBySubdivsion([...rlBySubdivsion, newTaxFee]);
                const taxIdx = updatedTaxFee.findIndex(
                  (t) =>
                    t.name &&
                    t.subdivision === taxState &&
                    t.country === taxCountry
                );
                setTaxIdx(taxIdx);
                reset({
                  ...getValues(),
                  taxFee: updatedTaxFee
                });
              }}
            >
              Add Tax / Fee
            </Button>
          </Grid>
        )}

        <ConfirmationDialog
          open={!!toDelete}
          title="Delete Tax and Fee"
          body={`Are you sure you want to delete Tax and Fee ${
            toDelete?.tax.name
          } ?`}
          close={() => setToDelete(undefined)}
          onConfirm={() => {
            const taxToDelete = toDelete?.tax;
            const slicedTaxFee = taxFees.filter(
              (_, index) => index !== toDelete?.index
            );
            const slicedTaxFeeBySubdivsiion = taxFeesBySubdivision.filter(
              (t) =>
                !(
                  t.name === taxToDelete?.name &&
                  t.calculationMethod === taxToDelete.calculationMethod &&
                  t.rate === taxToDelete.rate &&
                  t.type === taxToDelete.type
                )
            );
            reset({
              ...getValues(),
              taxFee: [...slicedTaxFee]
            });
            setTaxFees([...slicedTaxFee]);
            setTaxFeesBySubdivsion([...slicedTaxFeeBySubdivsiion]);
          }}
          onCancel={() => setToDelete(undefined)}
          confirmBtnVariant="admin-error"
          icon="error"
        />
      </Grid>
    </Loader>
  );
};
