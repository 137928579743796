import { Button } from "@components/Button";
import { FormInput } from "@components/FormInput";
import { InfoListCard } from "@components/InfoListCard";
import { LinkButton } from "@components/LinkButton";
import { LoadingSpinner } from "@components/LoadingSpinner";
import { NotificationCard } from "@components/NotificationCard";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { duplicatePendingAccountsAtom, personAtom } from "@recoil/signup";
import { verifyMergeCode } from "@services/Network";
import {
  ModelPerson,
  useAuthSignUpResendMergeCodePost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { formatPhone } from "@utils/phoneFormatters";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

export const DuplicatePendingAccountWarning = () => {
  const navigate = useNavigate();
  const person = useRecoilValue<ModelPerson | null>(personAtom);
  const duplicatePendingAccounts = useRecoilValue(duplicatePendingAccountsAtom);
  const [resendingTo, setResendingTo] = useState<string[]>([]);
  const [phonesResent, setPhonesResent] = useState<string[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);
  const [verifyError, setVerifyError] = useState<string[]>([]);
  const [verified, setVerified] = useState<string[]>([]);
  const [attempts, setAttempts] = useState<number>(0);

  const phoneToAccountMap = useMemo(() => {
    const phoneMap = {};
    duplicatePendingAccounts.forEach((account) => {
      if (!account.phone) {
        return;
      }
      if (!phoneMap[account.phone]) {
        phoneMap[account.phone] = [];
      }
      phoneMap[account.phone].push(account);
    });
    return phoneMap;
  }, [duplicatePendingAccounts]);

  const {
    control,
    handleSubmit,
    formState: { isValid },
    trigger
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: Object.fromEntries(
      Object.keys(phoneToAccountMap).map((phone) => {
        return [phone, ""];
      })
    )
  });

  const resendMutation = useAuthSignUpResendMergeCodePost();
  const verifyMutation = verifyMergeCode();

  const changeHandler = (phone) => {
    setVerifyError(verifyError.filter((errorPhone) => errorPhone !== phone));
  };
  const formatAccountToInfoList = (account) => {
    let phoneNumber = account.phone || account?.phonePrimary?.phone;
    if (phoneNumber) {
      phoneNumber = formatPhone(phoneNumber);
    }

    return [
      {
        label: "Person Record",
        value: `${account.firstName} ${account.lastName}`
      },
      {
        label: "Email Handle",
        value: account?.email || account?.emailPrimary?.email
      },
      {
        label: "Mobile Phone",
        value: phoneNumber || "N/A"
      },
      { label: "Status", value: "Pending" }
    ];
  };

  const resendCode = (personId, phone) => {
    setResendingTo([...resendingTo, phone]);
    resendMutation.mutate(
      {
        data: { personId }
      },
      {
        onSettled: () => {
          setResendingTo(
            resendingTo.filter((resendingPhone) => resendingPhone !== phone)
          );
          setPhonesResent([...phonesResent, phone]);
        }
      }
    );
  };

  const verifyCode = async (personIds, phone, code) => {
    try {
      await verifyMutation.mutateAsync({
        data: { personIds, code }
      });
      setVerified([...verified, phone]);
    } catch (error) {
      setVerifyError([...verifyError, phone]);
      setHasError(true);
      throw error;
    }
  };

  const submitForm = async (formValues) => {
    try {
      await Promise.all(
        Object.keys(formValues)
          .filter((phone) => !verified.includes(phone))
          .map(async (phone) => {
            const personIds = phoneToAccountMap[phone].map((a) => a.personId);
            await verifyCode(personIds, phone, formValues[phone]);
          })
      );
      navigate("/sign-up/select-primary-account");
    } catch (error) {
      setAttempts(attempts + 1);
    }
  };

  useEffect(() => {
    if (hasError) {
      trigger();
    }
    if (verifyError.length === 0) {
      setHasError(false);
    }
  }, [verifyError, hasError]);

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Grid container spacing={3} justifyContent={"space-evenly"}>
        <Grid xs={12}>
          <Typography
            variant="h2"
            color="text.general.primary"
            sx={{ fontWeight: 400 }}
          >
            Duplicate Pending Account Warning
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography variant="body1" color="text.general.secondary">
            You have multiple pending user accounts within our system. To verify
            your identity and continue setting up one unique user account please
            retrieve the 6 digit verification codes sent to your mobile phones
            and enter below.
          </Typography>
        </Grid>
        {attempts >= 3 && (
          <Grid xs={12}>
            <NotificationCard variant="error">
              {`You have entered the incorrect code too many times, please contact the SportsGravy Support Team`}
            </NotificationCard>
          </Grid>
        )}
        <Grid xs={12}>
          <>
            <Typography variant="h4">New Account Details</Typography>
            <InfoListCard infoList={formatAccountToInfoList(person)} />
          </>
        </Grid>
        {Object.keys(phoneToAccountMap).map((phone) => {
          const accounts = phoneToAccountMap[phone];
          return (
            <Fragment key={phone}>
              {accounts.map((account) => (
                <Grid
                  xs={12}
                  key={account.personId}
                  data-test-id={"DUPLICATE_" + account.personId}
                >
                  <Typography variant="h4">Existing Account Details</Typography>
                  <InfoListCard infoList={formatAccountToInfoList(account)} />
                </Grid>
              ))}
              <Grid xs={7} md={9} data-test-id={"DUPLICATE_PHONE_" + phone}>
                <FormInput
                  type="text"
                  onChange={() => changeHandler(phone)}
                  required
                  name={phone}
                  control={control}
                  label="Mobile Phone code"
                  rules={{
                    required: "Mobile Phone code is required",
                    validate: {
                      isVerifyError: () =>
                        !verifyError.includes(phone) ||
                        "The code you entered was incorrect. Please try again."
                    }
                  }}
                />
              </Grid>
              <Grid
                xs={5}
                md={3}
                alignSelf={"center"}
                sx={{ paddingTop: "2.5rem" }}
              >
                {!phonesResent.includes(phone) &&
                  (resendingTo.includes(phone) ? (
                    <LoadingSpinner />
                  ) : (
                    <LinkButton
                      href="#"
                      onClick={() => resendCode(accounts[0].personId, phone)}
                    >
                      Resend Code
                    </LinkButton>
                  ))}
              </Grid>
            </Fragment>
          );
        })}
        <Grid xs={12}>
          <Button
            variant="primary"
            disabled={!isValid || verifyMutation.isLoading || attempts >= 3}
            type="submit"
            isLoading={verifyMutation.isLoading}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
