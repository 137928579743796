import { Typography, styled } from "@mui/material";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { useContext, useMemo } from "react";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import WebsiteIcon from "@mui/icons-material/LanguageOutlined";
import CopyIcon from "@assets/icons/copyIcon.svg";
import { enqueueSnackbar } from "notistack";
import GoverningBodyIcon from "@assets/icons/governingBody.svg";
import { ToolTip } from "@components/ToolTip";
import { CallEmailTemplateContext } from "@templates/CallEmailTemplate";

const StyledLink = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  color: #007aff;
  cursor: pointer;
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  word-break: break-all;
  overflow-wrap: break-word;
`;

const StyledTitle = styled("div")`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #000;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
`;

const StyledValue = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #666666;
  margin-top: 4px;
`;

const IconStyle = {
  height: "20px",
  width: "20px",
  color: "#666666"
};

export const AccountDetails = ({
  details,
  hideSportsOffered,
  hideNoOfAthletes,
  hideParent,
  onParentClick,
  organizationId
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  details: any;
  hideSportsOffered?: boolean;
  hideNoOfAthletes?: boolean;
  hideParent?: boolean;
  onParentClick?: () => void;
  organizationId?: string;
}) => {
  const { setEmailTo } = useContext(CallEmailTemplateContext);
  const websiteClick = (website) => {
    if (website.includes("http://") || website.includes("https://"))
      window.open(website, "_blank");
    else window.open("https://" + website, "_blank");
  };

  const copyToClipboard = (text, type) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
        enqueueSnackbar(`${type} copied to clipboard`, {
          variant: "success"
        });
      });
    } else {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        const successful = document.execCommand("copy");
        if (successful)
          enqueueSnackbar(`${type} copied to clipboard`, {
            variant: "success"
          });
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }
      document.body.removeChild(textarea);
    }
  };

  const Addresses = useMemo(() => {
    const { officeAddress, billingAddress, shippingAddress } = details;
    let addressContent;
    if (officeAddress === billingAddress && officeAddress === shippingAddress) {
      addressContent = (
        <>
          <StyledTitle>
            <PlaceOutlinedIcon style={IconStyle} />
            Office, Billing, Shipping Address
          </StyledTitle>
          <StyledValue>{officeAddress}</StyledValue>
        </>
      );
    } else if (officeAddress === billingAddress) {
      addressContent = (
        <>
          <StyledTitle>
            <PlaceOutlinedIcon style={IconStyle} />
            Office, Billing Address
          </StyledTitle>
          <StyledValue>{officeAddress}</StyledValue>
          <StyledTitle>
            <PlaceOutlinedIcon style={IconStyle} />
            Shipping Address
          </StyledTitle>
          <StyledValue>{shippingAddress}</StyledValue>
        </>
      );
    } else if (officeAddress === shippingAddress) {
      addressContent = (
        <>
          <StyledTitle>
            <PlaceOutlinedIcon style={IconStyle} />
            Office, Shipping Address
          </StyledTitle>
          <StyledValue>{officeAddress}</StyledValue>
          <StyledTitle>
            <PlaceOutlinedIcon style={IconStyle} />
            Billing Address
          </StyledTitle>
          <StyledValue>{billingAddress}</StyledValue>
        </>
      );
    } else if (billingAddress === shippingAddress) {
      addressContent = (
        <>
          {billingAddress && (
            <>
              <StyledTitle>
                <PlaceOutlinedIcon style={IconStyle} />
                Billing, Shipping Address
              </StyledTitle>
              <StyledValue>{billingAddress}</StyledValue>
            </>
          )}
          <StyledTitle>
            <PlaceOutlinedIcon style={IconStyle} />
            Office Address
          </StyledTitle>
          <StyledValue>{officeAddress}</StyledValue>
        </>
      );
    } else {
      addressContent = (
        <>
          <StyledTitle>
            <PlaceOutlinedIcon style={IconStyle} />
            Office Address
          </StyledTitle>
          <StyledValue>{officeAddress}</StyledValue>
          <StyledTitle>
            <PlaceOutlinedIcon style={IconStyle} />
            Billing Address
          </StyledTitle>
          <StyledValue>{billingAddress}</StyledValue>
          <StyledTitle>
            <PlaceOutlinedIcon style={IconStyle} />
            Shipping Address
          </StyledTitle>
          <StyledValue>{shippingAddress}</StyledValue>
        </>
      );
    }
    return addressContent;
  }, [details]);

  return (
    <div style={{ marginTop: "24px" }}>
      <Typography
        style={{ color: "#1E293B", fontSize: "20px", fontWeight: 600 }}
      >
        {details.name}
      </Typography>
      <Typography
        style={{
          color: "#666666",
          fontSize: "16px",
          fontWeight: 400,
          marginTop: "5px",
          lineHeight: "24px"
        }}
      >
        {`${details.type} ${!organizationId ? "• " + details.orgType : ""}`}
      </Typography>
      {Addresses}
      {details.email && (
        <>
          <StyledTitle>
            <EmailOutlinedIcon style={IconStyle} />
            Email
          </StyledTitle>
          <StyledLink>
            <ToolTip
              title={organizationId ? "" : "Click to Email"}
              placement="top"
            >
              <span
                onClick={
                  organizationId
                    ? undefined
                    : () => {
                        setEmailTo({
                          to: details.email,
                          relatesTo: "ACCOUNT",
                          accountId: details.accountId,
                          name: details.name,
                          accountName: details.name
                        });
                      }
                }
              >
                {details.email}
              </span>
            </ToolTip>
            <ToolTip title="Copy Email to Clipboard" placement="top">
              <img
                src={CopyIcon}
                onClick={() => copyToClipboard(details.email, "Email")}
              />
            </ToolTip>
          </StyledLink>
        </>
      )}
      {details.website && (
        <>
          <StyledTitle>
            <WebsiteIcon style={IconStyle} />
            Website
          </StyledTitle>
          <StyledLink>
            <ToolTip title="Click to Visit Website" placement="top-start">
              <span onClick={() => websiteClick(details.website)}>
                {details.website}
              </span>
            </ToolTip>
            <ToolTip title="Copy Website to Clipboard" placement="top">
              <img
                src={CopyIcon}
                onClick={() => copyToClipboard(details.website, "Website")}
              />
            </ToolTip>
          </StyledLink>
        </>
      )}

      {!hideSportsOffered && !!details.sports?.length && (
        <>
          <StyledTitle>Sports Offered</StyledTitle>
          <StyledValue>{details.sports.map((s) => s).join(", ")}</StyledValue>
        </>
      )}
      {!hideNoOfAthletes && !!details.noOfAthletes && (
        <>
          <StyledTitle>No. of Yearly Athlete Registrations</StyledTitle>
          <StyledValue>{details.noOfAthletes}</StyledValue>
        </>
      )}
      {details.parent && !hideParent && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
            padding: "8px 16px",
            border: "1px solid #E5E5E5",
            borderRadius: "1px",
            width: "fit-content",
            marginTop: "8px"
          }}
        >
          <span>
            <img
              src={GoverningBodyIcon}
              style={{ height: "24px", width: "24px" }}
            />
          </span>
          <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <span style={{ color: "#666666", fontSize: "14px" }}>
              Parent Account
            </span>
            <span
              style={{
                color: "#007AFF",
                fontSize: "18px",
                fontWeight: 500,
                cursor: "pointer"
              }}
              onClick={() => {
                if (onParentClick) onParentClick();
              }}
            >
              {details.parent.name}
            </span>
          </div>
        </div>
      )}
      {details.bdrOwner && (
        <>
          <StyledTitle>BDR Owner</StyledTitle>
          <StyledValue>{details.bdrOwner}</StyledValue>
        </>
      )}
      {details.aeOwner && (
        <>
          <StyledTitle>AE Owner</StyledTitle>
          <StyledValue>{details.aeOwner}</StyledValue>
        </>
      )}
      {details.csmOwner && (
        <>
          <StyledTitle>CSM Owner</StyledTitle>
          <StyledValue>{details.csmOwner}</StyledValue>
        </>
      )}
      {details.owner && (
        <>
          <StyledTitle>Account Owner</StyledTitle>
          <StyledValue>{details.owner}</StyledValue>
        </>
      )}
    </div>
  );
};
