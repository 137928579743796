import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { MediaContextMenu, MoreOption } from "@components/MediaContextMenu";
import { PostToFeed } from "@components/PostToFeed";
import { ShareModal } from "@components/ShareModal";
import { AddToAlbum } from "@pages/live-stream/clips/AddToAlbum";
import Sentry from "@services/Sentry";
import {
  ModelAlbum,
  ModelMedia,
  useAdminAlbumAlbumIdPut,
  useAdminMediaDownload,
  useClipDelete,
  useMediaLibraryPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";

export const AlbumClipContextMenu = ({
  anchorEl,
  handleClose,
  mediaIds,
  album,
  permissions,
  mediaList,
  onDeleteClip,
  refetch,
  excludeMenu
}: {
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  mediaIds: string[];
  album?: ModelAlbum;
  permissions: {
    removeFromAlbum: boolean;
    deleteClip: boolean;
    editAlbum: boolean;
    deleteAlbum: boolean;
  };
  mediaList: ModelMedia[];
  onDeleteClip?: () => void;
  refetch?: () => void;
  excludeMenu?: string[];
}) => {
  const [openRemove, setOpenRemove] = useState(false);
  const [openAlbumModal, setOpenAlbumModal] = useState(false);
  const [openSaveToLibrary, setOpenSaveToLibrary] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openPostToFeed, setOpenPostToFeed] = useState(false);
  const [openShare, setOpenShare] = useState(false);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const { mutate: downloadClips } = useAdminMediaDownload();

  const { mutate: saveToLibrary, isLoading: isSavingToMyLibrary } =
    useMediaLibraryPost();

  const { mutate: deleteClips, isLoading: isDeleting } = useClipDelete();

  const { mutate: editAlbum, isLoading: isRemoving } =
    useAdminAlbumAlbumIdPut();

  const downloadFile = async () => {
    downloadClips(
      {
        data: {
          mediaIds: mediaIds
        }
      },
      {
        onSuccess: async (data) => {
          enqueueSnackbar("Download will start shortly", {
            variant: "success"
          });
          data.data.urls?.forEach((u, i) => {
            setTimeout(
              async () => {
                try {
                  const response = await fetch(u);
                  if (!response.ok) {
                    const media = mediaList.find(
                      (m) => m.mediaId === mediaIds[i]
                    );
                    return enqueueSnackbar({
                      message: `Failed to download clip ${media?.metadata.title || ""}`,
                      variant: "error"
                    });
                  }
                  const a = document.createElement("a");
                  a.href = u;
                  a.download = `Clip ${i + 1}.mp4`;
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
                  a.remove();
                } catch (error) {
                  Sentry.captureException(error);
                }
              },
              (i + 1) * 2000
            );
          });
        }
      }
    );
  };

  const handleSaveToLibrary = () => {
    saveToLibrary(
      {
        data: mediaIds
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Clip saved to library", { variant: "success" });
          setOpenSaveToLibrary(false);
        },
        onError: () => {
          enqueueSnackbar("Failed to save clip to library", {
            variant: "error"
          });
          setOpenSaveToLibrary(false);
        }
      }
    );
  };

  const onConfirmDelete = () => {
    deleteClips(
      {
        params: {
          mediaIds: mediaIds
        }
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Clip deleted successfully", { variant: "success" });
          onDeleteClip && onDeleteClip();
          refetch && refetch();
          setOpenDelete(false);
        },
        onError: () => {
          enqueueSnackbar("Failed to delete clip", { variant: "error" });
          setOpenDelete(false);
        }
      }
    );
  };

  const onConfirmRemove = () => {
    if (album)
      editAlbum(
        {
          albumId: album.albumId!,
          data: {
            operation: "disconnect",
            mediaIds: mediaIds
          }
        },
        {
          onSuccess: () => {
            enqueueSnackbar(`Clip removed from album`, { variant: "success" });
            setOpenRemove(false);
            refetch && refetch();
          }
        }
      );
  };

  return (
    <>
      <MediaContextMenu
        id={id}
        open={open}
        handleClose={handleClose}
        anchorEl={anchorEl}
        items={[
          ...(permissions.removeFromAlbum &&
          !excludeMenu?.includes("Remove from Album")
            ? [
                <MoreOption
                  key="1"
                  onClick={() => {
                    setOpenRemove(true);
                    handleClose();
                  }}
                >
                  Remove from Album
                </MoreOption>
              ]
            : []),
          ...(!excludeMenu?.includes("Add to Album")
            ? [
                <MoreOption
                  key="2"
                  onClick={() => {
                    handleClose();
                    setOpenAlbumModal(true);
                  }}
                >
                  Add to Album
                </MoreOption>
              ]
            : []),
          ...(!excludeMenu?.includes("Post to Feed")
            ? [
                <MoreOption
                  key="3"
                  onClick={() => {
                    handleClose();
                    setOpenPostToFeed(true);
                  }}
                >
                  Post to Feed
                </MoreOption>
              ]
            : []),
          ...(!excludeMenu?.includes("Save to My Library")
            ? [
                <MoreOption
                  key="4"
                  onClick={() => {
                    handleClose();
                    setOpenSaveToLibrary(true);
                  }}
                >
                  Save to My Library
                </MoreOption>
              ]
            : []),
          ...(!excludeMenu?.includes("Download")
            ? [
                <MoreOption
                  key="5"
                  onClick={() => {
                    downloadFile();
                    handleClose();
                  }}
                >
                  Download
                </MoreOption>
              ]
            : []),
          ...(!excludeMenu?.includes("Share Outside of SportsGravy")
            ? [
                <MoreOption
                  key="6"
                  onClick={() => {
                    handleClose();
                    setOpenShare(true);
                  }}
                >
                  Share Outside of SportsGravy
                </MoreOption>
              ]
            : []),
          ...(permissions.deleteClip && !excludeMenu?.includes("Delete Clip")
            ? [
                <MoreOption
                  key="7"
                  style={{ color: "#E82C2C" }}
                  onClick={() => {
                    handleClose();
                    setOpenDelete(true);
                  }}
                >
                  Delete {mediaIds.length > 1 ? "Clips" : "Clip"}
                </MoreOption>
              ]
            : [])
        ]}
      />
      {openPostToFeed && mediaIds.length <= 10 && (
        <PostToFeed
          onClose={() => {
            setOpenPostToFeed(false);
          }}
          media={mediaList.filter((m) => mediaIds.includes(m.mediaId!))}
        />
      )}
      {openShare && (
        <ShareModal onClose={() => setOpenShare(false)} mediaIds={mediaIds} />
      )}
      <ConfirmationDialog
        open={openDelete}
        title={`Delete ${mediaIds.length > 1 ? "Clips" : "Clip"}`}
        body={`Are you sure you want to delete ${
          mediaIds.length > 1 ? "these clips" : "this clip"
        }?`}
        onConfirm={onConfirmDelete}
        isConfirming={isDeleting}
        onCancel={() => setOpenDelete(false)}
        icon="error"
      />

      <ConfirmationDialog
        open={openRemove}
        title={`Remove from Album`}
        body={`Are you sure you want to remove ${
          mediaIds.length > 1 ? "these clips" : "this clip"
        } from the ${album?.name}?`}
        onConfirm={onConfirmRemove}
        isConfirming={isRemoving}
        onCancel={() => setOpenRemove(false)}
        icon="warning"
        confirmBtnVariant="admin-warning"
      />
      <ConfirmationDialog
        open={openSaveToLibrary}
        title="Confirm"
        body={`${
          mediaIds.length > 1 ? "These items" : "This item"
        } will be saved to your SportsGravy account's My Library.`}
        onConfirm={handleSaveToLibrary}
        isConfirming={isSavingToMyLibrary}
        confirmBtnText="Okay"
        onCancel={() => setOpenSaveToLibrary(false)}
      />
      <ConfirmationDialog
        open={openPostToFeed && mediaIds.length > 10}
        title="Photo/Video Limit"
        body="You have selected too many items. You may only select up to 10 items to include in a single post."
        onConfirm={() => setOpenPostToFeed(false)}
        confirmBtnText="Okay"
        onCancel={() => setOpenPostToFeed(false)}
        hideCancelBtn
      />
      {openAlbumModal && (
        <AddToAlbum
          type="ADD_TO_ALBUM"
          onClose={() => {
            setOpenAlbumModal(false);
          }}
          mediaIds={mediaIds}
        />
      )}
    </>
  );
};
