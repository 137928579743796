import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import {
  useAdminAdvertiserAdvertisementIdFeedbackFeedbackIdGet,
  useAdminAdvertiserAdvertisementIdGet,
  useAdminAdvertiserIdAdvertisementIdFeedbackReplyPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Typography, styled } from "@mui/material";
import { LivePreview } from "@components/LivePreview";
import { FormInput } from "@components/FormInput";
import { Loader } from "@components/crud/Loader";
const StyledForm = styled(Form)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {
    flexDirection: "column !important"
  }
}));
const StyledGrid = styled(Grid)(({ theme }) => ({
  width: "50% !important",
  [theme.breakpoints.down("xl")]: {
    width: "100% !important"
  }
}));
const StyledLivePreview = styled(Grid)(({ theme }) => ({
  width: "500px !important",
  marginLeft: "24px",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "0px",
    marginTop: "24px",
    width: "75% !important"
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "0px",
    marginTop: "24px",
    width: "100% !important"
  }
}));
export const FeedbackReply = () => {
  const navigate = useNavigate();
  const { advertiserId, advertisementId, feedbackId } = useParams();
  const [adplacement, setAdPlacement] = useState("");
  const [adLayout, setAdLayout] = useState("");
  const [adDetails, setAdDetails] = useState({
    title: "",
    description: "",
    media: undefined,
    icon: undefined,
    redirect: undefined
  });
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { isValid },
    reset
    // setValue,
    // getValues
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      advertiserFeedback: "",
      contact: "",
      Givenfeedback: ""
    }
  });
  const { data: advertisement, isLoading: isAdvertisementLoading } =
    useAdminAdvertiserAdvertisementIdGet(advertiserId!, advertisementId!);
  const { data: feedback, isLoading: isFeedbackLoading } =
    useAdminAdvertiserAdvertisementIdFeedbackFeedbackIdGet(
      advertiserId!,
      advertisementId!,
      feedbackId!
    );
  const { mutate: save, isLoading: isSaving } =
    useAdminAdvertiserIdAdvertisementIdFeedbackReplyPost();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    async (formValues) => {
      for (const key in formValues) {
        if (formValues[key] === null) {
          delete formValues[key];
        }
      }
      const values = {
        ...formValues
      };
      values["replyOfId"] = feedbackId;
      try {
        save(
          {
            advertiserId: advertiserId!,
            advertisementId: advertisementId!,
            data: values
          },
          {
            onSuccess: () => {
              enqueueSnackbar("Feedback Reply added successfully!", {
                variant: "success"
              });
              if (resetInsteadOfRoute) {
                reset();
              } else {
                navigate(
                  `/advertisers/${advertiserId}/advertisement/${advertisementId}`
                );
              }
            },
            onError: () => {
              enqueueSnackbar("Failed to add reply!", {
                variant: "error"
              });
            }
          }
        );
      } catch (error) {
        enqueueSnackbar("Failed to generate image data!", {
          variant: "error"
        });
      }
    };
  useEffect(() => {
    if (advertisement?.data) {
      reset({
        advertiserFeedback: advertisement.data.placement
      });
      //@ts-ignore
      setAdDetails((details) => ({
        ...details,
        title: advertisement.data.title,
        description: advertisement.data.description,
        media: advertisement.data.media
          ? (((advertisement.data.media.baseUrl as string) +
              advertisement.data.media.path) as string)
          : undefined,
        logo: advertisement.data.advertiser?.logo?.baseUrl
          ? advertisement.data.advertiser?.logo?.baseUrl +
            advertisement.data.advertiser?.logo?.path
          : undefined,
        redirect: advertisement.data.redirectLink || ""
      }));
      setAdPlacement(advertisement.data.placement as string);
      setAdLayout(advertisement.data.layout || "");
    }
    if (feedback?.data) {
      reset({
        contact: advertisement?.data?.advertiser?.organizationId
          ? `${feedback.data.contactSentBy?.firstName} ${feedback.data.contactSentBy?.lastName}`
          : `${feedback.data.sentBy?.firstName} ${feedback.data.sentBy?.lastName}`,
        Givenfeedback: feedback.data.feedback
      });
    }
  }, [advertisement, feedback]);
  return (
    <Loader isLoading={isAdvertisementLoading || isFeedbackLoading}>
      <Container data-testid="feedback-reply-form">
        <Toolbar title="Reply to Advertiser Feedback" />
        <StyledForm style={{ flexDirection: "row" }}>
          <StyledGrid
            data-testid="advertisement-add-form"
            container
            direction={"column"}
          >
            {" "}
            <Grid xs={12} data-testid="feedbackProvider-name">
              <FormInput
                control={control}
                name="contact"
                type="text"
                label="Contact Name"
                disabled
              />
            </Grid>
            <Grid
              xs={12}
              marginTop={"25px"}
              data-testid="feedbackProvider-feedback"
            >
              <FormInput
                control={control}
                name="Givenfeedback"
                type="text"
                multiline
                label="Advertiser Feedback"
                rows={4}
                disabled
              />
            </Grid>
            <Grid xs={12} marginTop={"25px"} data-testid="your-feedback">
              <FormInput
                control={control}
                name="feedback"
                type="text"
                label="Your Feedback"
                required={true}
                multiline
                rows={4}
                rules={{
                  required: "Feedback is required"
                }}
              />
            </Grid>
          </StyledGrid>
          <StyledLivePreview xs={12}>
            <Typography style={{ fontWeight: 600, fontSize: "14px" }}>
              Live Preview
            </Typography>{" "}
            <LivePreview
              layout={adLayout}
              placement={adplacement}
              adDetails={adDetails}
            />
          </StyledLivePreview>
        </StyledForm>
        <Footer
          cancelBtnClick={() => setOpenCancelDialog(true)}
          saveBtnClick={handleSubmit(saveHandler(false))}
          isDisabled={!isValid || isSaving}
          isLoading={isSaving}
        />
        <ConfirmationDialog
          title="Are you sure you want to cancel?"
          body="All of your current changes will be lost."
          open={openCancelDialog}
          close={() => setOpenCancelDialog(false)}
          onCancel={() => setOpenCancelDialog(false)}
          onConfirm={() =>
            navigate(
              `/advertisers/${advertiserId}/advertisement/${advertisementId}`
            )
          }
          cancelBtnText="Cancel"
          confirmBtnText="Confirm"
        />
      </Container>
    </Loader>
  );
};
