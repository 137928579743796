import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { FormSelect } from "@components/FormSelect";
import { MediaSelector } from "@components/MediaSelector";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import { useApiSelectOptions } from "@hooks/useApiSelectOptions";
import Grid from "@mui/material/Unstable_Grid2";
import {
  ModelMedia,
  useAdminGameConceptConceptIdGet,
  useAdminGameConceptConceptIdPut,
  useAdminPositionGet,
  useAdminSkillGet,
  useAdminSportGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Typography, FormLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import GamePickerImage from "@assets/images/gameConceptPicker.png";
import { uploadMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import { useRecoilState } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { capitalizeEveryWord } from "@utils/capitalize";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

export const GameConceptEdit = () => {
  const [filestoUpload, setFilesToUpload] = useState<(File | ModelMedia)[]>([]);
  const navigate = useNavigate();
  const { conceptId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [mediaUploadLoading, setMediaUploadLoading] = useState(false);
  const [positions, setPositions] = useState("");
  const [skills, setSkills] = useState<
    {
      skillId: string;
    }[]
  >([]);
  const [files, setFiles] = useState<ModelMedia[]>([]);

  const {
    data: gameConcept,
    isFetching: isGameConceptFetching,
    error: error
  } = useAdminGameConceptConceptIdGet(conceptId as string);
  useEffect(() => {
    if (error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
  }, [error]);

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
    setValue,
    reset
  } = useForm({
    mode: "onTouched"
  });
  const [organizationId] = useRecoilState(organizationAtom);

  const { data: sports, isLoading: isSportLoading } = useAdminSportGet({
    organizationId: organizationId!
  });

  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name!,
        value: sport.sportId!
      })) || [],
    [sports]
  );
  const { options: positionOptions, isLoading: positionOptionsLoading } =
    useApiSelectOptions({
      api: useAdminPositionGet,
      dataField: "positions",
      labelField: "name",
      valueField: "positionId",
      params: {
        sportId: positions || gameConcept?.data?.sportId
      },
      options: {
        query: {
          enabled: !!positions || !!gameConcept?.data?.sportId
        }
      }
    });

  const { options: skillOptions, isLoading: skillOptionsLoading } =
    useApiSelectOptions({
      api: useAdminSkillGet,
      dataField: "skills",
      labelField: "name",
      valueField: "skillId",
      params: {
        sportId: skills || gameConcept?.data?.sportId
      },
      options: {
        query: {
          enabled: !!skills || !!gameConcept?.data?.sportId
        }
      }
    });

  useEffect(() => {
    if (!isGameConceptFetching && gameConcept?.data) {
      reset(
        {
          name: gameConcept?.data?.name,
          description: gameConcept?.data?.description || "",
          sportId: gameConcept?.data?.sportId,
          positions: gameConcept?.data?.positions?.map(
            (position) => position.positionId
          ),
          skills: gameConcept?.data?.skills?.map((skill) => skill.skillId)
        },
        {
          keepDirtyValues: true
        }
      );
      setFiles(gameConcept?.data?.media || []);
    }
  }, [gameConcept]);

  const { mutate: save, isLoading: isSaving } =
    useAdminGameConceptConceptIdPut();
  const saveHandler = async (formValues) => {
    const values = {
      ...formValues
    };
    if (values.positions) {
      values.positions = values.positions.map((position) => ({
        positionId: position
      }));
    }
    if (values.skills) {
      values.skills = values.skills.map((skill) => ({
        skillId: skill
      }));
    }
    setMediaUploadLoading(true);
    const filesPromises = await Promise.all(
      //@ts-ignore
      filestoUpload.map((file) => {
        if (file instanceof File) {
          const promise = uploadMediaUsingPresignedUrl(file);
          console.log("PROMISE", promise);
          return promise;
        } else {
          return file.mediaId;
        }
      })
    );
    values["mediaIds"] = filesPromises;
    setMediaUploadLoading(false);
    save(
      {
        conceptId: conceptId as string,
        data: values
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Game concept saved successfully!", {
            variant: "success"
          });
          navigate(`/game-concepts/${conceptId}`);
        },
        onError: () => {
          enqueueSnackbar("Failed to save game concept!", { variant: "error" });
        }
      }
    );
  };
  const checkSaveDisabled = () => {
    if (filestoUpload && gameConcept?.data.media) {
      return filestoUpload
        ? !isDirty &&
          gameConcept?.data?.media?.length > filestoUpload.length &&
          filestoUpload.filter((file) => file instanceof File).length != 0 //file removed, no new file added
          ? false
          : !isDirty &&
              filestoUpload.filter((file) => file instanceof File).length != 0 //new file added
            ? false
            : isDirty &&
                filestoUpload.filter((file) => file instanceof File).length == 0 //done other changes
              ? false
              : isDirty &&
                  filestoUpload.filter((file) => file instanceof File).length !=
                    0 //done other changes with new file added
                ? false
                : !isDirty &&
                    filestoUpload &&
                    filestoUpload.filter((file) => file instanceof File)
                      .length == 0 &&
                    gameConcept?.data?.media?.length == filestoUpload.length
                  ? true //no changes
                  : false
        : false;
    }
  };
  return (
    <Container>
      <Toolbar title="Edit Game Concept" />
      <Form>
        <Loader
          isLoading={
            isGameConceptFetching ||
            positionOptionsLoading ||
            skillOptionsLoading
          }
        >
          <Grid data-testid="gameConcept-edit-form" container spacing={3}>
            <Grid data-testid="gameConcept-name" xs={12} md={6}>
              <FormInput
                control={control}
                name="name"
                type="text"
                label="Name"
                required={true}
                onChange={(e) => {
                  setValue("name", capitalizeEveryWord(e.target.value));
                }}
                rules={{
                  required: "Name is required"
                }}
              />
            </Grid>
            <Grid data-testid="gameConcept-sport" xs={12} md={6}>
              <FormSelect
                control={control}
                name="sportId"
                label="Sport"
                required={true}
                options={sportOptions}
                isLoading={isSportLoading}
                disabled={sportOptions.length === 1}
                onChange={(e) => {
                  setValue("positions", "");
                  setPositions(e.target.value);
                  setValue("skills", "");
                  setSkills(e.target.value);
                }}
                rules={{
                  required: "Sport is required"
                }}
              />
            </Grid>
            <Grid data-testid="gameConcept-position" xs={12} md={6}>
              <FormMultiSelect
                control={control}
                name="positions"
                label="Positions"
                value="positions"
                required={false}
                options={positionOptions}
              />
            </Grid>
            <Grid data-testid="gameConcept-skill" xs={12} md={6}>
              <FormMultiSelect
                control={control}
                name="skills"
                label="Skills"
                value="skills"
                required={false}
                options={skillOptions}
              />
            </Grid>
            <Grid data-testid="gameConcept-description" xs={12} md={12}>
              <FormInput
                control={control}
                name="description"
                type="text"
                label="Description"
                required={false}
                multiline={true}
                rows={4}
              />
            </Grid>
            <Grid xs={12} md={12}>
              <StyledFormLabel>
                <Typography display="inline" variant="formLabel">
                  {"Photo/Video Content"}
                </Typography>
              </StyledFormLabel>
            </Grid>
            <MediaSelector
              setFilesToUpload={(file) => {
                setFilesToUpload(file);
              }}
              uploadedFiles={files}
              imagePlaceHolder={GamePickerImage}
            />
          </Grid>
        </Loader>
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler)}
        isDisabled={
          !isValid || isSaving || mediaUploadLoading || checkSaveDisabled()
        }
        isLoading={isSaving || mediaUploadLoading}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/game-concepts")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
