import { FormCheckbox } from "@components/FormCheckbox";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { SearchAddPerson } from "@components/SearchAddPerson";
import { Loader } from "@components/crud/Loader";
import { FormLabel, Grid, Typography, styled } from "@mui/material";
import {
  ModelRole,
  RoleType,
  useAdminRoleGet,
  useAdminUserUserIdGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import convertUTCDateToLocalDate from "@utils/convertUtcToLocal";
import React, { useEffect, useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { EMAIL_REGEX } from "@utils/validation";
import { calculateAge } from "@utils/calculateAge";
import { RenderRolesWithAliases } from "./RenderRolesWithAliases";
import { capitalizeEveryWord } from "@utils/capitalize";
const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));
type RoleOption = {
  label: string;
  value: string;
  children: ModelRole[] | undefined;
  role?: ModelRole;
};
type Guardian = {
  firstName: string;
  lastName: string;
  email: string;
  personId: string;
};

const GuardianDetailsForm = ({
  isEditing,
  guardian,
  setParentFormValue,
  setCreateGuardian
}: {
  isEditing: boolean;
  guardian: Guardian;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setParentFormValue?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setCreateGuardian?: any;
}) => {
  const { control } = useForm({
    mode: "onTouched",
    defaultValues: {
      firstName: guardian.firstName,
      lastName: guardian.lastName,
      email: guardian.email,
      personId: guardian.personId
    }
  });

  useEffect(() => {
    setParentFormValue("parentId", guardian.personId);
  }, [guardian]);

  const [newParent, setNewParent] = useState({
    firstName: "",
    lastName: "",
    email: "",
    roles: []
  });

  return (
    <>
      <Grid
        item
        container
        direction="row"
        spacing="24px"
        data-testid="ADD_USER_GUARDIAN_SELECTION"
      >
        <Grid item xs={6}>
          <FormInput
            name="firstName"
            control={control}
            rules={{ required: "First Name is required" }}
            label="First Name"
            type="text"
            required={true}
            disabled={!isEditing}
            capitalizeWords
            onChange={(e) => {
              const oldParent = newParent;
              oldParent.firstName = e.target.value;
              setNewParent(oldParent);
              setParentFormValue("parentDetails", oldParent);
              const updatedParent = {
                ...newParent,
                firstName: e?.target.value
              };
              setCreateGuardian(updatedParent);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name={"lastName"}
            control={control}
            rules={{ required: "Last Name is required" }}
            label="Last Name"
            type="text"
            capitalizeWords
            required={true}
            disabled={!isEditing}
            onChange={(e) => {
              const oldParent = newParent;
              oldParent.lastName = e?.target.value;
              setNewParent(oldParent);
              setParentFormValue("parentDetails", oldParent);
              const updatedParent = { ...newParent, lastName: e?.target.value };
              setCreateGuardian(updatedParent);
            }}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing="24px">
        <Grid item xs={6}>
          <FormInput
            name={"email"}
            control={control}
            label="Email"
            type="email"
            required
            rules={{
              required: "Email is required",
              pattern: {
                value: EMAIL_REGEX,
                message: "Please enter a valid email address"
              }
            }}
            disabled={!isEditing}
            onChange={(e) => {
              const oldParent = newParent;
              oldParent.email = e?.target.value;
              setNewParent(oldParent);
              setParentFormValue("parentDetails", oldParent);
              const updatedParent = { ...newParent, email: e?.target.value };
              setCreateGuardian(updatedParent);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
export const CreateUserForm = ({
  isEditing,
  userId,
  form,
  onChangeRolesSelected,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setIsFormValid
}: {
  isEditing: boolean;
  userId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any, undefined>;
  onChangeRolesSelected: (roleId, checked) => void;
  setIsFormValid?: (value: boolean) => void;
}) => {
  const { control, reset, getValues, setValue } = form;
  const [organizationId] = useRecoilState(organizationAtom);
  const { data: userResponse, isFetching: isFetchingUser } =
    useAdminUserUserIdGet(userId as string);
  const [user, setUser] = useState(userResponse?.data);
  useEffect(() => {
    if (userResponse) setUser(userResponse.data);
  }, [userResponse]);

  const { data: roleOptionsRequest, isLoading: isRoleOptionsLoading } =
    useAdminRoleGet({
      organizationId,
      type: organizationId ? RoleType.ORGANIZATION : undefined,
      pageSize: "100",
      includeChildren: true
    });

  const [isUnderAge, setIsUnderAge] = useState(false);
  const defaultGuardian: Guardian = {
    firstName: "",
    lastName: "",
    email: "",
    personId: ""
  };
  const [newGuardian, setNewGuardian] = useState(defaultGuardian);
  const [newGuardianSelected, setNewGuardianSelected] = useState(false);
  const [existingGuardianSelected, setExistingGuardianSelected] =
    useState(false);
  const [currentRoleOptions, setCurrentRoleOptions] = useState<RoleOption[]>(
    []
  );
  const [createGaurdian, setCreateGuardian] = useState(defaultGuardian);
  useEffect(() => {
    const rolesSelected =
      user?.user?.roles?.reduce((acc, role) => {
        const key = role.roleId?.replace(".", "_") + "_SELECTED";
        acc[key] = true;
        return acc;
      }, {}) || {};

    const bannedAliases = user?.user?.roleAliasBans
      ?.filter((r) => !r.organizationId)
      ?.map((alias) => alias.alias)
      .filter(
        (alias) => alias && ["COACH", "MANAGER", "ADMIN"].includes(alias)
      );
    const bannedRolesAliases = !organizationId
      ? bannedAliases
      : user?.user?.roleAliasBans
          ?.filter((r) => r.organizationId === organizationId)
          ?.map((r) => r.alias) || [];

    const defaultValues = {
      firstName: user?.firstName,
      middleName: user?.middleName,
      lastName: user?.lastName,
      suffix: user?.suffix,
      email: user?.email,
      country: user?.addressPrimary?.country,
      province: user?.addressPrimary?.province,
      locality: user?.addressPrimary?.locality,
      address1: user?.addressPrimary?.lines?.[0],
      address2: user?.addressPrimary?.lines?.[1],
      postalCode: user?.addressPrimary?.postalCode,
      guardians: user?.guardians,
      birthedAt: user?.birthedAt
        ? convertUTCDateToLocalDate(new Date(user?.birthedAt))
        : "",
      isUnderAge: user?.birthedAt ? calculateAge(user.birthedAt) < 18 : false,
      bannedRoles: bannedRolesAliases,
      bannedRolesSG: bannedAliases,
      photoUrl: user?.avatar?.baseUrl
        ? user?.avatar?.baseUrl + user?.avatar?.path
        : undefined,
      ...rolesSelected
    };
    setIsUnderAge(defaultValues.isUnderAge);
    setValue("isUnderAge", defaultValues.isUnderAge);

    //not getting reset here
    reset(defaultValues, {
      keepDirtyValues: true
    });
  }, [user]);

  const roleOptions = () => {
    if (!roleOptionsRequest?.data?.roles) {
      return [];
    }

    if (!organizationId) {
      const SGRoles = roleOptionsRequest.data.roles.filter(
        (role) => role.type === "SYSTEM"
      );
      return SGRoles.map((role) => {
        return {
          label: role.name as string,
          value: role.roleId as string,
          children: role.children,
          role: role
        };
      });
    } else {
      const FSORoles = roleOptionsRequest.data.roles.filter(
        (role) => role.type === "ORGANIZATION"
      );
      return FSORoles.map((role) => {
        return {
          label:
            (role.name as string) + (role.type === "SYSTEM" ? " (System)" : ""),
          value: role.roleId as string,
          children: role.children
            ? role.children.filter((child) => child.type === "ORGANIZATION")
            : [],
          role: role
        };
      });
    }
  };
  useEffect(() => {
    const rolesOptions = roleOptions();
    setCurrentRoleOptions(rolesOptions);
  }, [roleOptionsRequest]);

  const openRoleDesc = (roleId) => {
    window.open("/roles/" + roleId, "_blank");
  };

  const suffixOptions = [
    { value: "", label: "" },
    { value: "Jr", label: "Jr" },
    { value: "Sr", label: "Sr" },
    { value: "I", label: "I" },
    { value: "II", label: "II" },
    { value: "III", label: "III" },
    { value: "IV", label: "IV" },
    { value: "V", label: "V" }
  ];

  const selectedRoles = user?.user?.roles || [];
  useEffect(() => {
    if (setIsFormValid) {
      let isValid = true;
      if (isUnderAge) {
        if (existingGuardianSelected) {
          isValid = true;
        } else if (newGuardianSelected) {
          isValid =
            createGaurdian.firstName !== "" &&
            createGaurdian.lastName !== "" &&
            createGaurdian.email !== "" &&
            createGaurdian.email.match(EMAIL_REGEX) !== null;
        } else {
          isValid = false;
        }
      }
      setIsFormValid(isValid);
    }
  }, [
    isUnderAge,
    existingGuardianSelected,
    newGuardianSelected,
    createGaurdian,
    setIsFormValid
  ]);
  return (
    <Grid
      container
      direction="column"
      spacing="25px"
      data-testid="createUserForm"
      style={{ padding: "20px 20px 20px 20px" }}
    >
      <Loader isLoading={isFetchingUser || isRoleOptionsLoading}>
        {user && (
          <Grid item container direction="row" spacing="24px">
            <Grid item xs={12}>
              <FormInput
                name="photoUrl"
                control={control}
                label="Avatar"
                type="avatar"
                disabled
              />
            </Grid>
          </Grid>
        )}
        <Grid item container direction="row" spacing="24px">
          <Grid item xs={3} data-testid="ADD_USER_FIRST_NAME">
            <FormInput
              name="firstName"
              control={control}
              rules={{ required: "First Name is required" }}
              label="First Name"
              type="text"
              required={true}
              onChange={(e) => {
                setValue("firstName", capitalizeEveryWord(e.target.value));
              }}
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={3}>
            <FormInput
              name="middleName"
              control={control}
              label="Middle Name"
              type="text"
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={3} data-testid="ADD_USER_LAST_NAME">
            <FormInput
              name="lastName"
              control={control}
              rules={{ required: "Last Name is required" }}
              label="Last Name"
              type="text"
              required={true}
              disabled={!isEditing}
              onChange={(e) => {
                setValue("lastName", capitalizeEveryWord(e.target.value));
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <FormSelect
              name="suffix"
              control={control}
              label="Suffix"
              options={suffixOptions}
              disabled={!isEditing}
            />
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing="24px">
          <Grid item xs={6} data-testid="ADD_USER_EMAIL">
            <FormInput
              name="email"
              control={control}
              label="Email"
              type="email"
              required
              rules={{
                required: "Email is required",
                pattern: {
                  value: EMAIL_REGEX,
                  message: "Please enter a valid email address"
                }
              }}
              disabled={!isEditing}
            />
          </Grid>
        </Grid>

        <Grid item container direction="row" spacing="24px">
          <Grid item xs={6} data-testid="ADD_USER_UNDER_AGE">
            <FormCheckbox
              key="isUnderAge"
              control={control}
              name="isUnderAge"
              label="This person is below 18 years of age"
              labelPadding="4px 0"
              onChange={() => {
                setValue("isUnderAge", !isUnderAge);
                setIsUnderAge(!isUnderAge);
              }}
              labelTypographyProps={{
                variant: "body1"
              }}
              disabled={!isEditing}
            />
          </Grid>
        </Grid>

        {!organizationId && currentRoleOptions.length
          ? currentRoleOptions.map((role) => {
              return (
                <>
                  <Grid item>
                    <StyledFormLabel required>
                      <Typography display="inline" variant="formLabel">
                        Role
                      </Typography>
                    </StyledFormLabel>
                  </Grid>
                  <Grid item container key={role.value}>
                    <Grid item container direction="row">
                      <Grid item data-testid={"ROLE_VALUE_" + role.value}>
                        <FormCheckbox
                          key={role.value}
                          control={control}
                          name={role.value.replace(".", "_") + "_SELECTED"}
                          label={role.label}
                          disabled={selectedRoles.some(
                            (selectedRole) => selectedRole.roleId === role.value
                          )}
                          labelPadding="4px 8px"
                          onClickLabel={() => openRoleDesc(role.value)}
                          onChange={(e) =>
                            onChangeRolesSelected(role.value, e.target.checked)
                          }
                          labelTypographyProps={{
                            variant: "body1",
                            style: {
                              color: "#3B6CF8",
                              fontWeight: 400,
                              textDecoration: "underline"
                            }
                          }}
                        />
                      </Grid>
                      {role.children?.length ? (
                        role.children.map((subRoles) => {
                          return (
                            <Grid
                              item
                              key={subRoles.roleId}
                              data-testid={"ROLE_VALUE_" + subRoles.roleId}
                            >
                              <FormCheckbox
                                control={control}
                                name={
                                  subRoles.roleId!.replace(".", "_") +
                                  "_SELECTED"
                                }
                                label={subRoles.displayText || subRoles.name}
                                disabled={selectedRoles.some(
                                  (selectedRole) =>
                                    selectedRole.roleId === subRoles.roleId
                                )}
                                labelPadding="4px 8px"
                                onClickLabel={() =>
                                  openRoleDesc(subRoles.roleId)
                                }
                                onChange={(e) =>
                                  onChangeRolesSelected(
                                    subRoles.roleId,
                                    e.target.checked
                                  )
                                }
                                labelTypographyProps={{
                                  variant: "body1",
                                  style: {
                                    color: "#3B6CF8",
                                    fontWeight: 400,
                                    textDecoration: "underline"
                                  }
                                }}
                              />
                            </Grid>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </>
              );
            })
          : currentRoleOptions.length && (
              <>
                <Grid item xs={6}>
                  <Typography
                    variant="permissionNames"
                    sx={{
                      color: "#00000",
                      fontSize: "12px",
                      font: "inter",
                      lineHeight: "14.52px",
                      letterSpacing: "10%",
                      fontWeight: 400
                    }}
                  >
                    ROLES & SUB ROLES
                  </Typography>
                </Grid>
                {/* {renderRolesAlias(currentRoleOptions)} */}
                <RenderRolesWithAliases
                  orgRoles={currentRoleOptions.filter(
                    (role) =>
                      role?.role?.alias !== "PARENT" &&
                      role?.role?.alias !== "PLAYER"
                  )}
                  control={control}
                  isNewUser={isEditing}
                  showUnderline={true}
                  organizationId={organizationId}
                  sgBannedRoles={getValues().bannedRolesSG}
                  orgBannedRoles={getValues().bannedRoles}
                  onChangeRolesSelected={onChangeRolesSelected}
                  openRoleDesc={openRoleDesc}
                />
              </>
            )}
        {isUnderAge && (
          <Grid
            item
            container
            spacing="24px"
            data-testid="USER_ADD_GUARDIANS_VIEW"
          >
            <Grid item xs={6}>
              <Typography
                variant="permissionNames"
                sx={{
                  color: "#00000",
                  fontSize: "12px",
                  font: "inter",
                  lineHeight: "14.52px",
                  letterSpacing: "10%",
                  fontWeight: 400
                }}
              >
                PARENT GUARDIAN DETAILS
              </Typography>
            </Grid>
            {user && user.guardians && user.guardians.length > 0 ? (
              <>
                <Grid
                  item
                  container
                  direction="row"
                  spacing="24px"
                  data-testid="ADD_USER_GUARDIAN_DETAILS"
                >
                  <Grid item xs={6}>
                    <FormInput
                      name={
                        user.guardians[0].guardian
                          ? "guardians[0].guardian.firstName"
                          : "guardianFirstName"
                      }
                      control={control}
                      rules={{ required: "First Name is required" }}
                      label="First Name"
                      type="text"
                      required={true}
                      disabled={!isEditing}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormInput
                      name={
                        user.guardians[0].guardian
                          ? "guardians[0].guardian.lastName"
                          : ""
                      }
                      control={control}
                      rules={{ required: "Last Name is required" }}
                      label="Last Name"
                      type="text"
                      required={true}
                      disabled={!isEditing}
                    />
                  </Grid>
                </Grid>
                <Grid item container direction="row" spacing="24px">
                  <Grid item xs={6}>
                    <FormInput
                      name={
                        user.guardians[0].guardian
                          ? "guardians[0].guardian.email"
                          : ""
                      }
                      control={control}
                      required
                      rules={{
                        required: "Email is required",
                        pattern: {
                          value: EMAIL_REGEX,
                          message: "Please enter a valid email address"
                        }
                      }}
                      label="Email"
                      type="email"
                      disabled={!isEditing}
                    />
                  </Grid>
                </Grid>
              </>
            ) : newGuardianSelected ? (
              <GuardianDetailsForm
                isEditing={!existingGuardianSelected}
                guardian={newGuardian}
                setParentFormValue={setValue}
                setCreateGuardian={setCreateGuardian}
              />
            ) : (
              <SearchAddPerson
                personSelected={(option, personSelected) => {
                  setNewGuardian(option);
                  setNewGuardianSelected(true);
                  setExistingGuardianSelected(personSelected);
                }}
              />
            )}
          </Grid>
        )}
      </Loader>
    </Grid>
  );
};
