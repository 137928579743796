import { ToolTip } from "@components/ToolTip";
import { Download, Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import {
  adminCrmOrderAgreementAgreementIdDownload,
  ModelAgreement
} from "@sportsgravyengineering/sg-api-react-sdk";
import { TableView } from "@components/TableView";
import { getBillingAgreements, getOrderAgreements } from "@services/Network";
import { enqueueSnackbar } from "notistack";
import { OrderAgreementViewer } from "./OrderAgreementViewer";
import { Loader } from "@components/crud/Loader";
import { hasPermission } from "@services/Casbin";
const IconStyle = {
  height: "24px",
  width: "24px",
  color: "#666666"
};
const formatDateForDisplay = (dateStr) => {
  const inputDate = new Date(dateStr);
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const day = inputDate.getDate().toString().padStart(2, "0");
  const year = inputDate.getFullYear();
  // Create the formatted date string in "MM/DD/YYYY" format
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
};
export const agreementsStatus = [
  {
    label: "Pending",
    value: "PENDING"
  },
  {
    label: "Fully Executed",
    value: "FULLY_EXECUTED"
  }
];

export const OrderAgreements = ({
  orderId,
  organizationId
}: {
  orderId: string;
  organizationId?: string;
}) => {
  const [showAgreementModal, setShowAgreementModal] = useState(false);
  const [agreementToView, setAgreementToView] = useState<{
    agreementId: string;
    agreementName: string;
  } | null>();
  const dateDiff = (date1, date2) => {
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // Hours * minutes * seconds * milliseconds
    const differenceInTime = Math.abs(date2 - date1);
    return Math.floor(differenceInTime / oneDayInMilliseconds);
  };
  const [isLoadingPermissions, setPermissionsLoading] = useState(true);
  const [isDownloading, setDownloading] = useState(false);

  const [permissions, setPermissions] = useState({
    viewAgreement: false,
    downloadAgreement: false
  });
  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const viewAgreement = await checkPermission(
        "crm.orders-view-agreement",
        "ON"
      );
      const downloadAgreement = await checkPermission(
        "crm.orders-download-agreement",
        "ON"
      );

      const permission = {
        viewAgreement,
        downloadAgreement
      };
      setPermissions(permission);
      setPermissionsLoading(false);
    };
    fetchPermissions();
  }, []);

  const downloadFile = async (agreementId: string, fileName: string) => {
    try {
      setDownloading(true);
      const agreementDownload =
        await adminCrmOrderAgreementAgreementIdDownload(agreementId);

      const byteArray = new Uint8Array(agreementDownload.data.data?.data);
      const blob = new Blob([byteArray], {
        type: "application/pdf"
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}.pdf`);
      document.body.appendChild(link);

      link.click();

      // Cleanup: remove the link and revoke the object URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      setDownloading(false);
    } catch (err) {
      enqueueSnackbar("Download Failed!", {
        variant: "error"
      });
      setDownloading(false);
    }
  };

  const COLUMNS: GridColDef<ModelAgreement>[] = [
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ padding: "20px 0px", display: "flex" }}>
            {permissions.viewAgreement && (
              <IconButton
                onClick={() => {
                  setShowAgreementModal(true);
                  setAgreementToView({
                    agreementName: params.row.agreementName!,
                    agreementId: params.row.agreementId!
                  });
                }}
              >
                <ToolTip title="View Agreement" placement="top">
                  <Visibility style={IconStyle} />
                </ToolTip>
              </IconButton>
            )}
            <Loader isLoading={isDownloading}>
              {permissions.downloadAgreement && (
                <IconButton
                  onClick={() => {
                    downloadFile(
                      params.row.agreementId!,
                      params.row.agreementName!
                    );
                  }}
                >
                  <ToolTip title="Download Agreement" placement="top">
                    <Download style={IconStyle} />
                  </ToolTip>
                </IconButton>
              )}
            </Loader>
          </div>
        );
      }
    },
    {
      field: "agreementName",
      headerName: "Agreement Name",
      flex: 1,
      minWidth: 250,
      valueGetter: ({ row }) => row.agreementName
    },
    {
      field: "dateSent",
      headerName: "Date Sent",
      flex: 1,
      minWidth: 250,
      valueGetter: ({ row }) => formatDateForDisplay(row.dateSent)
    },
    {
      field: "dateSigned",
      headerName: "Date Signed",
      flex: 1,
      minWidth: 250,
      valueGetter: ({ row }) =>
        row.dateSigned ? formatDateForDisplay(row.dateSigned) : "-"
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 250,
      valueGetter: ({ row }) =>
        agreementsStatus.find((a) => a.value === row.status)?.label
    },
    {
      field: "daysLeft",
      headerName: "Days to Sign",
      flex: 1,
      minWidth: 250,
      valueGetter: ({ row }) =>
        !row.dateSigned
          ? dateDiff(
              row.dateSigned ? row.dateSigned : new Date(),
              new Date(row.expiryDate!)
            )
          : "Signed"
    }
  ];
  return (
    <>
      <Loader isLoading={isLoadingPermissions}>
        <TableView
          columns={COLUMNS}
          style={{
            marginTop: "5px"
          }}
          getRowId={(row) => row.agreementId}
          title=""
          hasActionColumn={false}
          getRowHeight={() => "auto"}
          hideToolbar
          useGet={organizationId ? getBillingAgreements : getOrderAgreements}
          wildCardParam={organizationId ? undefined : orderId}
        />

        {agreementToView && (
          <OrderAgreementViewer
            open={showAgreementModal}
            setOpen={setShowAgreementModal}
            header={agreementToView?.agreementName}
            agreementId={agreementToView?.agreementId}
          />
        )}
      </Loader>
    </>
  );
};
