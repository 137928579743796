import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import {
  adminPositionGet,
  useAdminPositionPositionIdGet,
  useAdminPositionPositionIdPut,
  useSportGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalizeEveryWord } from "@utils/capitalize";
import { useSnackbar } from "notistack";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

export const PositionEdit = () => {
  const navigate = useNavigate();
  const { positionId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [parentOptions, setParentOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const {
    data: position,
    isFetching: isPositionFetching,
    error: error
  } = useAdminPositionPositionIdGet(positionId as string);
  useEffect(() => {
    if (error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
  }, [error]);
  const { data: sports, isFetching: isSportsFetching } = useSportGet({
    pageSize: 1000
  });
  const sportOptions = useMemo(
    () =>
      sports?.data?.sports?.map((sport) => ({
        label: sport.name as string,
        value: sport.sportId as string
      })) || [],
    [sports]
  );

  useEffect(() => {
    if (!position?.data?.sportId) {
      return;
    }

    adminPositionGet({
      sportId: position?.data?.sportId,
      pageSize: 1000
    }).then((parentOptions) => {
      setParentOptions([
        ...(parentOptions?.data?.positions?.map((position) => ({
          label: position.name as string,
          value: position.positionId as string
        })) || [])
      ]);
    });
  }, [position]);

  const loadParentOptions = async (inputValue) => {
    const data = await adminPositionGet({
      sportId: inputValue as string
    });

    setParentOptions([
      ...(data?.data?.positions?.map((position) => ({
        label: position.name as string,
        value: position.positionId as string
      })) || [])
    ]);
  };

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
    setValue,
    reset
  } = useForm({
    mode: "onTouched"
  });

  useEffect(
    () =>
      reset(
        {
          name: position?.data?.name,
          abbreviation: position?.data?.abbreviation,
          sportId: position?.data?.sportId,
          parentId: position?.data?.parentId
        },
        {
          keepDirtyValues: false
        }
      ),
    [position]
  );

  const { mutate: save, isLoading: isSaving } = useAdminPositionPositionIdPut();
  const saveHandler = (formValues) => {
    const values = { ...formValues };
    if (!values.parentId) {
      delete values.parentId;
    }

    save(
      {
        positionId: positionId as string,
        data: values
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Position saved successfully!", {
            variant: "success"
          });
          navigate(`/positions/${positionId}`);
        },
        onError: () => {
          enqueueSnackbar("Failed to save position!", { variant: "error" });
        }
      }
    );
  };

  return (
    <Container>
      <Toolbar title="Edit Position" />
      <Form>
        <Loader isLoading={isSportsFetching || isPositionFetching}>
          <Grid container spacing={2}>
            <Grid xs={12} md={6} data-testid="POSITION_EDIT_NAME">
              <FormInput
                control={control}
                name="name"
                type="text"
                label="Name"
                required={true}
                onChange={(e) => {
                  setValue(
                    "name",
                    capitalizeEveryWord(
                      (e as ChangeEvent<HTMLInputElement>).target.value
                    )
                  );
                }}
                rules={{
                  required: "Name is required"
                }}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="POSITION_EDIT_ABBR">
              <FormInput
                control={control}
                name="abbreviation"
                type="text"
                label="Abbreviation"
                required={true}
                rules={{
                  required: "Abbreviation is required",
                  maxLength: {
                    value: 6,
                    message: "Abbreviation must be 6 characters or less"
                  }
                }}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="POSITION_EDIT_SPORT">
              <FormSelect
                control={control}
                name="sportId"
                label="Sport"
                required={true}
                options={sportOptions}
                onChange={(e) => {
                  setValue("parentId", "");
                  loadParentOptions(e.target.value);
                }}
                rules={{
                  required: "Sport is required"
                }}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="POSITION_EDIT_PARENT">
              <FormSelect
                control={control}
                name="parentId"
                label="Parent Position"
                required={false}
                options={parentOptions}
              />
            </Grid>
          </Grid>
        </Loader>
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler)}
        isDisabled={!isValid || isSaving || !isDirty}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/positions")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
