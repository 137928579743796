import {
  BannedUserIcon,
  PendingUserIcon,
  ActiveUserIcon,
  ParentAcceptIcon,
  ParentAndKidAcceptIcon
} from "@components/Icons";
import { GridColDef } from "@mui/x-data-grid";
import { getCountries } from "@services/Network";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import formatFullName from "@utils/formatFullName";
import React from "react";
import { capitalize } from "@utils/capitalize";

import { RenderTableView } from "@components/RenderTableView";
import { useRecoilState } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { ModelPerson } from "@sportsgravyengineering/sg-api-react-sdk";
export const RelatedUsersView = ({
  countries,
  guardians,
  relatedChildren,
  viewDetailsTab
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  countries: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  guardians: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  relatedChildren: any[];
  viewDetailsTab: () => void;
}) => {
  const [organizationId] = useRecoilState(organizationAtom);
  const navigate = useNavigate();
  // const countries = getCountries();
  const countryOptions = useMemo(() => {
    if (countries) {
      return countries.map((country) => ({
        countryId: country.countryId,
        name: country.name
      }));
    }
    return getCountries();
  }, [countries]);

  const renderLocation = (addressPrimary) => {
    let country = addressPrimary.country;
    country =
      countryOptions.data?.find((c) => c.countryId === country)?.name ||
      country;
    return (
      <>
        {capitalize(addressPrimary.locality) +
          ", " +
          addressPrimary.province.toUpperCase()}
        <br></br>
        {country}
      </>
    );
  };
  const fillLocation = () => {
    return [
      {
        headerName: "Location",
        field: "state",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => {
          if (guardians.length && params.row.guardian.addressPrimary) {
            return renderLocation(params.row.guardian.addressPrimary);
          } else if (relatedChildren.length && params.row.person.addressPrimary)
            return renderLocation(params.row.person.addressPrimary);
          else return <></>;
        }
      }
    ];
  };
  const isOrgUserBanned = (user: ModelPerson) => {
    const orgRolesBanned = user.user?.roleAliasBans?.filter(
      (role) => role.organizationId === organizationId
    );
    return orgRolesBanned && orgRolesBanned.length === 5;
  };
  const renderStatus = (person, type) => {
    if (type === "KID")
      if (!person) return <ParentAcceptIcon />;
      else return <ParentAndKidAcceptIcon />;
    if (person.user && organizationId && isOrgUserBanned(person))
      return <BannedUserIcon />;
    if (person.user && person.user.status === "ACCOUNT_LOCKED") {
      return <BannedUserIcon />;
    } else if (person.status === "PRIMARY") return <ActiveUserIcon />;
    else return <PendingUserIcon />;
  };
  const USER_COLUMNS: GridColDef[] = [
    {
      headerName: "Name",
      field: "name",
      minWidth: 150,
      flex: 1,
      cellClassName: "super-app-theme--cell",
      valueGetter: ({ row }) =>
        guardians.length
          ? formatFullName(row.guardian)
          : formatFullName(row.person)
    },
    {
      headerName: "Relationship",
      field: "relationship",
      minWidth: 100,
      flex: 1,
      sortable: false,
      valueGetter: () => (guardians.length ? "Parent / Guardian" : "Child")
    },
    {
      headerName: "Email",
      field: "email",
      minWidth: 100,
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) =>
        guardians.length
          ? row.guardian.email
            ? row.guardian.email
            : ""
          : row.person
            ? row.person.email
            : ""
    },
    ...fillLocation(),
    {
      headerName: "Status",
      field: "status",
      minWidth: 30,
      flex: 0.5,
      renderCell: (params) => {
        if (guardians.length > 0)
          return renderStatus(params.row.guardian, "GUARDIAN");
        else return renderStatus(params.row.person, "KID");
      }
    }
  ];

  const onView = (user) => {
    console.log(user);
    viewDetailsTab();
    navigate(`/users/${guardians.length ? user.guardianId : user.personId}`);
  };
  return (
    <>
      <RenderTableView
        sx={{
          "& .disabled-row--BANNED": {
            color: "#ABAFB5",
            fontWeight: "400"
          }
        }}
        title="Related Users"
        hideToolbar
        rows={guardians.length ? guardians : relatedChildren}
        columns={USER_COLUMNS}
        onView={onView}
        sortingMode="client"
        hideFilter
        getRowId={(row) =>
          guardians?.length ? row?.guardianId : row?.personId
        }
        searchable={false}
        getRowClassName={(params) =>
          (organizationId && params.row.user && isOrgUserBanned(params.row)) ||
          (params.row.user && params.row.user.status === "ACCOUNT_LOCKED")
            ? `disabled-row--BANNED`
            : ``
        }
      />
    </>
  );
};
