import { Form } from "@components/crud/Form";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { FormInput } from "@components/FormInput";
import { useForm } from "react-hook-form";
import { Button } from "@components/Button";
import { FormSelect } from "@components/FormSelect";
import { FormCheckbox } from "@components/FormCheckbox";
import { useState } from "react";
import { Typography } from "@mui/material";
import {
  ModelPerson,
  useAdminUserPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { SearchAddPerson } from "../../../components/SearchAddPerson";
import { useRecoilState } from "recoil";
import { organizationAtom } from "../../../recoil/auth";
import { enqueueSnackbar } from "notistack";
import { isValidEmail } from "@utils/isValidEmail";
import { capitalizeEveryWord } from "@utils/capitalize";

export const UserOverrideForm = (props: {
  setShowModal: (arg0: boolean) => void;
  selectedPerson?: ModelPerson;
  masterList: string[];
  setMasterList: (arg0: string[]) => void;
  userOptions: ModelPerson[];
  setUserOptions: (arg0: ModelPerson[]) => void;
  organizationId?: string;
  roleId: string;
  parentRoleId?: string;
  sportId?: string;
}) => {
  const [disabled] = useState(props.selectedPerson?.personId ? true : false);
  const { control, setValue, handleSubmit, formState } = useForm({
    mode: "onTouched",
    defaultValues: {
      firstName: props.selectedPerson?.firstName || "",
      middleName: props.selectedPerson?.middleName || "",
      lastName: props.selectedPerson?.lastName || "",
      suffix: props.selectedPerson?.suffix || "",
      email: props.selectedPerson?.emailPrimary?.email || "",
      isMinor: props.selectedPerson?.guardians?.length || 0 > 0 ? true : false,
      guardianFirstName:
        props.selectedPerson?.guardians?.[0]?.guardian?.firstName || "",
      guardianLastName:
        props.selectedPerson?.guardians?.[0]?.guardian?.lastName || "",
      guardianEmail:
        props.selectedPerson?.guardians?.[0]?.guardian?.emailPrimary?.email ||
        "",
      parentId: props.selectedPerson?.guardians?.[0]?.guardian?.personId || ""
    }
  });
  const [isMinor, setIsMinor] = useState(
    props.selectedPerson?.guardians?.length || 0 > 0 || false
  );
  const [newParent, setNewParent] = useState(false);
  const [existingGuardianSelected, setExistingGuardianSelected] =
    useState(false);

  const { mutate: invite, isLoading } = useAdminUserPost();
  const [organizationId] = useRecoilState(organizationAtom);
  const saveHandler = (formValues) => {
    if (props.selectedPerson?.personId) {
      const newMasterList = [...props.masterList];
      newMasterList.push(props.selectedPerson?.personId);
      const newUserOptions = [...props.userOptions];
      //add only if selected person is not already present in the user options
      if (
        newUserOptions.findIndex(
          (u) => u.personId === props.selectedPerson?.personId
        ) === -1
      )
        newUserOptions.push(props.selectedPerson);
      props.setUserOptions(newUserOptions);
      props.setMasterList(newMasterList);
      props.setShowModal(false);
      return;
    }
    invite(
      {
        data: {
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          email: formValues.email,
          middleName: formValues.middleName,
          suffix: formValues.suffix,
          isMinor: formValues.isMinor,
          organizationId: organizationId,
          parent: {
            firstName: formValues.guardianFirstName,
            lastName: formValues.guardianLastName,
            email: formValues.guardianEmail,
            roles: [
              {
                roleId: props.parentRoleId
              }
            ]
          },
          parentId: formValues.parentId,
          roles: [
            {
              roleId: props.roleId
            }
          ],
          // @ts-expect-error
          skipNotification: true,
          sportIds: props.sportId ? [props.sportId] : []
        }
      },
      {
        onSuccess: (data) => {
          const newMasterList = [...props.masterList];
          newMasterList.push(data.data.personId!);
          props.setMasterList(newMasterList);
          const newUserOptions = [...props.userOptions];
          newUserOptions.push(data.data);
          props.setUserOptions(newUserOptions);
          props.setShowModal(false);
        },
        onError: (error) => {
          console.error(error);
          enqueueSnackbar("Error inviting user. Please try agin", {
            variant: "error"
          });
        }
      }
    );
  };

  return (
    <Form>
      <Grid container spacing={3} data-testid="USER_OVERRIDE_FORM">
        <Grid xs={12} md={3} data-testid="USER_OVERRIDE_FIRST_NAME">
          <FormInput
            control={control}
            name="firstName"
            type="text"
            label="First Name"
            required={true}
            disabled={disabled}
            onChange={(e) => {
              setValue("firstName", capitalizeEveryWord(e.target.value));
            }}
            rules={{
              required: "First name is required"
            }}
            TextProps={{
              inputProps: { style: { textTransform: "capitalize" } }
            }}
          />
        </Grid>
        <Grid xs={12} md={3} data-testid="USER_OVERRIDE_MIDDLE_NAME">
          <FormInput
            control={control}
            name="middleName"
            type="text"
            label="Middle Name"
            required={false}
            disabled={disabled}
            TextProps={{
              inputProps: { style: { textTransform: "capitalize" } }
            }}
          />
        </Grid>
        <Grid xs={12} md={3} data-testid="USER_OVERRIDE_LAST_NAME">
          <FormInput
            control={control}
            name="lastName"
            type="text"
            label="Last Name"
            required={true}
            disabled={disabled}
            onChange={(e) => {
              setValue("lastName", capitalizeEveryWord(e.target.value));
            }}
            rules={{
              required: "Last name is required"
            }}
            TextProps={{
              inputProps: { style: { textTransform: "capitalize" } }
            }}
          />
        </Grid>
        <Grid xs={12} md={3} data-testid="USER_OVERRIDE_SUFFIX">
          <FormSelect
            control={control}
            name="suffix"
            label="Suffix"
            disabled={disabled}
            isLoading={false}
            options={[
              { value: "Jr", label: "Jr" },
              { value: "Sr", label: "Sr" }
            ]}
          />
        </Grid>
        <Grid xs={12} md={6} data-testid="USER_OVERRIDE_EMAIL">
          <FormInput
            control={control}
            name="email"
            type="text"
            label="Email"
            required={true}
            disabled={disabled}
            rules={{
              required: "Email is required",
              validate: {
                validEmail: (email: string) =>
                  isValidEmail(email) || "Please enter a valid email address"
              }
            }}
          />
        </Grid>
        <Grid xs={12} md={6} />
        <Grid xs={12} md={6} data-testid="USER_OVERRIDE_IS_MINOR">
          <FormCheckbox
            name="isMinor"
            label="This person is below 18 years of age"
            control={control}
            disabled={disabled}
            onChange={(e) => {
              setIsMinor(e.target.checked);
              setNewParent(false);
            }}
          />
        </Grid>
        {isMinor && (
          <Grid container xs={12} md={12}>
            <Grid>
              <Typography
                variant="permissionNames"
                sx={{
                  color: "#00000",
                  fontSize: "12px",
                  font: "inter",
                  lineHeight: "14.52px",
                  letterSpacing: "10%",
                  fontWeight: 400
                }}
              >
                PARENT GUARDIAN DETAILS
              </Typography>
            </Grid>
          </Grid>
        )}

        {isMinor && !newParent && !existingGuardianSelected && !disabled && (
          <SearchAddPerson
            personSelected={(person, personSelected, newPersonSelected) => {
              setNewParent(newPersonSelected);
              setExistingGuardianSelected(personSelected);
              setValue("guardianFirstName", person.firstName);
              setValue("guardianLastName", person.lastName);
              setValue("guardianEmail", person.emailPrimary?.email);
              setValue("parentId", person.personId);
            }}
            organizationId={props.organizationId}
          />
        )}
        {(isMinor && existingGuardianSelected) ||
        (isMinor && disabled) ||
        (isMinor && newParent) ? (
          <>
            <Grid xs={12} md={6} data-testid="USER_OVERRIDE_PARENT_FIRST_NAME">
              <FormInput
                control={control}
                name="guardianFirstName"
                type="text"
                label="First Name"
                required={true}
                disabled={disabled || existingGuardianSelected}
                rules={{
                  required: "First name is required"
                }}
                onChange={(e) => {
                  setValue(
                    "guardianFirstName",
                    capitalizeEveryWord(e.target.value)
                  );
                }}
                TextProps={{
                  inputProps: { style: { textTransform: "capitalize" } }
                }}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <FormInput
                control={control}
                name="guardianLastName"
                type="text"
                label="Last Name"
                required={true}
                disabled={disabled || existingGuardianSelected}
                rules={{
                  required: "Last name is required"
                }}
                onChange={(e) => {
                  setValue(
                    "guardianLastName",
                    capitalizeEveryWord(e.target.value)
                  );
                }}
                TextProps={{
                  inputProps: { style: { textTransform: "capitalize" } }
                }}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <FormInput
                control={control}
                name="guardianEmail"
                type="text"
                label="Email"
                required={true}
                disabled={disabled || existingGuardianSelected}
                rules={{
                  required: "Email is required",
                  validate: {
                    validEmail: (email: string) =>
                      isValidEmail(email) ||
                      "Please enter a valid email address"
                  }
                }}
              />
            </Grid>
            <Grid xs={12} md={6} />
          </>
        ) : null}

        <Grid
          xs={12}
          md={12}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"flex-end"}
          gap={2}
          alignItems={"center"}
          data-testid="USER_OVERRIDE_BUTTONS"
        >
          <Button
            variant="admin-secondary"
            type="button"
            onClick={() => {
              props.setShowModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="admin-primary"
            type="button"
            disabled={Boolean(
              !formState.isValid ||
                (isMinor &&
                  !disabled &&
                  !existingGuardianSelected &&
                  !newParent)
            )}
            onClick={handleSubmit(saveHandler)}
            isLoading={isLoading}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};
