/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormCheckbox } from "@components/FormCheckbox";
import { FormInput } from "@components/FormInput";
import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography, styled, IconButton } from "@mui/material";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { Control, UseFormTrigger } from "react-hook-form";
import { FormSelect } from "@components/FormSelect";
import {
  ModelAccount,
  ModelCountry,
  ModelPerson,
  useAdminUserCrmGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useContext, useEffect, useMemo, useState } from "react";
import { CRM_ACCOUNT_CATEGORIES, CRM_ACCOUNT_TYPES } from "@utils/constants";
import { LoadingSpinner } from "@components/LoadingSpinner";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import EmailIcon from "@mui/icons-material/Email";
import LaunchIcon from "@mui/icons-material/Launch";
import CallIcon from "@mui/icons-material/Call";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { createCRMActivity, getSportsByCountry } from "@services/Network";
import { RelatedLink, RelatedLinks } from "@pages/crm/components/ReleatedLinks";
import { Add } from "@mui/icons-material";
import { SearchAddAccount } from "@pages/crm/components/SearchAddAccount";
import { useNavigate, useParams } from "react-router-dom";
import formatFullName from "@utils/formatFullName";
import { enqueueSnackbar } from "notistack";
import { CallEmailTemplateContext } from "@templates/CallEmailTemplate";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { useRecoilValue } from "recoil";
import { organizationAtom, profileAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";

const StyledHeader = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #000000;
  opacity: 0.7;
  text-transform: uppercase;
`;

const libraries = ["places"] as "places"[];
const autocompleteService = { current: null } as any;
const autocompleteService1 = { current: null } as any;
const autocompleteService2 = { current: null } as any;

const websiteClick = (website) => {
  if (website.includes("http://") || website.includes("https://"))
    window.open(website, "_blank");
  else window.open("https://" + website, "_blank");
};

export const AccountDetailsForm = ({
  control,
  isBillingSame,
  isShippingSame,
  setIsBillingSame,
  setIsShippingSame,
  selectAddressSuggestion,
  country,
  relatedLinks,
  setRelatedLinks,
  mode,
  setExistingAccountSelected,
  parentId,
  setParentId,
  trigger,
  accountOrg
}: {
  control: Control<ModelAccount, any>;
  isBillingSame?: boolean;
  isShippingSame?: boolean;
  setIsBillingSame?: (val: boolean) => void;
  setIsShippingSame?: (val: boolean) => void;
  selectAddressSuggestion: any;
  country: ModelCountry | undefined;
  relatedLinks?: RelatedLink[];
  setRelatedLinks;
  mode: "add" | "edit" | "view";
  setExistingAccountSelected?: (val: object | undefined) => void;
  parentId: string | null;
  setParentId;
  trigger?: UseFormTrigger<any>;
  accountOrg?: string;
}) => {
  dayjs.extend(timezone);
  const user = useRecoilValue(profileAtom);

  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(mode == "view" ? true : false);
  const { data: sports } = getSportsByCountry(country?.countryId);
  const [officeAddessSelected, setOfficeAddessSelected] = useState(true);
  const [billingAddessSelected, setBillingAddessSelected] = useState(true);
  const [shippingAddessSelected, setShippingAddessSelected] = useState(true);

  const [permissions, setPermissions] = useState<{
    bdrEdit: boolean;
    aeEdit: boolean;
    csmEdit: boolean;
  }>({
    bdrEdit: true,
    aeEdit: true,
    csmEdit: true
  });

  const [addNewAccount, setAddNewAccount] = useState(false);
  const mutation = createCRMActivity();
  const { accountId } = useParams();
  const {
    setCallerDetails,
    connectToCall,
    setCallAccepted,
    callInstance,
    setEmailTo
  } = useContext(CallEmailTemplateContext);
  const organizationId = useRecoilValue(organizationAtom);
  const { data: aeOwnerOptions, isLoading: isLoadingAeOwnerOptions } =
    organizationId
      ? { data: null, isLoading: false }
      : useAdminUserCrmGet({
          type: "aeOwner"
        });

  const [aeOwners, setAeOwners] = useState<{ label: string; value: string }[]>(
    []
  );

  useEffect(() => {
    if (mode === "edit") setAddNewAccount(true);
  }, [mode]);
  useEffect(() => {
    if (aeOwnerOptions?.data) {
      setAeOwners(
        aeOwnerOptions.data.map((o) => ({
          label: formatFullName(o as ModelPerson),
          value: o.userId!
        }))
      );
    }
  }, [aeOwnerOptions]);

  const { data: bdrOwnerOptions, isLoading: isLoadingBdrOwnerOptions } =
    organizationId
      ? { data: null, isLoading: false }
      : useAdminUserCrmGet({
          type: "bdrOwner"
        });

  const [bdrOwners, setBdrOwners] = useState<
    { label: string; value: string }[]
  >([]);
  useEffect(() => {
    if (bdrOwnerOptions?.data) {
      setBdrOwners(
        bdrOwnerOptions.data.map((o) => ({
          label: formatFullName(o as ModelPerson),
          value: o.userId!
        }))
      );
    }
  }, [bdrOwnerOptions]);

  const { data: csmOwnerOptions, isLoading: isLoadingCsmOwnerOptions } =
    organizationId
      ? { data: null, isLoading: false }
      : useAdminUserCrmGet({
          type: "csmOwner"
        });

  const [csmOwners, setCsmOwners] = useState<
    { label: string; value: string }[]
  >([]);
  useEffect(() => {
    if (csmOwnerOptions?.data) {
      setCsmOwners(
        csmOwnerOptions.data.map((o) => ({
          label: formatFullName(o as ModelPerson),
          value: o.userId!
        }))
      );
    }
  }, [csmOwnerOptions]);

  const { data: orgOwners, isLoading: isLoadingOrgOwners } = organizationId
    ? useAdminUserCrmGet({
        organizationId: organizationId
      })
    : { data: null, isLoading: false };

  const [orgAdminOwners, setOrgAdminOwners] = useState<
    { label: string; value: string }[]
  >([]);
  useEffect(() => {
    if (organizationId && orgOwners?.data) {
      setOrgAdminOwners(
        orgOwners.data.map((o) => ({
          label: formatFullName(o as ModelPerson),
          value: o.userId!
        }))
      );
    }
  }, [orgOwners]);

  const sportsOptions = useMemo(() => {
    return sports?.map((sport) => ({
      label: !sport.name ? (sport.sport?.name as string) : sport.name,
      value: sport.sportId as string
    }));
  }, [sports]);

  useEffect(() => {
    if (mode === "edit") {
      const checkPermission = async (permissionId, permission) => {
        const res = await hasPermission(
          organizationId ? "ORGANIZATION" : "SYSTEM",
          organizationId || "*",
          permissionId as string,
          permission as string
        );
        return res;
      };
      const fetchPermissions = async () => {
        const edit = await checkPermission("crm.accounts-owner-edit", "ON");
        setPermissions({
          bdrEdit: edit,
          aeEdit: edit,
          csmEdit: edit
        });
      };
      fetchPermissions();
    }
  }, []);

  useEffect(() => {
    if (trigger) {
      if (officeAddessSelected) {
        trigger("officeAddress");
      }
      if (billingAddessSelected) {
        trigger("billingAddress");
      }
      if (shippingAddessSelected) {
        trigger("shippingAddress");
      }
    }
  }, [officeAddessSelected, billingAddessSelected, shippingAddessSelected]);

  return (
    <Container>
      <Loader
        isLoading={
          isLoadingAeOwnerOptions ||
          isLoadingBdrOwnerOptions ||
          isLoadingCsmOwnerOptions ||
          isLoadingOrgOwners
        }
      >
        <Form>
          <Grid container spacing={3}>
            {accountOrg && disabled && (
              <Grid xs={6} md={12}>
                <FormInput
                  name="organizationName"
                  label="Account Linked to Sports Organization"
                  type="text"
                  control={control}
                  required
                  disabled
                />
              </Grid>
            )}
            <Grid xs={6}>
              {(addNewAccount || disabled) && (
                <FormInput
                  name="name"
                  label="Name"
                  type="text"
                  control={control}
                  required
                  rules={{ required: "Name is required" }}
                  disabled={disabled}
                  capitalizeWords
                />
              )}
              {!addNewAccount && !disabled && (
                <SearchAddAccount
                  accountSelected={(
                    selectedAccount,
                    isAccountSelected,
                    isNewAccountSelected
                  ) => {
                    if (isAccountSelected) {
                      if (mode === "add")
                        navigate(
                          `/crm/accounts/${selectedAccount.accountId}?tab=Account+Details`
                        );
                      if (mode === "edit") {
                        setDisabled(true);
                        if (setExistingAccountSelected)
                          setExistingAccountSelected(selectedAccount);
                      }
                    }
                    setAddNewAccount(isNewAccountSelected);
                  }}
                  showAddAccountOption={true}
                  label="Name"
                  parentId={parentId}
                  required={true}
                  disabled={disabled}
                  organizationId={organizationId}
                />
              )}
            </Grid>
            <Grid xs={6}>
              <SearchAddAccount
                accountSelected={(selectedAccount) => {
                  if (selectedAccount?.accountId)
                    setParentId(selectedAccount.accountId);
                }}
                label="Parent"
                required={false}
                disabled={disabled}
                onClear={() => {
                  setParentId(null);
                }}
                searchValue={control._formValues.parent || undefined}
                organizationId={organizationId}
              />
            </Grid>
            <Grid xs={6}>
              <FormSelect
                control={control}
                name="category"
                type="text"
                label="Industry"
                disabled={disabled || (mode === "add" && !addNewAccount)}
                required={true}
                options={CRM_ACCOUNT_CATEGORIES}
                rules={{
                  required: "Industry is required"
                }}
              />
            </Grid>

            {!organizationId ? (
              <>
                <Grid xs={6}>
                  <FormSelect
                    control={control}
                    name="type"
                    type="text"
                    label="Type"
                    disabled={disabled || (mode === "add" && !addNewAccount)}
                    required={true}
                    options={CRM_ACCOUNT_TYPES}
                    rules={{
                      required: "Type  is required"
                    }}
                  />
                </Grid>
                <Grid xs={4}>
                  <FormSelect
                    control={control}
                    name="bdrOwner"
                    type="text"
                    label="BDR Owner"
                    disabled={
                      disabled ||
                      !permissions.bdrEdit ||
                      (mode === "add" && !addNewAccount)
                    }
                    required={true}
                    options={bdrOwners}
                    rules={{
                      required: "BDR Owner is required"
                    }}
                  />
                </Grid>
                <Grid xs={4}>
                  <FormSelect
                    control={control}
                    name="aeOwner"
                    type="text"
                    label="AE Owner"
                    disabled={
                      disabled ||
                      !permissions.aeEdit ||
                      (mode === "add" && !addNewAccount)
                    }
                    required={true}
                    options={aeOwners}
                    rules={{
                      required: "AE Owner is required"
                    }}
                  />
                </Grid>
                <Grid xs={4}>
                  <FormSelect
                    control={control}
                    name="csmOwner"
                    type="text"
                    label="CSM Owner"
                    disabled={
                      disabled ||
                      !permissions.csmEdit ||
                      (mode === "add" && !addNewAccount)
                    }
                    required={true}
                    options={csmOwners}
                    rules={{
                      required: "CSM Owner is required"
                    }}
                  />
                </Grid>
              </>
            ) : (
              <Grid xs={6}>
                <FormSelect
                  control={control}
                  name="owner"
                  type="text"
                  label="Account Owner"
                  disabled={
                    disabled ||
                    (mode === "add" && !addNewAccount) ||
                    !permissions.bdrEdit
                  }
                  required={true}
                  options={orgAdminOwners}
                  rules={{
                    required: "Account Owner is required"
                  }}
                />
              </Grid>
            )}
            <Grid xs={12}>
              <StyledHeader>Address</StyledHeader>
              <HeaderUnderLine width="100%" />
            </Grid>
            <LoadScript
              key="address"
              googleMapsApiKey={import.meta.env.VITE_APP_MAP_API_KEY as string}
              libraries={libraries}
              loadingElement={<LoadingSpinner />}
            >
              <Grid xs={12}>
                <Autocomplete
                  key={"officeAddress"}
                  className="address-autocomplete"
                  onLoad={(autocomplete) => {
                    autocompleteService.current = autocomplete;
                  }}
                  onPlaceChanged={() => {
                    if (autocompleteService.current) {
                      const place = autocompleteService.current.getPlace();
                      selectAddressSuggestion(place, "officeAddress");
                      setOfficeAddessSelected(true);
                    }
                  }}
                >
                  <FormInput
                    name="officeAddress"
                    control={control}
                    rules={{
                      required: "Office Address is required",
                      validate: () => {
                        return !officeAddessSelected
                          ? "The address entered is invalid, please make a recommended selection"
                          : undefined;
                      }
                    }}
                    label="Office Address"
                    required
                    type="text"
                    disabled={disabled || (mode === "add" && !addNewAccount)}
                    onChange={() => {
                      setOfficeAddessSelected(false);
                    }}
                  />
                </Autocomplete>
              </Grid>
              <Grid xs={12}>
                <FormCheckbox
                  control={control}
                  name="isBillingSameAs"
                  label="Billing Address is the same as Office Address"
                  disabled={disabled || (mode === "add" && !addNewAccount)}
                  onChange={(e) => {
                    if (setIsBillingSame) {
                      setIsBillingSame(e.target.checked);
                    }
                  }}
                />
              </Grid>
              {!isBillingSame && (
                <>
                  <Grid xs={12}>
                    <Autocomplete
                      key={"billingAddress"}
                      className="address-autocomplete"
                      onLoad={(autocomplete) => {
                        autocompleteService1.current = autocomplete;
                      }}
                      onPlaceChanged={() => {
                        if (autocompleteService1.current) {
                          const place = autocompleteService1.current.getPlace();
                          selectAddressSuggestion(place, "billingAddress");
                          setBillingAddessSelected(true);
                        }
                      }}
                    >
                      <FormInput
                        name="billingAddress"
                        control={control}
                        rules={{
                          required: "Billing Address is required",
                          validate: () => {
                            return !billingAddessSelected
                              ? "The address entered is invalid, please make a recommended selection"
                              : undefined;
                          }
                        }}
                        label="Billing Address"
                        required
                        type="text"
                        disabled={
                          disabled || (mode === "add" && !addNewAccount)
                        }
                        onChange={() => {
                          setBillingAddessSelected(false);
                        }}
                      />
                    </Autocomplete>
                  </Grid>
                </>
              )}
              <Grid xs={3} sx={{ display: "flex" }}>
                <div style={{ minWidth: "280px" }}>
                  <FormCheckbox
                    control={control}
                    name="isShippingSameAs"
                    label="Shipping Address is the same as "
                    disabled={disabled || (mode === "add" && !addNewAccount)}
                    onChange={(e) => {
                      if (setIsShippingSame) {
                        setIsShippingSame(e.target.checked);
                      }
                    }}
                  />
                </div>
                <FormSelect
                  sx={{
                    height: "32px",
                    width: "150px",
                    marginTop: "5px"
                  }}
                  control={control}
                  disabled={disabled || (mode === "add" && !addNewAccount)}
                  name="shippingSameAs"
                  options={[
                    {
                      label: "Office Address",
                      value: "OFFICE"
                    },
                    {
                      label: "Billing Address",
                      value: "BILLING"
                    }
                  ]}
                />
              </Grid>
              {!isShippingSame && (
                <>
                  <Grid xs={12}>
                    <Autocomplete
                      key={"shippingAddress"}
                      className="address-autocomplete"
                      onLoad={(autocomplete) => {
                        autocompleteService2.current = autocomplete;
                      }}
                      onPlaceChanged={() => {
                        if (autocompleteService2.current) {
                          const place = autocompleteService2.current.getPlace();
                          selectAddressSuggestion(place, "shippingAddress");
                          setShippingAddessSelected(true);
                        }
                      }}
                    >
                      <FormInput
                        name="shippingAddress"
                        control={control}
                        rules={{
                          required: "Shipping Address is required",
                          validate: () => {
                            return !shippingAddessSelected
                              ? "The address entered is invalid, please make a recommended selection"
                              : undefined;
                          }
                        }}
                        label="Shipping Address"
                        required
                        type="text"
                        disabled={
                          disabled || (mode === "add" && !addNewAccount)
                        }
                        onChange={() => {
                          setShippingAddessSelected(false);
                        }}
                      />
                    </Autocomplete>
                  </Grid>
                </>
              )}
            </LoadScript>
            <Grid xs={12}>
              <StyledHeader>Contact</StyledHeader>
              <HeaderUnderLine width="100%" marginTop="9px" />
            </Grid>
            <Grid xs={6}>
              <FormInput
                control={control}
                label="Phone"
                name="phone"
                type="tel"
                disabled={disabled || (mode === "add" && !addNewAccount)}
                country={country}
                InputProps={{
                  ...(!organizationId &&
                    disabled && {
                      endAdornment: (
                        <Loader align="flex-end" isLoading={mutation.isLoading}>
                          <IconButton
                            onClick={async () => {
                              if (callInstance) {
                                enqueueSnackbar("You are already on a call!", {
                                  variant: "error"
                                });
                                return;
                              }
                              mutation.mutate(
                                {
                                  data: {
                                    accountId: accountId,
                                    type: "CALL",
                                    relatesTo: "ACCOUNT",
                                    date: new Date(),
                                    timezone: dayjs.tz.guess(),
                                    direction: "outbound",
                                    status: "LIVE",
                                    outcome: "CONNECTED_WITH_CONTACT",
                                    internalParticipants: [
                                      user?.userId as string
                                    ],
                                    externalParticipants: [
                                      {
                                        phone: control._formValues?.phone || ""
                                      }
                                    ]
                                  }
                                },
                                {
                                  onSuccess: (data) => {
                                    setCallerDetails({
                                      activityId:
                                        data?.data?.activity?.activityId,
                                      accountId: accountId,
                                      accountName: control._formValues?.name,
                                      callDirection: "outbound",
                                      location:
                                        control._formValues?.officeAddress,
                                      phone: control._formValues?.phone,
                                      isConnected: accountId ? true : false,
                                      showContactDetails: false,
                                      contact: {
                                        contactName: control._formValues?.name,
                                        contactPhone:
                                          control._formValues?.phone,
                                        isPrimary: false
                                      }
                                    });
                                    connectToCall(
                                      control._formValues?.phone as string,
                                      data?.data?.activity?.activityId
                                    );
                                    setCallAccepted(true);
                                  }
                                }
                              );
                            }}
                          >
                            <CallIcon style={{ color: "#007AFF" }} />
                          </IconButton>
                        </Loader>
                      )
                    })
                }}
              />
            </Grid>
            <Grid xs={6}>
              <FormInput
                control={control}
                label="FAX"
                name="fax"
                type="tel"
                disabled={disabled || (mode === "add" && !addNewAccount)}
                country={country}
              />
            </Grid>
            <Grid xs={6}>
              <FormInput
                control={control}
                label="Email"
                name="email"
                type="text"
                required
                disabled={disabled || (mode === "add" && !addNewAccount)}
                rules={{
                  required: "Email is required",
                  validate: (value) => {
                    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (!regex.test(value) && value && value != "") {
                      return "Invalid Email";
                    }
                  }
                }}
                InputProps={{
                  ...(!organizationId &&
                    disabled && {
                      endAdornment: (
                        <IconButton
                          onClick={() =>
                            setEmailTo({
                              to: control._formValues?.email,
                              name: control._formValues?.name,
                              accountId: accountId,
                              relatesTo: "ACCOUNT",
                              accountName: control._formValues?.name
                            })
                          }
                        >
                          <EmailIcon style={{ color: "#007AFF" }} />
                        </IconButton>
                      )
                    })
                }}
              />
            </Grid>
            <Grid xs={6}>
              <FormInput
                control={control}
                label="Website"
                name="website"
                type="text"
                disabled={disabled || (mode === "add" && !addNewAccount)}
                InputProps={{
                  ...(disabled && {
                    endAdornment: (
                      <IconButton
                        onClick={() =>
                          websiteClick(control._formValues?.website)
                        }
                      >
                        <LaunchIcon style={{ color: "#007AFF" }} />
                      </IconButton>
                    )
                  })
                }}
              />
            </Grid>
            {!organizationId && (
              <>
                <Grid xs={12}>
                  <StyledHeader>Additional Info</StyledHeader>
                  <HeaderUnderLine width="100%" />
                </Grid>
                <Grid xs={12}>
                  <FormMultiSelect
                    control={control}
                    label="Sports Offered"
                    name="sportsOffered"
                    required
                    rules={{
                      required: "Sports Offered is required"
                    }}
                    disabled={disabled || (mode === "add" && !addNewAccount)}
                    options={sportsOptions}
                  />
                </Grid>
                <Grid xs={6}>
                  <FormInput
                    control={control}
                    label="No. of Yearly Athlete Registrations"
                    name="noOfAtheletes"
                    required
                    type="number"
                    disabled={disabled || (mode === "add" && !addNewAccount)}
                    rules={{
                      required:
                        "No. of Yearly Athlete Registrations is required",
                      valueAsNumber: true,
                      validate: (value) => {
                        if (value && value < 0)
                          return "Enter a valid No. of Athletes";
                      }
                    }}
                  />
                </Grid>
              </>
            )}
            <Grid xs={organizationId ? 12 : 6}>
              <FormInput
                name="notes"
                label="Notes"
                type="text"
                control={control}
                disabled={disabled || (mode === "add" && !addNewAccount)}
              />
            </Grid>
            <Grid xs={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <StyledHeader>Related Links</StyledHeader>
                {mode != "view" && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "3px",
                      color: "#007AFF",
                      cursor: "pointer"
                    }}
                    onClick={() =>
                      setRelatedLinks([
                        ...(relatedLinks as RelatedLink[]),
                        {
                          url: "",
                          name: "",
                          otherName: ""
                        }
                      ])
                    }
                  >
                    <Add style={{ height: "18px", width: "18px" }} />
                    <Typography style={{ color: "#007AFF", fontWeight: 600 }}>
                      Add Link
                    </Typography>
                  </div>
                )}
              </div>
              <HeaderUnderLine width="100%" marginTop="9px" />
            </Grid>
            <RelatedLinks
              control={control}
              relatedLinks={relatedLinks || []}
              setRelatedLinks={setRelatedLinks}
              disabled={disabled || (mode === "add" && !addNewAccount)}
            />
          </Grid>
        </Form>
      </Loader>
    </Container>
  );
};
