import { FormCheckbox } from "@components/FormCheckbox";
import { FormInput } from "@components/FormInput";

import { FormSelect } from "@components/FormSelect";

import { Form } from "@components/crud/Form";
import { FormLabel, Grid, Typography, styled } from "@mui/material";
import { EmailReplyEditor } from "@pages/crm/email-module/EmailReplyEditor";
import {
  ModelMedia,
  useAdminDepartmentGet,
  useAdminJobTitleGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalizeEveryWord } from "@utils/capitalize";
import React, { useMemo, useState } from "react";
import { UseFormReturn } from "react-hook-form";
const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));
export const EmailTemplateForm = ({
  form,
  disabled,
  isActiveReadOnly,
  setIsDescValid,
  departmentId,
  setDepartmentId,
  files,
  setFiles
}: {
  form: UseFormReturn;
  disabled?: boolean;
  isActiveReadOnly?: boolean;
  setIsDescValid?;
  departmentId: string | undefined;
  setDepartmentId: (val: string) => void;
  files: { id: string; file: File | ModelMedia }[];
  setFiles: (files: { id: string; file: File | ModelMedia }[]) => void;
}) => {
  const { control, setValue, getValues } = form;
  const [descValid, setDescValid] = useState(true);
  const validateDescriptionArray = (value) => {
    if (value && value.length >= 2) {
      if (setIsDescValid) setIsDescValid(true);
      setDescValid(true);
    } else if (value && value.length == 1 && value[0].insert !== "\n") {
      if (setIsDescValid) setIsDescValid(true);
      setDescValid(true);
    } else {
      if (setIsDescValid) setIsDescValid(false);
      setDescValid(false);
    }
  };
  const { data: departments, isLoading: isLoadingDepartments } =
    useAdminDepartmentGet({
      pageSize: 100
    });
  const { data: jobTitles, isLoading: isLoadingJobTitles } =
    useAdminJobTitleGet({
      pageSize: 1000
    });

  const jobTitleOptions = useMemo(
    () =>
      jobTitles?.data?.jobTitles
        ?.filter((j) => j.departmentId === departmentId)
        .map((job) => ({
          label: job.name!,
          value: job.jobtitleId!
        })) || [],
    [departmentId, jobTitles]
  );

  return (
    <Form>
      <Grid container direction="column" spacing="20px">
        <Grid item xs={12} data-testid="email-template-name">
          <FormInput
            disabled={disabled}
            name="name"
            onChange={(e) => {
              setValue("name", capitalizeEveryWord(e.target.value));
            }}
            control={control}
            label="Name"
            required
            rules={{
              required: "Name is required"
            }}
            type="text"
          />
        </Grid>
        <Grid
          item
          container
          direction="row"
          spacing="24px"
          data-testid="jobTitle-isActive"
          style={{
            marginTop: "-45px"
          }}
        >
          <Grid item>
            <FormCheckbox
              name="isActive"
              control={control}
              label="Is Active"
              disabled={disabled || !!isActiveReadOnly}
            />
          </Grid>
        </Grid>

        <Grid item container direction="row" spacing="24px">
          <Grid item xs={6} data-testid="email-template-department">
            <FormSelect
              name="departmentId"
              disabled={disabled}
              control={control}
              isLoading={isLoadingDepartments}
              label="Department"
              options={
                departments?.data?.departments
                  ? departments.data.departments.map((department) => ({
                      label: department.name!,
                      value: department.departmentId!
                    }))
                  : []
              }
              required
              rules={{
                required: "Department is required"
              }}
              onChange={(e) => {
                setDepartmentId(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6} data-testid="email-template-jobTitle">
            <FormSelect
              name="jobTitleId"
              disabled={disabled || !departmentId}
              control={control}
              isLoading={isLoadingJobTitles}
              label="Job Title"
              options={jobTitleOptions}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormInput
            disabled={disabled}
            name="subject"
            control={control}
            label="Subject"
            required
            rules={{
              required: "Subject is required"
            }}
            type="text"
          />
        </Grid>
        <Grid
          item
          container
          direction="column"
          spacing="10px"
          minHeight="300px"
          marginBottom="30px"
          data-testid="email-template-body"
        >
          <Grid item>
            <StyledFormLabel required>
              <Typography variant="formLabel">Email Body</Typography>
            </StyledFormLabel>
          </Grid>
          <Grid item>
            {/* <Editor
              disabled={disabled}
              html={getValues().description ? getValues().description : null}
              onBlur={() => {
                validateDescriptionArray(getValues().description);
              }}
              onValueChange={(deltaOperations, hasMedia) => {
                if (getValues().description && deltaOperations) {
                  if (!arraysEqual(getValues().description, deltaOperations)) {
                    setValue("description", deltaOperations, {
                      shouldValidate: true,
                      shouldDirty: true
                    });
                    setValue("hasMedia", hasMedia);
                    validateDescriptionArray(deltaOperations);
                  }
                } else {
                  setValue("description", deltaOperations, {
                    shouldValidate: true,
                    shouldDirty: true
                  });
                  setValue("hasMedia", hasMedia);
                  validateDescriptionArray(deltaOperations);
                }
              }}
              style={{
                height: "300px"
              }}
            /> */}
            <EmailReplyEditor
              disabled={disabled}
              html={
                getValues().description ? getValues().description : undefined
              }
              onBlur={() => {
                validateDescriptionArray(getValues().description);
              }}
              onValueChange={(deltaOperations, hasMedia) => {
                setValue("description", deltaOperations, {
                  shouldValidate: true,
                  shouldDirty: true
                });
                setValue("hasMedia", hasMedia);

                validateDescriptionArray(deltaOperations);
              }}
              style={{
                height: "350px"
              }}
              files={files}
              setFiles={setFiles}
            />
          </Grid>
        </Grid>
        {!descValid && (
          <Grid item style={{ paddingTop: 0 }}>
            <Typography style={{ color: "#E82C2C" }} variant="body2">
              Email Body is required
            </Typography>
          </Grid>
        )}
      </Grid>
    </Form>
  );
};
