import { FormInput } from "@components/FormInput";
import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { FormSelect } from "@components/FormSelect";
import { PLATFORM_OPTIONS } from "@utils/constants";
import { Typography } from "@mui/material";
import {
  ModelPerson,
  useAdminDirectoryGet,
  useAdminQAFeatureTestcaseTestcaseIdGet,
  useAdminQAFeatureTestcaseTestcaseIdPut,
  useAdminReleaseAllGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";
import { useRecoilValue } from "recoil";
import { organizationAtom, selectedReleaseAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import formatFullName from "@utils/formatFullName";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { useSnackbar } from "notistack";
import { Footer } from "@components/crud/Footer";

export const QATestcaseEdit = () => {
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const { enqueueSnackbar } = useSnackbar();
  const selectedReleaseValue = useRecoilValue(selectedReleaseAtom);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramValue = searchParams.get("platform");
  const { testcaseId } = useParams();
  const [releaseId, setReleaseId] = useState(selectedReleaseValue);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [platforms, setPlatforms] = useState<string[]>([]);
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { isValid, isDirty }
  } = useForm({
    mode: "onTouched"
  });

  const { data: testcase, isLoading: testcaseLoading } =
    useAdminQAFeatureTestcaseTestcaseIdGet(testcaseId!, {
      releaseId: releaseId!
    });
  const { data: companyDirectory, isLoading: companyDirectoryLoading } =
    useAdminDirectoryGet();

  const { data: releasesData, isLoading: releaseDataLoading } =
    useAdminReleaseAllGet();

  const releaseOptions = useMemo(() => {
    if (releasesData && testcase) {
      return testcase.data.release
        .map((releaseId) => {
          const release = releasesData.data.releases?.find(
            (r) => r.releaseId === releaseId
          );
          if (release) {
            return {
              label: release.name,
              value: releaseId,
              status: release.releaseStatus
            };
          }
        })
        .filter(Boolean);
    }
    return [];
  }, [releasesData, testcase]);

  const testerOptions = useMemo(() => {
    if (companyDirectory) {
      return companyDirectory.data!.persons!.map((tester: ModelPerson) => {
        return {
          label: formatFullName(tester),
          value: tester.personId
        };
      });
    }
    return [];
  }, [companyDirectory]);
  useEffect(() => {
    if (organizationId) navigate("/not-found");
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const view = await checkPermission("tech.test-cases", "VIEW");
      if (!view) navigate("/not-found");
    };
    fetchPermissions();
  }, [organizationId]);

  useEffect(() => {
    if (testcase?.data) {
      setPlatforms([]);
      const tc = testcase.data;
      reset({
        selectedPlatform: paramValue,
        description: tc.testCase?.description,
        expectedResult: tc.testCase?.expectedResult,
        featureId: tc.feature?.feature?.name
      });
      if (tc.iosTesterId) {
        setPlatforms((prevPlatforms) => [...prevPlatforms, "ios"]);
        setValue("iosTesterId", tc.iosTesterId);
        setValue("ios", "ios");
      }
      if (tc.webTesterId) {
        setPlatforms((prevPlatforms) => [...prevPlatforms, "web"]);
        setValue("webTesterId", tc.webTesterId);
        setValue("web", "web");
      }
      if (tc.andTesterId) {
        setPlatforms((prevPlatforms) => [...prevPlatforms, "and"]);
        setValue("andTesterId", tc.andTesterId);
        setValue("and", "and");
      }
    }
  }, [testcase]);

  const { mutate: save, isLoading: isSaving } =
    useAdminQAFeatureTestcaseTestcaseIdPut();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    async (formValues) => {
      const values = {
        ...formValues
      };
      try {
        save(
          {
            testcaseId: testcaseId!,
            data: values,
            params: {
              releaseId: releaseId!
            }
          },
          {
            onSuccess: () => {
              enqueueSnackbar("Testcase edited successfully!", {
                variant: "success"
              });
              if (resetInsteadOfRoute) {
                reset();
              } else {
                navigate(`/qa-testcases/${testcaseId}`);
              }
            },
            onError: () => {
              enqueueSnackbar("Failed to edit testcase!", {
                variant: "error"
              });
            }
          }
        );
      } catch (error) {
        enqueueSnackbar("Failed to edit testcase!", {
          variant: "error"
        });
      }
    };

  return (
    <Container>
      <Toolbar
        title="Edit QA Test Case"
        showFilters={
          <Grid container flexDirection="row" alignItems="center">
            <Grid marginRight="10px">
              <Typography variant="gridToolbarFilterLabel">Release</Typography>
            </Grid>
            <Grid
              marginRight="10px"
              minWidth="85px"
              data-testid="releaseFilter"
            >
              <FormSelect
                name="release"
                options={releaseOptions}
                isReleaseSelect
                disabled
                value={releaseId}
                onChange={(e) => {
                  setReleaseId(e.target.value);
                }}
                optionStyle={{
                  color: "#3B6CF8"
                }}
                sx={{
                  minWidth: 85
                }}
              />
            </Grid>
          </Grid>
        }
      />
      <Loader
        isLoading={
          testcaseLoading || releaseDataLoading || companyDirectoryLoading
        }
      >
        <Form>
          <Grid data-testid="testcase-view-form" container spacing={3}>
            <Grid data-testid="testcase-description" xs={12}>
              <FormInput
                control={control}
                name="description"
                type="text"
                label="Test Case Description"
                disabled
                required={true}
                rules={{
                  required: "Test Case Description is required"
                }}
              />
            </Grid>
            <Grid data-testid="testcase-expectedResult" xs={12}>
              <FormInput
                control={control}
                name="expectedResult"
                type="text"
                multiline
                disabled
                rows={7}
                label="Expected Result"
                required={true}
                rules={{
                  required: "Expected Result is required"
                }}
              />
            </Grid>
            <Grid data-testid="testcase-featureId" xs={12}>
              <FormInput
                control={control}
                name="featureId"
                type="text"
                label="Feature"
                disabled
                required={true}
                rules={{
                  required: "Feature is required"
                }}
              />
            </Grid>
            {platforms.map((platform, index) => {
              return (
                <Grid container key={index} xs={12}>
                  <Grid data-testid={"platform_" + platform} xs={6}>
                    <FormSelect
                      options={PLATFORM_OPTIONS}
                      control={control}
                      name={platform}
                      disabled
                      label={index == 0 ? "Platforms" : ""}
                      required={true}
                    />
                  </Grid>
                  <Grid data-testid={`${platform}TesterId`} xs={6}>
                    <FormSelect
                      control={control}
                      options={testerOptions}
                      name={platform + "TesterId"}
                      label={index == 0 ? "Testers" : ""}
                      required={true}
                      rules={{
                        required: "Tester is required"
                      }}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Form>
        <Footer
          cancelBtnClick={() => setOpenCancelDialog(true)}
          saveBtnClick={handleSubmit(saveHandler(false))}
          isDisabled={!isValid || isSaving || !isDirty}
          isLoading={isSaving}
        />
        <ConfirmationDialog
          title="Are you sure you want to cancel?"
          body="All of your current changes will be lost."
          open={openCancelDialog}
          close={() => setOpenCancelDialog(false)}
          onCancel={() => setOpenCancelDialog(false)}
          onConfirm={() => {
            navigate("/qa-testcases");
          }}
          cancelBtnText="Cancel"
          confirmBtnText="Confirm"
        />
      </Loader>
    </Container>
  );
};
