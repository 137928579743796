import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import {
  lookupCountryGet,
  ModelOrganization,
  useAdminOrganizationOrganizationIdDelete,
  useAdminOrganizationOrganizationIdGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { OrganizationContactForm } from "./OrganizationContactForm";
import { OrganizationDetailsForm } from "./OrganizationDetailsForm";
import { OrganizationSportsForm } from "./OrganizationSportsForm";
import { organizationAtom } from "@recoil/auth";
import { useRecoilValue } from "recoil";
import { useSnackbar } from "notistack";
import { hasPermission } from "@services/Casbin";
import { useQuery } from "@tanstack/react-query";

export const OrganizationView = () => {
  const navigate = useNavigate();
  const { organizationId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const loggedInOrganizationId = useRecoilValue(organizationAtom);
  const [tab, setTab] = useState("Organization Details");
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [toDelete, setToDelete] = useState<ModelOrganization | null>(null);

  const onTabChange = (event: SyntheticEvent, value: unknown) => {
    setTab(value as string);
  };

  const [permissions, setPermissions] = useState({
    edit: false,
    delete: false
  });

  const { control, watch, setValue, trigger, reset } = useForm({
    mode: "onTouched",
    defaultValues: {
      name: "",
      abbreviation: "",
      type: "",
      offering: "",
      country: "",
      dialCode: "",
      province: "",
      locality: "",
      address1: "",
      address2: "",
      postalCode: "",
      phone: "",
      email: "",
      website: "",
      versionId: "",
      providerId: "",
      isGovernedByOrg: "",
      loadDataFromSportsGravy: "",
      sportsOffered: [],
      sportsFields: [],
      allowGoverningBodyBySport: "",
      primaryContact: null,
      secondaryContact: null,
      hasPrimaryContact: true,
      hasSecondaryContact: false,
      shippingSameAs: "OFFICE",
      billingSameAs: "OFFICE"
    }
  });

  const {
    providerId,
    country,
    sportsOffered,
    allowGoverningBodyBySport,
    hasSecondaryContact,
    primaryContact,
    secondaryContact,
    type,
    isGovernedByOrg
  } = watch();

  const { data: countriesResponse, isLoading: isFetchingCountries } = useQuery(
    ["countries"],
    () => lookupCountryGet(),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: true
    }
  );

  const countries = useMemo(
    () => countriesResponse?.data,
    [countriesResponse, isFetchingCountries]
  );

  const {
    data: organizationResponse,
    isFetching: isFetchingOrganization,
    error: error
  } = useAdminOrganizationOrganizationIdGet(organizationId as string);
  useEffect(() => {
    if (error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
  }, [error]);

  const organization = useMemo(
    () => organizationResponse?.data,
    [organizationResponse]
  );

  useEffect(() => {
    if (!countries || !organization) return;

    const sportsOffered =
      organization?.sports?.map((sport) => sport?.sportId) || [];

    const isGovernedByOrg =
      organization?.governingBodyId ||
      (organization?.sports &&
        organization.sports.some((sport) => sport.governingBodyId))
        ? "yes"
        : "no";
    const allowGoverningBodyBySport = organization?.sports?.some(
      (sport) => sport?.governingBody
    )
      ? "yes"
      : "no";
    const dialCode = countries?.find(
      (country) => country?.countryId === organization?.country
    )?.dialCode;

    const billingAddressComponents =
      organization?.billingAddress?.split("|") || undefined;
    const shippingAddressComponents =
      organization?.shippingAddress?.split("|") || undefined;
    const billingAddress = {
      ...(billingAddressComponents &&
        billingAddressComponents.length > 5 && {
          country: billingAddressComponents[5],
          address1: billingAddressComponents[0],
          address2: billingAddressComponents[1],
          locality: billingAddressComponents[2],
          province: billingAddressComponents[3],
          postalCode: billingAddressComponents[4]
        })
    };
    const shippingAddress = {
      ...(shippingAddressComponents &&
        shippingAddressComponents.length > 5 && {
          country: shippingAddressComponents[5],
          address1: shippingAddressComponents[0],
          address2: shippingAddressComponents[1],
          locality: shippingAddressComponents[2],
          province: shippingAddressComponents[3],
          postalCode: shippingAddressComponents[4]
        })
    };

    const defaultValues = {
      name: organization?.name,
      abbreviation: organization?.abbreviation,
      type: organization?.type,
      offering: organization?.offering,
      loadDataFromSportsGravy: "",
      country: organization?.country,
      dialCode,
      province: organization?.province,
      locality: organization?.locality,
      address1: organization?.lines?.[0],
      address2: organization?.lines?.[1],
      postalCode: organization?.postalCode,
      phone: organization?.phone,
      email: organization?.email,
      website: organization?.website,
      versionId: organization?.versionId,
      providerId: organization?.providerId,
      isGovernedByOrg,
      sportsOffered,
      allowGoverningBodyBySport,
      governingBodyId: organization?.governingBodyId,
      sportsFields: organization?.sports?.map((sport) => ({
        sportId: sport?.sportId,
        offeredFor: sport?.offeredFor,
        maleSingularInterval: sport?.maleSingularInterval,
        malePluralInterval: sport?.malePluralInterval,
        maleIntervalAbbreviation: sport?.maleIntervalAbbreviation,
        femaleSingularInterval: sport?.femaleSingularInterval,
        femalePluralInterval: sport?.femalePluralInterval,
        femaleIntervalAbbreviation: sport?.femaleIntervalAbbreviation,
        governingBodyId: sport?.governingBodyId
      })),
      primaryContact: organization?.primaryContact,
      secondaryContact: organization?.secondaryContact,
      hasPrimaryContact: !!organization?.primaryContact,
      hasSecondaryContact: !!organization?.secondaryContact,
      billingAddress: billingAddress,
      shippingAddress: shippingAddress,
      shippingSameAs: "OFFICE",
      billingSameAs: "OFFICE",
      revenueShare: organization?.revenueShare?.toString()
    };

    const isBillingSameAsOffice =
      `${defaultValues.address1},${defaultValues.address2 || ""},${
        defaultValues.locality
      },${defaultValues.province},${defaultValues.postalCode},${
        defaultValues.country
      }` ==
      `${billingAddress?.address1},${billingAddress?.address2},${billingAddress?.locality},${billingAddress?.province},${billingAddress?.postalCode},${billingAddress?.country}`;
    defaultValues["isBillingSameAs"] = isBillingSameAsOffice;

    const isShippingSameAsOffice =
      `${defaultValues.address1},${defaultValues.address2 || ""},${
        defaultValues.locality
      },${defaultValues.province},${defaultValues.postalCode},${
        defaultValues.country
      }` ==
      `${shippingAddress?.address1},${shippingAddress?.address2},${shippingAddress?.locality},${shippingAddress?.province},${shippingAddress?.postalCode},${shippingAddress?.country}`;
    const isShippingSameAsBilling =
      organization?.billingAddress === organization?.shippingAddress;
    defaultValues["isShippingSameAs"] =
      isShippingSameAsOffice || isShippingSameAsBilling;
    defaultValues["shippingSameAs"] = isShippingSameAsOffice
      ? "OFFICE"
      : isShippingSameAsBilling
        ? "BILLING"
        : "OFFICE";

    reset(defaultValues, {
      keepDirtyValues: true
    });
    setIsShippingSame(isShippingSameAsOffice || isShippingSameAsBilling);
    setIsBillingSame(isBillingSameAsOffice);
  }, [countries, organization]);

  const selectAddressSuggestion = (place, prefix = "") => {
    const addressComponents = place?.address_components || [];
    const streetNumber = addressComponents.find((c) =>
      c.types.includes("street_number")
    );
    const route = addressComponents.find((c) => c.types.includes("route"));
    const address1 = `${streetNumber?.long_name} ${route?.long_name}`;
    const subpremise = addressComponents.find((c) =>
      c.types.includes("subpremise")
    );
    const country = addressComponents.find((c) => c.types.includes("country"));
    const state = addressComponents.find((c) =>
      c.types.includes("administrative_area_level_1")
    );
    const city = addressComponents.find(
      (c) => c.types.includes("locality") || c.types.includes("sublocality")
    );
    const zip = addressComponents.find((c) => c.types.includes("postal_code"));
    setValue(`${prefix}address1`, address1);
    if (subpremise) setValue(`${prefix}address2`, subpremise?.long_name);
    setValue(`${prefix}country`, country?.short_name);
    setValue(`${prefix}province`, state?.short_name);
    setValue(`${prefix}locality`, city?.long_name);
    setValue(`${prefix}postalCode`, zip?.long_name);
    trigger(`${prefix}address1`);
    trigger(`${prefix}country`);
    trigger(`${prefix}province`);
    trigger(`${prefix}locality`);
    trigger(`${prefix}postalCode`);
  };

  const onBackClick = () => {
    navigate("/organizations");
  };
  const onConfirmCancel = () => {
    setIsConfirmationDialogOpen(false);
    navigate("/organizations");
  };
  const onCancelCancel = () => {
    setIsConfirmationDialogOpen(false);
  };

  const selectPrimaryContact = (contact) => {
    setValue("primaryContact", contact);
  };

  const selectSecondaryContact = (contact) => {
    setValue("secondaryContact", contact);
  };

  const [isBillingSame, setIsBillingSame] = useState(false);
  const [isShippingSame, setIsShippingSame] = useState(false);

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const edit = await checkPermission("general.organization", "EDIT");
      const del = await checkPermission("general.organization", "DELETE");
      setPermissions({
        edit,
        delete: del
      });
    };
    fetchPermissions();
  }, []);

  const { mutateAsync, isLoading } = useAdminOrganizationOrganizationIdDelete();

  const isDeleteDisabled = (organization) => {
    return !!organization?.invites?.some((invite) => !!invite?.acceptedAt);
  };

  const onConfirmDelete = async () => {
    if (!toDelete?.organizationId) return;
    try {
      await mutateAsync({
        organizationId: toDelete.organizationId
      });
      enqueueSnackbar("Organization deleted successfully", {
        variant: "success"
      });
      navigate("/organizations");
      setToDelete(null);
    } catch (error) {
      enqueueSnackbar("Error deleting organization", {
        variant: "error"
      });
      setToDelete(null);
    }
  };

  const onCanceledDelete = () => {
    setToDelete(null);
  };
  return (
    <Container>
      <Loader isLoading={isFetchingCountries || isFetchingOrganization}>
        <Toolbar
          title="View Organization"
          {...((loggedInOrganizationId || permissions.edit) && {
            editBtnClick: () =>
              navigate(`/organizations/${organizationId}/edit`)
          })}
          {...(!loggedInOrganizationId && { backBtnClick: onBackClick })}
          //@ts-ignore
          {...(!isDeleteDisabled(organizationResponse?.data) &&
            !loggedInOrganizationId &&
            !(
              !!organization?.isPrimaryAccepted ||
              !!organization?.isSecondaryAccepted
            ) &&
            permissions.delete && {
              deleteBtnClick: () => setToDelete(organizationResponse!.data!)
            })}
          tabs={{
            tabs: ["Organization Details", "Sports Details", "Contact Details"],
            onTabChange: onTabChange,
            activeTab: tab
          }}
        />
        <Form>
          <Grid container spacing={3}>
            {tab === "Organization Details" && (
              <Grid xs={12}>
                <OrganizationDetailsForm
                  disabled={true}
                  isEditing={false}
                  avatarId={organization?.avatarId}
                  control={control}
                  isBillingSame={isBillingSame}
                  isShippingSame={isShippingSame}
                  providerId={providerId}
                  country={country}
                  selectAddressSuggestion={selectAddressSuggestion}
                  countries={countries}
                  isFetchingCountries={isFetchingCountries}
                  type={type}
                />
              </Grid>
            )}
            {tab === "Sports Details" && (
              <Grid xs={12}>
                <OrganizationSportsForm
                  disabled={true}
                  control={control}
                  country={country}
                  allowGoverningBodyBySport={allowGoverningBodyBySport}
                  sportsOffered={sportsOffered}
                  isGovernedByOrg={isGovernedByOrg}
                  setValue={setValue}
                />
              </Grid>
            )}
            {tab === "Contact Details" && (
              <Grid xs={12}>
                <OrganizationContactForm
                  disabled={true}
                  control={control}
                  hasSecondaryContact={hasSecondaryContact}
                  selectPrimaryContact={selectPrimaryContact}
                  primaryContact={primaryContact}
                  selectSecondaryContact={selectSecondaryContact}
                  secondaryContact={secondaryContact}
                  organizationId={organizationId}
                />
              </Grid>
            )}
          </Grid>
        </Form>
      </Loader>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="Cancel Organization Creation?"
        body="Are you sure you want to cancel creating this organization? All unsaved changes will be lost."
        onConfirm={onConfirmCancel}
        onCancel={onCancelCancel}
      />
      <ConfirmationDialog
        open={!!toDelete}
        title="Delete Organization"
        body={`Are you sure you want to delete ${toDelete?.name}?`}
        close={() => setToDelete(null)}
        onConfirm={onConfirmDelete}
        onCancel={onCanceledDelete}
        isConfirming={isLoading}
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </Container>
  );
};
