import { FormInput } from "@components/FormInput";
import { StyledFormLabel } from "@components/StyledFormLabel";
import { Search } from "@mui/icons-material";
import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import {
  useAdminAlbumGet,
  useAdminLiveStreamStreamIdClipPost,
  useAdminLiveStreamStreamIdClipTagGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import React, { useMemo, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Button } from "@components/Button";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { AddToAlbum } from "./AddToAlbum";
import { ClipTimeFields } from "./ClipTimeFields";
import { VideoFrames } from "./VideoFrames";
import ReactPlayer from "react-player";
import { useRecoilValue } from "recoil";
import { organizationAtom, profileAtom } from "@recoil/auth";
import { enqueueSnackbar } from "notistack";
import { capitalize } from "@utils/capitalize";

export const LivestreamClipCreate = ({
  streamDetails,
  onClose,
  playerRef,
  setClipData,
  setIsDragging
}: {
  streamDetails: {
    streamId: string;
    sportId: string;
    clipId?: string;
    url?: string;
    teamProgramId: string | undefined;
    duration: number;
    startTime?: number;
    endTime?: number;
    shredWith?: "TEAM" | "PROGRAM";
  };
  playerRef: React.RefObject<ReactPlayer>;
  onClose: () => void;
  setClipData: (data) => void;
  setIsDragging: (dragging: boolean) => void;
}) => {
  const [tagsOpen, setTagsOpen] = useState(false);
  const user = useRecoilValue(profileAtom);
  const organizationId = useRecoilValue(organizationAtom);
  const [albumSearch, setAlbumSearch] = useState<string | undefined>(undefined);
  const form = useForm({ mode: "onTouched" });
  const {
    control,
    setValue,
    formState: { isValid },
    reset,
    trigger,
    getValues
  } = form;
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [createAlbum, setCreateAlbum] = useState(false);
  const [newlyCreatedAlbum, setNewlyCreatedAlbum] = useState<
    { label: string; value: string } | undefined
  >(undefined);

  const { data: presetTags } = useAdminLiveStreamStreamIdClipTagGet(
    streamDetails?.streamId
  );

  const tagsOptions = useMemo(() => {
    return [
      ...(presetTags?.data?.presetTags || [])
        .sort((a, b) => a.tagId?.localeCompare(b.tagId ?? "") ?? 0)
        .map((tag) => ({
          label: tag.tagId,
          value: tag.tagId,
          type: "Pre-set Tags"
        })),
      ...(presetTags?.data?.memberTags || [])
        .sort((a, b) => a.tagId?.localeCompare(b.tagId ?? "") ?? 0)
        .map((tag) => ({
          label: tag.tagId,
          value: tag.tagId,
          type: "Team Members"
        })),
      ...(presetTags?.data?.personGroupTags || [])
        .sort((a, b) => a.tagId?.localeCompare(b.tagId ?? "") ?? 0)
        .map((tag) => ({
          label: tag.tagId,
          value: tag.tagId,
          type: `${capitalize(streamDetails.shredWith || "")} Person Groups`
        }))
    ];
  }, [presetTags]);

  const toggleAlbumCreate = () => {
    setCreateAlbum(!createAlbum);
  };

  const { data: albums, isLoading: isAlbumLoading } = useAdminAlbumGet(
    {
      organizationId: organizationId!,
      ...(albumSearch && {
        textSearch: albumSearch
      }),
      contributorId: user?.userId
    },
    {
      query: {
        enabled: !!user?.userId
      }
    }
  );

  const albumsOptions = useMemo(() => {
    if (albums?.data) {
      return albums.data.map((album) => ({
        label: album.name,
        value: album.albumId
      }));
    }
    return [];
  }, [albums]);

  const { mutate: save, isLoading: isSaving } =
    useAdminLiveStreamStreamIdClipPost();
  const saveHandler = async () => {
    const values = getValues();
    save(
      {
        streamId: streamDetails.streamId,
        data: {
          title: values.title,
          tags: values?.tags && values?.tags?.map((tag) => tag.value),
          albumId: values?.album?.value || undefined,
          position: streamDetails.endTime,
          duration:
            (streamDetails?.endTime || 0) - (streamDetails?.startTime || 0)
        }
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Clip created successfully", { variant: "success" });
          reset({}, { keepErrors: false });
          onClose();
        }
      }
    );
  };
  return (
    <FormProvider {...form}>
      <div
        style={{
          minHeight: "415px",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Typography style={{ fontSize: "18px", fontWeight: 700 }}>
              Create Clip
            </Typography>
          </Grid>
          <Grid xs={12}>
            <FormInput
              control={control}
              type="text"
              name="title"
              label="Title"
              required
              rules={{
                required: "Title is required"
              }}
            />
          </Grid>

          <ClipTimeFields
            time={{
              startTime: streamDetails?.startTime as number,
              maxTime: streamDetails.duration,
              endTime: streamDetails?.endTime as number
            }}
            setClipData={setClipData}
            playerRef={playerRef}
          />

          <Grid
            xs={12}
            sx={{
              "&:focus-visible": {
                outline: "none"
              }
            }}
          >
            <VideoFrames
              details={streamDetails}
              onIndicatorMove={(startTime, endTime) => {
                const formatedStartTime = Number(startTime.toFixed(0));
                const formattedEndTime = Number(endTime.toFixed(0));
                setClipData((prev) => ({
                  ...prev,
                  startTime: formatedStartTime,
                  endTime: formattedEndTime
                }));
              }}
              playerRef={playerRef}
              setIsDraggingBox={setIsDragging}
            />
          </Grid>

          <Grid xs={12}>
            <StyledFormLabel>
              <Typography display="inline" variant="formLabel">
                Tags
              </Typography>
            </StyledFormLabel>
            <Autocomplete
              open={tagsOpen}
              disableClearable={true}
              multiple
              groupBy={(option) => option.type}
              options={tagsOptions}
              filterSelectedOptions
              renderInput={(params) => {
                return <TextField {...params} />;
              }}
              clearOnBlur
              noOptionsText="No tags found"
              loadingText="Loading..."
              slotProps={{
                paper: {
                  style: {
                    marginTop: "8px"
                  }
                }
              }}
              onChange={(e, value) => {
                setValue(
                  "tags",
                  value?.map((v) => v)
                );
              }}
              onOpen={() => setTagsOpen(true)}
              onBlur={() => setTagsOpen(false)}
            />
          </Grid>

          <Grid xs={11}>
            <StyledFormLabel>
              <Typography display="inline" variant="formLabel">
                Album
              </Typography>
            </StyledFormLabel>
            <Controller
              key={newlyCreatedAlbum?.value}
              name="album"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  value={field.value}
                  options={albumsOptions.concat(newlyCreatedAlbum || [])}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Typography>{option.label}</Typography>
                    </li>
                  )}
                  noOptionsText="No albums found"
                  loadingText="Loading..."
                  getOptionLabel={(option) => option.label}
                  clearOnBlur
                  onBlur={() => {
                    setAlbumSearch(undefined);
                  }}
                  loading={isAlbumLoading}
                  renderInput={(params) => {
                    if (Array.isArray(params.InputProps.startAdornment)) {
                      params.InputProps.startAdornment.splice(
                        0,
                        0,
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      );
                    } else {
                      params.InputProps.startAdornment = (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      );
                    }
                    return (
                      <TextField
                        {...params}
                        onChange={(e) => {
                          setAlbumSearch(e.target.value);
                        }}
                      />
                    );
                  }}
                  onChange={(e, value) => {
                    field.onChange(value);
                  }}
                />
              )}
            />
          </Grid>
          <Grid
            xs={1}
            sx={{
              display: "flex",
              alignItems: "center",
              padding: 0,
              marginTop: "20px"
            }}
          >
            <IconButton onClick={toggleAlbumCreate}>
              <AddRoundedIcon style={{ color: "#007AFF" }} />
            </IconButton>
          </Grid>
          <Grid
            xs={12}
            container
            gap="8px"
            marginTop="16px"
            justifyContent="flex-end"
          >
            <Button
              variant="admin-secondary"
              onClick={() => setOpenCancelDialog(true)}
            >
              Cancel
            </Button>
            <Button
              variant="admin-primary"
              disabled={!isValid || isSaving}
              isLoading={isSaving}
              onClick={saveHandler}
            >
              Save
            </Button>
          </Grid>
        </Grid>
        <ConfirmationDialog
          title="Discard the clip?"
          body="Are you sure you want to discard this clip?"
          open={openCancelDialog}
          close={() => setOpenCancelDialog(false)}
          onCancel={() => {
            reset({}, { keepErrors: false });
            onClose();
          }}
          onConfirm={() => setOpenCancelDialog(false)}
          cancelBtnText="Discard Clip"
          confirmBtnText="Keep"
        />
        {createAlbum && (
          <AddToAlbum
            onClose={toggleAlbumCreate}
            onSave={(data) => {
              const album = {
                label: data.name!,
                value: data.albumId!
              };
              setNewlyCreatedAlbum(album);
              setValue("album", album, { shouldValidate: true });
              setTimeout(() => {
                trigger("album");
              }, 500);
            }}
            type="CREATE_ALBUM"
          />
        )}
      </div>
    </FormProvider>
  );
};
