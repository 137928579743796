import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import {
  useAdminHelpCategoryCategoryIdGet,
  useAdminHelpCategoryCategoryIdPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalizeEveryWord } from "@utils/capitalize";
import { useSnackbar } from "notistack";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

export const HelpCategoryEdit = () => {
  const navigate = useNavigate();
  const { helpCategoryId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid, isDirty },
    reset
  } = useForm({
    mode: "onTouched"
  });

  const { data: helpCategory, isLoading: helpCategoryLoading } =
    useAdminHelpCategoryCategoryIdGet(helpCategoryId!);

  useEffect(() => {
    if (helpCategory?.data) {
      reset({
        name: helpCategory.data.name
      });
    }
  }, [helpCategory]);

  const { mutate: save, isLoading: isSaving } =
    useAdminHelpCategoryCategoryIdPut();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    async (formValues) => {
      const values = {
        ...formValues
      };
      values["name"] = values["name"]
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      save(
        {
          helpCategoryId: helpCategoryId!,
          data: values
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Help category edited successfully!", {
              variant: "success"
            });
            if (resetInsteadOfRoute) {
              reset();
            } else {
              navigate(`/help-category/${helpCategoryId}`);
            }
          },
          onError: () => {
            enqueueSnackbar("Failed to edit Help category!", {
              variant: "error"
            });
          }
        }
      );
    };

  return (
    <Container>
      <Toolbar title="Edit Help Category" />
      <Loader isLoading={helpCategoryLoading}>
        <Form>
          <Grid data-testid="helpCategory-add-form" container spacing={3}>
            <Grid data-testid="helpCategory-name" xs={12} md={6}>
              <FormInput
                control={control}
                name="name"
                type="text"
                label="Name"
                required={true}
                rules={{
                  required: "Name is required"
                }}
                onChange={(e) => {
                  setValue(
                    "name",
                    capitalizeEveryWord(
                      (e as ChangeEvent<HTMLInputElement>).target.value
                    )
                  );
                }}
              />
            </Grid>
          </Grid>
        </Form>
      </Loader>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler(false))}
        isDisabled={!isValid || isSaving || !isDirty}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/help-category")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
