import React, { useEffect, useState } from "react";
import { Container } from "@components/crud/Container";
import { useFormContext } from "react-hook-form";
import Grid from "@mui/system/Unstable_Grid";
import { FormSelect } from "@components/FormSelect";
import { FormInput } from "@components/FormInput";
import { FormDatePicker } from "@components/FormDatePicker";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormTimePicker } from "@components/FormTimePicker";
import { axios } from "@sportsgravyengineering/sg-api-react-sdk";
import Sentry from "@services/Sentry";
import { Backdrop, Box, IconButton, Typography } from "@mui/material";
import { OpenInNew } from "@mui/icons-material";
import { Footer } from "@components/crud/Footer";

const BOARDING_STATUS = [
  {
    label: "Not Ready",
    value: 0
  },
  {
    label: "Board Immediately",
    value: 1
  }
];

const MERCHANT_TYPE = [
  {
    label: "Merchant accepts cards in person primarily",
    value: "MERCHANT_ACCEPT_CARDS_IN_PERSON"
  },
  {
    label: "Customer enter their payment information online primarily",
    value: "CUSTOMER_ENTER_PAYMENT_INFORMATION"
  },
  {
    label:
      "Merchant takes payment information over the phone or mail primarily",
    value: "MERCHANT_TAKES_PAYMENT_OVER_PHONE"
  }
];

export const AccountSetup = () => {
  const { control, watch, trigger, setValue } = useFormContext();
  const [openTC, setOpenTC] = useState(false);
  const [enableCheckBox, setEnableCheckbox] = useState(false);
  const [enableClose, setEnableClose] = useState(false);
  const tncAccepted = watch("tnc");

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://jsonip.com");
        setValue("tcAcceptIp", response.data.ip);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    fetchIpAddress();
  }, []);

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid xs={6}>
          <FormSelect
            control={control}
            name="status"
            required
            label="Boarding Status"
            options={BOARDING_STATUS}
            defaultValue={0}
            rules={{
              required: "Boarding Status is required"
            }}
            tooltip="To select 'Board Immediately' an MCC code must be entered in the MCC field."
          />
        </Grid>
        <Grid xs={6}>
          <FormSelect
            control={control}
            name="mcc"
            label="Add MCC"
            options={[
              // { label: "Sports and Riding Apparel Stores", value: "5655" },
              {
                value: "7941",
                label:
                  "Athletic Fields, Commercial Sports, Professional Sport Clubs, and Sport Promoters"
              }
              // {
              //   value: "7997",
              //   label:
              //     "Membership Clubs (Sports, Recreation, Athletic), Country Clubs, and Private Golf Courses"
              // }
            ]}
            required
            rules={{
              required: "MCC is required"
            }}
            tooltip="Locate and select the business category most relevant to your company from the dropdown menu"
          />
        </Grid>
        <Grid xs={6}>
          <FormSelect
            control={control}
            name="merchantType"
            label="Merchant Type"
            options={MERCHANT_TYPE}
            required
            rules={{
              required: "Merchant Type is required"
            }}
          />
        </Grid>
        <Grid xs={6}></Grid>
        <Grid container spacing={3} xs={12}>
          <Grid
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
            onClick={() => setOpenTC(!openTC)}
          >
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 700
              }}
            >
              Read Terms and Conditions
            </Typography>
            <IconButton size="small">
              <OpenInNew />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={3} xs={12}>
          <Grid xs={12}>
            <FormCheckbox
              control={control}
              name="tnc"
              label="Accept Terms and Conditions"
              required
              rules={{
                required: "Attestation is required"
              }}
              disabled={!enableCheckBox}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} xs={12}>
          <Grid xs={12}>
            <FormCheckbox
              control={control}
              name="tcAttestation"
              label="I attest that the information provided in this application is correct to the best of my knowledge as an authorized signer for this business."
              required
              rules={{
                required: "Attestation is required"
              }}
              disabled={!enableCheckBox}
            />
          </Grid>
        </Grid>
        {tncAccepted && (
          <>
            <Grid xs={5}>
              <FormSelect
                control={control}
                name="tcVersion"
                label="Version Accepted"
                options={[
                  { label: "Payrix Sub Merchant Agreement", value: "072023" },
                  {
                    label: "DIRECT AGREEMENT & FIFTH THIRD BANK DISCLOSURE",
                    value: "10.2022."
                  },
                  {
                    label: "PAYRIX SUB-MERCHANT AGREEMENT ADDENDUM A",
                    value: "072023"
                  }
                ]}
                required
                rules={{
                  required: "Version Accepted is required"
                }}
              />
            </Grid>
            <Grid xs={3}>
              <FormDatePicker
                control={control}
                name="tcAcceptDate"
                label="Date"
                required
                rules={{
                  required: "Date is required"
                }}
                trigger={trigger}
                disableFuture
              />
            </Grid>
            <Grid xs={3}>
              <FormTimePicker
                control={control}
                name="tcAcceptTime"
                label="Time"
                required
                rules={{
                  required: "Time is required"
                }}
              />
            </Grid>
            <Grid xs={6}>
              <FormInput
                control={control}
                type="text"
                name="tcAcceptIp"
                label="IP Address"
                required
                rules={{
                  required: "IP Address is required"
                }}
                disabled
              />
            </Grid>
          </>
        )}
      </Grid>
      {openTC && (
        <Backdrop
          sx={{
            overflow: "hidden",
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1
          }}
          open={true}
        >
          <Box
            sx={{
              width: "80vw",
              height: "80vh"
            }}
          >
            <iframe
              src="https://portal.sportsgravy.com/terms"
              style={{
                width: "100%",
                height: "100%",
                border: "none",
                flexGrow: 1,
                overflow: "auto"
              }}
              onLoad={() => {
                setEnableClose(true);
              }}
            />
            {enableClose && (
              <div
                style={{
                  marginTop: "-8px"
                }}
              >
                <Footer
                  cancelBtnLabel="Close"
                  cancelBtnClick={() => {
                    setOpenTC(false);
                    setEnableCheckbox(true);
                  }}
                />
              </div>
            )}
          </Box>
        </Backdrop>
      )}
    </Container>
  );
};
