/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from "@components/Button";
import { ReactToolTip } from "@components/ToolTip";
import { CalendarMonth } from "@mui/icons-material";
import { Button as MuiButton, Popover, Container, styled } from "@mui/material";
import {
  DateRangeCalendar,
  LocalizationProvider
} from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { TIME_FILTER_OPTIONS } from "@utils/constants";
import { useEffect, useState } from "react";
const StyledOption = styled("option")({
  fontSize: "13px",
  padding: "5px 10px",
  width: "100%",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#E5E5E5"
  }
});
export const TimeFilter = ({
  selectedTimeRange,
  setSelectedTimeRange,
  setRefreshKey,
  refreshKey,
  isQaMetric,
  defaultTimeFilter,
  customTimeFilterOptions,
  setSelectedTimeFilter
}: {
  selectedTimeRange?: any;
  setSelectedTimeRange?: any;
  setRefreshKey: any;
  refreshKey: any;
  isQaMetric?: boolean;
  defaultTimeFilter?: string;
  customTimeFilterOptions?: any;
  setSelectedTimeFilter?: any;
}) => {
  const [timeFilter, setTimeFilter] = useState<string>("ALL_TIME");
  const [timeFilterOptions, setTimeFilterOptions] =
    useState<any>(TIME_FILTER_OPTIONS);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [anchorEl1, setAnchorEl1] = useState<HTMLButtonElement | null>(null);
  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? "simple-popover1" : undefined;

  const formatDateRange = (dateRange) => {
    if (!dateRange[0] || !dateRange[1])
      return timeFilterOptions.find((option) => option.value === timeFilter)
        ?.label;

    return dateRange
      .map((date) => {
        const dateObj = new Date(date);
        return dateObj.toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric"
        });
      })
      .join(" - ");
  };

  useEffect(() => {
    if (customTimeFilterOptions) {
      setTimeFilterOptions(customTimeFilterOptions);
    } else {
      setTimeFilterOptions(TIME_FILTER_OPTIONS);
    }
  }, [customTimeFilterOptions]);

  useEffect(() => {
    if (defaultTimeFilter) {
      setTimeFilter(defaultTimeFilter);
      calculateTimeFilter(defaultTimeFilter);
    }
  }, [defaultTimeFilter]);

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl1(null);
  };

  const padZero = (value) => {
    return value < 10 ? `0${value}` : `${value}`;
  };

  const calculateTimeFilter = (timeFilter) => {
    const now = new Date();
    const start = new Date();
    const end = new Date();

    const setTime = (date, hours, minutes, seconds, milliseconds) => {
      date.setHours(hours, minutes, seconds, milliseconds);
      return date;
    };

    const filterMap = {
      TODAY: () => [
        setTime(new Date(start.setHours(0, 0, 0, 0)), 0, 0, 0, 0),
        setTime(new Date(end.setHours(23, 59, 59, 999)), 23, 59, 59, 999)
      ],
      YESTERDAY: () => [
        setTime(new Date(start.setDate(now.getDate() - 1)), 0, 0, 0, 0),
        setTime(new Date(end.setDate(now.getDate() - 1)), 23, 59, 59, 999)
      ],
      THIS_WEEK: () => [
        // prettier-ignore
        setTime(new Date(start.setDate(now.getDate() - now.getDay())), 0, 0, 0, 0),
        setTime(new Date(end.setDate(start.getDate() + 6)), 23, 59, 59, 999)
      ],
      LAST_WEEK: () => [
        // prettier-ignore
        setTime(new Date(start.setDate(now.getDate() - now.getDay() - 7)), 0, 0, 0, 0 ),
        setTime(new Date(end.setDate(start.getDate() + 6)), 23, 59, 59, 999)
      ],
      THIS_MONTH: () => [
        setTime(new Date(start.setMonth(now.getMonth(), 1)), 0, 0, 0, 0),
        setTime(new Date(end.setMonth(now.getMonth() + 1, 0)), 23, 59, 59, 999)
      ],
      LAST_MONTH: () => [
        setTime(new Date(start.setMonth(now.getMonth() - 1, 1)), 0, 0, 0, 0),
        // prettier-ignore
        setTime(new Date(end.setMonth(start.getMonth() + 1, 0)), 23, 59, 59, 999)
      ],
      THIS_QUARTER: () => {
        const quarter = Math.floor(now.getMonth() / 3);
        return [
          setTime(new Date(start.setMonth(quarter * 3, 1)), 0, 0, 0, 0),
          // prettier-ignore
          setTime(new Date(end.setMonth(start.getMonth() + 3, 0)), 23, 59, 59, 999 )
        ];
      },
      LAST_QUARTER: () => {
        const quarter = Math.floor(now.getMonth() / 3);
        return [
          setTime(new Date(start.setMonth((quarter - 1) * 3, 1)), 0, 0, 0, 0),
          // prettier-ignore
          setTime(new Date(end.setMonth(start.getMonth() + 3, 0)), 23, 59, 59, 999)
        ];
      },
      THIS_YEAR: () => [
        // prettier-ignore
        setTime(new Date(start.setFullYear(now.getFullYear(), 0, 1)), 0, 0, 0, 0),
        // prettier-ignore
        setTime(new Date(end.setFullYear(now.getFullYear(), 11, 31)), 23, 59, 59, 999 )
      ],
      LAST_YEAR: () => [
        // prettier-ignore
        setTime(new Date(start.setFullYear(now.getFullYear() - 1, 0, 1)), 0, 0, 0, 0),
        // prettier-ignore
        setTime(new Date(end.setFullYear(start.getFullYear(), 11, 31)), 23, 59, 59, 999)
      ],
      CUSTOM: () => [selectedTimeRange[0], selectedTimeRange[1]]
    };

    if (filterMap[timeFilter]) {
      const [start, end] = filterMap[timeFilter]();
      const offset = new Date().getTimezoneOffset();
      const absoluteOffset = Math.abs(offset);
      const hours = Math.floor(absoluteOffset / 60);
      const minutes = absoluteOffset % 60;
      const offsetSign = offset > 0 ? "-" : "+";
      const formattedOffset = `${offsetSign}${hours
        .toString()
        .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;

      const startDateUTC = `${start.getFullYear()}-${padZero(
        start.getMonth() + 1
      )}-${padZero(start.getDate())}T00:00:00${formattedOffset}`;
      const endDateUTC = `${end.getFullYear()}-${padZero(
        end.getMonth() + 1
      )}-${padZero(end.getDate())}T23:59:59${formattedOffset}`;

      setSelectedTimeRange([startDateUTC, endDateUTC]);
      setRefreshKey(refreshKey + 1);
    }
    if (timeFilter == "ALL_TIME") {
      setSelectedTimeRange([null, null]);
      setRefreshKey(refreshKey + 1);
    }
  };

  window.addEventListener("scroll", handleClose);
  return (
    <>
      <MuiButton
        startIcon={<CalendarMonth />}
        variant="outlined"
        style={{
          color: "#64748B",
          fontSize: "14px",
          fontWeight: 600,
          textTransform: "none"
        }}
        onClick={(event) => {
          setAnchorEl(anchorEl ? null : event.currentTarget);
        }}
      >
        {timeFilter != "CUSTOM" && !isQaMetric
          ? timeFilterOptions.find((option) => option.value === timeFilter)
              ?.label
          : timeFilter != "CUSTOM" &&
              isQaMetric &&
              !timeFilterOptions.some((option) => option.value === "TODAY")
            ? [
                ...timeFilterOptions.slice(0, 1),
                { label: "Today", value: "TODAY", tooltip: "Today" },
                ...timeFilterOptions.slice(1)
              ].find((option) => option.value === timeFilter)?.label
            : formatDateRange(selectedTimeRange)}
      </MuiButton>
      <Popover
        style={{ marginLeft: "40px" }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        disableScrollLock={true}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Container style={{ padding: 0 }}>
          {(!isQaMetric
            ? timeFilterOptions
            : [
                ...timeFilterOptions.slice(0, 1),
                { label: "Today", value: "TODAY", tooltip: "Today" },
                ...timeFilterOptions.slice(1)
              ]
          ).map((option) => (
            <StyledOption
              data-tooltip-id="time-filter-option-tooltip"
              data-tooltip-place="left-start"
              data-tooltip-content={option.tooltip}
              onClick={(e) => {
                //@ts-ignore
                setTimeFilter(e.target.value);
                setAnchorEl(null);
                //@ts-ignore
                if (e.target.value === "CUSTOM") {
                  setSelectedTimeRange([null, null]);
                  setAnchorEl1(anchorEl);
                }
                //@ts-ignore
                if (e.target.value !== "CUSTOM") {
                  //@ts-ignore
                  calculateTimeFilter(e.target.value);
                }
                if (setSelectedTimeFilter) {
                  //@ts-ignore
                  setSelectedTimeFilter(e.target.value);
                }
              }}
              key={option.value}
              value={option.value}
            >
              {option.label}
            </StyledOption>
          ))}
        </Container>
      </Popover>
      <ReactToolTip id="time-filter-option-tooltip" />
      <Popover
        id={id1}
        open={open1}
        anchorEl={anchorEl1}
        disableScrollLock={true}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Container style={{ padding: 0 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangeCalendar
              disableFuture
              disableHighlightToday
              slotProps={{}}
              onChange={(date) => {
                const startDate = new Date(date[0] as Date);
                const endDate = date[1] ? new Date(date[1] as Date) : null;
                setSelectedTimeRange([startDate, endDate]);
              }}
              calendars={2}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "16px",
                gap: "8px"
              }}
            >
              <Button variant="admin-secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="admin-primary"
                disabled={!selectedTimeRange[0] || !selectedTimeRange[1]}
                onClick={() => {
                  calculateTimeFilter("CUSTOM");
                  handleClose();
                }}
              >
                Apply
              </Button>
            </div>
          </LocalizationProvider>
        </Container>
      </Popover>
    </>
  );
};

TimeFilter.defaultProps = {
  isQaMetric: false
};
