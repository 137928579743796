/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@components/crud/Accordian";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import { Toolbar as MUIToolbar, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Grid from "@mui/material/Unstable_Grid2";
import { organizationAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import {
  useAdminCalendarUploadPost,
  useLookupSGCalendarField
} from "@sportsgravyengineering/sg-api-react-sdk";
import { setPropertyRecursive } from "@utils/objectFunctions";
import axios from "axios";
import { useSnackbar } from "notistack";
import papaparse from "papaparse";
import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

export const CalendarImportEvents = () => {
  const organizationId = useRecoilValue(organizationAtom);
  const [usedFields, setUsedFields] = React.useState<string[]>([]);
  const [availableFields, setAvailableFields] = React.useState<string[]>([]);
  const [disabledFields, setDisabledFields] = React.useState<string[]>([]);
  const [fieldsSet, setFieldsSet] = React.useState<string[]>([]);
  const [openCancelDialog, setOpenCancelDialog] = React.useState(false);
  const [selectableFields, setSelectableFields] = React.useState<
    { label: string; value: string }[]
  >([]);
  const [fields, setFields] = React.useState<string[]>([]);
  const [fileType, setFileType] = React.useState<string>("");
  const [csvFile, setCsvFile] = React.useState<File | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { mutate, isLoading: isSaving } = useAdminCalendarUploadPost({});

  const { data: mappingResult, isFetched: mappingFetched } =
    useLookupSGCalendarField();
  const importFields = useMemo(
    () => mappingResult?.data || {},
    [mappingResult]
  );

  const csvTemplateChangeHandler = (
    file: any,
    field: { onChange: (arg0: { target: { value: any } }) => void }
  ) => {
    if (!file) {
      setSelectableFields([]);
      setCsvFile(undefined);
      field.onChange({ target: { value: undefined } });
      return;
    }
    if (file.type == "text/csv") {
      papaparse.parse(file, {
        header: true,
        dynamicTyping: false,
        complete: (results: { meta: { fields: any[] } }, file: any) => {
          const fields =
            results?.meta?.fields?.map((v: any) => ({ label: v, value: v })) ||
            [];
          setFields((results?.meta?.fields as string[]) || []);
          setSelectableFields([...fields]);
          setCsvFile(file);
          field.onChange({ target: { value: file } });
        }
      });
      setFileType("csv");
    }
    if (file.type == "text/calendar") {
      setFileType("ics");
      setCsvFile(file);
      field.onChange({ target: { value: file } });
    }
  };

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { defaultValues, isValid },
    reset
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      csvTemplate: csvFile,
      description: "",
      providerName: "",
      version: "",
      fieldMap: {}
    }
  });
  useEffect(() => {
    const permission = hasPermission(
      "ORGANIZATION",
      organizationId!,
      "general.calendar-import" as string,
      "ON" as string
    );
    permission.then((res) => {
      if (!res) {
        navigate("/not-found");
      }
    });
  }, []);
  useEffect(() => {
    const currentValues = getValues();

    const disabledFields: string[] = [];
    const availableFields: string[] = [];
    const fieldsSet: string[] = [];
    const mappingDefaults: any = {};
    for (const key in importFields) {
      if (importFields[key].isMandatory) {
        fieldsSet.push(key);
        disabledFields.push(key);
        mappingDefaults[key] = {
          heading: importFields[key].isMandatory
        };
      }
      if (!importFields[key].isMandatory) {
        fieldsSet.push(key);
        mappingDefaults[key] = {
          heading: true
        };
      }
      for (const fieldKey in importFields[key].fields) {
        if (importFields[key].fields[fieldKey].isMandatory) {
          disabledFields.push(importFields[key].fields[fieldKey].key);
          availableFields.push(importFields[key].fields[fieldKey].key);
        }
        setPropertyRecursive(
          mappingDefaults,
          importFields[key].fields[fieldKey].key,
          {
            available: importFields[key].fields[fieldKey].isMandatory,
            isRequired: importFields[key].fields[fieldKey].isMandatory,
            header: ""
          }
        );
      }
    }
    setDisabledFields(disabledFields);
    setAvailableFields(availableFields);
    setFieldsSet(fieldsSet);
    reset({
      csvTemplate: csvFile,
      fieldMap:
        Object.keys(currentValues?.fieldMap).length > 0
          ? currentValues?.fieldMap
          : Object.keys(defaultValues?.fieldMap || {}).length > 0
            ? defaultValues?.fieldMap
            : mappingDefaults
    });
  }, [importFields]);

  const resetUsedFields = (existingValue = "", newValue = "") => {
    const values = getValues("fieldMap");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const usedFields = Object.entries(values).flatMap(([_, fieldGroup]) =>
      Object.values(fieldGroup)
        .map((field) => field.header) // Extract header values
        .filter((header) => header && header !== existingValue)
    );

    if (newValue && !usedFields.includes(newValue)) {
      usedFields.push(newValue);
    }
    console.log(usedFields);
    setUsedFields(usedFields);
  };

  const saveHandler = (formValues: { csvTemplate: any; fieldMap: any }) => {
    for (const key in formValues.fieldMap) {
      for (const key2 in formValues.fieldMap[key]) {
        if (
          formValues.fieldMap[key][key2]?.header === "" ||
          formValues.fieldMap[key][key2]?.header === undefined
        ) {
          delete formValues.fieldMap[key][key2];
        } else {
          formValues.fieldMap[key][key2].available = true;
          formValues.fieldMap[key][key2].canBeEmpty =
            !formValues.fieldMap[key][key2].isRequired;
        }
      }
      if (Object.keys(formValues.fieldMap[key]).length === 0) {
        delete formValues.fieldMap[key];
      }
    }

    const values = {
      headers: fields,
      fieldMap: formValues.fieldMap,
      fileName: csvFile?.name,
      organizationId: organizationId!,
      fileType: fileType
    };
    mutate(
      { data: values },
      {
        onSuccess: async (data) => {
          const formData = new FormData();
          if (
            data.data?.uploadParams &&
            data.data?.uploadParams.url &&
            data.data?.uploadParams?.fields
          ) {
            const fields = data.data?.uploadParams?.fields;
            for (const key in fields) {
              formData.append(key, fields[key]);
            }
            formData.append("file", csvFile!);
            try {
              await axios.post(data.data?.uploadParams.url, formData);
              enqueueSnackbar("Imported successfully!", {
                variant: "success"
              });
              navigate("/calendar");
            } catch (error) {
              enqueueSnackbar("Failed to import!", { variant: "error" });
              console.log("ERR");
            }
          }
        }
      }
    );
  };
  const tableHeader = () => {
    return (
      <TableHead sx={{ backgroundColor: "#F8FAFC" }}>
        <TableRow>
          <TableCell>
            <TableSortLabel>
              <Typography variant="tableHeader">Available</Typography>
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel>
              <Typography variant="tableHeader">SportsGravy Fields</Typography>
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel>
              <Typography variant="tableHeader">
                Template File Columns
              </Typography>
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel>
              <Typography variant="tableHeader">Value is Required</Typography>
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };
  const tableRowInput = (
    { name, isEmptyAllowed, isMandatory, key },
    disabled
  ) => {
    return (
      fieldsSet.includes(key.split(".")[0]) && (
        <TableRow key={key}>
          <TableCell sx={{ paddingLeft: 4 }}>
            <FormCheckbox
              name={`fieldMap.${key}.available`}
              control={control}
              disabled={disabled || disabledFields.includes(key)}
              onChange={(e) => {
                if (!e.target.checked) {
                  // @ts-ignore
                  setValue(`fieldMap.${key}.header`, "");
                  // @ts-ignore
                  setValue(`fieldMap.${key}.isRequired`, false);
                  setAvailableFields((prev) => prev.filter((f) => f !== key));
                  setFieldsSet(fieldsSet.filter((f) => f !== key));
                  resetUsedFields();
                  reset({
                    ...getValues(),
                    fieldMap: {
                      ...getValues().fieldMap,
                      [key]: {
                        ...getValues().fieldMap[key],
                        header: ""
                      }
                    }
                  });
                } else {
                  setAvailableFields((prev) => [...prev, key]);
                }
              }}
            />
          </TableCell>
          <TableCell>
            <Typography variant="body1">{name}</Typography>
          </TableCell>
          <TableCell data-testid={"import-event-select" + key}>
            <FormSelect
              name={`fieldMap.${key}.header`}
              options={selectableFields.filter(
                (f) =>
                  f.value === "" ||
                  // @ts-ignore
                  f.value === getValues(`fieldMap.${key}.header`) ||
                  !usedFields.includes(f.value)
              )}
              control={control}
              required={
                !disabled && (!!isMandatory || availableFields.includes(key))
              }
              disabled={disabled || !availableFields.includes(key)}
              rules={
                !disabled && isMandatory
                  ? {
                      required: `${name} is required`
                    }
                  : !disabled && availableFields.includes(key)
                    ? {
                        required: `${name} is required when marked as available ${availableFields.includes(
                          key
                        )}`
                      }
                    : {}
              }
              onChange={(e) => {
                const existingValue = getValues(
                  // @ts-ignore
                  `fieldMap.${key}.header`
                ) as string;
                const newValue = e.target.value;

                if (newValue === "") {
                  setFieldsSet(fieldsSet.filter((f) => f !== key));
                } else if (!fieldsSet.includes(key)) {
                  setFieldsSet([...fieldsSet, key]);
                }
                // @ts-ignore
                setValue(`fieldMap.${key}.header`, newValue);
                resetUsedFields(existingValue, newValue);
              }}
            />
          </TableCell>
          <TableCell sx={{ paddingLeft: 6.85 }}>
            <FormCheckbox
              name={`fieldMap.${key}.isRequired`}
              control={control}
              disabled={
                !isEmptyAllowed ||
                isMandatory ||
                disabled ||
                disabledFields.includes(key)
              }
            />
          </TableCell>
        </TableRow>
      )
    );
  };
  return (
    <Container>
      <Toolbar title="Import Events" />
      <Loader isLoading={!mappingFetched}>
        <Form>
          <Grid container spacing={3}>
            <Grid xs={6} data-testid="import-file">
              <FormInput
                control={control}
                name="csvTemplate"
                type="file"
                label="Import File"
                required={true}
                rules={{ required: "Import File is required" }}
                // @ts-ignore
                onChange={csvTemplateChangeHandler}
                value={csvFile}
                InputProps={{
                  accept: ".ics"
                }}
                // InputProps={{
                //   accept: "text/csv,.ics"
                // }}
              />
            </Grid>
            <Grid xs={12} container spacing={1}>
              {mappingFetched &&
                selectableFields.length > 0 &&
                Object.keys(importFields).length &&
                Object.keys(importFields).map((key, index) => {
                  return (
                    <Grid xs={12} key={`${index}_${key}`}>
                      <Accordion expanded={fieldsSet.includes(key)}>
                        <AccordionSummary>
                          <MUIToolbar
                            sx={{
                              padding: "0 !important"
                            }}
                          >
                            <FormCheckbox
                              label={importFields[key].name}
                              labelTypographyProps={{
                                variant: "h6",
                                sx: { opacity: 0.6 }
                              }}
                              name={`fieldMap.${key}.heading`}
                              control={control}
                              disabled={disabledFields.includes(key)}
                              onChange={(e) => {
                                if (!e.target.checked) {
                                  setFieldsSet(
                                    fieldsSet.filter((f) => f !== key)
                                  );
                                  for (const fieldKey in importFields[key]
                                    .fields) {
                                    setValue(
                                      // @ts-ignore
                                      `fieldMap.${importFields[key].fields[fieldKey].key}.header`,
                                      ""
                                    );
                                    setValue(
                                      // @ts-ignore
                                      `fieldMap.${importFields[key].fields[fieldKey].key}.isRequired`,
                                      false
                                    );
                                    setValue(
                                      // @ts-ignore
                                      `fieldMap.${importFields[key].fields[fieldKey].key}.available`,
                                      false
                                    );
                                  }
                                  resetUsedFields();
                                } else {
                                  for (const fieldKey in importFields[key]
                                    .fields) {
                                    setValue(
                                      // @ts-ignore
                                      `fieldMap.${importFields[key].fields[fieldKey].key}.isRequired`,
                                      importFields[key].fields[fieldKey]
                                        .isMandatory
                                    );
                                    setValue(
                                      // @ts-ignore
                                      `fieldMap.${importFields[key].fields[fieldKey].key}.available`,
                                      importFields[key].fields[fieldKey]
                                        .isMandatory
                                    );
                                  }

                                  if (!fieldsSet.includes(key)) {
                                    setFieldsSet([...fieldsSet, key]);
                                  }
                                }
                              }}
                            />
                          </MUIToolbar>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Table>
                            {tableHeader()}
                            <TableBody
                              sx={{
                                "& tr": {
                                  borderBottom:
                                    "2px solid rgba(226, 232, 240, .75)"
                                },
                                "& tr:last-child": { borderBottom: 0 }
                              }}
                            >
                              {Object.keys(importFields[key].fields).map(
                                (fieldKey: string) =>
                                  tableRowInput(
                                    importFields[key].fields[fieldKey],
                                    !fieldsSet.includes(key)
                                  )
                              )}
                            </TableBody>
                          </Table>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        </Form>
      </Loader>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler)}
        isDisabled={!isValid || isSaving || !mappingFetched}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate(`/calendar`)}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
