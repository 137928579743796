import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import {
  AdminEpicPostPutRequest,
  useAdminEpicPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { cleanObject } from "@utils/cleanObject";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import CardsTable from "./CardsTable";
import Grid from "@mui/system/Unstable_Grid";
import { hasPermission } from "@services/Casbin";
import { useNavigate } from "react-router-dom";
export const EpicCreate = () => {
  const navigate = useNavigate();
  const form = useForm({
    mode: "onTouched"
  });

  const {
    control,
    getValues,
    reset,
    formState: { isValid }
  } = form;
  const { mutate: save, isLoading: isSaving } = useAdminEpicPost();
  const onSave = (saveAndNew: boolean) => {
    const filteredValues = cleanObject(getValues());
    const issues = Object.keys(filteredValues)
      .filter((key) => key.endsWith("_card") && filteredValues[key])
      .map((cardKey) => {
        const jiraIssueId = cardKey.split("_")[0];
        const platformKey = `${jiraIssueId}_platform`;
        return {
          jiraIssueId,
          platform: filteredValues[platformKey]
        };
      });

    const data: AdminEpicPostPutRequest = {
      name: filteredValues.name,
      description: filteredValues.description,
      issues: issues
    };
    save(
      {
        data
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Epic Created successfully!", {
            variant: "success"
          });

          if (saveAndNew) {
            reset();
          } else {
            window.history.back();
          }
        },
        onError: () => {
          enqueueSnackbar("Failed to Create Epic!", {
            variant: "error"
          });
        }
      }
    );
  };

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("tech.epics", "ADD");
      if (!create) navigate("/not-found");
    };
    fetchPermissions();
  }, []);

  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  return (
    <Container>
      <Toolbar title="Add Epic" />
      <Form>
        <Grid container direction="column" spacing="25px">
          <Grid xs={12} data-testid="epic-name">
            <FormInput
              name="name"
              control={control}
              label="Epic Name"
              required
              rules={{
                required: "Epic Name Is Required"
              }}
              type="text"
            />
          </Grid>
          <Grid xs={12} data-testid="epic-description">
            <FormInput
              name="description"
              control={control}
              label="Description"
              required
              rules={{
                required: "Description Is Required"
              }}
              multiline
              rows={5}
              type="text"
            />
          </Grid>
          <FormProvider {...form}>
            <Grid xs={12}>
              <CardsTable />
            </Grid>
          </FormProvider>
        </Grid>
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={() => {
          onSave(false);
        }}
        saveAndNewBtnClick={() => {
          onSave(true);
        }}
        isDisabled={!isValid || isSaving}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => window.history.back()}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
