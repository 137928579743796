/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Box, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ReplayIcon from "@mui/icons-material/Replay";
import { ModelMedia } from "@sportsgravyengineering/sg-api-react-sdk";
import ImagePlaceHolder from "@assets/images/imagePlaceholder.png";
import { LoadingSpinner } from "@components/LoadingSpinner";
interface ContainerProps {
  isMobile: boolean;
}

const StyledBox = styled(Box)`
  position: relative;
  background-color: #000;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Container = styled("div")<ContainerProps>`
  position: relative;
  height: ${(props) => (props.isMobile ? "100vh" : "506px")};
`;

const Count = styled(Typography)<ContainerProps>`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  ${(props) => (props.isMobile ? "top:15px;" : "bottom: 16px;")}
  color: #fff;
  z-index: 1000;
  background-color: #00000094;
  padding: 5px 10px;
  border-radius: 99px;
`;

const Duration = styled(Typography)`
  position: absolute;
  right: 15px;
  top: 15px;
  color: #fff;
  z-index: 1000;
  background-color: #00000094;
  padding: 5px 10px;
  border-radius: 99px;
`;

const VolumeButton = styled(IconButton)`
  background-color: #00000094;
  padding: 6px;
  border-radius: 99px;
  height: 36px;
  width: 36px;
  z-index: 1001;
  &:hover {
    background-color: #000000c2;
  }
`;

const PlayPauseButton = styled(IconButton)`
  background-color: #00000094;
  padding: 6px;
  border-radius: 99px;
  height: 80px;
  width: 80px;
  &:hover {
    background-color: #000000c2;
  }
`;

const ReplayButton = styled(IconButton)`
  background-color: #00000094;
  padding: 4px 8px;
  border-radius: 99px;
  height: 40px;
  width: 125px;
  font-size: 14px;
  color: #fff;
  gap: 5px;
  align-items: center;
  &:hover {
    background-color: #000000c2;
  }
`;

const formatDuration = (seconds: number) => {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = padZero(date.getUTCSeconds());
  if (hh) {
    return `${hh}:${padZero(mm)}:${ss}`;
  }
  return `${padZero(mm)}:${ss}`;
};

const padZero = (string) => {
  return ("0" + string).slice(-2);
};

export const MediaViewer = (props: { mediaFiles; currentIndex; isMobile }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMuted, setIsMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  const [videoDuration, setVideoDuration] = useState<number>(0);
  const [videoTimer, setVideoTimer] = useState<string>("");
  const [isVideoEnded, setIsVideoEnded] = useState<boolean>(false);
  const [disablePanning, setDisablePanning] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [touchStartX, setTouchStartX] = useState<number | null>(null);
  const [touchEndX, setTouchEndX] = useState<number | null>(null);

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    setTouchEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStartX && touchEndX) {
      if (touchEndX < touchStartX - 50) {
        handleNext();
      } else if (touchEndX > touchStartX + 50) {
        handlePrevious();
      }
    }
    setTouchStartX(null);
    setTouchEndX(null);
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setVideoDuration(0);
      setIsMuted(true);
      setIsPlaying(true);
    }
  };

  const handleNext = () => {
    if (currentIndex < props.mediaFiles.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setVideoDuration(0);
      setIsMuted(true);
      setIsPlaying(true);
    }
  };

  const currentMedia =
    props.mediaFiles[currentIndex]?.media ||
    (props.mediaFiles[currentIndex] as ModelMedia);

  console.log(currentMedia);

  const buttonStyle = {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    border: "none",
    color: "#fff",
    width: "40px",
    height: "40px",
    cursor: "pointer",
    borderRadius: "99px",
    backgroundColor: "#00000094",
    "&:hover": {
      backgroundColor: "#000000c2"
    }
  };

  const isImage = (
    baseUrl: string | undefined,
    path: string | undefined
  ): boolean => {
    if (!baseUrl || !path) return false;
    const extension = path.split(".").pop()?.toLowerCase();
    return extension === "jpg" || extension === "jpeg" || extension === "png";
  };

  const isVideo = (
    baseUrl: string | undefined,
    path: string | undefined
  ): boolean => {
    if (!baseUrl || !path) return false;
    const extension = path.split(".").pop()?.toLowerCase();
    return extension === "mp4" || extension === "m3u8" || extension === "png";
  };
  return (
    <Container
      isMobile={props.isMobile}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {props.mediaFiles.length > 1 && (
        <Count isMobile={props.isMobile}>
          {currentIndex + 1} / {props.mediaFiles.length}
        </Count>
      )}
      <TransformWrapper
        doubleClick={{ disabled: true }}
        onZoom={(z) => {
          if (z.state.scale > 1) {
            setDisablePanning(false);
            return;
          }
          setDisablePanning(true);
        }}
        panning={{ disabled: disablePanning }}
        initialPositionX={0}
      >
        {({ resetTransform, instance }) => {
          if (
            !instance.contentComponent?.classList.contains(
              `media-${currentIndex}`
            )
          ) {
            resetTransform(0);
          }
          return (
            <TransformComponent contentClass={`media-${currentIndex}`}>
              <StyledBox
                style={{
                  height: props.isMobile
                    ? "calc(var(--vh, 1vh) * 100)"
                    : "506px"
                }}
              >
                {isImage(currentMedia?.baseUrl, currentMedia?.path) ? (
                  <img
                    src={
                      currentMedia?.baseUrl
                        ? currentMedia?.baseUrl + currentMedia?.path
                        : ImagePlaceHolder
                    }
                    style={{
                      maxWidth: props.isMobile ? "100vw" : "100%",
                      maxHeight: props.isMobile
                        ? "calc(var(--vh, 1vh) * 100)"
                        : "506px",
                      width: "auto",
                      height: "auto",
                      position: "relative",
                      margin: "auto"
                    }}
                    alt="Image"
                  />
                ) : isVideo(currentMedia?.baseUrl, currentMedia?.path) ||
                  currentMedia?.mimeType?.includes("video") ? (
                  <div
                    style={{
                      position: "relative",
                      height: "100%",
                      width: "100%",
                      overflow: "hidden"
                    }}
                  >
                    <TransformWrapper
                      doubleClick={{ disabled: true }}
                      onZoom={(z) => {
                        if (z.state.scale > 1) {
                          setDisablePanning(false);
                          return;
                        }
                        setDisablePanning(true);
                      }}
                      panning={{ disabled: disablePanning }}
                      initialPositionX={0}
                    >
                      {({ resetTransform, instance }) => {
                        if (
                          !instance.contentComponent?.classList.contains(
                            `media-${currentIndex}`
                          )
                        ) {
                          resetTransform(0);
                        }
                        return (
                          <TransformComponent
                            contentClass={`media-${currentIndex}`}
                          >
                            <div
                              className="video-player"
                              style={{
                                position: "relative",
                                height: props.isMobile
                                  ? "calc(var(--vh, 1vh) * 100)"
                                  : "506px",
                                width: "100%"
                              }}
                              onClick={() => {
                                if (!isVideoEnded) {
                                  setIsPlaying(!isPlaying);
                                  setIsVideoEnded(false);
                                }
                              }}
                            >
                              {isLoading && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)"
                                  }}
                                >
                                  <LoadingSpinner size={50} />
                                </div>
                              )}
                              <ReactPlayer
                                muted={isMuted}
                                playing={isPlaying}
                                height={"100%"}
                                width={"100%"}
                                onBuffer={() => setIsLoading(true)}
                                onBufferEnd={() => setIsLoading(false)}
                                onStart={() => setIsLoading(false)}
                                onDuration={(duration) => {
                                  setVideoDuration(duration);
                                  setVideoTimer(formatDuration(duration));
                                }}
                                onProgress={(progress) => {
                                  setVideoTimer(
                                    formatDuration(
                                      videoDuration - progress.playedSeconds
                                    )
                                  );
                                }}
                                onEnded={() => {
                                  setIsVideoEnded(true);
                                  setIsPlaying(false);
                                }}
                                url={
                                  currentMedia?.baseUrl
                                    ? currentMedia?.baseUrl + currentMedia?.path
                                    : ImagePlaceHolder
                                }
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)"
                                }}
                              />
                            </div>
                          </TransformComponent>
                        );
                      }}
                    </TransformWrapper>
                  </div>
                ) : null}
              </StyledBox>
            </TransformComponent>
          );
        }}
      </TransformWrapper>
      <ChevronLeftIcon
        sx={{
          ...buttonStyle,
          left: 0,
          display: currentIndex === 0 ? "none" : "block",
          marginLeft: "10px"
        }}
        onClick={handlePrevious}
      />
      <ChevronRightIcon
        sx={{
          ...buttonStyle,
          right: 0,
          display:
            currentIndex === props.mediaFiles.length - 1 ? "none" : "block",
          marginRight: "10px"
        }}
        onClick={handleNext}
      />
      {videoDuration != 0 && (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0
          }}
        >
          <Duration>{videoTimer}</Duration>
        </div>
      )}
      {videoDuration != 0 && (
        <div
          style={{
            position: "absolute",
            ...(props.isMobile ? { top: 16, left: 16 } : { top: 456 }),
            right: 16
          }}
        >
          {isMuted ? (
            <VolumeButton onClick={() => setIsMuted(false)}>
              <VolumeOffIcon
                style={{ color: "#fff", height: "20px", width: "20px" }}
              />
            </VolumeButton>
          ) : (
            <VolumeButton onClick={() => setIsMuted(true)}>
              <VolumeUpIcon
                style={{ color: "#fff", height: "20px", width: "20px" }}
              />
            </VolumeButton>
          )}
        </div>
      )}

      {!isPlaying && !isVideoEnded && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#fff",
            fontSize: "24px",
            zIndex: 1000
          }}
        >
          <PlayPauseButton
            onClick={() => {
              setIsPlaying(true);
              setIsVideoEnded(false);
            }}
          >
            <PlayArrowIcon
              style={{ color: "#fff", height: "45px", width: "45px" }}
            />
          </PlayPauseButton>
        </div>
      )}

      {isVideoEnded && !isPlaying && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#fff",
            fontSize: "24px",
            zIndex: 1000
          }}
        >
          <ReplayButton
            onClick={() => {
              setIsPlaying(true);
              setIsVideoEnded(false);
            }}
          >
            <ReplayIcon
              style={{ color: "#fff", height: "20px", width: "20px" }}
            />
            <span>Watch again</span>
          </ReplayButton>
        </div>
      )}
    </Container>
  );
};
