import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Toolbar } from "@components/crud/Toolbar";
import {
  useAdminPresetTagPresetTagIdGet,
  useAdminPresetTagPresetTagIdPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { PresetTagForm } from "./PresetTagForm";
import { Loader } from "@components/crud/Loader";
import { hasPermission } from "@services/Casbin";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";

export const PresetTagEdit = () => {
  const organizationId = useRecoilValue(organizationAtom);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { presetTagId } = useParams();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const form = useForm({
    mode: "onTouched"
  });
  const {
    handleSubmit,
    formState: { isValid },
    reset
  } = form;

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId as string,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const edit = await checkPermission("live-streaming.clip-tags", "EDIT");
      if (!edit) navigate("/not-found");
    };
    fetchPermissions();
  }, []);

  const { data: presetTag, isLoading: isLoading } =
    useAdminPresetTagPresetTagIdGet(presetTagId as string);

  useEffect(() => {
    if (presetTag?.data) {
      const tag = presetTag.data;
      reset({
        name: tag.name,
        isActive: tag.isActive ? "true" : "false",
        sport: tag.sportId
      });
    }
  }, [presetTag]);

  const { mutate: save, isLoading: isSaving } =
    useAdminPresetTagPresetTagIdPut();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    async (formValues) => {
      const values = {
        ...formValues
      };
      values.isActive = values.isActive === "true";
      save(
        {
          presetTagId: presetTagId as string,
          data: values
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Preset Tag edited successfully!", {
              variant: "success"
            });
            if (resetInsteadOfRoute) {
              reset();
            } else {
              navigate(`/preset-tags/${presetTagId}`);
            }
          },
          onError: () => {
            enqueueSnackbar("Failed to edit tag!", {
              variant: "error"
            });
          }
        }
      );
    };

  return (
    <Container>
      <Toolbar title="Edit Clip Pre-set Tag" />
      <Loader isLoading={isLoading}>
        <FormProvider {...form}>
          <PresetTagForm />
        </FormProvider>
        <Footer
          cancelBtnClick={() => setOpenCancelDialog(true)}
          saveBtnClick={handleSubmit(saveHandler(false))}
          isDisabled={!isValid || isSaving}
          isLoading={isSaving}
        />
      </Loader>
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/preset-tags")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
