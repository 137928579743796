/* eslint-disable @typescript-eslint/no-explicit-any */
import { Controller, UseFormReturn } from "react-hook-form";
import ShareWithIcon from "../../../../src/assets/icons/shareWithIcon.svg";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { FormControl } from "@mui/base";

export const LiveStreamShareWith = (props: {
  form: UseFormReturn<any>;
  required: boolean | undefined;
  disabled?: boolean;
  action?: string;
  selectedContest?: string;
  setConnections?: any;
}) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "18px"
        }}
      >
        <img src={ShareWithIcon} style={{ marginRight: "15px" }} />
        <Controller
          name="shareWithTeam_Program"
          control={props.form.control}
          render={({ field }) => (
            <>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={field.value}
                  data-testid="sharewith-radioGroup"
                  onChange={(e) => {
                    field.onChange(e.target.value);
                  }}
                >
                  {props.form.getValues("shareTo") != "" && (
                    <FormControlLabel
                      control={<Radio />}
                      value="shareWithTeam"
                      label={
                        props.selectedContest === "TRAINING_SESSION"
                          ? "Share with Training Program"
                          : "Share with Team"
                      }
                    />
                  )}
                  <FormControlLabel
                    control={<Radio />}
                    value="isSharedWithOrg"
                    label="Share with Organization"
                  />
                </RadioGroup>
              </FormControl>
            </>
          )}
        />
      </div>
    </div>
  );
};

LiveStreamShareWith.defaultProps = {
  required: false,
  disabled: false
};
