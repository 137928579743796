import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { organizationAtom } from "@recoil/auth";
import {
  useAdminLevelLevelIdGet,
  useAdminLevelLevelIdPut,
  useAdminSportGet,
  useLookupCountryGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalizeEveryWord } from "@utils/capitalize";
import { GENDERS } from "@utils/constants";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { SearchInput } from "@components/SearchInput";
import { Box, Typography, styled } from "@mui/material";
import { Loader } from "@components/crud/Loader";
import CountryList from "./CountryList";

const StyledLabel = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #000000;
  opacity: 0.7;
  text-transform: uppercase;
`;

export const LevelEdit = () => {
  const navigate = useNavigate();
  const { levelId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const organizationId = useRecoilValue(organizationAtom);
  const [search, setSearch] = useState("");

  const {
    data: level,
    isFetching: isLevelFetching,
    error: error
  } = useAdminLevelLevelIdGet(levelId as string);
  useEffect(() => {
    if (error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
    if (level && level?.data?.isInherited && organizationId)
      navigate("/not-found");
  }, [error, level]);

  const { data: countries, isLoading: isCountriesLoading } =
    useLookupCountryGet();

  const defaultValues = useMemo<{
    name: string;
    abbv: string;
    description: string;
    sportId: string;
    genders: string[];
    countries: {
      countryId: string;
      countryName: string;
      selected: boolean;
    }[];
  }>(() => {
    if (!countries || !level) return { name: "", countries: [] };
    return {
      name: level?.data?.name,
      abbv: level?.data?.abbv,
      description: level?.data?.description,
      sportId: level?.data?.sports?.map((sport) => sport.sportId),
      genders: level?.data?.genders,
      countries: countries.data.reduce((acc, country) => {
        const levelCountry = level.data?.countries?.find(
          (c) => c.countryId === country.countryId
        );
        acc[country.countryId!] = {
          countryId: country.countryId!,
          countryName: country.name!,
          selected: !!levelCountry
        };
        return acc;
      }, {})
    };
  }, [level, countries]);

  const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  };

  const debouncedSearch = useDebounce(search, 100);

  const { data: sports, isLoading: isSportLoading } = useAdminSportGet({
    organizationId: organizationId!
  });

  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name!,
        value: sport.sportId!
      })) || [],
    [sports]
  );
  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid, isDirty },
    reset
  } = useForm({
    mode: "onTouched",
    defaultValues
  });

  useEffect(
    () =>
      reset(defaultValues, {
        keepDirtyValues: true
      }),
    [level, sportOptions]
  );

  const { mutate: save, isLoading: isSaving } = useAdminLevelLevelIdPut();
  const saveHandler = (formValues) => {
    const values = {
      ...formValues
    };
    if (organizationId) delete values.countries;

    if (values.sportId && Array.isArray(values.sportId)) {
      values.sports = values.sportId;
    }

    if (values.sportId && !Array.isArray(values.sportId)) {
      values.sports = [values.sportId];
    }

    if (!organizationId) {
      values.countries = Object.values(values.countries as object)
        .filter((country) => country.selected)
        .map((country) => country.countryId);
    }

    save(
      {
        levelId: levelId as string,
        data: values
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Level updated successfully!", {
            variant: "success"
          });
          navigate(`/levels/${levelId}`);
        },
        onError: () => {
          enqueueSnackbar("Failed to update level!", { variant: "error" });
        }
      }
    );
  };

  const renderCountryList = useMemo(() => {
    if (!defaultValues.countries) return null;
    return (
      <CountryList
        countryList={defaultValues.countries}
        control={control}
        search={debouncedSearch}
        disabled={false}
      />
    );
  }, [defaultValues.countries, debouncedSearch]);
  return (
    <Container>
      <Toolbar title="Edit Level" />
      <Form>
        <Loader
          isLoading={isLevelFetching || isSportLoading || isCountriesLoading}
        >
          <Grid data-testid="level-edit-form" container spacing={3}>
            <Grid xs={12} md={6} data-testid="level-name">
              <FormInput
                control={control}
                name="name"
                type="text"
                label="Name"
                required={true}
                onChange={(e) => {
                  setValue("name", capitalizeEveryWord(e.target.value));
                }}
                rules={{
                  required: "Name is required"
                }}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="level-abbv">
              <FormInput
                control={control}
                name="abbv"
                type="text"
                label="Abbreviation"
                required={true}
                rules={{
                  required: "Abbreviation is required",
                  maxLength: {
                    value: 6,
                    message: "Abbreviation must be 6 characters or less"
                  }
                }}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="level-sport-input">
              <FormMultiSelect
                control={control}
                name="sportId"
                label="Sports"
                value="sportId"
                required={true}
                options={sportOptions}
                isLoading={isSportLoading}
                disabled={sportOptions.length === 1}
                rules={{
                  required: "Sport is required"
                }}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="level-gender-input">
              <FormMultiSelect
                control={control}
                name="genders"
                label="Gender"
                value="gender"
                required={true}
                options={GENDERS}
                rules={{
                  required: "Gender is required"
                }}
              />
            </Grid>
            <Grid xs={12} md={12} data-testid="level-description">
              <FormInput
                control={control}
                name="description"
                type="text"
                label="Description"
                required={true}
                multiline={true}
                rules={{
                  required: "Description is required"
                }}
              />
            </Grid>
            {!organizationId && (
              <>
                <Grid xs={12}>
                  <StyledLabel>Countries</StyledLabel>
                  <HeaderUnderLine width="100%" />
                  <Box sx={{ marginTop: "8px" }}>
                    <SearchInput
                      placeholder="Search"
                      required={false}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </Box>
                </Grid>

                {renderCountryList}
              </>
            )}
          </Grid>
        </Loader>
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler)}
        isDisabled={!isValid || isSaving || !isDirty}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/levels")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
