import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormDatePicker } from "@components/FormDatePicker";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { organizationAtom } from "@recoil/auth";
import {
  useAdminSeasonPost,
  useAdminSportGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalizeEveryWord } from "@utils/capitalize";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

export const SeasonCreate = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const organizationId = useRecoilValue(organizationAtom);

  const { data: sports, isLoading: isSportLoading } = useAdminSportGet({
    organizationId: organizationId!
  });
  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name!,
        value: sport.sportId!
      })) || [],
    [sports]
  );
  const {
    handleSubmit,
    control,
    formState: { isValid },
    reset,
    setValue
  } = useForm({
    mode: "onTouched"
  });
  useEffect(() => {
    if (sportOptions.length == 1) {
      setValue("sportId", sportOptions[0].value);
    }
  }, [sportOptions]);
  const { mutate: save, isLoading: isSaving } = useAdminSeasonPost();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    (formValues) => {
      const values = {
        organizationId,
        ...formValues
      };

      save(
        {
          data: values
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Season added successfully!", {
              variant: "success"
            });
            if (resetInsteadOfRoute) {
              reset({
                name: "",
                sportId: "",
                startDate: "MM/DD/YYYY",
                endDate: "MM/DD/YYYY"
              });
            } else {
              navigate("/seasons");
            }
          },
          onError: () => {
            enqueueSnackbar("Failed to add season!", {
              variant: "error"
            });
          }
        }
      );
    };

  return (
    <Container>
      <Toolbar title="Add Season" />
      <Form>
        <Grid container spacing={3}>
          <Grid xs={12} md={6} data-testid="CREATE_SEASON_NAME">
            <FormInput
              control={control}
              name="name"
              type="text"
              onChange={(e) => {
                setValue("name", capitalizeEveryWord(e.target.value));
              }}
              label="Name"
              required={true}
              rules={{
                required: "Name is required"
              }}
            />
          </Grid>
          <Grid xs={12} md={6} data-testid="CREATE_SEASON_SPORT">
            <FormSelect
              control={control}
              name="sportId"
              label="Sport"
              required={true}
              isLoading={isSportLoading}
              disabled={sportOptions.length === 1}
              options={sportOptions}
              rules={{
                required: "Sport is required"
              }}
            />
          </Grid>
          <Grid xs={12} md={6} data-testid="CREATE_SEASON_START_DATE">
            <FormDatePicker
              control={control}
              name="startDate"
              label="Start Date"
              required={true}
              rules={{
                required: "Start Date is required"
              }}
            />
          </Grid>
          <Grid xs={12} md={6} data-testid="CREATE_SEASON_END_DATE">
            <FormDatePicker
              control={control}
              name="endDate"
              label="End Date"
              required={true}
              rules={{
                required: "End Date is required"
              }}
            />
          </Grid>
        </Grid>
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler(false))}
        saveAndNewBtnClick={handleSubmit(saveHandler(true))}
        isDisabled={!isValid || isSaving}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/seasons")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
