import { Button } from "@components/Button";
import { FormInput } from "@components/FormInput";
import { NotificationCard } from "@components/NotificationCard";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useAuthForgotPasswordPost } from "@sportsgravyengineering/sg-api-react-sdk";
import { EMAIL_REGEX } from "@utils/validation";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const FormRow = styled(Box)`
  display: flex;
  margin-top: 1.5rem;
  ${(props) => props.theme.breakpoints.up("xs")} {
    flex-direction: column;
  }
  ${(props) => props.theme.breakpoints.up("sm")} {
    flex-direction: row;
  }
`;

const NotificationCardContainer = styled(Box)`
  margin-top: 1rem;
`;

const ButtonContainer = styled(Box)`
  margin-top: 3rem;
`;

const FormFieldContainer = styled(Box)`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
`;

const SignInContainer = styled(Box)`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ForgotPassword = () => {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      email: "",
      password: "",
      isKeepLoggedIn: false
    }
  });

  const mutation = useAuthForgotPasswordPost();

  const submitForm = async (formValues) => {
    const { email } = formValues;
    mutation.mutate(
      {
        data: { email }
      },
      {
        onSettled: () => {
          setIsEmailSent(true);
        }
      }
    );
  };

  return (
    <FormContainer data-testid="forgot-password-form">
      <Typography
        variant="h2"
        color="text.general.primary"
        sx={{ fontWeight: 400, mb: ".5rem" }}
      >
        Forgot Password
      </Typography>
      <Typography variant="body1" color="text.general.secondary">
        Enter your Email to reset your password
      </Typography>
      {isEmailSent && (
        <NotificationCardContainer data-testid="forgot-password-notificationCard">
          <NotificationCard variant="success">
            If the email you provided exists in our database we will email you
            instructions to reset your password. If you don’t receive the email,
            please check your spam/junk folder.
          </NotificationCard>
        </NotificationCardContainer>
      )}
      <form onSubmit={handleSubmit(submitForm)}>
        <FormRow>
          <FormFieldContainer>
            <FormInput
              name="email"
              type="text"
              required
              label="Email"
              control={control}
              rules={{
                required: "Email is required",
                pattern: {
                  value: EMAIL_REGEX,
                  message: "Please enter a valid email address"
                }
              }}
            />
          </FormFieldContainer>
        </FormRow>
        <ButtonContainer>
          <Button
            variant="primary"
            disabled={!isValid || mutation.isLoading}
            type="submit"
            isLoading={mutation.isLoading}
          >
            Continue
          </Button>
        </ButtonContainer>
      </form>
      <SignInContainer>
        <Typography variant="body1">
          Back to <Link to="/">Sign In</Link>
        </Typography>
      </SignInContainer>
    </FormContainer>
  );
};
