import { FormInput } from "@components/FormInput";
import { FormToggleDays } from "@components/FormToggleDays";
import { Grid, Select, styled, MenuItem } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { RRule, Weekday } from "rrule";

const StyledGrid = styled(Grid)(() => ({
  "& .repeatEvery": {
    paddingLeft: "10px"
  },
  "& .repeatEvery .MuiBox-root": {
    width: "150px"
  },
  "& .repeatEvery input": {
    padding: "8px 0 0 0"
  },
  "& .repeatEvery .MuiInputBase-root": {
    borderBottom: "1px solid",
    borderBottomColor: "#E2E8F0",
    width: "50px"
  },
  "& .frequency": {
    paddingLeft: 0,
    paddingTop: "10px",
    marginLeft: "-88px",
    height: "50px"
  },
  "& .frequency select": {
    padding: 0
  },
  "& .repeatOn input": {
    padding: "5px 0 0 0",
    width: "500px"
  }
}));

interface CalendarRecurrenceProps {
  startDate?: number;
  setCustomText: (text: string) => void;
  onConfirm: (customText, interval, timeFrame, days) => Promise<void> | void;
  action?: string;
  repeat;
  fullDate?: Date;
  onChange?: () => void;
}

export const CalendarRecurrence = ({
  startDate,
  setCustomText,
  onConfirm,
  action,
  repeat,
  fullDate,
  onChange
}: CalendarRecurrenceProps) => {
  const [repeatOn, setRepeatOn] = useState("");
  const [repeatEvery, setRepeatEvery] = useState("");
  const [timeFrame, setTimeFrame] = useState(
    repeat.freq
      ? repeat.freq == 1
        ? "MONTHLY"
        : repeat.freq == 2
          ? "WEEKLY"
          : "DAILY"
      : "DAILY"
  );
  const [days, setDays] = useState([]);

  const { control, setValue, getValues } = useForm({
    mode: "onTouched",
    defaultValues: {
      repeatEvery: 1,
      timeFrame: "DAILY",
      repeatOn: "Every month on day " + startDate,
      days: []
    }
  });

  useEffect(() => {
    setValue("repeatOn", "Every month on day " + startDate);
    setRepeatOn("Every month on day " + startDate);
  }, [startDate]);

  const getShortDayName = (day) => {
    const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    return daysOfWeek[day];
  };
  const getDaysOfWeekText = (selectedDays): string => {
    selectedDays.sort((a, b) => a - b);
    if (selectedDays.length == 1) {
      return `on ${getShortDayName(selectedDays[0])}`;
    }
    if (selectedDays.length === 2) {
      if (selectedDays.includes(5) && selectedDays.includes(6)) {
        return "on weekends";
      }
      return `on ${getShortDayName(selectedDays[0])} and ${getShortDayName(
        selectedDays[1]
      )}`;
    } else if (selectedDays.length >= 3 && selectedDays.length < 7) {
      if (
        selectedDays.length === 5 &&
        selectedDays.every((day) => day >= 0 && day <= 4)
      ) {
        return "on weekdays";
      } else {
        const dayNames = selectedDays.map((day) => getShortDayName(day));
        const lastDay = dayNames.pop();
        return `on ${dayNames.join(", ")} and ${lastDay}`;
      }
    } else if (selectedDays.length === 7) {
      return "on all days";
    }
    return "";
  };
  const acceptClickHandler = () => {
    const interval = getValues().repeatEvery;
    const tFrame = timeFrame;
    const days: Weekday[] = getValues().days;
    let customText = "Repeats Every";
    switch (tFrame) {
      case "DAILY":
        customText =
          customText + (interval > 1 ? " " + interval + " days" : " day");
        break;
      case "WEEKLY":
        customText =
          customText + (interval > 1 ? " " + interval + " weeks" : " week");
        customText =
          customText +
          (days.length >= 1
            ? " " + getDaysOfWeekText(days.map((day) => day.weekday))
            : "");
        break;
      case "MONTHLY":
        customText =
          customText + (interval > 1 ? " " + interval + " months" : " month");
        break;
    }
    onConfirm(customText, interval, tFrame, days);
    return customText;
  };
  useEffect(() => {
    if (repeatEvery && repeatOn === "MONTHLY") {
      setValue(
        "repeatOn",
        `Every ${parseInt(repeatEvery) > 1 ? repeatEvery + " " : ""}${
          parseInt(repeatEvery) > 1 ? "months" : "month"
        } on day ${startDate}`
      );
    }
  }, [repeatOn, repeatEvery]);
  useEffect(() => {
    setCustomText(acceptClickHandler());
  }, [repeatOn, repeatEvery, timeFrame, days]);

  console.log("IMAZ______", getValues().timeFrame);
  useEffect(() => {
    if (action === "edit") {
      if (repeat.freq == 1) {
        setTimeFrame("MONTHLY");
        setValue("timeFrame", "MONTHLY");
        setRepeatOn("MONTHLY");
      }
      if (repeat.freq == 2) {
        setTimeFrame("WEEKLY");
        setValue("timeFrame", "WEEKLY");
        setRepeatOn("WEEKLY");
      }
      if (repeat.freq == 3) {
        setTimeFrame("DAILY");
        setValue("timeFrame", "DAILY");
        setRepeatOn("DAILY");
      }
      setValue("repeatEvery", repeat.interval);
      setValue("days", repeat.byweekday);
      setRepeatEvery(repeat.interval);
    }
  }, [action]);

  useEffect(() => {
    if ((action === "create" || action === "edit") && repeatOn === "WEEKLY") {
      if (getValues().days?.length == 0) {
        const dayOfWeek =
          startDate && !isNaN(fullDate?.getDay()) ? fullDate.getDay() : 1;
        const rruleWeekdayMap = {
          0: RRule.SU,
          1: RRule.MO,
          2: RRule.TU,
          3: RRule.WE,
          4: RRule.TH,
          5: RRule.FR,
          6: RRule.SA
        };
        const rruleWeekday = rruleWeekdayMap[dayOfWeek];
        const startDay = [RRule[rruleWeekday]];
        setValue("days", [...startDay]);
      }
    }
  }, [repeatOn]);
  const [disableWeekDay, setDisableWeekDay] = useState(true);

  useEffect(() => {
    if (getValues()?.days?.length > 1) setDisableWeekDay(false);
    else setDisableWeekDay(true);
  }, [getValues()?.days]);
  return (
    <>
      <Grid
        style={{ marginBottom: "10px" }}
        container
        marginLeft={"14px"}
        data-testId="calendar-custom-repeat"
      >
        <StyledGrid item container direction="row" spacing="24px">
          <Grid item className="repeatEvery">
            <FormInput
              type="text"
              label="Repeat Every"
              control={control}
              name="repeatEvery"
              onChange={(e) => {
                setRepeatEvery(
                  (e as ChangeEvent<HTMLInputElement>)?.target.value
                );
                if (onChange) onChange();
              }}
              min={1}
              TextProps={{
                inputProps: {
                  maxLength: 3,
                  onInput: (e) => {
                    (e as ChangeEvent<HTMLInputElement>).target.value = (
                      e as ChangeEvent<HTMLInputElement>
                    ).target.value.replace(/[^0-9]/g, "");
                  }
                }
              }}
            />
          </Grid>
          <Grid item style={{ alignSelf: "flex-end" }} className="frequency">
            <Controller
              name="timeFrame"
              control={control}
              render={({ field }) => (
                <Select
                  defaultValue="DAILY"
                  value={field.value}
                  data-testId="calendar-custom-timeFrame"
                  onChange={(e) => {
                    setRepeatOn(e.target.value);
                    setTimeFrame(e.target.value);
                    setValue("timeFrame", e.target.value);
                    if (onChange) onChange();
                  }}
                >
                  <MenuItem value="DAILY">
                    {repeatEvery && parseInt(repeatEvery) > 1 ? "Days" : "Day"}
                  </MenuItem>
                  <MenuItem value="WEEKLY">
                    {repeatEvery && parseInt(repeatEvery) > 1
                      ? "Weeks"
                      : "Week"}
                  </MenuItem>
                  <MenuItem value="MONTHLY">
                    {repeatEvery && parseInt(repeatEvery) > 1
                      ? "Months"
                      : "Month"}
                  </MenuItem>
                </Select>
              )}
            />
          </Grid>
          {(repeatOn === "MONTHLY" || repeatOn == "WEEKLY") && (
            <Grid
              className="repeatOn"
              item
              xs={6}
              data-testId="calendar-repeatOn"
            >
              {repeatOn === "WEEKLY" && (
                <FormToggleDays
                  name="days"
                  control={control}
                  label="Repeat On"
                  disableSelected={disableWeekDay}
                  onChange={(value) => {
                    setValue("days", value);
                    if (value.length > 1) {
                      setDisableWeekDay(false);
                    } else {
                      setDisableWeekDay(true);
                    }
                    setDays(value);
                    if (onChange) onChange();
                  }}
                />
              )}
              {repeatOn === "MONTHLY" && (
                <FormInput
                  label="Repeat On"
                  control={control}
                  name="repeatOn"
                  type="text"
                  disabled
                />
              )}
            </Grid>
          )}
        </StyledGrid>
      </Grid>
    </>
  );
};
