import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Box } from "@mui/system";
import alertCircle from "@assets/icons/alertCircle.svg";
import { useParams } from "react-router-dom";
import { LivePreview } from "@components/LivePreview";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  AD_LAYOUT_OPTIONS,
  AD_PLACEMENT_OPTIONS,
  CALL_TO_ACTION_OPTIONS
} from "@utils/constants";
import { useForm } from "react-hook-form";
import { FormInput } from "@components/FormInput";
import { Button } from "@components/Button";
import { useSnackbar } from "notistack";
import {
  useAdminAdvertiserAdvertisementIdCompressedEmailGet,
  useAdminAdvertiserIdAdvertisementIdCompressedEmailPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSetRecoilState } from "recoil";
import { signupTokenAtom } from "@recoil/signup";
import { Loader } from "@components/crud/Loader";

const StyledTypographyLabel = styled(Typography)(() => ({
  display: "block",
  width: "100px",
  marginTop: "16px"
}));
const StyledTypographyValue = styled(Typography)(() => ({
  display: "inline",
  marginLeft: "24px",
  marginTop: "16px",
  width: "80%",
  maxWidth: "450px",
  wordWrap: "break-word",
  "@media (max-width: 700px)": {
    maxWidth: "55vw"
  },
  "@media (max-width: 400px)": {
    maxWidth: "45vw"
  }
}));
const StyledFormLabel = styled(FormLabel)(() => ({
  fontWeight: 700,
  "& .MuiFormLabel-asterisk": {
    color: "#ff0000"
  }
}));
const StyledHelperText = styled("div")(() => ({
  color: "#B3B3B3",
  fontSize: "12px"
}));
export const AdvertisementApproval = () => {
  const { advertisementId, advertiserId, compressEmail } = useParams<{
    advertiserId: string;
    advertisementId: string;
    compressEmail: string;
  }>();
  const { enqueueSnackbar } = useSnackbar();
  const [approve, setApprove] = useState<boolean | null>(null);
  const [feedback, setFeedback] = useState<string>("");
  const setSignUpToken = useSetRecoilState<string>(signupTokenAtom);
  const [approvalCompleted, setApprovalCompleted] = useState<boolean>(false);
  const { data, isFetching } =
    useAdminAdvertiserAdvertisementIdCompressedEmailGet(
      advertiserId as string,
      advertisementId as string,
      encodeURIComponent(encodeURIComponent(compressEmail as string))
    );
  const { control } = useForm({
    mode: "onTouched",
    defaultValues: {}
  });
  const { mutate: save, isLoading: isSaving } =
    useAdminAdvertiserIdAdvertisementIdCompressedEmailPut();
  const saveHandler = () => {
    const values = {
      ...(!approve && { feedback }),
      isApproved: approve as boolean
    };
    try {
      save(
        {
          advertiserId: advertiserId!,
          advertisementId: advertisementId!,
          compressedEmail: compressEmail!,
          data: values
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Feedback added successfully!", {
              variant: "success"
            });
            setApprovalCompleted(true);
          },
          onError: () => {
            enqueueSnackbar("Failed to add feedback!", {
              variant: "error"
            });
          }
        }
      );
    } catch (error) {
      enqueueSnackbar("Failed to add feedback!", {
        variant: "error"
      });
    }
  };
  const [adDetails, setAdDetails] = useState({
    title: "",
    description: "",
    media: "",
    icon: undefined,
    redirect: "",
    adplacement: "",
    adLayout: "",
    orgName: "",
    advertiserName: "",
    approvedByOther: false,
    declinedByOther: false,
    approvedBy: "",
    declinedBy: "",
    linkText: "",
    actionButton: ""
  });
  useEffect(() => {
    if (data) {
      const latestFeedbacks = data.data.feedbacks?.filter((f) => {
        const feedbackCreatedAt = new Date(f.createdAt);
        feedbackCreatedAt.setSeconds(0, 0);
        const dataCreatedAt = new Date(data.data.updatedAt);
        dataCreatedAt.setSeconds(0, 0);
        return feedbackCreatedAt.getTime() >= dataCreatedAt.getTime();
      });

      //@ts-ignore
      setAdDetails((details) => ({
        ...details,
        title: data.data.title || "",
        description: data.data.description || "",
        media:
          data.data.layout == "Link" && data.data.metadata?.image
            ? data.data.metadata.image
            : data.data.media?.baseUrl
              ? data.data.media.baseUrl + data.data.media.path
              : "",
        logo: data.data.advertiser?.logo?.baseUrl
          ? data.data.advertiser?.logo?.baseUrl +
            data.data.advertiser?.logo?.path
          : undefined,
        redirect: data.data.redirectLink || "",
        adplacement: data.data.placement || "",
        adLayout: data.data.layout || "",
        orgName: !data.data.advertiser.organizationId
          ? "SportsGravy"
          : data.data.advertiser
            ? data.data.advertiser.organization?.name
            : "",
        advertiserName: data.data.advertiser?.businessName || "",
        approvedByOther:
          latestFeedbacks &&
          latestFeedbacks.find((f) => f.isApproved && !f.isOrgFeedback)
            ? data.data.feedbacks.find((f) => f.isApproved && !f.isOrgFeedback)
                ?.isApproved
            : false,
        declinedByOther:
          latestFeedbacks &&
          latestFeedbacks.find((f) => !f.isApproved && !f.isOrgFeedback)
            ? !data.data.feedbacks.find(
                (f) => !f.isApproved && !f.isOrgFeedback
              )?.isApproved
            : false,
        approvedBy:
          (latestFeedbacks &&
            (data.data.advertiser?.organizationId
              ? latestFeedbacks.find((f) => f.isApproved && !f.isOrgFeedback)
                  ?.contactSentBy?.firstName
              : latestFeedbacks.find((f) => f.isApproved && !f.isOrgFeedback)
                  ?.sentBy?.firstName)) ||
          "",
        declinedBy:
          (latestFeedbacks &&
            (data.data.advertiser?.organizationId
              ? latestFeedbacks.find((f) => !f.isApproved && !f.isOrgFeedback)
                  ?.contactSentBy?.firstName
              : latestFeedbacks.find((f) => !f.isApproved && !f.isOrgFeedback)
                  ?.sentBy?.firstName)) ||
          "",
        actionButton: data.data.actionButton || "",
        businessName: data.data.advertiser?.businessName || "",
        linkText: data.data.linkText || ""
      }));
      setSignUpToken(data.headers["x-sportsgravy-token"]);
    }
  }, [data]);
  const adProperties = AD_LAYOUT_OPTIONS.find(
    (ad) => ad.value === adDetails.adLayout
  );
  return !approvalCompleted ? (
    <Loader isLoading={isFetching}>
      <Grid spacing={2} direction={"column"}>
        <Grid>
          <Typography
            style={{
              fontSize: "26px",
              fontWeight: 300,
              lineHeight: "36px",
              fontFamily: "Open Sans"
            }}
          >
            Advertisement Approval
          </Typography>
          <Typography
            style={{
              marginTop: "5px",
              fontSize: "14px",
              fontWeight: 400,
              color: "#666",
              fontFamily: "Open Sans",
              lineHeight: "20px"
            }}
          >
            {adDetails.orgName} is requesting your approval or feedback for the
            advertisement below.
          </Typography>
          {(adDetails.approvedByOther || adDetails.declinedByOther) && (
            <Box
              sx={{
                background: "#1ABC9C26",
                padding: "12px",
                marginTop: "15px",
                display: "flex",
                alignItems: "center"
              }}
            >
              <img style={{ padding: "12px" }} src={alertCircle} alt="alert" />
              <Typography
                style={{
                  display: "inline",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "#000",
                  fontFamily: "Open Sans",
                  lineHeight: "16px"
                }}
              >
                {adDetails.approvedByOther
                  ? `${adDetails.approvedBy} at ${adDetails.advertiserName} has already approved the advertisement below.`
                  : adDetails.declinedByOther
                    ? `${adDetails.declinedBy} at ${adDetails.advertiserName} has already declined and provided feedback to update this advertisement.`
                    : ""}
              </Typography>
            </Box>
          )}
          <Typography
            style={{ fontWeight: 700, fontSize: "13px", marginTop: "24px" }}
          >
            Ad Preview
          </Typography>
          <LivePreview
            layout={adDetails.adLayout}
            placement={adDetails.adplacement}
            adDetails={adDetails}
            type={"email"}
          />
          <Typography
            style={{ fontWeight: 700, fontSize: "13px", marginTop: "24px" }}
          >
            Ad Details:
          </Typography>
          <Box display={"flex"}>
            <StyledTypographyLabel data-testid="adplacement">
              Ad Placement
            </StyledTypographyLabel>
            <StyledTypographyValue data-testid="adplacement-value">
              {" "}
              {
                AD_PLACEMENT_OPTIONS.find(
                  (p) => p.value === adDetails.adplacement
                )?.label
              }
            </StyledTypographyValue>
          </Box>
          {adDetails.adplacement !== "INSTREAM" && (
            <Box display={"flex"}>
              <StyledTypographyLabel data-testid="adlayout">
                Ad Layout
              </StyledTypographyLabel>
              <StyledTypographyValue data-testid="adlayout-value">
                {adProperties?.label}
              </StyledTypographyValue>
            </Box>
          )}
          {adProperties?.fields.includes("title") &&
            adDetails.adplacement != "FEED_POST" && (
              <>
                <Box display={"flex"}>
                  <StyledTypographyLabel data-testid="adtitle">
                    Ad Title
                  </StyledTypographyLabel>
                  <StyledTypographyValue data-testid="adtitle-value">
                    {adDetails.title}
                    <StyledHelperText data-testid="adtitle-helper">
                      Max allowed character with spaces is 40.
                    </StyledHelperText>
                  </StyledTypographyValue>
                </Box>
              </>
            )}
          {adProperties?.fields.includes("description") &&
            adDetails.adplacement != "FEED_POST" && (
              <>
                <Box display={"flex"}>
                  <StyledTypographyLabel data-testid="addescription">
                    Ad Description
                  </StyledTypographyLabel>
                  <StyledTypographyValue data-testid="addescription-value">
                    {adDetails.description}
                    <StyledHelperText data-testid="addescription-helper">
                      Max allowed character with spaces is 100.
                    </StyledHelperText>
                  </StyledTypographyValue>
                </Box>
              </>
            )}
          {adDetails.adplacement == "FEED_POST" && (
            <>
              {adProperties?.fields.includes("description") &&
                adDetails.description && (
                  <>
                    <Box display={"flex"}>
                      <StyledTypographyLabel data-testid="addescription">
                        Ad Intro Copy
                      </StyledTypographyLabel>
                      <StyledTypographyValue data-testid="addescription-value">
                        {adDetails.description}
                        <StyledHelperText data-testid="addescription-helper">
                          Max allowed character with spaces is 150.
                        </StyledHelperText>
                      </StyledTypographyValue>
                    </Box>
                  </>
                )}
              {adDetails.redirect != "" && (
                <>
                  <Box display={"flex"}>
                    <StyledTypographyLabel data-testid="adredirect">
                      Ad Redirect Link
                    </StyledTypographyLabel>
                    <StyledTypographyValue data-testid="adredirect-value">
                      {adDetails.redirect}
                    </StyledTypographyValue>
                  </Box>
                </>
              )}
              {adProperties?.fields.includes("title") &&
                adDetails.adLayout?.includes("Link") && (
                  <>
                    <Box display={"flex"}>
                      <StyledTypographyLabel data-testid="adtitle">
                        Call To Action Title
                      </StyledTypographyLabel>
                      <StyledTypographyValue data-testid="adtitle-value">
                        {adDetails.title}
                        <StyledHelperText data-testid="adtitle-helper">
                          Max allowed character with spaces is 30.
                        </StyledHelperText>
                      </StyledTypographyValue>
                    </Box>
                  </>
                )}
              {adDetails.linkText != "" &&
                adDetails.adLayout?.includes("Link") && (
                  <>
                    <Box display={"flex"}>
                      <StyledTypographyLabel data-testid="adlinkText">
                        Call To Action URL Text
                      </StyledTypographyLabel>
                      <StyledTypographyValue data-testid="adlinkText-value">
                        {adDetails.linkText}
                        <StyledHelperText data-testid="adlinkText-helper">
                          Max allowed character with spaces is 30.
                        </StyledHelperText>
                      </StyledTypographyValue>
                    </Box>
                  </>
                )}
              {adDetails.actionButton != "" &&
                adDetails.adLayout?.includes("Link") && (
                  <>
                    <Box display={"flex"}>
                      <StyledTypographyLabel data-testid="adActionButton">
                        Call To Action Button
                      </StyledTypographyLabel>
                      <StyledTypographyValue data-testid="adActionButton-value">
                        {
                          CALL_TO_ACTION_OPTIONS.find(
                            (b) => b.value == adDetails.actionButton
                          )?.label
                        }
                        <StyledHelperText>
                          Options: Buy Now, Learn More, Register, Sign Up
                        </StyledHelperText>
                      </StyledTypographyValue>
                    </Box>
                  </>
                )}
            </>
          )}
          <div style={{ margin: "24px 0" }} data-testid="approve-buttons">
            <StyledFormLabel required>Do you Approve?</StyledFormLabel>
            <div style={{ marginTop: "12px" }}>
              <RadioGroup
                row
                name="approve"
                onChange={(e) => {
                  setApprove(e.target.value === "true" ? true : false);
                }}
              >
                <FormControlLabel
                  value={"true"}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value={"false"}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </div>
            {approve == false && (
              <div style={{ marginTop: "24px" }} data-testid="feedback">
                <FormInput
                  control={control}
                  required
                  name="feedback"
                  label="Please provide your feedback"
                  multiline
                  rows={3}
                  type="text"
                  rules={{ required: "Feedback is required" }}
                  onChange={(e) => {
                    setFeedback(e.target.value);
                  }}
                />
              </div>
            )}
          </div>
          <div>
            <Button
              disabled={
                approve == null
                  ? true
                  : approve != null && approve == false && feedback == ""
                    ? true
                    : approve != null && approve == false && feedback != ""
                      ? false
                      : isSaving
                        ? true
                        : false
              }
              isLoading={isSaving}
              onClick={() => {
                saveHandler();
              }}
            >
              Submit
            </Button>
          </div>
        </Grid>
      </Grid>
    </Loader>
  ) : (
    <Grid container spacing={2} direction={"column"}>
      <Grid>
        <Typography
          style={{
            fontSize: "26px",
            fontWeight: 300,
            lineHeight: "36px",
            fontFamily: "Open Sans"
          }}
        >
          Congratulations
        </Typography>
        <Typography
          style={{
            marginTop: "5px",
            fontSize: "14px",
            fontWeight: 400,
            color: "#666",
            fontFamily: "Open Sans",
            lineHeight: "20px",
            marginBottom: "24px"
          }}
        >
          You have successfully submitted your feedback to update your
          advertisement. A notification has been sent to the sports
          organization.
        </Typography>
      </Grid>
    </Grid>
  );
};
