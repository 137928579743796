import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import { SyntheticEvent, useEffect, useState } from "react";
import { TeamsProgramsSettings } from "./TeamsProgramsSettings";
import { Form } from "@components/crud/Form";
import { organizationAtom } from "@recoil/auth";
import { useRecoilState } from "recoil";
import { LiveStreamingSetting } from "./LiveStreamingSetting";
import { SubscriptionBillingSettings } from "./SubscriptionsBillingSettings";
import { CalendarSetting } from "./CalendarSetting";
import { MobileApp } from "./MobileApp";
import { ProductEngineering } from "./ProductEngineering";
import { FeedSetting } from "./FeedSetting";
import { DashboardSetting } from "./DashboardSetting";
import { MarketingSetting } from "./MarketingSetting";
import { FSGOAdvertiserSettings } from "./FSGOAdvertiserSettings";
import { CRMSetting } from "./CRMSetting";
import {
  Grid,
  MenuItem,
  Select,
  styled,
  Tab,
  Tabs,
  Typography
} from "@mui/material";

const StyledTabs = styled(Tabs)(({ theme }) => ({
  position: "relative",
  top: 2,
  paddingBottom: 1,
  zIndex: 1,
  overflow: "visible",
  paddingLeft: 24,

  "& .MuiTabs-fixed.MuiTabs-scroller": {
    position: "static"
  },

  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.white.main,
    height: "1px",
    marginBottom: "-1px",
    zIndex: 1,
    transition: "none"
  },

  "& .MuiTab-root": {
    textTransform: "none",
    padding: "8px 20px",

    "&.Mui-selected": {
      backgroundColor: theme.palette.white.main,
      border: `1px solid ${theme.palette.divider}`,
      borderBottom: "1px solid transparent",
      borderRadius: "12px",
      borderBottomLeftRadius: "0",
      borderBottomRightRadius: "0"
    }
  }
}));

export const Settings = () => {
  const [organizationId] = useRecoilState(organizationAtom);
  const [mode, setMode] = useState(organizationId ? "FSO" : "MOBILE");
  const [tab, setTab] = useState(
    organizationId ? "Dashboard" : "Subscription & Billing"
  );
  const [tabs, setTabs] = useState<string[]>([]);
  const onTabChange = (event: SyntheticEvent, value: unknown) => {
    setTab(value as string);
  };
  useEffect(() => {
    if (mode === "FSO")
      setTabs(
        organizationId
          ? [...["Dashboard", "Calendar", "Teams/Programs", "Live Streaming"]]
          : []
      );
    else if (mode === "MOBILE") {
      setTabs([...["Subscription & Billing", "Live Streaming", "App Version"]]);
      setTab("Subscription & Billing");
    } else if (mode === "FSGO") {
      setTabs([
        ...[
          "Feed",
          "Dashboard",
          "Live Streaming",
          "CRM",
          "Marketing",
          "Product & Engineering"
        ]
      ]);
      setTab("Feed");
    }
  }, [mode]);
  return (
    <Container>
      <Toolbar title="Settings" />
      {!organizationId && (
        <Select
          style={{
            marginTop: "10px",
            width: "40%",
            marginLeft: "24px"
          }}
          value={mode}
          onChange={(e) => {
            setMode(e.target.value);
          }}
        >
          <MenuItem value={"MOBILE"}>Mobile App</MenuItem>
          <MenuItem value={"FSGO"}>FSGO</MenuItem>
          <MenuItem value={"FSO"}>FSO</MenuItem>
        </Select>
      )}
      {!!tabs.length && (
        <StyledTabs
          value={tab}
          onChange={onTabChange}
          style={{ marginTop: "10px" }}
        >
          {tabs.map((tab) => (
            <Tab
              data-testid={"TAB_BUTTON_" + tab}
              key={tab}
              label={
                <Grid
                  container
                  sx={{
                    flex: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "15px"
                  }}
                >
                  <Typography variant="tableHeader">{tab}</Typography>
                </Grid>
              }
              value={tab}
            />
          ))}
        </StyledTabs>
      )}
      <Form style={{ padding: "24px 0 0 0" }}>
        {tab === "Calendar" && organizationId && <CalendarSetting />}
        {tab === "Teams/Programs" && <TeamsProgramsSettings />}
        {tab === "Subscriptions and Billing" && <SubscriptionBillingSettings />}
        {mode === "MOBILE" && <MobileApp activeTab={tab} />}
        {tab === "Live Streaming" &&
          (organizationId ? (
            <LiveStreamingSetting />
          ) : (
            mode === "FSGO" && <FSGOAdvertiserSettings />
          ))}
        {mode == "FSGO" && tab === "Product & Engineering" && (
          <ProductEngineering />
        )}
        {mode == "FSGO" && tab === "Feed" && <FeedSetting />}
        {tab === "Dashboard" && <DashboardSetting />}
        {mode == "FSGO" && tab === "Marketing" && <MarketingSetting />}
        {mode == "FSGO" && tab === "CRM" && <CRMSetting />}
      </Form>
    </Container>
  );
};
