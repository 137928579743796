import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Toolbar } from "@components/crud/Toolbar";
import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { LeadDetailsForm } from "./LeadDetailsForm";
import { Form } from "@components/crud/Form";
import {
  adminCrmOpportunityOpportunityIdGet,
  ContactResponse,
  getAdminCrmAccountAccountId,
  getAdminCrmAccountAccountIdContact,
  useAdminCrmLeadPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";
import formatFullName from "@utils/formatFullName";
import { hasPermission } from "@services/Casbin";

export const LeadCreate = () => {
  const navigate = useNavigate();
  const form = useForm({
    mode: "onTouched"
  });

  const {
    formState: { isValid },
    reset,
    getValues
  } = form;

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("crm.leads", "ADD");
      if (!create) navigate("/not-found");
    };
    fetchPermissions();
  }, []);

  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [leadOwner, setLeadOwner] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedContact, setSelectedContact] = useState("");

  const [contacts, setContacts] = useState<
    {
      label: string;
      value: string;
      isPrimary: boolean;
      details: ContactResponse;
      jobTitle: string;
      workEmail: string;
      workPhone: string;
    }[]
  >([]);

  const { mutate: save, isLoading: isSaving } = useAdminCrmLeadPost();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const opportunityId = searchParams.get("opportunityId");
  const accountId = searchParams.get("accountId");
  useEffect(() => {
    const fetchData = async () => {
      if (opportunityId) {
        const data = await adminCrmOpportunityOpportunityIdGet(opportunityId);
        if (data.data.accountId) {
          const account = data.data.account;
          const contact = data.data.contact;
          reset({
            account: {
              name: account?.name || "",
              parent: account?.parent?.name || "",
              category: account?.category,
              type: account?.type,
              officeAddress: account?.officeAddress,
              email: account?.email,
              website: account?.website,
              sportsOffered: account?.sports?.map((sp) => sp.sportId) || [],
              noOfAthletes: account?.numberOfAthletes,
              aeOwner: account?.aeOwner?.person
                ? formatFullName(account?.aeOwner?.person)
                : ""
            },
            contact: {
              firstName: contact?.firstName,
              lastName: contact?.lastName,
              personalEmail: contact?.email,
              homeAddress: contact?.location,
              phoneType: "MOB",
              phone: contact?.phone,
              whatsappNumber: contact?.whatsappNumber
            }
          });
          setSelectedAccount(data.data.accountId);
          // setSelectedContact(data.data.contactId);
        }
      }
    };
    fetchData();
  }, [opportunityId]);

  useEffect(() => {
    const fetchData = async () => {
      if (accountId) {
        const data = await getAdminCrmAccountAccountId(accountId);
        if (data.data.accountId) {
          const account = data.data;
          // const contact = data.data.contact;
          reset({
            account: {
              name: account?.name || "",
              parent: account?.parent?.name || "",
              category: account?.category,
              type: account?.type,
              officeAddress: account?.officeAddress,
              email: account?.email,
              website: account?.website,
              sportsOffered: account?.sports?.map((sp) => sp.sportId) || [],
              noOfAthletes: account?.numberOfAthletes,
              aeOwner: account?.aeOwner?.person
                ? formatFullName(account?.aeOwner?.person)
                : ""
            }
            // contact: {
            //   firstName: contact?.firstName,
            //   lastName: contact?.lastName,
            //   personalEmail: contact?.email,
            //   homeAddress: contact?.location,
            //   phoneType: "MOB",
            //   phone: contact?.phone,
            //   whatsappNumber: contact?.whatsappNumber
            // }
          });
          setSelectedAccount(data.data.accountId);
          // setSelectedContact(data.data.contactId);
        }
      }
    };
    fetchData();
  }, [accountId]);

  const onSave = () => {
    const data = {
      name: getValues().name,
      accountId: selectedAccount,
      contactId: selectedContact,
      ownerId: leadOwner,
      source: {
        name: "",
        type: getValues().source
      },
      status: getValues().status
    };
    save(
      {
        data: data
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Lead Created successfully!", {
            variant: "success"
          });

          if (accountId) navigate(`/crm/accounts/${accountId}`);
          else if (opportunityId)
            navigate(`/crm/opportunities/${opportunityId}`);
          else navigate("/crm/leads");
        },
        onError: () => {
          enqueueSnackbar("Failed to Create Lead!", {
            variant: "error"
          });
        }
      }
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedAccount) {
        try {
          const data =
            await getAdminCrmAccountAccountIdContact(selectedAccount);
          setContacts(
            data.data.map((c) => ({
              label: `${c.contact.firstName} ${c.contact.lastName}`,
              value: c.contactId as string,
              isPrimary: !!c.isPrimary,
              jobTitle: c.jobTitle || "",
              workEmail: c.workEmail || "",
              workPhone: c.workPhone || "",
              details: c.contact as ContactResponse
            }))
          );
          const primaryOrFirstContact =
            data.data.find((c) => c.isPrimary)?.contactId ||
            data.data?.[0]?.contactId;
          setSelectedContact(primaryOrFirstContact || "");
        } catch (error) {
          setContacts([]);
        }
      }
    };
    fetchData();
  }, [selectedAccount]);
  return (
    <Container>
      <Toolbar title="Add Lead" />
      <Form>
        <LeadDetailsForm
          form={form}
          leadOwner={leadOwner}
          setLeadOwner={setLeadOwner}
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
          selectedContact={selectedContact}
          setSelectedContact={setSelectedContact}
          contacts={contacts}
          setContacts={setContacts}
        />
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={() => {
          onSave();
        }}
        isDisabled={!isValid || isSaving}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/crm/leads")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
