import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import {
  ModelFeature,
  ModelFeatureRelease,
  ModelPerson,
  useAdminDirectoryGet,
  useAdminFeatureGet,
  useAdminReleaseIdGet,
  useAdminReleaseIdPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import { Typography, styled } from "@mui/material";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormSelect } from "@components/FormSelect";
import { Form } from "@components/crud/Form";
import TickIcon from "../../assets/icons/tick.svg";
import { FeatureBar } from "./FeatureBar";

const filterOptions = [
  { label: "All", value: "all" },
  { label: "Mobile", value: "MOB" },
  { label: "Web", value: "WEB" }
];

const StyledStatusHeader = styled(Typography)(() => ({
  color: "#666666",
  fontWeight: 500,
  fontSize: "14px",
  display: "inline-block",
  width: "170px"
}));
const StyledStatusValue = styled(Typography)(() => ({
  color: "#666666",
  fontWeight: 500,
  display: "inline-block"
}));

interface TestcaseStatus {
  totalFeatures: number;
  totalFeaturesPassed: number;
  testcasePassed: number;
  testcaseNotTested: number;
  testcaseFailed: number;
  testcaseHold: number;
  testcaseBlocked: number;
}

const renderTree = (
  control,
  testerOptions,
  allFeaturesSelected,
  node,
  level
) => {
  return (
    <div key={node.featureId}>
      <FeatureBar
        key={node.featureId}
        control={control}
        companyDirectory={testerOptions}
        level={level}
        node={node}
        allChecked={allFeaturesSelected}
      />
      {node.children &&
        node.children.length > 0 &&
        node.children.map((child) =>
          renderTree(
            control,
            testerOptions,
            allFeaturesSelected,
            child,
            level + 1
          )
        )}
    </div>
  );
};

export const ReleaseEdit = () => {
  const organizationId = useRecoilValue(organizationAtom);
  const navigate = useNavigate();
  const { releaseId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [filter, setFilter] = useState("all");
  const [saveBtnDisabled, setSaveBtnDisabled] = useState<boolean>(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [options, setOptions] = useState<ModelFeature[]>([]);
  const [allFeaturesSelected, setAllFeaturesSelected] = useState(false);
  const [allFeaturesIds, setAllFeaturesIds] = useState<string[]>([]);
  const [renderFeature, setRenderFeature] = useState(false);
  //@ts-ignore
  const [testcaseStatus, setTestcaseStatus] = useState<TestcaseStatus>({});
  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
    reset,
    setValue
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      filter: "all",
      name: "",
      description: ""
    }
  });
  const { data: data, isLoading: isLoading } = useAdminFeatureGet({
    flatten: false,
    platform: filter,
    sortField: "name",
    sortDirection: "asc",
    pageSize: 100
  });
  const { data: companyDirectory, isLoading: companyDirectoryLoading } =
    useAdminDirectoryGet({
      pageSize: 1000
    });

  const { data: release, isLoading: releaseLoading } = useAdminReleaseIdGet(
    releaseId!
  );
  useEffect(() => {
    if (release?.data) {
      const defaultValues = {
        name: release.data.name,
        description: release.data.description || "",
        released: release.data.releaseStatus === "RELEASED"
      };
      //@ts-ignore
      setTestcaseStatus(release.data.testcaseStatus);
      release.data.features!.map((feature: ModelFeatureRelease) => {
        defaultValues[feature.featureId] = true;
        if (feature.iosTesterId)
          defaultValues[feature.featureId + ".iosTester"] = feature.iosTesterId;
        if (feature.andTesterId)
          defaultValues[feature.featureId + ".andTester"] = feature.andTesterId;
        if (feature.webTesterId)
          defaultValues[feature.featureId + ".webTester"] = feature.webTesterId;
      });
      reset(defaultValues);
      setRenderFeature(true);
    }
  }, [release]);
  const testerOptions = useMemo(
    () =>
      (companyDirectory &&
        companyDirectory.data.persons &&
        companyDirectory.data.persons
          .map((person: ModelPerson) => {
            if (person?.sportsgravyUser) {
              return {
                label: person.firstName + " " + person.lastName,
                value: person.personId
              };
            }
            return null;
          })
          .filter(Boolean)) ||
      [],
    [companyDirectory?.data]
  );
  const getAllFeatureIds = (features) => {
    const result: string[] = [];

    const traverse = (feature: ModelFeature) => {
      result.push(feature.featureId as string);
      if (feature.children) feature.children.forEach(traverse);
    };

    features.forEach(traverse);
    return result;
  };
  useEffect(() => {
    if (data?.data?.features) {
      setOptions(data.data.features);
      setAllFeaturesIds(getAllFeatureIds(data.data.features));
    }
  }, [data]);
  useEffect(() => {
    if (organizationId) navigate("/not-found");
    const create = hasPermission(
      "ORGANIZATION",
      organizationId!,
      "tech.test-cases",
      "ADD"
    );
    create.then((res) => {
      if (!res) navigate("/not-found");
    });
  }, [organizationId]);

  const { mutate: save, isLoading: isSaving } = useAdminReleaseIdPut();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    async (formValues) => {
      const values = {
        ...formValues
      };
      const data = {
        name: values.name,
        description: values.description,
        features: Object.entries(values)
          .filter(
            ([key, value]) =>
              key !== "allFeatures" &&
              typeof value === "object" &&
              value !== null
          )
          .map(([featureId, testers]) => ({
            featureId,
            ...(testers &&
              //@ts-ignore
              testers.iosTester && { iosTesterId: testers.iosTester }),
            ...(testers &&
              //@ts-ignore
              testers.andTester && { andTesterId: testers.andTester }),
            ...(testers &&
              //@ts-ignore
              testers.webTester && { webTesterId: testers.webTester })
          }))
      };
      const existingFeaturesMap: Map<string, ModelFeatureRelease> = new Map(
        //@ts-ignore
        release.data.features.map((f) => [f.featureId, f])
      );
      const addedFeatures = [];
      const deletedFeatures = [];
      const editedFeatures = [];
      for (const feature of data.features) {
        if (!existingFeaturesMap.has(feature.featureId)) {
          //@ts-ignore
          addedFeatures.push(feature);
        } else {
          const existingFeature: ModelFeatureRelease | undefined =
            existingFeaturesMap.get(feature.featureId);
          if (
            existingFeature &&
            (feature.iosTesterId != existingFeature.iosTesterId ||
              feature.andTesterId != existingFeature.andTesterId ||
              feature.webTesterId != existingFeature.webTesterId)
          ) {
            //@ts-ignore
            editedFeatures.push(feature);
          }
          existingFeaturesMap.delete(feature.featureId);
        }
      }
      //@ts-ignore
      deletedFeatures.push(...existingFeaturesMap.values());
      const processedData = {
        name: data["name"],
        description: data["description"]
      };
      if (values["released"]) processedData["releaseStatus"] = "RELEASED";
      processedData["featuresToAdd"] = addedFeatures;
      processedData["featuresToRemove"] = deletedFeatures;
      processedData["featuresToEdit"] = editedFeatures;
      try {
        save(
          {
            releaseId: releaseId!,
            data: processedData
          },
          {
            onSuccess: () => {
              setSaveBtnDisabled(false);
              enqueueSnackbar("Release edited successfully!", {
                variant: "success"
              });
              if (resetInsteadOfRoute) {
                reset();
              } else {
                navigate(`/releases/${releaseId}`);
              }
            },
            onError: () => {
              setSaveBtnDisabled(false);
              enqueueSnackbar("Failed to edit release!", {
                variant: "error"
              });
            }
          }
        );
      } catch (error) {
        enqueueSnackbar("Failed to edit release!", {
          variant: "error"
        });
      }
    };

  const renderedTree = useMemo(() => {
    return (
      <Grid sx={{ padding: "0px 0px" }} xs={6}>
        <Loader isLoading={isLoading || companyDirectoryLoading}>
          {testerOptions &&
            options.map((feature) =>
              renderTree(
                control,
                testerOptions,
                allFeaturesSelected,
                feature,
                0
              )
            )}
        </Loader>
      </Grid>
    );
  }, [isLoading, testerOptions, options, allFeaturesSelected]);

  const handleAllFeatureSelect = (e) => {
    const isChecked = e.target.checked;
    allFeaturesIds.forEach((id) => {
      //@ts-ignore
      setValue(id, isChecked);
    });
  };
  return (
    <Container>
      <Toolbar title="Edit Release" />
      <Loader isLoading={releaseLoading}>
        <Form sx={{ paddingBottom: "0 !important" }}>
          <Grid data-testid="release-add-form" container spacing={3}>
            <Grid data-testid="release-name" xs={6}>
              <FormInput
                control={control}
                name="name"
                type="text"
                label="Name"
                required={true}
                rules={{
                  required: "Name is required"
                }}
              />
            </Grid>
            <Grid data-testid="release-description" xs={6}>
              <FormInput
                control={control}
                name="description"
                type="text"
                label="Description"
              />
            </Grid>
            <Grid data-testid="release-status" xs={3}>
              <Typography variant="formLabel">Status</Typography>
              {release?.data?.releaseStatus == "IN_PROGRESS" && (
                <div
                  style={{
                    background: "#F3F4F7",
                    padding: "14px",
                    borderRadius: "6px",
                    marginTop: "10px"
                  }}
                >
                  <div>
                    <StyledStatusHeader>Total Features </StyledStatusHeader>
                    <StyledStatusValue>
                      {testcaseStatus.totalFeatures &&
                        testcaseStatus.totalFeatures.toLocaleString()}
                    </StyledStatusValue>
                  </div>
                  <div>
                    <StyledStatusHeader>Features Passed</StyledStatusHeader>
                    <StyledStatusValue>
                      {testcaseStatus.totalFeaturesPassed &&
                        testcaseStatus.totalFeaturesPassed.toLocaleString()}
                    </StyledStatusValue>
                  </div>
                  <div>
                    <StyledStatusHeader> Total Test Cases</StyledStatusHeader>
                    <StyledStatusValue>
                      {testcaseStatus.testcasePassed &&
                        (
                          testcaseStatus.testcasePassed +
                          testcaseStatus.testcaseNotTested +
                          testcaseStatus.testcaseFailed +
                          testcaseStatus.testcaseHold +
                          testcaseStatus.testcaseBlocked
                        ).toLocaleString()}
                    </StyledStatusValue>
                  </div>
                  <div>
                    <StyledStatusHeader> Test Cases Passed</StyledStatusHeader>
                    <StyledStatusValue>
                      {testcaseStatus.testcasePassed &&
                        testcaseStatus.testcasePassed.toLocaleString()}
                    </StyledStatusValue>
                  </div>
                  <div>
                    <StyledStatusHeader>
                      Test Cases Not Tested
                    </StyledStatusHeader>
                    <StyledStatusValue>
                      {testcaseStatus.testcaseNotTested &&
                        testcaseStatus.testcaseNotTested.toLocaleString()}
                    </StyledStatusValue>
                  </div>
                  <div>
                    <StyledStatusHeader>Test Cases Failed </StyledStatusHeader>
                    <StyledStatusValue>
                      {testcaseStatus.testcaseFailed &&
                        testcaseStatus.testcaseFailed.toLocaleString()}
                    </StyledStatusValue>
                  </div>
                  <div>
                    <StyledStatusHeader> Test Cases Onhold</StyledStatusHeader>
                    <StyledStatusValue>
                      {testcaseStatus.testcaseHold &&
                        testcaseStatus.testcaseHold.toLocaleString()}
                    </StyledStatusValue>
                  </div>
                  <div>
                    <StyledStatusHeader>Test Cases Blocked </StyledStatusHeader>
                    <StyledStatusValue>
                      {testcaseStatus.testcaseBlocked &&
                        testcaseStatus.testcaseBlocked.toLocaleString()}
                    </StyledStatusValue>
                  </div>
                </div>
              )}
              {release?.data?.releaseStatus == "READY_TO_RELEASE" && (
                <div
                  style={{
                    background: "#E2FCF7",
                    padding: "14px",
                    color: "#095A4A",
                    fontWeight: 500,
                    fontSize: "14px",
                    borderRadius: "6px",
                    marginTop: "10px",
                    width: "153px"
                  }}
                >
                  Ready for Release
                </div>
              )}
              {release?.data?.releaseStatus == "RELEASED" && (
                <div
                  style={{
                    background: "#1ABC9C",
                    padding: "14px",
                    color: "#fff",
                    fontWeight: 500,
                    fontSize: "14px",
                    borderRadius: "6px",
                    marginTop: "10px",
                    width: "123px",
                    display: "flex"
                  }}
                >
                  <img src={TickIcon} />
                  <span style={{ marginLeft: "8px" }}>Released</span>
                </div>
              )}
            </Grid>
            <Grid xs={12}>
              <FormCheckbox
                control={control}
                name="released"
                label="Released"
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              background: "#f3f4f7",
              marginTop: "24px",
              padding: "12px 24px",
              margin: "24px -24px 0px -24px"
            }}
          >
            <Grid xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <div style={{ maxWidth: "50px" }}>
                <FormCheckbox
                  control={control}
                  name="allFeatures"
                  onChange={(e) => {
                    setAllFeaturesSelected(e.target.checked);
                    handleAllFeatureSelect(e);
                  }}
                />
              </div>
              <Typography
                style={{
                  marginLeft: "20px",
                  marginRight: "24px",
                  fontWeight: 600,
                  fontSize: "18px",
                  color: "#666666"
                }}
              >
                Features
              </Typography>
              <FormSelect
                name="filter"
                options={filterOptions}
                onChange={(e) => setFilter(e.target.value)}
                sx={{ width: "200px", background: "#fff" }}
              />
            </Grid>
            <Grid xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "18px",
                  color: "#666666"
                }}
              >
                Platforms / Testers
              </Typography>
            </Grid>
          </Grid>
          <Grid sx={{ padding: "0px 24px" }} xs={6}>
            <Loader isLoading={isLoading || releaseLoading}>
              {renderFeature && renderedTree}
            </Loader>
          </Grid>
        </Form>
      </Loader>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={() => {
          setSaveBtnDisabled(true);
          handleSubmit(saveHandler(false))();
        }}
        isDisabled={!isValid || !isDirty || isSaving || saveBtnDisabled}
        isLoading={isSaving || saveBtnDisabled}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/releases")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
