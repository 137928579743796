/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, Avatar, TextField, Typography } from "@mui/material";
import { getUsers } from "@services/Network";
import {
  ModelPerson,
  ModelUser,
  useAdminConnectionGroupGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useMemo, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { CalendarAttendee } from "./CalendarAttendee";
import ShareWithIcon from "../../../../src/assets/icons/shareWithIcon.svg";
import { organizationAtom, organizationsAtom, profileAtom } from "@recoil/auth";
import { useRecoilValue } from "recoil";
import { Container } from "@components/crud/Container";
import Grid from "@mui/system/Unstable_Grid/Grid";
import ClearIcon from "@mui/icons-material/Clear";
import {
  CalendarConnectionGroupSearch,
  ConnectionGroup
} from "./CalendarConnectionGroupSearch";
import TeamIcon from "../../../../src/assets/icons/teamIcon.svg";
import personGroupIcon from "../../../../src/assets/icons/personGroupIcon.svg";

export const ShareWith = (props: {
  name: string;
  control: Control<any, any>;
  required: boolean | undefined;
  disabled?: boolean;
  setPersons?: React.Dispatch<React.SetStateAction<ModelUser[]>>;
  persons: ModelPerson[] | ModelUser[];
  setConnectionGroups?: React.Dispatch<React.SetStateAction<ConnectionGroup[]>>;
  connectionGroups?: any;
  onChange?: (arg0: any) => void;
  action?;
}) => {
  const orgId = useRecoilValue(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const org = organizations.find((org) => org.organizationId === orgId);
  const loggedInUser = useRecoilValue(profileAtom);
  const [personAddMenu, setPersonAddMenu] = useState(false);
  const [personSearch, setPersonSearch] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const { data: personResults, isFetching: isFetchingUsers } = getUsers(
    {
      textSearch: encodeURIComponent(personSearch) || "a",
      organizationId: orgId
    },
    { staleTime: Infinity }
  );
  const { data: connectionGroupResults } = useAdminConnectionGroupGet({
    organizationId: orgId!
  });

  const connectionGroupOptions = useMemo(() => {
    const orgOption = [
      {
        name: org?.name as string,
        id: org?.organizationId as string,
        description: "Organization",
        orgOwned: true,
        kind: "Organization"
      }
    ];

    const trainingProgram =
      connectionGroupResults?.data.programs &&
      connectionGroupResults.data.programs.map((program) => ({
        name: program.name,
        id: program.programId,
        description: "Training Program",
        orgOwned: !!program.organizationId,
        kind: "TrainingProgram"
      }));

    const trainingProgramPersonGroups =
      connectionGroupResults?.data.programs &&
      connectionGroupResults.data.programs.flatMap(
        (program) =>
          program.personGroups &&
          program.personGroups.map((group) => ({
            name: group.name,
            description: `${program.name} - Person Group`,
            id: group.groupId,
            orgOwned: !!program.organizationId,
            kind: "ConnectionGroup"
          }))
      );

    const team =
      connectionGroupResults?.data.teams &&
      connectionGroupResults.data.teams.map((team) => ({
        name: team.name,
        id: team.teamId,
        description: "Team",
        orgOwned: !!team.organizationId,
        kind: "Team"
      }));

    const teamPersonGroups =
      connectionGroupResults?.data.teams &&
      connectionGroupResults.data.teams.flatMap(
        (team) =>
          team.personGroups &&
          team.personGroups.map((group) => ({
            name: group.name,
            description: `${team.name} - Person Group`,
            id: group.groupId,
            orgOwned: !!team.organizationId,
            kind: "ConnectionGroup"
          }))
      );

    return [
      ...(orgOption || []),
      ...(trainingProgram || []),
      ...(trainingProgramPersonGroups || []),
      ...(team || []),
      ...(teamPersonGroups || [])
    ];
  }, [connectionGroupResults?.data]);
  const personOptions = [...personResults, null];
  const handleHover = (index: number | null) => {
    setHoveredIndex(index);
  };
  const personAvatar = (person: ModelPerson, index: number) => {
    return (
      <Container>
        <Grid container alignItems="center">
          <Grid>
            <Avatar
              sx={{
                bgcolor: "#" + person.colorCode,
                color: "#fff !important",
                padding: "14px",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "20px",
                textAlign: "center",
                borderRadius: "8px",
                width: "32px",
                height: "32px"
              }}
              variant="square"
            >
              {person.avatar?.baseUrl ? (
                <img
                  src={person.avatar.baseUrl + person.avatar.path}
                  width="32px"
                  height="32px"
                />
              ) : (
                <span>
                  {`${person.firstName} ${person.lastName}`
                    .split(" ")
                    .map((name) => name[0])
                    .join("")
                    .toUpperCase()}
                </span>
              )}
            </Avatar>
          </Grid>

          <Grid
            maxWidth={"fit-content"}
            onMouseEnter={() => handleHover(index)}
            onMouseLeave={() => handleHover(null)}
            marginLeft={"11px"}
            xs
            container
            direction="column"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <Typography style={{ fontSize: "14px", fontWeight: 400 }}>
                {`${person.firstName} ${person.lastName}`}
              </Typography>
              {hoveredIndex === index && (
                <ClearIcon
                  style={{
                    color: "#64748B",
                    cursor: "pointer",
                    fontSize: "20px",
                    width: "200px"
                  }}
                  onClick={() => {
                    if (props.action == "create") {
                      props.setPersons &&
                        props.setPersons((prevPersons: ModelUser[]) =>
                          prevPersons.filter(
                            (p: ModelUser) => p.personId !== person.personId
                          )
                        );
                    }
                    if (props.action == "edit") {
                      props.setPersons &&
                        props.setPersons((prevPersons: ModelUser[]) =>
                          prevPersons.filter((p) =>
                            p.person
                              ? p.person.personId !== person.personId
                              : p.personId !== person.personId
                          )
                        );
                    }
                  }}
                />
              )}
            </div>
            <Typography
              style={{ fontSize: "14px", fontWeight: 400, color: "#64748B" }}
            >
              {`${person.email}`}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  };

  const connectionGroupAvatar = (connectionGroup, index?) => {
    let thumbnailContent;
    if (org) {
      if (
        (connectionGroup?.kind === "Team" ||
          connectionGroup?.kind === "TrainingProgram") &&
        !connectionGroup?.orgOwned
      ) {
        thumbnailContent = (
          <Avatar
            variant="square"
            style={{ width: "32px", height: "32px", borderRadius: "8px" }}
            src={TeamIcon}
            alt="Team Icon"
          />
        );
      } else if (
        connectionGroup?.kind === "ConnectionGroup" &&
        !connectionGroup?.orgOwned
      ) {
        thumbnailContent = (
          <Avatar
            variant="square"
            style={{ width: "32px", height: "32px", borderRadius: "8px" }}
            src={personGroupIcon}
            alt="Person Group Icon"
          />
        );
      } else if (org.avatarId) {
        thumbnailContent = (
          <Avatar
            variant="square"
            style={{ width: "32px", height: "32px", borderRadius: "8px" }}
            src={org.avatar!.baseUrl! + org.avatar!.path!}
            alt="Org Avatar"
          />
        );
      } else {
        if (connectionGroup?.kind === "ConnectionGroup") {
          thumbnailContent = (
            <Avatar
              variant="square"
              style={{ width: "32px", height: "32px", borderRadius: "8px" }}
              src={personGroupIcon}
              alt="Org Avatar"
            />
          );
        }
        if (
          connectionGroup?.kind === "Team" ||
          connectionGroup?.kind === "TrainingProgram"
        ) {
          thumbnailContent = (
            <Avatar
              variant="square"
              style={{ width: "32px", height: "32px", borderRadius: "8px" }}
              src={TeamIcon}
              alt="Org Avatar"
            />
          );
        }
      }
    }
    return (
      <Container>
        <Grid container alignItems="center">
          <Grid>{thumbnailContent}</Grid>

          <Grid
            maxWidth={"fit-content"}
            onMouseEnter={() => handleHover(index)}
            onMouseLeave={() => handleHover(null)}
            marginLeft={"11px"}
            xs
            container
            direction="column"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "200px"
              }}
            >
              <Typography style={{ fontSize: "14px", fontWeight: 400 }}>
                {`${connectionGroup.name}`}
              </Typography>
              {hoveredIndex === index && (
                <ClearIcon
                  style={{
                    color: "#64748B",
                    cursor: "pointer",
                    fontSize: "20px"
                  }}
                  onClick={() => {
                    props.setConnectionGroups &&
                      props.setConnectionGroups((prevConnection) =>
                        prevConnection.filter(
                          (p) => p.id !== connectionGroup.id
                        )
                      );
                  }}
                />
              )}
            </div>
            <Typography
              style={{ fontSize: "14px", fontWeight: 400, color: "#64748B" }}
            >
              {`${connectionGroup.description}`}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "10px"
        }}
      >
        <>
          <img src={ShareWithIcon} />
          <Controller
            control={props.control}
            name={props.name}
            render={() => (
              <Autocomplete
                data-testId="shareWithInput"
                open={personAddMenu}
                clearOnBlur={false}
                sx={{ minWidth: "525px" }}
                onOpen={() => setPersonAddMenu(true)}
                onClose={() => setPersonAddMenu(false)}
                getOptionLabel={(option) =>
                  (option as ModelPerson)?.personId
                    ? `${(option as ModelPerson)?.firstName} ${(option as ModelPerson)?.lastName}`
                    : ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="shareWith"
                    style={{ marginLeft: "14px" }}
                    hiddenLabel
                    variant="standard"
                    placeholder="Share with"
                    onChange={(e) => setPersonSearch(e.target.value)}
                  />
                )}
                filterOptions={(options) =>
                  personAddMenu && !isFetchingUsers
                    ? options.filter(
                        (o) =>
                          !props.persons?.find(
                            (p) => p.personId === (o as ModelPerson)?.personId
                          ) &&
                          !props.connectionGroups?.find((c) => c.id === o?.id)
                      )
                    : []
                }
                renderOption={(prop, option) => {
                  if ((option as ModelPerson)?.personId) {
                    return (
                      <CalendarAttendee
                        {...prop}
                        key={(option as ModelPerson).personId}
                        person={option as ModelPerson}
                        onClick={() => {
                          if (props.action == "create") {
                            props.setPersons &&
                              props.setPersons((prevPersons: ModelUser[]) => [
                                ...prevPersons,
                                option as ModelUser
                              ]);
                          }
                          if (props.action == "edit") {
                            const person = {};
                            person["person"] = option;
                            props.setPersons &&
                              props.setPersons((prevPersons: ModelUser[]) => [
                                ...prevPersons,
                                person
                              ]);
                            if (props.onChange) props.onChange(props.persons);
                          }
                        }}
                      />
                    );
                  }
                  if (option?.id) {
                    return (
                      <CalendarConnectionGroupSearch
                        {...prop}
                        key={option.id}
                        connectionGroup={option as ConnectionGroup}
                        onClick={() => {
                          if (option.kind === "Organization") {
                            props.setConnectionGroups &&
                              props.setConnectionGroups([
                                option as ConnectionGroup
                              ]);
                            props.setPersons && props.setPersons([]);
                            if (props.onChange)
                              props.onChange(props.connectionGroups);
                            return;
                          }
                          props.setConnectionGroups &&
                            props.setConnectionGroups(
                              (prevConnection: ConnectionGroup[]) => [
                                ...prevConnection,
                                option as ConnectionGroup
                              ]
                            );
                          if (props.onChange)
                            props.onChange(props.connectionGroups);
                        }}
                      />
                    );
                  }
                }}
                options={connectionGroupOptions
                  .filter(
                    (cgo) =>
                      cgo &&
                      (cgo?.name
                        ?.toLowerCase()
                        ?.includes(personSearch.toLowerCase()) ||
                        cgo.description
                          ?.toLowerCase()
                          ?.includes(personSearch.toLowerCase()))
                  )
                  //@ts-ignore
                  .concat(personOptions)}
                loading={isFetchingUsers}
                loadingText="Loading..."
                disabled={props.connectionGroups?.find(
                  (cg) => cg.kind === "Organization"
                )}
              />
            )}
          />
        </>
      </div>
      <div
        style={{
          marginLeft: "35px",
          marginTop: "10px",
          maxHeight: "160px",
          overflow: "auto"
        }}
      >
        {props.action === "create" && (
          <div>
            {props.persons.map((person, index) => (
              <div
                style={{ marginTop: "10px" }}
                key={index}
                data-testId={`shareWith-${person.personId}`}
              >
                {personAvatar(person, index)}
              </div>
            ))}
            {props.connectionGroups?.map((group, index) => (
              <div
                style={{ marginTop: "10px" }}
                key={index}
                data-testId={`shareWith-${group.id}`}
              >
                {connectionGroupAvatar(group, index)}
              </div>
            ))}
          </div>
        )}
        {props.action === "edit" && (
          <div>
            {props.persons
              ?.sort((a, b) => {
                if (a.person!.personId === loggedInUser?.person?.personId)
                  return -1;
                if (b.person!.personId === loggedInUser?.person?.personId)
                  return 1;
                return 0;
              })
              .map((person, index) => (
                <div
                  style={{ marginTop: "10px" }}
                  key={index}
                  data-testid={`share-with-${index}`}
                >
                  {personAvatar(person.person, index)}
                </div>
              ))}
            {props.connectionGroups?.map((group, index) => (
              <div style={{ marginTop: "10px" }} key={index}>
                {connectionGroupAvatar(group, index)}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

ShareWith.defaultProps = {
  required: false,
  disabled: false,
  action: "create"
};
