import { Button } from "@components/Button";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import { FormDatePicker } from "@components/FormDatePicker";
import { FormInput } from "@components/FormInput";
import {
  InputAdornment,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { formatCurrency } from "@utils/formatCurrency";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  AdvertiserInvoiceSchema,
  EditAdvertiserInvoiceInput,
  useAdminAdvertiseInvoiceInvoiceIdPut,
  useAdminAdvertiserInvoiceInvoiceIdDelete,
  useAdminAdvertiserInvoiceInvoiceIdGet,
  useAdminAdvertiserInvoiceInvoiceIdResendPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";
import { enqueueSnackbar } from "notistack";

const FlexBox = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const HeaderText = styled(Typography)`
  font-size: 20px;
  font-weight: 600;
  color: #1e293b;
  line-height: 30px;
`;
const TitleText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  color: #000;
  line-height: 24px;
`;
const DescriptionText = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  color: #666;
  line-height: 24px;
`;

const StyledTableCell = styled(TableCell)`
  padding: 8px;
  font-size: 16px;
  line-height: 24px;
`;

const StyledInput = styled("div")`
  width: 140px;
  input {
    padding: 8px;
  }
  label {
    display: none;
  }
  button {
    padding: 3px;
  }
  button svg {
    hwight: 20px;
    width: 20px;
  }
  .input {
    width: 70%;
  }
`;

const formatDate = (isoString: string | Date | undefined): string => {
  if (!isoString) return "";
  const date = new Date(isoString);
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

export const AdvertiserInvoiceView = () => {
  const navigate = useNavigate();
  const [mode, setMode] = useState<"view" | "edit">("view");
  const [invoiceDetails, setInvoiceDetails] =
    useState<AdvertiserInvoiceSchema | null>(null);
  const [invoiceToDelete, setInvoiceToDelete] =
    useState<AdvertiserInvoiceSchema | null>(null);

  const [searchParams] = useSearchParams();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const { invoiceId } = useParams();
  const {
    control,
    reset,
    formState: { isValid, isDirty }
  } = useForm({
    mode: "onTouched"
  });

  const { data: adveriserInvoice, isLoading: isLoading } =
    useAdminAdvertiserInvoiceInvoiceIdGet(invoiceId!);

  const { mutate: save, isLoading: isSaving } =
    useAdminAdvertiseInvoiceInvoiceIdPut();

  const { mutateAsync, isLoading: isDeleteing } =
    useAdminAdvertiserInvoiceInvoiceIdDelete();

  const { mutate, isLoading: isSendingInvoice } =
    useAdminAdvertiserInvoiceInvoiceIdResendPost();

  const onConfirmDelete = async () => {
    if (!invoiceToDelete?.invoiceId) return;
    try {
      await mutateAsync({ invoiceId: invoiceToDelete.invoiceId });
      enqueueSnackbar("Invoice deleted successfully", { variant: "success" });
      setInvoiceDetails(null);
      navigate("/advertiser-invoices");
    } catch (error) {
      enqueueSnackbar("Something went wrong! Unable to delete invoice", {
        variant: "error"
      });
      setInvoiceDetails(null);
    }
  };

  const saveHandler = () => {
    const values: EditAdvertiserInvoiceInput = {
      amountPreTax: invoiceDetails?.amountPreTax as number,
      taxAmount: invoiceDetails?.taxAmount as number,
      createdAt: invoiceDetails?.createdAt as Date
    };
    try {
      save(
        {
          invoiceId: invoiceId!,
          data: values
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Invoice edited successfully!", {
              variant: "success"
            });
            setOpenCancelDialog(false);
            setMode("view");
          },
          onError: () => {
            enqueueSnackbar("Failed to edit invoice!", {
              variant: "error"
            });
          }
        }
      );
    } catch (error) {
      enqueueSnackbar("Failed to edit invoice!", {
        variant: "error"
      });
    }
  };
  useEffect(() => {
    if (searchParams.get("mode")) {
      setMode(searchParams.get("mode") as "edit" | "view");
    }
  }, [searchParams]);

  useEffect(() => {
    const invoice = adveriserInvoice?.data;
    if (invoice) {
      setInvoiceDetails(invoice);
      reset({
        createdAt: new Date(invoice.createdAt as Date),
        amount: invoice.amountPreTax
      });
      if (invoice.status === "PAID") setMode("view");
    }
  }, [adveriserInvoice]);

  return (
    <Container>
      <Loader isLoading={isLoading}>
        <Toolbar
          title={mode === "view" ? "View Invoice" : "Edit Invoice"}
          {...(mode === "view" && {
            backBtnClick: () => navigate("/advertiser-invoices"),
            ...(invoiceDetails?.status !== "PAID" && {
              editBtnClick: () => setMode("edit"),
              deleteBtnClick: () =>
                setInvoiceToDelete(
                  adveriserInvoice?.data as AdvertiserInvoiceSchema
                )
            }),

            additionalBtns: [
              {
                button: (
                  <Button
                    onClick={() => {
                      mutate(
                        { invoiceId: invoiceId! },
                        {
                          onSuccess: () => {
                            enqueueSnackbar("Invoice sent successfully", {
                              variant: "success"
                            });
                          },
                          onError: () => {
                            enqueueSnackbar("Failed to send invoice", {
                              variant: "error"
                            });
                          }
                        }
                      );
                    }}
                    isLoading={isSendingInvoice}
                    type="button"
                    variant="admin-secondary"
                  >
                    Send Invoice
                  </Button>
                )
              }
            ]
          })}
        />
        <Form>
          <FlexBox>
            <HeaderText>
              {invoiceDetails?.advertiser?.organization?.name}
            </HeaderText>
            <HeaderText>Invoice</HeaderText>
          </FlexBox>
          <FlexBox style={{ marginTop: "5px" }}>
            <DescriptionText>
              {invoiceDetails?.advertiser?.organization?.lines?.[0]},{" "}
              {invoiceDetails?.advertiser?.organization?.locality},{" "}
              {invoiceDetails?.advertiser?.organization?.province},{" "}
              {invoiceDetails?.advertiser?.organization?.country}
            </DescriptionText>
            <HeaderText>
              Payment Status:{" "}
              <span
                style={{
                  color:
                    invoiceDetails?.status === "PAID" ? "#1ABC9C" : "#E82C2C"
                }}
              >
                {invoiceDetails?.status === "PAID" ? "Paid" : "Unpaid"}
              </span>
            </HeaderText>
          </FlexBox>
          <FlexBox style={{ marginTop: "32px" }}>
            <FlexBox style={{ flexDirection: "column", gap: "4px" }}>
              <TitleText>Bill To:</TitleText>
              <DescriptionText>
                {invoiceDetails?.advertiser?.businessName}
              </DescriptionText>
              <DescriptionText>
                {invoiceDetails?.advertiser?.primaryContact?.firstName &&
                invoiceDetails?.advertiser?.primaryContact?.lastName
                  ? invoiceDetails?.advertiser?.primaryContact?.firstName +
                    " " +
                    invoiceDetails?.advertiser?.primaryContact?.lastName
                  : ""}
              </DescriptionText>
              <DescriptionText>
                {invoiceDetails?.advertiser?.primaryContact?.workPhone}
              </DescriptionText>
              <DescriptionText>
                {invoiceDetails?.advertiser?.primaryContact?.workEmail}
              </DescriptionText>
            </FlexBox>
            <FlexBox style={{ flexDirection: "column" }}>
              <FlexBox style={{ gap: "96px" }}>
                <TitleText>Invoice No.</TitleText>
                <DescriptionText>{invoiceDetails?.invoiceId}</DescriptionText>
              </FlexBox>
              <FlexBox style={{ alignItems: "center" }}>
                <TitleText>Date</TitleText>
                {mode === "view" ? (
                  <DescriptionText>
                    {invoiceDetails?.createdAt
                      ? formatDate(invoiceDetails.createdAt)
                      : ""}
                  </DescriptionText>
                ) : (
                  <StyledInput>
                    <FormDatePicker
                      control={control}
                      name="createdAt"
                      label=""
                      onChange={(e: unknown) => {
                        const date = e as Date;
                        setInvoiceDetails((prev) => ({
                          ...prev,
                          createdAt: new Date(date).toISOString()
                        }));
                      }}
                    />
                  </StyledInput>
                )}
              </FlexBox>
              <FlexBox>
                <TitleText>Due Date</TitleText>
                <DescriptionText>
                  {invoiceDetails?.sentAt
                    ? formatDate(invoiceDetails.sentAt)
                    : ""}
                </DescriptionText>
              </FlexBox>
              <FlexBox>
                <TitleText>Terms</TitleText>
                <DescriptionText>Due Upon Receipt</DescriptionText>
              </FlexBox>
            </FlexBox>
          </FlexBox>
          <TableContainer
            style={{ marginTop: "32px", border: "1px solid #B3B3B3" }}
          >
            <Table>
              <TableHead style={{ backgroundColor: "#F3F4F7", height: "40px" }}>
                <TableRow>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    QTY
                  </StyledTableCell>
                  <StyledTableCell style={{ width: "70%" }}>
                    Description
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ textAlign: "center", paddingLeft: "50px" }}
                  >
                    Amount
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  style={{
                    verticalAlign: "baseline"
                  }}
                >
                  <StyledTableCell
                    style={{
                      borderBottom: "1px solid #E5E5E5",
                      textAlign: "center"
                    }}
                  >
                    1
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ borderBottom: "1px solid #E5E5E5" }}
                  >
                    <FlexBox style={{ flexDirection: "column" }}>
                      <TitleText>
                        {invoiceDetails?.advertiser?.sponsorship?.name}{" "}
                        Sponsorship
                      </TitleText>
                      <ul style={{ marginLeft: "30px" }}>
                        <li>
                          <TitleText>Ad Placements</TitleText>
                          <ul
                            style={{
                              marginLeft: "30px",
                              listStyleType: "disc"
                            }}
                          >
                            {invoiceDetails?.advertiser?.sponsorship
                              ?.sponsoredByEnabled && (
                              <li>
                                Sponsored By Ad - Live Stream Event Feed Posts
                              </li>
                            )}
                            {invoiceDetails?.advertiser?.sponsorship
                              ?.outStreamEnabled && (
                              <li>Out-Stream Ad - View Live Stream Screen</li>
                            )}
                            {invoiceDetails?.advertiser?.sponsorship
                              ?.inStreamEnabled && (
                              <li>
                                In-Stream Ad - Live Streaming Intermissions
                              </li>
                            )}
                          </ul>
                        </li>
                        <li>
                          <TitleText>
                            Ads will run on{" "}
                            <span style={{ fontWeight: 600 }}>
                              {invoiceDetails?.advertiser?.organization?.name}
                            </span>{" "}
                            from {formatDate(invoiceDetails?.createdAt)} to{" "}
                            {formatDate(invoiceDetails?.createdAt)}
                          </TitleText>
                        </li>
                        <li>
                          <TitleText>Unlimited Impressions</TitleText>
                        </li>
                      </ul>
                    </FlexBox>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderBottom: "1px solid #E5E5E5",
                      textAlign: "center",
                      width: "20%",
                      paddingLeft: "50px"
                    }}
                  >
                    {mode === "view" ? (
                      <>
                        {" "}
                        {formatCurrency(invoiceDetails?.amountPreTax as number)}
                      </>
                    ) : (
                      <StyledInput
                        style={{
                          textAlign: "center",
                          display: "flex",
                          width: "100%",
                          justifyContent: "center"
                        }}
                      >
                        <FormInput
                          control={control}
                          name={`amount`}
                          label=""
                          type="text"
                          InputProps={{
                            inputMode: "decimal",
                            pattern: "[0-9]*[.,]?[0-9]*",
                            onInput: (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9.]/g,
                                ""
                              );
                              if (
                                (e.target.value.match(/\./g) || []).length > 1
                              ) {
                                e.target.value = e.target.value.slice(0, -1);
                              }
                            },

                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            )
                          }}
                          onChange={(e) => {
                            const amount = (
                              e as React.ChangeEvent<HTMLInputElement>
                            ).target.value;
                            setInvoiceDetails((prev) => {
                              return {
                                ...prev,
                                amountPreTax: Number(amount),
                                taxAmount: Number(amount) * 0.12
                              };
                            });
                          }}
                        />
                      </StyledInput>
                    )}
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <FlexBox style={{ justifyContent: "flex-end", gap: "40px" }}>
            <FlexBox style={{ flexDirection: "column" }}>
              <TitleText style={{ padding: "8px 12px" }}>Sub Total</TitleText>
              <TitleText style={{ padding: "8px 12px" }}>Tax (12%)</TitleText>
              <TitleText style={{ fontWeight: 600, padding: "8px 12px" }}>
                Total
              </TitleText>
            </FlexBox>
            <FlexBox
              style={{
                flexDirection: "column",
                border: "2px solid #E5E5E5",
                borderTop: "none",
                minWidth: "140px"
              }}
            >
              <TitleText style={{ padding: "8px 12px" }}>
                {formatCurrency(invoiceDetails?.amountPreTax as number)}
              </TitleText>
              <TitleText style={{ padding: "8px 12px" }}>
                {formatCurrency(invoiceDetails?.taxAmount as number)}
              </TitleText>
              <TitleText
                style={{
                  fontWeight: 600,
                  padding: "8px 12px",
                  borderTop: "2px solid #E5E5E5"
                }}
              >
                {formatCurrency(
                  (invoiceDetails?.amountPreTax as number) +
                    (invoiceDetails?.taxAmount as number)
                )}
              </TitleText>
            </FlexBox>
          </FlexBox>
        </Form>
        {mode === "edit" && (
          <Footer
            saveBtnClick={() => saveHandler()}
            cancelBtnClick={() => setOpenCancelDialog(true)}
            isLoading={isSaving}
            isDisabled={isSaving || !isValid || !isDirty}
          />
        )}
        <ConfirmationDialog
          title="Are you sure you want to cancel?"
          body="All of your current changes will be lost."
          open={openCancelDialog}
          close={() => setOpenCancelDialog(false)}
          onCancel={() => setOpenCancelDialog(false)}
          onConfirm={() => {
            setOpenCancelDialog(false);
            if (adveriserInvoice?.data) {
              setInvoiceDetails(adveriserInvoice?.data);
              reset({
                createdAt: new Date(adveriserInvoice.data.createdAt as Date),
                amount: adveriserInvoice.data.amountPreTax
              });
            }
            setMode("view");
          }}
          cancelBtnText="Cancel"
          confirmBtnText="Confirm"
        />
        <ConfirmationDialog
          open={!!invoiceToDelete}
          title="Delete Sport?"
          body={`Are you sure you want to delete ${invoiceToDelete?.invoiceId}?`}
          close={() => setInvoiceToDelete(null)}
          onConfirm={onConfirmDelete}
          onCancel={() => setInvoiceToDelete(null)}
          isConfirming={isDeleteing}
          confirmBtnVariant="admin-error"
          icon="error"
        />
      </Loader>
    </Container>
  );
};
