/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@components/crud/Accordian";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import { Toolbar as MUIToolbar, styled, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Grid from "@mui/material/Unstable_Grid2";
import {
  useAdminImportTmplProviderIdGet,
  useAdminImportTmplProviderIdVersionPost,
  useLookupSGField
} from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalizeEveryWord } from "@utils/capitalize";
import { setPropertyRecursive } from "@utils/objectFunctions";
import { useSnackbar } from "notistack";
import papaparse from "papaparse";
import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

const StyledCheckBoxCell = styled(TableCell)`
  div {
    justify-content: center;
  }
`;

export const ImportTemplateProviderVersionCreate = () => {
  const [usedFields, setUsedFields] = React.useState<string[]>([]);
  const [availableFields, setAvailableFields] = React.useState<string[]>([]);
  const [disabledFields, setDisabledFields] = React.useState<string[]>([]);
  const [fieldsSet, setFieldsSet] = React.useState<string[]>([]);
  const [openCancelDialog, setOpenCancelDialog] = React.useState(false);
  const [selectableFields, setSelectableFields] = React.useState<
    { label: string; value: string }[]
  >([]);
  const [fields, setFields] = React.useState<string[]>([]);
  const [csvFile, setCsvFile] = React.useState<File | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { providerId } = useParams();

  const { mutate, isLoading: isSaving } =
    useAdminImportTmplProviderIdVersionPost({});
  const { data: mappingResult, isFetched: mappingFetched } = useLookupSGField();
  const mapping = useMemo(() => mappingResult?.data || {}, [mappingResult]);

  const {
    data: importTemplateProvider,
    isFetched: importTemplateProviderFetched
  } = useAdminImportTmplProviderIdGet(providerId as string);

  const csvTemplateChangeHandler = (
    file: any,
    field: { onChange: (arg0: { target: { value: any } }) => void }
  ) => {
    if (!file) {
      setSelectableFields([]);
      setCsvFile(undefined);
      return;
    }

    papaparse.parse(file, {
      header: true,
      dynamicTyping: false,
      complete: (results: { meta: { fields: any[] } }, file: any) => {
        const fields =
          results?.meta?.fields?.map((v: any) => ({ label: v, value: v })) ||
          [];
        setFields((results?.meta?.fields as string[]) || []);
        setSelectableFields([...fields]);
        setCsvFile(file);
        field.onChange({ target: { value: file } });
      }
    });
    if (mapping) {
      const mappingDefaults: any = {};
      for (const key in mapping) {
        if (mapping[key].isMandatory) {
          fieldsSet.push(key);
          disabledFields.push(key);
          mappingDefaults[key] = {
            heading: mapping[key].isMandatory
          };
        }
        if (!mapping[key].isMandatory) {
          fieldsSet.push(key);
          mappingDefaults[key] = {
            heading: true
          };
        }
        for (const fieldKey in mapping[key].fields) {
          if (mapping[key].fields[fieldKey].isMandatory) {
            disabledFields.push(mapping[key].fields[fieldKey].key);
            availableFields.push(mapping[key].fields[fieldKey].key);
          }
          setPropertyRecursive(
            mappingDefaults,
            mapping[key].fields[fieldKey].key,
            {
              available: mapping[key].fields[fieldKey].isMandatory,
              isRequired: !mapping[key].fields[fieldKey].isEmptyAllowed,
              header: ""
            }
          );
        }
      }
      setValue("fieldMap", mappingDefaults);
    }
  };

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    // formState: { defaultValues },
    formState: { defaultValues, isValid },
    reset
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      csvTemplate: csvFile,
      description: "",
      providerName: "",
      version: "",
      fieldMap: {}
    }
  });

  useEffect(() => {
    const currentValues = getValues();

    const disabledFields: string[] = [];
    const availableFields: string[] = [];
    const fieldsSet: string[] = [];
    const mappingDefaults: any = {};
    for (const key in mapping) {
      if (mapping[key].isMandatory) {
        fieldsSet.push(key);
        disabledFields.push(key);
        mappingDefaults[key] = {
          heading: mapping[key].isMandatory
        };
      }
      if (!mapping[key].isMandatory) {
        fieldsSet.push(key);
        mappingDefaults[key] = {
          heading: true
        };
      }
      for (const fieldKey in mapping[key].fields) {
        if (mapping[key].fields[fieldKey].isMandatory) {
          disabledFields.push(mapping[key].fields[fieldKey].key);
          availableFields.push(mapping[key].fields[fieldKey].key);
        }
        setPropertyRecursive(
          mappingDefaults,
          mapping[key].fields[fieldKey].key,
          {
            available: mapping[key].fields[fieldKey].isMandatory,
            isRequired: !mapping[key].fields[fieldKey].isEmptyAllowed,
            header: ""
          }
        );
      }
    }
    setDisabledFields(disabledFields);
    setAvailableFields(availableFields);
    setFieldsSet(fieldsSet);
    reset({
      csvTemplate: csvFile,
      description:
        currentValues?.description || defaultValues?.description || "",
      providerName: importTemplateProvider?.data?.name,
      version: currentValues?.version || defaultValues?.version || "",
      fieldMap:
        Object.keys(currentValues?.fieldMap).length > 0
          ? currentValues?.fieldMap
          : Object.keys(defaultValues?.fieldMap || {}).length > 0
            ? defaultValues?.fieldMap
            : mappingDefaults
    });
  }, [importTemplateProviderFetched, selectableFields, mapping]);

  const resetUsedFields = (existingValue = "", newValue = "") => {
    // very ugly way to update usedFields
    const values = getValues("fieldMap");
    const usedFields = Object.keys(values).reduce((acc, key) => {
      Object.keys(values[key]).reduce((acc2, key2) => {
        if (
          values?.[key]?.[key2]?.header &&
          values?.[key]?.[key2]?.header !== existingValue
        ) {
          acc2.push(values[key][key2].header as string);
        }
        return acc2;
      }, acc);
      return acc;
    }, [] as string[]);
    if (newValue !== "" && !usedFields.includes(newValue)) {
      usedFields.push(newValue);
    }
    setUsedFields(usedFields);
  };

  const saveHandler = (formValues: {
    version: any;
    description: any;
    csvTemplate: any;
    fieldMap: any;
  }) => {
    for (const key in formValues.fieldMap) {
      for (const key2 in formValues.fieldMap[key]) {
        if (
          formValues.fieldMap[key][key2]?.header === "" ||
          formValues.fieldMap[key][key2]?.header === undefined
        ) {
          delete formValues.fieldMap[key][key2];
        } else {
          formValues.fieldMap[key][key2].available = true;
          formValues.fieldMap[key][key2].canBeEmpty =
            !formValues.fieldMap[key][key2].isRequired;
        }
      }
      if (Object.keys(formValues.fieldMap[key]).length === 0) {
        delete formValues.fieldMap[key];
      }
    }

    const importTemplateProvider = {
      version: formValues.version,
      description: formValues.description,
      csvTemplate: fields,
      fieldMap: formValues.fieldMap,
      fileName: csvFile?.name
    };
    mutate(
      { providerId: providerId as string, data: importTemplateProvider },
      {
        onSuccess: () => {
          enqueueSnackbar("Version created successfully!", {
            variant: "success"
          });
          navigate(`/import-template-providers/${providerId}`);
        },
        onError: () => {
          enqueueSnackbar("Failed to create version!", { variant: "error" });
        }
      }
    );
  };
  useEffect(() => {
    if (
      //@ts-ignore
      getValues("fieldMap.parent1.heading") == false &&
      //@ts-ignore
      getValues("fieldMap.parent2.heading") == false
    ) {
      //@ts-ignore
      setValue("fieldMap.player.email.available", true);
      //@ts-ignore
      setValue("fieldMap.player.email.isRequired", true);
      setAvailableFields((prev) => [...prev, "player.email"]);
      setDisabledFields((prev) => [...prev, "player.email"]);
      setDisabledFields((prev) => [
        ...prev,
        "fieldMap.player.email.isRequired"
      ]);

      //@ts-ignore
      setValue("fieldMap.player.phone.available", true);
      //@ts-ignore
      setValue("fieldMap.player.phone.isRequired", true);
      setAvailableFields((prev) => [...prev, "player.phone"]);
      setDisabledFields((prev) => [...prev, "player.phone"]);
      setDisabledFields((prev) => [
        ...prev,
        "fieldMap.player.phone.isRequired"
      ]);
    }
    if (
      //@ts-ignore
      getValues("fieldMap.parent1.heading") == true
    ) {
      //@ts-ignore
      setValue("fieldMap.player.email.available", false);
      //@ts-ignore
      setValue("fieldMap.player.email.isRequired", false);
      setAvailableFields((prev) => prev.filter((f) => f !== "player.email"));
      setDisabledFields((prev) => prev.filter((f) => f !== "player.email"));
      setDisabledFields((prev) =>
        prev.filter((f) => f !== "fieldMap.player.email.isRequired")
      );
      //@ts-ignore
      setValue("fieldMap.player.phone.available", false);
      //@ts-ignore
      setValue("fieldMap.player.phone.isRequired", false);
      setAvailableFields((prev) => prev.filter((f) => f !== "player.phone"));
      setDisabledFields((prev) => prev.filter((f) => f !== "player.phone"));
      setDisabledFields((prev) =>
        prev.filter((f) => f !== "fieldMap.player.phone.isRequired")
      );
    }
    if (
      //@ts-ignore
      getValues("fieldMap.parent1.heading") == false
    ) {
      //@ts-ignore
      setValue("fieldMap.parent2.heading", false);
      setFieldsSet((prev) => prev.filter((f) => f !== "parent2"));
      setDisabledFields((prev) => [...prev, "parent2"]);
    }
    if (
      //@ts-ignore
      getValues("fieldMap.parent1.heading") == true
    ) {
      setDisabledFields((prev) => prev.filter((f) => f != "parent2"));
    }
  }, [
    //@ts-ignore
    getValues("fieldMap.parent1.heading"),
    //@ts-ignore
    getValues("fieldMap.parent2.heading")
  ]);

  const tableHeader = () => {
    return (
      <TableHead sx={{ backgroundColor: "#F8FAFC" }}>
        <TableRow>
          <TableCell sx={{ width: "25%", textAlign: "center", paddingLeft: 5 }}>
            <TableSortLabel>
              <Typography variant="tableHeader">Available</Typography>
            </TableSortLabel>
          </TableCell>
          <TableCell sx={{ width: "25%" }}>
            <TableSortLabel>
              <Typography variant="tableHeader">SportsGravy Fields</Typography>
            </TableSortLabel>
          </TableCell>
          <TableCell sx={{ width: "25%" }}>
            <TableSortLabel>
              <Typography variant="tableHeader">
                Template File Columns
              </Typography>
            </TableSortLabel>
          </TableCell>
          <TableCell sx={{ width: "25%", textAlign: "center", paddingLeft: 5 }}>
            <TableSortLabel>
              <Typography variant="tableHeader">Value is Required</Typography>
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };
  const tableRowInput = (
    { name, isEmptyAllowed, isMandatory, key },
    disabled
  ) => {
    return (
      fieldsSet.includes(key.split(".")[0]) && (
        <TableRow key={key}>
          <StyledCheckBoxCell sx={{ width: "25%" }}>
            <FormCheckbox
              name={`fieldMap.${key}.available`}
              control={control}
              disabled={disabled || disabledFields.includes(key)}
              onChange={(e) => {
                if (!e.target.checked) {
                  // @ts-ignore
                  setValue(`fieldMap.${key}.header`, "");
                  // @ts-ignore
                  setValue(`fieldMap.${key}.isRequired`, false);
                  setAvailableFields((prev) => prev.filter((f) => f !== key));
                  setFieldsSet(fieldsSet.filter((f) => f !== key));
                  resetUsedFields();
                  reset({
                    ...getValues(),
                    fieldMap: {
                      ...getValues().fieldMap,
                      [key]: {
                        ...getValues().fieldMap[key],
                        header: ""
                      }
                    }
                  });
                } else {
                  setAvailableFields((prev) => [...prev, key]);
                }
              }}
            />
          </StyledCheckBoxCell>
          <TableCell sx={{ width: "25%" }}>
            <Typography variant="body1">{name}</Typography>
          </TableCell>
          <TableCell
            data-testid={"Import_tpl_add_ver_select_" + key}
            sx={{ width: "25%" }}
          >
            <FormSelect
              name={`fieldMap.${key}.header`}
              options={selectableFields.filter(
                (f) =>
                  f.value === "" ||
                  // @ts-ignore
                  f.value === getValues(`fieldMap.${key}.header`) ||
                  !usedFields.includes(f.value)
              )}
              control={control}
              required={
                !disabled && (!!isMandatory || availableFields.includes(key))
              }
              disabled={disabled || !availableFields.includes(key)}
              rules={
                !disabled && isMandatory
                  ? {
                      required: `${name} is required`
                    }
                  : !disabled && availableFields.includes(key)
                    ? {
                        required: `${name} is required when marked as available ${availableFields.includes(
                          key
                        )}`
                      }
                    : {}
              }
              onChange={(e) => {
                const existingValue = getValues(
                  // @ts-ignore
                  `fieldMap.${key}.header`
                ) as string;
                const newValue = e.target.value;

                if (newValue === "") {
                  setFieldsSet(fieldsSet.filter((f) => f !== key));
                } else if (!fieldsSet.includes(key)) {
                  setFieldsSet([...fieldsSet, key]);
                }
                // @ts-ignore
                setValue(`fieldMap.${key}.header`, newValue);
                resetUsedFields(existingValue, newValue);
              }}
            />
          </TableCell>
          <StyledCheckBoxCell sx={{ width: "25%" }}>
            {key != "player.suffix" &&
              key != "parent1.middleName" &&
              key != "parent2.middleName" &&
              key != "parent1.secondaryPhone" &&
              key != "parent2.secondaryPhone" && (
                <FormCheckbox
                  name={`fieldMap.${key}.isRequired`}
                  control={control}
                  disabled={
                    [
                      "parent2.firstName",
                      "parent2.lastName",
                      "parent2.email"
                    ].includes(key)
                      ? false
                      : !isEmptyAllowed ||
                        isMandatory ||
                        disabled ||
                        disabledFields.includes(key) ||
                        !getValues(`fieldMap.${key}.available`)
                  }
                />
              )}
          </StyledCheckBoxCell>
        </TableRow>
      )
    );
  };

  return (
    <Container>
      <Toolbar title="Add Version for Import Template Provider" />
      <Form>
        <Grid container spacing={3}>
          <Grid xs={12} data-testid="importTpl_provider_name">
            <FormInput
              control={control}
              disabled={true}
              name="providerName"
              type="text"
              label="Template Provider Name"
            />
          </Grid>
          <Grid xs={12} data-testid="importTpl_ver_name">
            <FormInput
              control={control}
              name="version"
              type="text"
              onChange={(e) => {
                setValue("version", capitalizeEveryWord(e.target.value));
              }}
              label="Version Name"
              required={true}
              rules={{ required: "Version Name is required" }}
            />
          </Grid>
          <Grid xs={12} data-testid="importTpl_ver_desc">
            <FormInput
              control={control}
              name="description"
              type="text"
              label="Description"
              multiline
              InputProps={{
                rows: 3
              }}
            />
          </Grid>
          <Grid xs={12} data-testid="importTpl_ver_tempFile">
            <FormInput
              control={control}
              name="csvTemplate"
              type="file"
              label="Template File"
              required={true}
              rules={{ required: "Template File is required" }}
              // @ts-ignore
              onChange={csvTemplateChangeHandler}
              value={csvFile}
              InputProps={{
                accept: "text/csv"
              }}
            />
          </Grid>
          <Grid xs={12} container spacing={1}>
            {mappingFetched &&
              selectableFields.length > 0 &&
              Object.keys(mapping).length &&
              Object.keys(mapping).map((key, index) => {
                return (
                  <Grid xs={12} key={`${index}_${key}`}>
                    <Accordion expanded={fieldsSet.includes(key)}>
                      <AccordionSummary>
                        <MUIToolbar
                          sx={{
                            padding: "0 !important"
                          }}
                        >
                          <FormCheckbox
                            label={mapping[key].name}
                            labelTypographyProps={{
                              variant: "h6",
                              sx: { opacity: 0.6 }
                            }}
                            name={`fieldMap.${key}.heading`}
                            control={control}
                            disabled={disabledFields.includes(key)}
                            onChange={(e) => {
                              if (!e.target.checked) {
                                setFieldsSet(
                                  fieldsSet.filter((f) => f !== key)
                                );
                                for (const fieldKey in mapping[key].fields) {
                                  setValue(
                                    // @ts-ignore
                                    `fieldMap.${mapping[key].fields[fieldKey].key}.header`,
                                    ""
                                  );
                                  setValue(
                                    // @ts-ignore
                                    `fieldMap.${mapping[key].fields[fieldKey].key}.isRequired`,
                                    false
                                  );
                                  setValue(
                                    // @ts-ignore
                                    `fieldMap.${mapping[key].fields[fieldKey].key}.available`,
                                    false
                                  );
                                }
                                resetUsedFields();
                              } else {
                                for (const fieldKey in mapping[key].fields) {
                                  setValue(
                                    // @ts-ignore
                                    `fieldMap.${mapping[key].fields[fieldKey].key}.isRequired`,
                                    mapping[key].fields[fieldKey].isMandatory
                                  );
                                  setValue(
                                    // @ts-ignore
                                    `fieldMap.${mapping[key].fields[fieldKey].key}.available`,
                                    mapping[key].fields[fieldKey].isMandatory
                                  );
                                }

                                if (!fieldsSet.includes(key)) {
                                  setFieldsSet([...fieldsSet, key]);
                                }
                              }
                            }}
                          />
                        </MUIToolbar>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table>
                          {tableHeader()}
                          <TableBody
                            sx={{
                              "& tr": {
                                borderBottom:
                                  "2px solid rgba(226, 232, 240, .75)"
                              },
                              "& tr:last-child": { borderBottom: 0 }
                            }}
                          >
                            {Object.keys(mapping[key].fields).map(
                              (fieldKey: string) =>
                                tableRowInput(
                                  mapping[key].fields[fieldKey],
                                  !fieldsSet.includes(key)
                                )
                            )}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler)}
        isDisabled={!isValid || isSaving}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes to this version will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate(`/import-template-providers/${providerId}`)}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
